/* eslint-disable guard-for-in */
import { get } from 'lodash';
import { importManhrsActionTypes } from '../actions';

const InitialState = {};

export const importManhrsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case importManhrsActionTypes.GET_MANHRS_INFO_FORM:
      return { ...state };
    case importManhrsActionTypes.GET_MANHRS_INFO_FORM_SUCCESS: {
      const formatedData = get(action, 'payload.tableData.body', []);
      return { ...state, ...action.payload, tableData: { ...action.payload.tableData, body: formatedData } };
    }
    case importManhrsActionTypes.GET_MANHRS_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case importManhrsActionTypes.SAVE_MANHRS_INFO_FORM:
      return { ...state, ...action.data };
    case importManhrsActionTypes.SAVE_MANHRS_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case importManhrsActionTypes.SAVE_MANHRS_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
