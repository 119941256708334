import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { getFormValues } from 'redux-form';
import { get } from 'lodash';
import SideMenuList from '../../components/common/sideMenuList';
import ProjectTopbar from '../../components/projectTopbar';
import { projectSetupActions } from '../../redux/actions';
import DefineActivities from '../../components/projectActivitiesForm/defineActivties/defineActivities';
import ActivityManhrsTable from '../../components/projectActivitiesForm/activityManhrsTable';
import { TOP_MENU_ID } from '../../constants/common';

function ProjectActivities(props) {
  const { projectDetails, submenuList, form } = props;
  const [discipline, setDiscipline] = React.useState();

  const updatedSideContent = [];
  const { routeProjectNumber } = useParams();

  React.useEffect(() => {
    if (projectDetails.projectNumber === undefined) {
      props.setProjectDetails({
        projectNumber: routeProjectNumber,
        selectedMenu: TOP_MENU_ID.PROJECT_ACTIVITIES,
        stateKey: 'setUpInProgress',
      });
      const submenuData = {
        projectNumber: routeProjectNumber,
        menuId: TOP_MENU_ID.PROJECT_ACTIVITIES,
      };
      props.getProjectSubMenu(submenuData);
    } else {
      const submenuData = {
        projectNumber: get(projectDetails, 'projectNumber', routeProjectNumber),
        menuId: get(projectDetails, 'selectedMenu', ''),
      };
      props.getProjectSubMenu(submenuData);
    }
    // clears the store object
    return () => {
      props.subMenuClear();
    };
  }, []);

  React.useEffect(() => {
    if (submenuList.disciplines) {
      const discipline = form.Discipline
        ? form.Discipline
        : submenuList?.disciplines && submenuList?.disciplines.length && submenuList?.disciplines.length > 1
        ? submenuList?.disciplines.filter((discipline) => discipline !== 'Management' && discipline !== 'PJC')[0]
        : submenuList.disciplines[0];
      setDiscipline(discipline);
    }
  }, [submenuList, form]);

  const list = get(submenuList, 'menuItems.subMenus', []);

  const contentBasedOnSubMenu = (data) => {
    let content;
    if (data.submenuName === 'Manhour Budgets')
      content = (
        <ActivityManhrsTable
          discipline={discipline}
          permissionLevel={data.rights}
          isComplete={data.isComplete}
          formKey={data.submenuName}
        />
      );
    else if (data.submenuName === 'Define Activities')
      content = (
        <DefineActivities permissionLevel={data.rights} isComplete={data.isComplete} formKey={data.submenuName} />
      );

    return content;
  };

  if (Array.isArray(list)) {
    list.forEach((data, index) => {
      const i = index;
      updatedSideContent[i] = {
        label: data.submenuName,
        isComplete: data.isComplete,
        submenuid: data.id,
        content: contentBasedOnSubMenu(data),
      };
    });
  }

  return (
    <div>
      <ProjectTopbar currentPage="Project Activities" />
      {updatedSideContent.length !== 0 && (
        <SideMenuList
          heading="Project Activities"
          optionsList={updatedSideContent}
          showDiscipline
          discipline={discipline}
        />
      )}
    </div>
  );
}

export const mapStateToProps = (state) => ({
  menuList: get(state, 'projectSetupReducer.menuList', {}),
  projectDetails: get(state, 'projectSetupReducer.projectDetails', {}),
  submenuList: get(state, 'projectSetupReducer.submenuList', {}),
  form: getFormValues('DefineActivities')(state) ? getFormValues('DefineActivities')(state) : {},
});

export const mapDispatchToProps = (dispatch) => ({
  getProjectSubMenu: (submenuData) => dispatch(projectSetupActions.getProjectSubMenu(submenuData)),
  subMenuClear: () => dispatch(projectSetupActions.clearProjectSubMenu()),
  setProjectDetails: (data) => dispatch(projectSetupActions.projectDetails(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectActivities));
