import { get, isEmpty } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import servicelist, { camundaService, getIterationChangeReportUrl } from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';
import { p6Actions, p6ActionTypes, commonActions, viewApproveProjectActions } from '../actions';

export function* getP6IterationsListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getP6Iterations'), {
      params: payload.data,
    });

    yield put(
      p6Actions.getIterationListSucceeded({
        isSuccceeded: true,
        p6IterationData: responseData.data,
      })
    );
    // get viewplan data payload
    // based on the iteration available loading gantt chart data
    if (get(payload, 'data.category', '') === 'viewplan') {
      let viewPlanPayloadData;
      const results = get(responseData, 'data.iterations', []);
      const newData = [];

      if (!isEmpty(results)) {
        results.forEach((item) => {
          // filtering item.engineeringReview false && item.versionType === 'base' remove from list
          if (
            (item.engineeringReview && item.versionType === 'base') ||
            item.versionType === 'updated' ||
            item.name === 'Preliminary Discipline Plan'
          ) {
            newData.push({ name: item.name, value: item.iterationId, info: item });
          }
        });
      }

      if (!isEmpty(newData)) {
        viewPlanPayloadData = {
          projectNumber: payload.data.projectNumber,
          discipline: payload.data.discipline,
          iterationId: newData[0].value,
          iterationName: newData[0].name,
          userEmail: payload.data.userEmail,
          pageNumber: payload.data.pageNumber,
        };
      } else {
        viewPlanPayloadData = {
          projectNumber: payload.data.projectNumber,
          discipline: payload.data.discipline,
          iterationId: payload.data.iterationId === 'Preliminary Discipline Plan' ? '' : payload.data.iterationId,
          iterationName: payload.data.iterationName,
          userEmail: payload.data.userEmail,
          pageNumber: payload.data.pageNumber,
        };
      }

      yield put(viewApproveProjectActions.getPlans(viewPlanPayloadData));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      p6Actions.getIterationListFailed({
        isSuccceeded: false,
        p6IterationData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getP6IterationsListStatus() {
  yield takeEvery([p6ActionTypes.GET_ITERATION_LIST], getP6IterationsListSaga);
}

export function* getP6ProjectListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, camundaService('getP6ProjectFiles'), payload.data);
    yield put(
      p6Actions.getP6ProjectListSucceeded({
        isSuccceeded: true,
        p6ProjectsListData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      p6Actions.getP6ProjectListFailed({
        isSuccceeded: false,
        p6ProjectsListData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getP6ProjectListStatus() {
  yield takeEvery([p6ActionTypes.GET_P6_PROJECT_LIST], getP6ProjectListSaga);
}

export function* addP6IterationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('addP6Iteration'), payload.data);
    yield put(
      p6Actions.addP6IterationSucceeded({
        isSuccceeded: true,
        p6IterationData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(p6Actions.addP6IterationFailed({ isSuccceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* addP6IterationStatus() {
  yield takeEvery([p6ActionTypes.ADD_P6_ITERATION], addP6IterationSaga);
}

export function* releaseP6IterationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('releaseP6Iteration'), payload.data);
    yield put(
      p6Actions.releaseP6IterationSucceeded({
        isSuccceeded: true,
        releasedIteration: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Notification was successfully sent.',
      })
    );
    yield put(
      p6Actions.getIterationList({
        projectNumber: payload.data.projectNumber,
        menuId: payload.data.menuId,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      p6Actions.releaseP6IterationFailed({
        isSuccceeded: false,
        releasedIteration: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* releaseP6IterationStatus() {
  yield takeEvery([p6ActionTypes.RELEASE_P6_ITERATION], releaseP6IterationSaga);
}

export function* getP6FormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('p6send'), {
      params: payload.data,
    });
    yield put(
      p6Actions.getP6formsucceeded({
        isSuccceeded: true,
        p6Data: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      p6Actions.getP6formfailed({
        isSuccceeded: false,
        p6Data: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getP6FormStatus() {
  yield takeEvery([p6ActionTypes.GET_P6_FORM], getP6FormSaga);
}

export function* sendP6NotificationSaga(payload) {
  // try {
  yield put(commonActions.showLoader(true));
  const responseData = null;

  yield put(
    p6Actions.sendP6notificationsucceeded({
      isSuccceeded: true,
      p6Data: responseData,
    })
  );
  yield put(commonActions.showLoader(false));
}

export function* sendP6NotificationStatus() {
  yield takeEvery([p6ActionTypes.SEND_P6_NOTIFICATION], sendP6NotificationSaga);
}

export function* exportP6TableSaga(payload) {
  // incase we need the error data from the api
  try {
    let params = payload.data.params;
    let endpoint = servicelist('p6Export');
    if(payload?.data?.params?.isIterationChangeReport ){
      params = '';
      endpoint = getIterationChangeReportUrl('projectSetup', payload.data.params.projectNumber, payload.data.params.iteration)
    }
    delete payload.data.params.isIterationChangeReport;
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, endpoint, {
      params,
      responseType: 'blob',
    });
    yield put(
      p6Actions.exportP6tablesucceeded({
        isSucceeded: true,
        exportData: responseData.data,
      })
    );
    const url = window.URL.createObjectURL(new Blob([responseData.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.data.fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(p6Actions.exportP6tablefailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* exportP6TableStatus() {
  yield takeEvery([p6ActionTypes.EXPORT_P6_TABLE], exportP6TableSaga);
}
