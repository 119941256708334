import { projectInformationActionTypes } from '../actions';

const InitialState = {
  additionalField: [],
  addField: false,
  newField: false,
  tempAdditionalFields: {},
};

export const projectInfoReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projectInformationActionTypes.GET_PROJECT_INFO_FORM:
      return { ...state };
    case projectInformationActionTypes.GET_PROJECT_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projectInformationActionTypes.GET_PROJECT_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case projectInformationActionTypes.ADD_FIELD:
      return { ...state, ...action.addField };
    case projectInformationActionTypes.NEW_FIELD:
      return { ...state, ...action.newField };
    case projectInformationActionTypes.TEMP_ADDITIONAL_FIELDS:
      return {
        ...state,
        tempAdditionalFields: action.tempAdditionalFields,
      };
    case projectInformationActionTypes.GET_ADDITIONAL_FIELD_FORM:
      return { ...state };
    case projectInformationActionTypes.GET_ADDITIONAL_FIELD_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projectInformationActionTypes.GET_ADDITIONAL_FIELD_FORM_FAILURE:
      return { ...state, ...action.payload };
    case projectInformationActionTypes.SAVE_PROJECT_FORM:
      return { ...state, ...action.data };
    case projectInformationActionTypes.SAVE_PROJECT_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projectInformationActionTypes.SAVE_PROJECT_FORM_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
