export const actionTypes = {
  LOGIN_AUTH: 'LOGIN_AUTH',
  LOGIN_AUTH_SUCCESS: 'LOGIN_AUTH_SUCCESS',
  LOGIN_AUTH_FAILURE: 'LOGIN_AUTH_FAILURE',
  LOGOUT_AUTH: 'LOGOUT_AUTH',
  LOGOUT_AUTH_SUCCESS: 'LOGOUT_AUTH_SUCCESS',
  LOGOUT_AUTH_FAILURE: 'LOGOUT_AUTH_FAILURE',
  MSAL_AUTH: 'MSAL_AUTH',
  MSAL_AUTH_SUCCESS: 'MSAL_AUTH_SUCCESS',
  MSAL_AUTH_FAILURE: 'MSAL_AUTH_FAILURE',
  MSAL_LOGOUT_AUTH: 'MSAL_LOGOUT_AUTH',
  MSAL_LOGOUT_AUTH_SUCCESS: 'MSAL_LOGOUT_AUTH_SUCCESS',
  MSAL_LOGOUT_AUTH_FAILURE: 'MSAL_LOGOUT_AUTH_FAILURE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
};

export const actions = {
  loginAuth: (data) => ({
    type: actionTypes.LOGIN_AUTH,
    data,
  }),
  loginAuthSucceeded: (content) => ({
    type: actionTypes.LOGIN_AUTH_SUCCESS,
    payload: content,
  }),
  loginAuthFailed: (content) => ({
    type: actionTypes.LOGIN_AUTH_FAILURE,
    payload: content,
  }),
  logoutAuth: () => ({
    type: actionTypes.LOGOUT_AUTH,
  }),
  logoutAuthSucceeded: () => ({
    type: actionTypes.LOGOUT_AUTH_SUCCESS,
  }),
  logoutAuthFailed: () => ({
    type: actionTypes.LOGOUT_AUTH_SUCCESS,
  }),
  msalAuth: (data) => ({
    type: actionTypes.MSAL_AUTH,
    data,
  }),
  msalAuthSucceeded: (content) => ({
    type: actionTypes.MSAL_AUTH_SUCCESS,
    payload: content,
  }),
  msalAuthFailed: (content) => ({
    type: actionTypes.MSAL_AUTH_FAILURE,
    payload: content,
  }),
  msalLogoutAuth: (data) => ({
    type: actionTypes.MSAL_LOGOUT_AUTH,
    data,
  }),
  msalLogoutAuthSucceeded: (content) => ({
    type: actionTypes.MSAL_LOGOUT_AUTH_SUCCESS,
    payload: content,
  }),
  msalLogoutAuthFailed: (content) => ({
    type: actionTypes.MSAL_LOGOUT_AUTH_FAILURE,
    payload: content,
  }),
  refreshToken: (data) => ({
    type: actionTypes.REFRESH_TOKEN,
    data,
  }),
  refreshTokenSucceeded: (content) => ({
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    payload: content,
  }),
};
