import { data } from '../../mocks/referenceDetail';

export const actionTypes = {
  // Overview
  GET_PROJECT_HOME_OVERVIEW: 'GET_PROJECT_HOME_OVERVIEW',
  GET_PROJECT_HOME_OVERVIEW_SUCCESS: 'GET_PROJECT_HOME_OVERVIEW_SUCCESS',
  GET_PROJECT_HOME_OVERVIEW_FAILURE: 'GET_PROJECT_HOME_OVERVIEW_FAILURE',
  // LOG TIME WIDGET
  GET_PROJECT_HOME_LOG_TIME_DATA: 'GET_PROJECT_HOME_LOG_TIME_DATA',
  GET_PROJECT_HOME_LOG_TIME_DATA_SUCCESS: 'GET_PROJECT_HOME_LOG_TIME_DATA_SUCCESS',
  GET_PROJECT_HOME_LOG_TIME_DATA_FAILURE: 'GET_PROJECT_HOME_LOG_TIME_DATA_FAILURE',
  SAVE_PROJECT_HOME_LOG_TIME_DATA: 'SAVE_PROJECT_HOME_LOG_TIME_DATA',
  SAVE_PROJECT_HOME_LOG_TIME_DATA_SUCCESS: 'SAVE_PROJECT_HOME_LOG_TIME_DATA_SUCCESS',
  SAVE_PROJECT_HOME_LOG_TIME_DATA_FAILURE: 'SAVE_PROJECT_HOME_LOG_TIME_DATA_FAILURE',
  // My Gigs
  GET_PROJECT_HOME_MY_GIGS: 'GET_PROJECT_HOME_MY_GIGS',
  GET_PROJECT_HOME_MY_GIGS_SUCCESS: 'GET_PROJECT_HOME_MY_GIGS_SUCCESS',
  GET_PROJECT_HOME_MY_GIGS_FAILURE: 'GET_PROJECT_HOME_MY_GIGS_FAILURE',
  // MY WORK ITEMS
  GET_PROJECT_HOME_MY_WORK_ITEMS: 'GET_PROJECT_HOME_MY_WORK_ITEMS',
  GET_PROJECT_HOME_MY_WORK_ITEMS_SUCCESS: 'GET_PROJECT_HOME_MY_WORK_ITEMS_SUCCESS',
  GET_PROJECT_HOME_MY_WORK_ITEMS_FAILURE: 'GET_PROJECT_HOME_MY_WORK_ITEMS_FAILURE',
};

export const actions = {
  getProjectHomeOverviewData: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_OVERVIEW,
    payload: data,
  }),
  getProjectHomeOverviewSuccess: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_OVERVIEW_SUCCESS,
    payload: data,
  }),
  getProjectHomeOverviewFailure: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_OVERVIEW_FAILURE,
    payload: data,
  }),
  getProjectHomeLogTimeData: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_LOG_TIME_DATA,
    data,
  }),
  getProjectHomeLogTimeDataSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_HOME_LOG_TIME_DATA_SUCCESS,
    payload: content,
  }),
  getProjectHomeLogTimeDataFailed: (content) => ({
    type: actionTypes.GET_PROJECT_HOME_LOG_TIME_DATA_FAILURE,
    payload: content,
  }),
  saveProjectHomeLogTimeData: (data) => ({
    type: actionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA,
    data,
  }),
  saveProjectHomeLogTimeDataSucceeded: (content) => ({
    type: actionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA_SUCCESS,
    payload: content,
  }),
  saveProjectHomeLogTimeDataFailed: (content) => ({
    type: actionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA_FAILURE,
    payload: content,
  }),
  // MY WORK ITEMS
  getProjectHomeMyWorkItemsData: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS,
    payload: data,
  }),
  getProjectHomeMyWorkItemsSuccess: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS_SUCCESS,
    payload: data,
  }),
  getProjectHomeMyWorkItemsFailure: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS_FAILURE,
    payload: data,
  }),
  getProjectHomeMyGigsData: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_GIGS,
    payload: data,
  }),
  getProjectHomeMyGigsSuccess: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_GIGS_SUCCESS,
    payload: data,
  }),
  getProjectHomeMyGigsFailure: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_MY_GIGS_FAILURE,
    payload: data,
  }),
};
