import { laminarScopeActionTypes } from '../actions';

const InitialState = {};

export const laminarScopeReducer = (state = InitialState, action) => {
  switch (action.type) {
    case laminarScopeActionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case laminarScopeActionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.REQUEST_ASSUMPTION_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.REQUEST_ASSUMPTION_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_REQUEST_ASSUMPTION_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION:
      return { ...state };

    case laminarScopeActionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_MISSING_IO_TABLE:
      return { ...state, updateIOSucceeded: false };

    case laminarScopeActionTypes.GET_MISSING_IO_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_MISSING_IO_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.UPDATE_MISSING_IO_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.UPDATE_MISSING_IO_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_GIG_INSTRUCTION_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_GIG_INSTRUCTION_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_OUTPUT_CHECKLIST_DATA_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_OUTPUT_CHECKLIST_DATA_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_LOG_TIME_DATA_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_LOG_TIME_DATA_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_GIG_LOG_TIME_HISTORY_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_GIG_LOG_TIME_HISTORY_FAILURE:
      return { ...state, ...action.payload };

    // Laminar Scope Actions Section
    case laminarScopeActionTypes.ASSIGN_GIG_REQUEST_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.ASSIGN_GIG_REQUEST_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GIGTRANSFER_REQUEST_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GIGTRANSFER_REQUEST_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GIGTRANSFER_REQUEST_CANCELLATION_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GIGTRANSFER_REQUEST_CANCELLATION_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.UPDATE_GIG_STATUS_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.UPDATE_GIG_STATUS_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_GIG_STATUS_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_GIG_STATUS_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_ACTION_DROPDOWN_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_ACTION_DROPDOWN_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.CLEAR_ACTION_DROPDOWN:
      return { ...state, actionDropdown: [] };

    case laminarScopeActionTypes.GET_USERS_DROPDOWN_SUCCESS:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_USERS_DROPDOWN_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_SUCCESS:
      return { ...state, ...action.payload };
    case laminarScopeActionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_EDIT_REQUIRED_INPUT_SUCCESS:
      return { ...state, ...action.payload };
    case laminarScopeActionTypes.SAVE_EDIT_REQUIRED_INPUT_FAILURE:
      return { ...state, ...action.payload };

    case laminarScopeActionTypes.SAVE_SELECT_INPUT_VERSION_SUCCESS:
      return { ...state, ...action.payload };
    case laminarScopeActionTypes.SAVE_SELECT_INPUT_VERSION_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
