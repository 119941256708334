import { put, takeEvery, call } from 'redux-saga/effects';
import { get, isEmpty, cloneDeep, omit } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist, {
  reviseValue,
  setReviseAssumption,
  getOutputList,
  submitOutputList,
  saveOutputData,
  saveInputData,
  getInputList,
  updateOutputData,
  getNonEngigOutputList,
  submitInputList,
  saveNonEngigOutput,
} from '../../services/serviceList';
import { commonActions, ioActions, ioActionTypes, enggExeProjectHomeActions } from '../actions';

export function* getIOFormSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    let responseData = '';
    if (payload.data.type === 'enggOutputTable' || payload.data.type === 'enggInputTable') {
      if (isEmpty(payload.data.filters) || (payload.data.isExcelExport && !payload.data.exportWithFilter)) {
        if(payload.data.isExcelExport && !payload.data.exportWithFilter) {
          responseData = yield call(AxiosInstance.post, servicelist(payload.data.type), payload.data, { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([responseData.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${payload.data.excelFileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else responseData = yield call(AxiosInstance.post, servicelist(payload.data.type), payload.data);
      } else if (payload.data.type === 'enggOutputTable') {
        if(payload.data.isExcelExport && payload.data.exportWithFilter) {
          responseData = yield call(AxiosInstance.post, servicelist('enggOutputTableFilter'), payload.data, { responseType: 'blob' });
          const url = window.URL.createObjectURL(new Blob([responseData.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${payload.data.excelFileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else responseData = yield call(AxiosInstance.post, servicelist('enggOutputTableFilter'), payload.data);
      } else {
        responseData = yield call(AxiosInstance.post, servicelist(payload.data.type), payload.data);
      }
    } else if (payload.data.type === 'getIOInput') {
      if (payload.data.isOnlyNotification) {
        responseData = yield call(AxiosInstance.post, servicelist(payload.data.type), {
          projectNumber: payload.data.projectNumber,
          submenuId: payload.data.submenuId,
          pageNumber: payload.data.pageNumber,
          filterQuery: get(payload, 'data.filterQuery', []),
        });
      } else {
        const filter = get(payload, 'data.filterQuery', []);
        const filterQuery = JSON.stringify(filter);

        const response = yield call(AxiosInstance.get, getInputList('projectSetup', payload.data.projectNumber), {
          params: {
            submenuId: payload.data.submenuId,
            pageNumber: payload.data.pageNumber,
            filterQuery,
          },
        });
        responseData = response.data;
      }
    } else if (payload.data.type === 'getIOOuput') {
      responseData = yield call(AxiosInstance.get, getOutputList('projectSetup', payload.data.projectNumber), {
        params: {
          submenuId: payload.data.submenuId,
          pageNumber: payload.data.pageNumber,
        },
      });
    } else if (payload.data.type === 'getNonLaminar' && !payload.data.isOnlyNotification) {
      const response = yield call(
        AxiosInstance.get,
        getNonEngigOutputList('projectSetup', payload.data.projectNumber),
        {
          params: {
            submenuId: payload.data.submenuId,
            pageNumber: payload.data.pageNumber,
            type: 'non-engig',
          },
        }
      );
      responseData = response.data;
    } else {
      responseData = yield call(AxiosInstance.get, servicelist(payload.data.type), {
        params: {
          projectNumber: payload.data.projectNumber,
          submenuId: payload.data.submenuId,
          pageNumber: payload.data.pageNumber,
        },
      });
    }

    if (!payload.data.isOnlyNotification) {
      if (payload.data.type === 'getIOOuput') {
        yield put(
          ioActions.getIOFormSucceeded({
            isSucceeded: true,
            ioTableData: responseData.data.data,
          })
        );
      } else if (payload.data.type === 'getNonLaminar') {
        yield put(
          commonActions.setNotificationCountNonLaminar({
            count: get(responseData.data, 'totalCount', 0),
            menu: 'nonLaminarOutput',
          })
        );
        yield put(
          ioActions.getIOFormSucceeded({
            isSucceeded: true,
            ioTableData: responseData.data,
          })
        );
      } else {
        if(!payload.data.isExcelExport)
        yield put(
          ioActions.getIOFormSucceeded({
            isSucceeded: true,
            ioTableData: responseData.data,
          })
        );
      }
    }

    if (payload.data.type === 'getIOInput') {
      yield put(
        commonActions.notificationCount({
          count: get(responseData.data, 'conflictCounts', 0),
          menu: 'inputOutput',
        })
      );
    } else if (payload.data.type === 'getNonLaminar' && payload.data.isOnlyNotification) {
      yield put(
        commonActions.setNotificationCountNonLaminar({
          count: get(responseData.data, 'totalCount', 0),
          menu: 'nonLaminarOutput',
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting data as null to remove previous success response if there's any
    yield put(ioActions.getIOFormFailed({ isSucceeded: false, ioTableData: null }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getIOFormStatus() {
  yield takeEvery([ioActionTypes.GET_IO_FORM], getIOFormSaga);
}

export function* saveIOFormSaga(payload) {
  try {

    yield put(commonActions.showLoader(true));
    const payloadData = payload?.data?.body || {};
    const payloadValues = { ...payloadData };
    delete payloadValues?.projectNumber;

    let responseData = '';
    if (payload.data.type === 'reviseValue') {
      const { projectNumber, outputInstanceId } = payload.data.body || {};
      const { data } = payload.data.body || {};
      responseData = yield call(AxiosInstance.post, reviseValue(projectNumber, outputInstanceId), data);
    } else if (payload.data.type === 'setReviseAssumption') {
      const { projectNumber, outputInstanceId } = payload.data.body || {};
      const { data } = payload.data.body || {};
      responseData = yield call(AxiosInstance.post, setReviseAssumption(projectNumber, outputInstanceId), data);
    } else if (payload.data.type === 'saveEnggOutputTable') {
      responseData = yield call(AxiosInstance.put, servicelist(payload.data.type), payloadData);
    } else if (payload.data.type === 'completeIOOutput') {
      const { projectNumber } = payload?.data?.body;
      const response = yield call(AxiosInstance.post, submitOutputList('projectSetup', projectNumber), payloadValues);
      responseData = response.data;
    } else if (payload.data.type === 'completeIOInput') {
      const { projectNumber } = payload?.data?.body;
      const response = yield call(AxiosInstance.post, submitInputList('projectSetup', projectNumber), payloadValues);
      responseData = response.data;
    } else if (payload.data.type === 'saveIOOutput') {
      const { projectNumber } = payload?.data?.body;
      const { id } = payload?.data;
      let response;

      if (payload?.data?.body?.modifiedData?.length > 0 || payload?.data?.body?.deletedRows?.length) {
        response = yield call(AxiosInstance.put, updateOutputData('projectSetup', projectNumber, id), payloadData);
      } else {
        response = yield call(AxiosInstance.post, saveOutputData('projectSetup', projectNumber), payloadData);
      }
      responseData = response.data;
    } else if (payload.data.type === 'saveIOInput') {
      const { projectNumber } = payload?.data?.body;
      const response = yield call(AxiosInstance.post, saveInputData('projectSetup', projectNumber), payloadValues);
      responseData = response.data;
    } else if (payload.data.type === 'saveNonLaminar') {
      const { projectNumber } = payload?.data?.body;
      const response = yield call(AxiosInstance.post, saveNonEngigOutput('projectSetup', projectNumber), payloadValues);
      responseData = response.data;
    } else {
      responseData = yield call(AxiosInstance.post, servicelist(payload.data.type), payloadData);
    }

    yield put(
      ioActions.saveIOFormSucceeded({
        isSucceeded: true,
        savedForm: responseData.data,
      })
    );

    // after a success save call a get call is done.
    if (payload.data.fetchType === 'myProjectHome') {
      const data = payload?.data?.myGigs || {
        projectNumber: payloadData.projectNumber,
        discipline: payloadData.discipline,
      };

      yield put(enggExeProjectHomeActions.getProjectHomeOverviewData(data));
      yield put(enggExeProjectHomeActions.getProjectHomeMyGigsData(data));
      yield put(enggExeProjectHomeActions.getProjectHomeMyWorkItemsData(data));
    } else if (payload.data.fetchType === 'enggOutputTable') {
      const omitList = ['modifiedData', 'deleteData', 'newData', 'data'];
      const getPayload = cloneDeep(omit(payloadData, omitList));
      yield put(ioActions.getIOForm(getPayload));
    } else {
      yield put(
        ioActions.getIOForm({
          projectNumber: payloadData.projectNumber || responseData.data.projectNumber,
          submenuId: payloadData.submenuId || responseData.data.submenuId,
          type: payload.data.fetchType,
          pageNumber: payload.data.pageNumber,
          filterQuery: get(payload, 'data.filterQuery', []),
        })
      );
    }
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
  } catch (e) {
    const { data, oldValue, oldManagerComments } = payload?.data?.body || {};
    const defineManagerComments = (isManagerBypassed, oldManagerComments, managerComments) => {
      /**
       *  A non-bypassed assumption with non-undefined manager comments OR bypassed assumption with current non "" manager comments
       *  -> Treated like a regular assumption with manager comments inputted so if the current manager comments are the same as the
       *  old ones, an error will be thrown.
       *  */
      if (
          ((isManagerBypassed === undefined || !isManagerBypassed) &&
              managerComments !== undefined) ||
          (isManagerBypassed && managerComments !== '')
      ) {
          return oldManagerComments === managerComments;
      }
      // True is returned since the other scenarios do not depend on the manager comments
      return true;
  };
    if (['reviseValue', 'setReviseAssumption'].includes(payload?.data?.type) && 
    data?.value === oldValue
     && defineManagerComments(payload.data.body.managerBypassed, oldManagerComments, data.managerComments)
     ){
      yield put(
        commonActions.infoPopup({
          messageId: 'Existing and new revision values are same',
          theme: 'alert-danger',
        })
      );
    } else if (payload?.data?.type === 'saveEnggOutputTable') {
      yield put(
        commonActions.infoPopup({
          messageId:
            e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
          theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
        })
      );
      const getPayload = cloneDeep(omit(payloadData, ['modifiedData', 'deleteData', 'newData', 'data']));
      yield put(ioActions.getIOForm(getPayload));
    } else if (payload?.data?.type === 'saveIOOutput') {
      let errorMessage = '';
      if (e.response && e.response.data && e.response.data.message) {
        errorMessage =
          e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
      }
      const infoPopupMessage = errorMessage;
      yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    } else if (payload?.data?.type === 'saveIOInput') {
      let errorMessage = '';
      if (e.response && e.response.data && e.response.data.message) {
        errorMessage =
          e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
      }
      const infoPopupMessage = errorMessage;
      yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    } else {
      yield put(
        commonActions.infoPopup({
          messageId:
            e?.response?.status === 409
              ? e?.response?.data?.errorMsg
              : 'Something went wrong. A system error has occurred.',
          theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
        })
      );
    }
    yield put(ioActions.saveIOFormFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* saveIOFormStatus() {
  yield takeEvery([ioActionTypes.SAVE_IO_FORM], saveIOFormSaga);
}

export function* getIOChangeSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload?.data?.body || {};
    const responseData = yield call(AxiosInstance.post, servicelist('getIOOutputInstances'), payloadData);

    yield put(
      ioActions.getIOChangeSucceeded({
        isSucceeded: true,
        ioChangeData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting data as null to remove previous success response if there's any
    yield put(ioActions.getIOChangeFailed({ isSucceeded: false, ioChangeData: null }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getIOChangeStatus() {
  yield takeEvery([ioActionTypes.GET_IO_CHANGE], getIOChangeSaga);
}

export function* getIODropdownSaga({ payload }) {
  try {
    yield put(commonActions.showLoader(true));

    const responseData = yield call(AxiosInstance.get, servicelist('enggOutputDropdown'), { params: payload });

    yield put(ioActions.getIODropdownSucceeded({ isSucceeded: true, ioDropdown: responseData.data }));
  } catch (e) {
    // setting data as null to remove previous success response if there's any
    yield put(ioActions.getIODropdownFailed({ isSucceeded: false, ioDropdown: null }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getIODropdownStatus() {
  yield takeEvery([ioActionTypes.GET_IO_DROPDOWN], getIODropdownSaga);
}
