import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import './styles.scss';

const BVPagination = (props) => {
  const {
    breakClassName,
    breakLinkClassName,
    previousClassName,
    previousLinkClassName,
    nextClassName,
    nextLinkClassName,
    previousLabel,
    nextLabel,
    breakLabel,
    pageCount,
    marginPagesDisplayed,
    pageRangeDisplayed,
    pageLinkClassName,
    activeClassName,
    onPageChange,
    containerClassName,
    subContainerClassName,
    pageClassName,
    forcePage,
  } = props;

  return (
    <ReactPaginate
      breakClassName={breakClassName}
      breakLinkClassName={breakLinkClassName}
      previousClassName={previousClassName}
      previousLinkClassName={previousLinkClassName}
      nextClassName={nextClassName}
      nextLinkClassName={nextLinkClassName}
      previousLabel={previousLabel}
      nextLabel={nextLabel}
      breakLabel={breakLabel}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      pageLinkClassName={pageLinkClassName}
      pageClassName={pageClassName}
      activeClassName={activeClassName}
      onPageChange={onPageChange}
      containerClassName={containerClassName}
      subContainerClassName={subContainerClassName}
      forcePage={forcePage}
    />
  );
};

BVPagination.propTypes = {
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  previousClassName: PropTypes.string,
  previousLinkClassName: PropTypes.string,
  nextClassName: PropTypes.string,
  nextLinkClassName: PropTypes.string,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  breakLabel: PropTypes.string,
  pageCount: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  pageLinkClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  onPageChange: PropTypes.func,
  containerClassName: PropTypes.string,
  subContainerClassName: PropTypes.string,
  forcePage: PropTypes.number,
};

BVPagination.defaultProps = {
  breakClassName: 'page-item',
  breakLinkClassName: 'page-link',
  previousClassName: 'page-item',
  previousLinkClassName: 'page-link',
  nextClassName: 'page-item',
  nextLinkClassName: 'page-link',
  previousLabel: 'Previous',
  nextLabel: 'Next',
  breakLabel: '...',
  pageCount: 20,
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 5,
  pageLinkClassName: 'page-link',
  pageClassName: 'page-item',
  activeClassName: 'active',
  onPageChange: () => null,
  containerClassName: 'pagination',
  subContainerClassName: 'pages pagination',
  forcePage: null,
};

export default BVPagination;
