import { enggRefLibraryActiontypes } from '../actions';

const InitialState = {};

export const enggRefLibraryReducer = (state = InitialState, action) => {
  switch (action.type) {
    case enggRefLibraryActiontypes.GET_REFERENCE_LIBRARY:
      return { ...state };
    case enggRefLibraryActiontypes.GET_REFERENCE_LIBRARY_SUCCESS:
      return { ...state, ...action.payload };
    case enggRefLibraryActiontypes.GET_REFERENCE_LIBRARY_FAILURE:
      return { ...state, ...action.payload };

    case enggRefLibraryActiontypes.SAVE_REFERENCE_LIBRARY:
      return { ...state };
    case enggRefLibraryActiontypes.SAVE_REFERENCE_LIBRARY_SUCCESS:
      return { ...state, ...action.payload };
    case enggRefLibraryActiontypes.SAVE_REFERENCE_LIBRARY_FAILURE:
      return { ...state, ...action.payload };

    // MANAGE_REFERENCE
    case enggRefLibraryActiontypes.GET_MANAGE_REFERENCE_LIBRARY:
      return { ...state };
    case enggRefLibraryActiontypes.GET_MANAGE_REFERENCE_LIBRARY_SUCCESS:
      return { ...state, ...action.payload };
    case enggRefLibraryActiontypes.GET_MANAGE_REFERENCE_LIBRARY_FAILURE:
      return { ...state, ...action.payload };

    // SAVE MANAGE REFERENCE
    case enggRefLibraryActiontypes.SAVE_MANAGE_REFERENCE_LIBRARY:
      return { ...state };
    case enggRefLibraryActiontypes.SAVE_MANAGE_REFERENCE_LIBRARY_SUCCESS:
      return { ...state, ...action.payload };
    case enggRefLibraryActiontypes.SAVE_MANAGE_REFERENCE_LIBRARY_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
