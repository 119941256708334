// This file contains all the parameters for MSAL to work.
// The application should run with just adding the clientid (app guid) of a previously registered application on AAD and your tenant id (tenant guid).
// You also must register http://localhost:3000/callback on your app registration redirect URLs.
import * as Msal from 'msal';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    redirectUri: (window.location.protocol + '//' + window.location.host + '/'),
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    // This configures where your cache will be stored
    cacheLocation: 'localStorage',
    // Set this to "true" if you are having issues on IE11 or Edge
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

// scopes for the login request
const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
  account: msalInstance.getAccount(),
};

// scopes to request an authorization bearer token to the Microsoft Graph API after authentication
const tokenRequestForGraphApi = {
  scopes: ['openid', 'profile', 'User.Read'],
  account: msalInstance.getAccount(),
};

export { loginRequest, tokenRequestForGraphApi, msalInstance };
