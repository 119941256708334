export const projActivityMock = {
  headers: [
    { key: 'dosActivity', value: 'Activity' },
    { key: 'usEngHours', value: 'US ENG Hours' },
    { key: 'usDsgnHours', value: 'US DSGN Hours' },
    { key: 'ousEngHours', value: 'OUS ENG Hours' },
    { key: 'ousDsgnHours', value: 'OUS DSGN Hours' },
    { key: 'totalENGHours', value: 'Total ENG Hours' },
    { key: 'totalDSGNHours', value: 'Total DSGN Hours' },
  ],
  fields: [
    {
      key: 'dosActivity',
      label: 'Activity',
      value: '',
      multipleValues: false,
      type: 'string',
      sortOrder: 1,
      isEditable: false,
      isDuplicateAllowed: false,
      validation: { required: true, maxLength: 256 },
    },
    {
      key: 'usEngHours',
      label: 'US ENG Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 2,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
    {
      key: 'usDsgnHours',
      label: 'US DSGN Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 3,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
    {
      key: 'ousEngHours',
      label: 'OUS ENG Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 4,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
    {
      key: 'ousDsgnHours',
      label: 'OUS DSGN Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 5,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
    {
      key: 'totalENGHours',
      label: 'Total ENG Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 6,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
    {
      key: 'totalDSGNHours',
      label: 'Total DSGN Hours',
      value: '',
      multipleValues: false,
      type: 'number',
      sortOrder: 7,
      isEditable: true,
      isDuplicateAllowed: true,
      validation: { required: true, maxLength: 6 },
    },
  ],
  firstRow: [
    {
      id: '58bbb91b-16f0-4df4-84da-a0a5d7892f97',
      dosActivity: 'Tie-In List and details',
      usEngHours: '55',
      usDsgnHours: '90',
      ousEngHours: '44',
      ousDsgnHours: '55',
      totalENGHours: '99',
      totalDSGNHours: '145',
    },
  ],
  body: {
    projectNumber: '123456',
    menuId: 'c7a3a315-aa7b-456f-b1d5-47fe5eb9625e',
    submenuId: 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b',
    dosActivity: 'Tie-In List and details',
    body: [
      {
        dosActivity: 'Project Activity One',
        ousDsgnHours: '50',
        ousEngHours: '40',
        totalDSGNHours: '130',
        totalENGHours: '81',
        usDsgnHours: '80',
        usEngHours: '41',
      },
      {
        dosActivity: 'Project Activity two',
        ousDsgnHours: '5',
        ousEngHours: '4',
        totalDSGNHours: '15',
        totalENGHours: '18',
        usDsgnHours: '10',
        usEngHours: '14',
      },
    ],
    id: 'a24312ff-d06b-4dee-97b7-4a1c76c8547e',
    _rid: 'YhciALI9OrhUCQAAAAAAAA==',
    _self: 'dbs/YhciAA==/colls/YhciALI9Org=/docs/YhciALI9OrhUCQAAAAAAAA==/',
    _etag: '"730045bc-0000-1800-0000-5fdb54d20000"',
    _attachments: 'attachments/',
    _ts: 1608209618,
  },
};
