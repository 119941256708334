export const users = {
  users: [
    {
      _id: '1',
      name: { first: 'RanVijay', last: 'Singh' },
    },
    {
      _id: '2',
      name: { first: 'Ramesh', last: 'Chandar' },
    },
    {
      _id: '3',
      name: { first: 'Manoj', last: 'Kumar' },
    },
    {
      _id: '4',
      name: { first: 'Abhijeet', last: 'Bidwe' },
    },
    {
      _id: '5',
      name: { first: 'VijayKumar', last: 'Murugesh' },
    },
    {
      _id: '6',
      name: { first: 'Nikhil', last: 'Singh' },
    },
    {
      _id: '7',
      name: { first: 'Sivaprasanna', last: 'Krishnamoorthy' },
    },
    {
      _id: '8',
      name: { first: 'Rohan', last: 'Mendu' },
    },
    {
      _id: '9',
      name: { first: 'Rajendraprasad', last: 'Pathi' },
    },
    {
      _id: '10',
      name: { first: 'Himani', last: 'Bhatnagar' },
    },
    {
      _id: '11',
      name: { first: 'Shilpa', last: 'Vepur' },
    },
    {
      _id: '12',
      name: { first: 'Prince', last: 'David' },
    },
    {
      _id: '13',
      name: { first: 'Prakul', last: 'Kumar' },
    },
  ],
};
