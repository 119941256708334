export const getDiscussionData = {
  actions: [
    {
      assignedTo: 'sam smith',
      isTransferred: true,
      gigStatus: 'Not Ready',
      isStartEnabled: false,
      actionGigStartName: 'start Gig',
    },
  ],
  longTime: [
    {
      spentTime: 'O hrs',
      total: '0 hrs',
    },
  ],
  gigInfo: {
    'Schedule Status': 'On Time',
    'Manhour Budget': '20 hrs',
    isEditable: true,
    'Forecast Start Date': {
      date: '12/17/20',
      isEditable: true,
    },
    'Forecast Finish Date': {
      date: '12/17/20',
      isEditable: true,
    },
    Charge: 'c0110',
    isReadMore: true,
  },
  discussion: {
    isEditable: true,
    isComplete: true,
    discussionId: '897999',
    comments: [
      {
        commentId: 'r7984e7tdfkjghdkjgfhw0498r',
        createdDate: '05/06/20',
        createdTime: '10:30 AM',
        createdBy: 'Mathew',
        markAsImportant: true,
        commentArea: 'User can mention someone like this @Sam Smith and it will trigger ...',
      },
      {
        commentId: 'r7984e7tdfkjghdkjgfhw0498r',
        createdDate: '05/06/20',
        createdTime: '10:30 AM',
        createdBy: 'Mathew',
        markAsImportant: true,
        commentArea: 'User can mention someone like this @Sam Smith and it will trigger ...',
      },
    ],
  },
  gigReferencess: {},
  qualityInformation: {},
  requiredInputs: {},
  gigInstruction: {
    isEditable: true,
    instruction: 'User can mention someone like this @Sam Smith and it will trigger',
  },
  outputCheckList: {
    isEditable: true,
    details: [
      {
        name: 'Textbox Type',
        type: 'textbox',
      },
      {
        name: 'Simple Label Type',
        type: 'none',
      },
      {
        name: 'Link Type',
        type: 'textbox',
      },
      {
        name: 'Dropdown Type',
        type: 'select',
      },
      {
        name: 'Numerical Value Type',
        type: 'fields',
      },
    ],
  },
};
