import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, masterDataGigDashboardActionTypes, masterDataGigDashboardActions } from '../actions';

export function* getMasterGigDashboardDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('previewGigDashboard'), payload.data);
    yield put(
      masterDataGigDashboardActions.getMasterGigDashboardDataSuccess({
        isSucceeded: true,
        previewGigDashboardData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataGigDashboardActions.getMasterGigDashboardDataFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterGigDashboardDataStatus() {
  yield takeEvery([masterDataGigDashboardActionTypes.GET_MASTER_GIG_DASHBOARD_DATA], getMasterGigDashboardDataSaga);
}
