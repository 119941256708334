import { put, takeEvery, call } from 'redux-saga/effects';
import servicelist from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';
import { projActivityAndFragStepsActions, projActivityAndFragStepsActionTypes, commonActions } from '../actions';

export function* getProjActivityAndFragStepsSaga(data) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('laminarScopeOverview'), {
      params: data.payload,
    });

    const setActive = (x, id) =>
      x.filter(
        (gig) => gig.gigID === id && (!data.payload?.giginstanceId || gig.giginstanceId === data.payload?.giginstanceId)
      ).length > 0;
    const activityData = {
      ...responseData.data,
      fragnetSteps: responseData.data.fragnetSteps.map((step) => ({
        ...step,
        isActive: setActive(step.gignets, data.id),
        gignets: step.gignets.map((gig) => ({
          ...gig,
          isGigActive: gig.gigID === data.id,
        })),
        // .sort((a, b) => {
        //   if (a.gigType < b.gigType) {
        //     return -1;
        //   }
        //   if (a.gigType > b.gigType) {
        //     return 1;
        //   }
        //   return 0;
        // }),
      })),
      // .sort((a, b) => {
      //   if (a.fragnetStepName < b.fragnetStepName) {
      //     return -1;
      //   }
      //   if (a.fragnetStepName > b.fragnetStepName) {
      //     return 1;
      //   }
      //   return 0;
      // }),
    };

    yield put(
      projActivityAndFragStepsActions.getProjActivityAndFragStepsSucceeded({
        projActivityData: activityData,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projActivityAndFragStepsActions.getProjActivityAndFragStepsFailed({
        projActivityData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* getProjActivityAndFragStepsStatus() {
  yield takeEvery(
    [projActivityAndFragStepsActionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS],
    getProjActivityAndFragStepsSaga
  );
}
