import { put, takeEvery, call } from 'redux-saga/effects';
import { getLaminarScopeGigInfoActions, getLaminarScopeGigInfoActionTypes, commonActions } from '../actions';
import servicelist from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';

export function* getLaminarScopeGigInfoSaga(data) {
  try {
    yield put(commonActions.showLoader(true));
    // const responseData = yield call(AxiosInstance.get,
    //   servicelist('getProjectDashboardGanttModal'), {
    //   params: data.payload,
    // });
    const responseData = yield call(AxiosInstance.post, servicelist('getProjectDashboardGanttModal'), data.payload);
    yield put(
      getLaminarScopeGigInfoActions.getLaminarScopeGigInfoSucceeded({
        gigInfoData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      getLaminarScopeGigInfoActions.getLaminarScopeGigInfoFailed({
        gigInfoData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getLaminarScopeGigInfoStatus() {
  yield takeEvery([getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO], getLaminarScopeGigInfoSaga);
}

export function* getLaminarScopeGigInfoDropDownSaga(data) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getGigInfoDropdown'), {
      params: data.payload,
    });
    yield put(
      getLaminarScopeGigInfoActions.getLaminarScopeGigInfoDropDownSucceeded({
        gigInfoDropDownData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      getLaminarScopeGigInfoActions.getLaminarScopeGigInfoDropDownFailed({
        gigInfoDropDownData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getLaminarScopeGigInfoDropDownStatus() {
  yield takeEvery(
    [getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN],
    getLaminarScopeGigInfoDropDownSaga
  );
}

export function* saveGetLaminarScopeGigInfoSaga(data) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('laminarScopeGigInfo'), data.payload);
    yield put(
      getLaminarScopeGigInfoActions.saveGetLaminarScopeGigInfoSuccess({
        gigInfoData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      getLaminarScopeGigInfoActions.saveGetLaminarScopeGigInfoFailure({
        gigInfoData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveGetLaminarScopeGigInfoStatus() {
  yield takeEvery([getLaminarScopeGigInfoActionTypes.SAVE_GET_LAMINAR_SCOPE_GIG_INFO], saveGetLaminarScopeGigInfoSaga);
}
