import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { enggExeProjectHomeActionTypes, enggExeProjectHomeActions, commonActions } from '../actions';

// GET OVERVIEW
export function* getProjectHomeOverviewSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    yield put(enggExeProjectHomeActions.getProjectHomeOverviewSuccess({ isSucceeded: true, overview: {} }));
    // reset data
    const responseData = yield call(AxiosInstance.post, servicelist('getEnggExeProjectHomeOverview'), payload.payload);
    yield put(
      enggExeProjectHomeActions.getProjectHomeOverviewSuccess({
        isSucceeded: true,
        overview: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggExeProjectHomeActions.getProjectHomeOverviewFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectHomeOverviewStatus() {
  yield takeEvery([enggExeProjectHomeActionTypes.GET_PROJECT_HOME_OVERVIEW], getProjectHomeOverviewSaga);
}

// GET LOG TIME
export function* getProjectHomeLogTmeSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('getEnggExeProjectHomeLogTime'), payload.data);
    yield put(
      enggExeProjectHomeActions.getProjectHomeLogTimeDataSucceeded({
        isSucceeded: true,
        logTimeData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggExeProjectHomeActions.getProjectHomeLogTimeDataFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectHomeLogTmeStatus() {
  yield takeEvery([enggExeProjectHomeActionTypes.GET_PROJECT_HOME_LOG_TIME_DATA], getProjectHomeLogTmeSaga);
}

// SAVE LOG TIME DATA
export function* saveProjectHomeLogTimeSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));

    const previousLogTimeData = Object.assign({}, payload.data.logTimeData);
    const index = previousLogTimeData.getNonGigActivityData.findIndex(
      (data) => data.name == payload.data.category || data.value == payload.data.category
    );

    previousLogTimeData.getNonGigActivityData[index].totalHours =
      Number(previousLogTimeData.getNonGigActivityData[index].totalHours) + Number(payload.data.logTime);

    delete payload.data.logTimeData;
    delete payload.data.logTime;

    const response = yield call(AxiosInstance.post, servicelist('saveEnggExeProjectHomeLogTime'), payload.data);
    yield put(
      enggExeProjectHomeActions.saveProjectHomeLogTimeDataSucceeded({
        isSucceeded: true,
        saveData: response.data,
        logTimeData: previousLogTimeData,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Your data has been saved',
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      enggExeProjectHomeActions.saveProjectHomeLogTimeDataFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveProjectHomeLogTimeStatus() {
  yield takeEvery([enggExeProjectHomeActionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA], saveProjectHomeLogTimeSaga);
}

// GET My Gigs
export function* getProjectHomeMyGigsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    yield put(enggExeProjectHomeActions.getProjectHomeMyGigsSuccess({ isSucceeded: true, myGigs: {} }));
    // reset data
    const responseData = yield call(AxiosInstance.get, servicelist('getEnggExeProjectHomeMyGigs'), {
      params: payload.payload,
    });
    yield put(
      enggExeProjectHomeActions.getProjectHomeMyGigsSuccess({
        isSucceeded: true,
        myGigs: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggExeProjectHomeActions.getProjectHomeMyGigsFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectHomeMyGigsStatus() {
  yield takeEvery([enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_GIGS], getProjectHomeMyGigsSaga);
}

// MY WORK ITEMS
export function* getProjectHomeMyWorkItemsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    yield put(enggExeProjectHomeActions.getProjectHomeMyWorkItemsSuccess({ isSucceeded: true, myWorkItemsData: [] }));
    // reset data
    const responseData = yield call(
      AxiosInstance.post,
      servicelist('getEnggExeProjectHomeMyWorkItems'),
      payload.payload
    );
    yield put(
      enggExeProjectHomeActions.getProjectHomeMyWorkItemsSuccess({
        isSucceeded: true,
        myWorkItemsData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggExeProjectHomeActions.getProjectHomeMyWorkItemsFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectHomeMyWorkItemsStatus() {
  yield takeEvery([enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS], getProjectHomeMyWorkItemsSaga);
}
