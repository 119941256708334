import { put, takeEvery, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, projectSetupActions, workflowDataActions, workflowDataActionTypes } from '../actions';

export function* getWorkflowDataSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    let responseData = {};
    if (payload.payload.projectNumber) {
      responseData = yield call(AxiosInstance.get, servicelist('getWorkflowData'), {
        params: {
          menuId: payload.payload.menuId,
          projectNumber: payload.payload.projectNumber,
        },
      });
    } else {
      responseData = yield call(AxiosInstance.get, servicelist('getWorkflowData'), {
        params: {
          menuId: payload.payload.menuId,
        },
      });
    }
    yield put(
      workflowDataActions.getWorkflowDataSucceeded({
        isSucceeded: true,
        workflowData: responseData.data[0],
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      workflowDataActions.getWorkflowDataFailed({
        isSucceeded: false,
        workflowData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getWorkflowDataStatus() {
  yield takeEvery([workflowDataActionTypes.GET_WORKFLOW_DATA], getWorkflowDataSaga);
}

export function* saveWorkflowSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.payload || {};
    const responseData = payloadData.data.id
      ? yield call(AxiosInstance.put, servicelist('getWorkflowData'), {
          ...payloadData,
          id: payloadData.data.id,
        })
      : yield call(AxiosInstance.post, servicelist('getWorkflowData'), payloadData);

    yield put(
      workflowDataActions.saveWorkflowDataSucceeded({
        isSucceeded: true,
        saveWorkflow: responseData,
      })
    );
    // after a succes save call a get call is done.
    const requestPayload = {
      projectNumber: responseData.data.projectNumber,
      menuId: responseData.data.menuId,
    };
    yield put(workflowDataActions.getWorkflowData(requestPayload));
    yield put(commonActions.infoPopup({ messageId: 'Your Workflow has been saved' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      workflowDataActions.saveWorkflowDataFailed({
        isSucceeded: false,
        saveWorkflow: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveWorkflowStatus() {
  yield takeEvery([workflowDataActionTypes.SAVE_WORKFLOW_DATA], saveWorkflowSaga);
}

export function* completeWorkflowSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.put, servicelist('workflowComplete'), payload.payload);
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', false);
    const stateKey = yield select(getStateKey);

    yield put(
      workflowDataActions.workflowCompleteDataSucceeded({
        isSucceeded: true,
        completeResponse: responseData.data,
      })
    );
    if (payload.payload.isComplete) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Your Workflow has been Completed',
        })
      );
    } else {
      yield put(commonActions.infoPopup({ messageId: 'You can Edit Workflow' }));
    }
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData.data.projectNumber,
        stateKey,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(workflowDataActions.workflowCompleteDataFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* completeWorkflowStatus() {
  yield takeEvery([workflowDataActionTypes.COMPLETE_WORKFLOW_DATA], completeWorkflowSaga);
}
