export const actionTypes = {
  GET_HELP_LINKS: 'GET_HELP_LINKS',
  GET_HELP_LINKS_SUCCESS: 'GET_HELP_LINKS_SUCCESS',
  GET_HELP_LINKS_FAILURE: 'GET_HELP_LINKS_FAILURE',
  SET_HELP_LINKS: 'SET_HELP_LINKS',
  SET_HELP_LINKS_SUCCESS: 'SET_HELP_LINKS_SUCCESS',
  SET_HELP_LINKS_FAILURE: 'SET_HELP_LINKS_FAILURE',
};

export const actions = {
  getHelpLinks: (data) => ({
    type: actionTypes.GET_HELP_LINKS,
    data,
  }),
  getHelpLinksSucceeded: (content) => ({
    type: actionTypes.GET_HELP_LINKS_SUCCESS,
    payload: content,
  }),
  getHelpLinksFailed: (content) => ({
    type: actionTypes.GET_HELP_LINKS_FAILURE,
    payload: content,
  }),

  setHelpLinks: (data) => ({
    type: actionTypes.SET_HELP_LINKS,
    data,
  }),
  setHelpLinksSucceeded: (content) => ({
    type: actionTypes.SET_HELP_LINKS_SUCCESS,
    payload: content,
  }),
  setHelpLinksFailed: (content) => ({
    type: actionTypes.SET_HELP_LINKS_FAILURE,
    payload: content,
  }),
};
