import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { commonActions } from './redux/actions';
import IconPack from './constants/IconPack';
import Loader from './assets/common/icon-loading.svg';
import Routes from './routes';
import ProjectNavbar from './components/navbar/navbar';
import withNetworkDetector from './hoc/withNetworkDetector';
import '@progress/kendo-theme-bootstrap/dist/all.scss';
import { getItemFromLocalStorage } from './helpers/localStorage';

function App(props) {
  const location = useLocation();
  const history = useHistory();
  const [fromProjectDashboard, setFromProjectDashboard] = useState(false);
  const laminarHistoryData = getItemFromLocalStorage('laminarGigHistory');
  const DEBUG = false;
  const guardEnabled = useRef(false);
  const disableWarning = useRef(false);
  const pushedLocation = useRef('');

  React.useEffect(() => {
    const isShow = location.state ? location.state.fromProjectDashboard : false;
    const localData = laminarHistoryData && JSON.parse(laminarHistoryData);
    const isShowHeader = localData && get(localData[localData.length - 1], 'fromProjectDashboard', false);
    setFromProjectDashboard(
      isShow || (isShowHeader && get(location, 'pathname', '').includes('/engineeringExecution/laminarScope'))
    );
  }, [location]);

  React.useEffect(
    () =>
      history.listen((location) => {
        if (location.pathname?.indexOf('project-dashboard') > -1 || location.pathname?.indexOf('laminarScope') > -1) {
          return;
        }
        if (sessionStorage.getItem('gantItem') !== undefined) {
          sessionStorage.removeItem('gantItem');
          sessionStorage.removeItem('scrollPosition');
          sessionStorage.removeItem('ganttScrollPosition');
          sessionStorage.removeItem('paginationStore');
          sessionStorage.removeItem('viewStore');
          sessionStorage.removeItem('viewScaleStore');
          sessionStorage.removeItem('stateColumnsStore');
          sessionStorage.removeItem('searchColumnsStore');
          sessionStorage.removeItem('ganttFilterStore');
          sessionStorage.removeItem('showLabelStore');
          sessionStorage.removeItem('dateShownStore');
          sessionStorage.removeItem('enableTextBoxStore');
          sessionStorage.removeItem('enableSubjectStore');
          sessionStorage.removeItem('afterApplyDateShownStore');
          sessionStorage.removeItem('afterApplyEnableTextBoxStore');
        }
      }),
    [history]
  );

  React.useEffect(() => {
    DEBUG && console.log('== useEffect start ==');
    DEBUG && console.log(`location: ${window.location.href}`);
    // Clear any listeners.
    window.onpopstate = null;

    if (window.location.pathname.indexOf('changeInstanceForm') !== -1) {
      // The changeInstanceForm page has its own "RouteLeavingGuard" component that
      // conflicts with this protection.
      return;
    }

    if (props.formEditing || props.modalPopupIsEdited) {
      DEBUG && console.log('form is edited');
      if (guardEnabled.current) {
        DEBUG && console.log('guard is enabled');
        if (pushedLocation.current !== window.location.href) {
          DEBUG && console.log('location changed while guard was active');
          // Location changed while guard was active which can happen if the
          // isEdited state isn't updated first or is stale
          disableWarning.current = true;
        }

        if (!disableWarning.current) {
          DEBUG && console.log('show alert');
          // Show warning message if guard is enabled and warning message hasn't been
          // temporarily disabled
          props.sendMessageDispatch({
            messageId: 'Save the form before leaving the page',
            theme: 'alert-danger',
          });
        }

        disableWarning.current = false;
      }

      if (window.location.href) {
        // Keep track of which location was pushed to the history stack
        pushedLocation.current = window.location.href;

        // Duplicate the current browser location / state and push it to the browser
        // history so that it is not lost in the event that the user pushes the back button.
        window.history.pushState(window.location.state, null, window.location.href);

        // Set guardEnabled flag to true to enable the warning message and also indicate that this
        // duplicate entry should later be removed once the form is no longer being edited.
        guardEnabled.current = true;
        DEBUG && console.log('guard enabled');
      }
    } else {
      DEBUG && console.log('form is not edited');
      const excludedPaths = ['changeInstanceForm', 'laminarScope', 'project-dashboard'];
      const isExcluded = excludedPaths.find((y) => window.location.pathname.indexOf(y) !== -1);

      if (guardEnabled.current) {
        DEBUG && console.log('guard is enabled - disable it');
        // Form is no longer being edited but guard is enabled. Disable it.
        guardEnabled.current = false;

        // Make sure that the browser location hasn't already changed, which can happen before this
        // hook has had a chance to fire.
        if (pushedLocation.current === window.location.href) {
          DEBUG && console.log('go back to previous history location');
          // The go(-1) function will trigger a location change. Temporarily disable the warning message
          // so that it does not appear for that operation in case the formEditing or modalPopupIsEdited
          // states are stale
          disableWarning.current = true;

          // Go back to previous location in the history stack so that the Back button will now
          // return to the previous location before the guard was enabled
          history.go(-1);
        } else {
          DEBUG && console.log('location changed before guard could be disabled - do not go back');
        }
      } else if (!isExcluded && window.location.href !== `${window.location.protocol}//${window.location.host}/`) {
        DEBUG && console.log('set refresh onpopstate');
        // Add listener that will refresh the page if the back button is pushed. This is only
        // needed for components that don't automatically refresh the view when the browser
        // location changes. Components that do this are excluded using excludedPaths.
        disableWarning.current = false;
        window.onpopstate = function (event) {
          setTimeout(() => {
            window.location.reload();
          }, 100);
        };
      } else {
        DEBUG && console.log('no guards or listeners enabled');
        disableWarning.current = false;
      }
    }

    DEBUG && console.log('== useEffect stop ==');
    DEBUG && console.log('');
  }, [props.formEditing, props.modalPopupIsEdited, location]);

  DEBUG &&
    useEffect(() => {
      document.title = window.location.href;
    });

  // If there are unsaved changes on the page, trigger a browser confirmation dialog asking the user if they
  // really want to leave when the window is about to be "unloaded", e.g. when Refresh is clicked, etc.
  React.useEffect(() => {
    if (props.formEditing || props.modalPopupIsEdited) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [props.formEditing, props.modalPopupIsEdited]);

  if (window.top.location.host !== window.location.host) {
    return null;
  }

  return (
    <div className="App">
      {!!props.showLoader && (
        <div className="global-loader">
          <img className="loader-img" src={Loader} alt="loader" />
        </div>
      )}
      {window.location.pathname !== '/' &&
        window.location.pathname.indexOf('masterData/gigDashboard') === -1 &&
        window.location.pathname.indexOf('masterData/preview') === -1 &&
        window.location.pathname !== '/notifications' &&
        !fromProjectDashboard && <ProjectNavbar />}
      <Routes theme={props.alertTheme} messageId={props.messageId} />
      <IconPack />
    </div>
  );
}

export const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer && state.authReducer.isLoggedIn,
  messageId: state.commonData && state.commonData.globalAlert.messageId,
  alertTheme: get(state, 'commonData.globalAlert.theme', 'alert-success'),
  showLoader: state.commonData && state.commonData.showLoader,
  formEditing: state.commonData && state.commonData.formEditing,
  modalPopupIsEdited: get(state, 'commonData.modalPopup.isEdited', false),
});

export const mapDispatchToProps = (dispatch) => ({
  sendMessageDispatch: (data) => dispatch(commonActions.infoPopup(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNetworkDetector(App)));
