import React, { useState, useEffect } from 'react';
import { BVModal } from '../baseComponents/BVModal';
import './styles.scss';

export default function withNetworkDetector(ComposedComponent) {
  const NetworkDetector = (props) => {
    const [isDisconnected, setIsDisconnected] = useState(false);
    const handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        return setIsDisconnected(false);
      }
      return setIsDisconnected(true);
    };

    useEffect(() => {
      handleConnectionChange();
      window.addEventListener('online', handleConnectionChange);
      window.addEventListener('offline', handleConnectionChange);
      return () => {
        window.removeEventListener('online', handleConnectionChange);
        window.removeEventListener('offline', handleConnectionChange);
      };
    }, []);

    return (
      <div id="networkDetector">
        {isDisconnected && (
          <BVModal
            showModal
            isOutSideDismiss={false}
            title="Connection Error"
            modalContent="Oops! Looks like your device is not connected to the Internet."
            backdropClassName="unselectable disable"
            className="unselectable"
          />
        )}
        <ComposedComponent {...props} />
      </div>
    );
  };
  return NetworkDetector;
}
