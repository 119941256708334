export const actionTypes = {
  GET_PROJECT: 'GET_PROJECT',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECT_FAILURE: 'GET_PROJECT_FAILURE',
};

export const actions = {
  getProject: (content) => ({
    type: actionTypes.GET_PROJECT,
    payload: content,
  }),
  getProjectSuccess: (content) => ({
    type: actionTypes.GET_PROJECT_SUCCESS,
    payload: content,
  }),

  getProjectFaliure: (content) => ({
    type: actionTypes.GET_PROJECT_FAILURE,
    payload: content,
  }),
};
