import { all, fork } from 'redux-saga/effects';

import { loginStatus, logoutStatus, msalStatus, msalLogoutStatus, refreshTokenStatus } from './auth';

import {
  createProjectStatus,
  getCreateProjectFieldsStatus,
  completeSubMenuStatus,
  getProjectMenuStatus,
  getProjectSubMenuStatus,
  getProjectInfoStatus,
  completeProjectFormMenuStatus,
} from './projectSetup';
import { getProjectHomeContentStatus } from './projectHome';

import { getWorkflowDataStatus, saveWorkflowStatus, completeWorkflowStatus } from './workflowData';

import { getWbsInfoFormStatus, saveWbsInfoFormStatus } from './wbsInformation';

import { getIOFormStatus, saveIOFormStatus, getIOChangeStatus, getIODropdownStatus } from './inputOutput';

import {
  watchAssignLeadsInformInfoFormSaga,
  watchAddingRolesFromSaga,
  watchSavingRolesFromSaga,
  watchAssignLeadsfetchInitialSaga,
  getAddRoleStatus,
  getAddRoleMaterStatus,
  saveRoleDataStatus,
} from './assignLeadsInformationSaga';

import { getManhrsInfoStatus, saveManhrsInfoStatus } from './importManhrs';

import {
  getManHourBudgetStatus,
  getManHourBudgetTableStatus,
  getProjActivitiesInfoStatus,
  saveProjActivitiesInfoStatus,
  getProjActivityByDisciplineStatus,
  getModifiedProjActivityListStatus,
  saveModifiedProjActivityListStatus,
  getProjActivityTableDataStatus,
  saveProjActivityTableDataStatus,
  saveProjActivityByDisciplineStatus,
  completeManHourBudgetListItemStatus,
  completeDefineActivityStatus,
  deleteProjActivityTableDataStatus,
} from './projectActivities';

import {
  getReferenceLibraryFormStatus,
  saveReferenceLibraryFormStatus,
  getReferenceLibraryCategoryStatus,
} from './referenceLibrary';

import { getAdditionalFieldFormStatus, getProjectInfoFormStatus, saveProjectFormStatus } from './projectInformation';

import {
  getDropdownDataStatus,
  getSubActivityListStatus,
  getCustomizeFragnetDataStatus,
  fetchFragnetStepsStatus,
  saveCustomizeFragnetDataStatus,
  getActivityInfoFormStatus,
  getFragnetStepsStatus,
  getGigInfoFormStatus,
  saveGigInfoFormStatus,
  getCustomizedGigStepsStatus,
  getCustomizeGignetFormStatus,
  saveCustomizeGignetFormStatus,
  watchFragnetStepInfoStatus,
  watchSaveFragnetStepInfoFromSaga,
  saveActivityInfoFormStatus,
  getGignetStepInfoStatus,
  saveGignetStepInfoStatus,
  saveLandingPageDataStatus,
  getLandingPageDataStatus,
  finishWorkflowStatus,
  updateActivityRelatedStatus,
  autoCompleteGigsStatus,
  completeGigWorkFlowsStatus,
  updateGigInformationMenuStatus,
} from './gigWorkflow';

import { getProjectStatus } from './landing';

import {
  getLaminarScopeGigInfoStatus,
  saveGetLaminarScopeGigInfoStatus,
  getLaminarScopeGigInfoDropDownStatus,
} from './gigInfo';

import { getProjActivityAndFragStepsStatus } from './projActivityAndFragSteps';

import {
  getLaminarScopeOverviewStatus,
  requestAssumptionStatus,
  saveGigInstructionStatus,
  saveRequestAssumptionStatus,
  bypassManagerRequestAssumptionStatus,
  getMissingIOTableStatus,
  saveLogTimeDataStatus,
  saveOutputChecklistDataStatus,
  assignGigRequestStatus,
  gigTransferRequestStatus,
  gigTransferRequestCancellationStatus,
  updateGigStatusStatus,
  getGigStatusStatus,
  getActionDropdownStatus,
  getUsersDropdownStatus,
  updateMissingIOTableStatus,
  getRequiredInputDropdownOptionsStatus,
  saveEditRequiredInputStatus,
  saveSelectInputVersionStatus,
  saveGigLogTimeHistoryStatus,
} from './laminarScope';

import {
  getP6FormStatus,
  sendP6NotificationStatus,
  exportP6TableStatus,
  addP6IterationStatus,
  getP6IterationsListStatus,
  releaseP6IterationStatus,
  getP6ProjectListStatus,
} from './p6';

import {
  watchGetIterationsDataStatus,
  watchProjectTeamUsersSaga,
  getProjectDashboardStatus,
  saveQuickLinksStatus,
  getDropdownQuicklinksStatus,
  watchPlotPlanSaving,
  getEngExecProjectInfoDropdownStatus,
  getEngExecProjectInfoFormStatus,
  saveEngExecProjectInfoFormStatus,
  getEngExecWbsInfoDropdownStatus,
  getEngExecWbsInfoTableStatus,
  saveEngExecWbsInfoTableStatus,
  watchProjectDashboardWidgetsData,
} from './projectDashboard';

import {
  getPlansStatus,
  watchGetfilterDataStatus,
  watchSaveDisciplineDataFromSaga,
  watchPlanCompleteReference,
} from './viewApproveProject';

import {
  getDiscussionDataStatus,
  getUserDataStatus,
  saveDiscussionDataStatus,
  getLaminarDiscussionStatus,
} from './discussionSaga';

import {
  getReferenceDataStatus,
  getReferenceDropdownDataStatus,
  saveReferenceStatus,
  deleteReferenceStatus,
} from './gigReferenceSaga';
import {
  getP6SyncFormStatus,
  getP6VersionsStatus,
  exportP6SyncTableStatus,
  getExcelExportEventTableDataStatus,
  exportArchiveTableToExcelStatus,
  addP6SyncIterationStatus,
  pushP6ToLaminarStatus,
} from './laminarP6Sync';

import {
  getEnggRefLibraryStatus,
  getEnggManageRefLibraryStatus,
  saveEnggManageRefLibraryStatus,
} from './enggExeRefLibrary';

import {
  getProjectHomeOverviewStatus,
  getProjectHomeLogTmeStatus,
  saveProjectHomeLogTimeStatus,
  getProjectHomeMyGigsStatus,
  getProjectHomeMyWorkItemsStatus,
} from './enggExeProjectHome';
import { getMasterGigDashboardDataStatus } from './masterGigDashboard';

import {
  getMasterQACountStatus,
  getMasterQAEditModeStatus,
  saveMasterQAEditModeStatus,
  resetMasterQAChangesStatus,
  getMasterDataTableStatus,
  saveMasterDataTableStatus,
  saveMasterQAChangesStatus,
  sendMasterToQAStatus,
  getMasterDataTableQAStatus,
  getMasterPreviewDataStatus,
  getMasterDataTableStatusStatus,
  changeMasterDataTableStatusStatus,
  // pullRelatedGigsStatus,
  // getChargeCodeByFragnetIdStatus,
} from './masterDataManager';

import {
  getSystemNotificationsStatus,
  saveSystemNotificationsStatus,
  getSystemNotificationLandingPage,
  getProjectsForNotification,
  getMoreProjectForNotification,
} from './systemNotifications';

import { getHelpLinksStatus, setHelpLinksStatus } from './helpLinks';
import { getProjectGigsTableStatus, massAssignGigRequestStatus } from './enggLaminarScope';
import { getAssumptionArchiveTableStatus } from './IOAssumptionArchive';

export default function* rootSaga() {
  yield all([
    fork(loginStatus),
    fork(logoutStatus),
    fork(msalStatus),
    fork(msalLogoutStatus),
    fork(refreshTokenStatus),
    fork(getCreateProjectFieldsStatus),
    fork(getProjectStatus),
    fork(createProjectStatus),
    fork(getProjectMenuStatus),
    fork(getProjectHomeContentStatus),
    fork(saveProjectFormStatus),
    fork(getProjectInfoFormStatus),
    fork(getAdditionalFieldFormStatus),
    fork(getProjectSubMenuStatus),
    fork(completeSubMenuStatus),
    fork(completeProjectFormMenuStatus),
    fork(getWbsInfoFormStatus),
    fork(saveWbsInfoFormStatus),
    fork(getIOFormStatus),
    fork(saveIOFormStatus),
    fork(getIOChangeStatus),
    fork(getIODropdownStatus),
    fork(watchAssignLeadsInformInfoFormSaga),
    fork(watchAddingRolesFromSaga),
    fork(watchSavingRolesFromSaga),
    fork(getReferenceLibraryFormStatus),
    fork(saveReferenceLibraryFormStatus),
    fork(getReferenceLibraryCategoryStatus),
    fork(getManhrsInfoStatus),
    fork(saveManhrsInfoStatus),
    fork(getProjActivitiesInfoStatus),
    fork(saveProjActivitiesInfoStatus),
    fork(getWorkflowDataStatus),
    fork(saveWorkflowStatus),
    fork(completeWorkflowStatus),
    fork(getAddRoleStatus),
    fork(getAddRoleMaterStatus),
    fork(saveRoleDataStatus),
    fork(getProjActivityByDisciplineStatus),
    fork(getModifiedProjActivityListStatus),
    fork(saveModifiedProjActivityListStatus),
    fork(getProjActivityTableDataStatus),
    fork(saveProjActivityTableDataStatus),
    fork(saveProjActivityByDisciplineStatus),
    fork(getManHourBudgetStatus),
    fork(getManHourBudgetTableStatus),
    fork(completeManHourBudgetListItemStatus),
    fork(completeDefineActivityStatus),
    fork(watchAssignLeadsfetchInitialSaga),
    fork(getP6FormStatus),
    fork(getP6IterationsListStatus),
    fork(addP6IterationStatus),
    fork(releaseP6IterationStatus),
    fork(getP6ProjectListStatus),
    fork(getLaminarScopeGigInfoStatus),
    fork(saveGetLaminarScopeGigInfoStatus),
    fork(getLaminarScopeGigInfoDropDownStatus),
    fork(getProjActivityAndFragStepsStatus),
    fork(sendP6NotificationStatus),
    fork(exportP6TableStatus),
    fork(getDropdownDataStatus),
    fork(getSubActivityListStatus),
    fork(getCustomizeFragnetDataStatus),
    fork(fetchFragnetStepsStatus),
    fork(saveCustomizeFragnetDataStatus),
    fork(getActivityInfoFormStatus),
    fork(getCustomizedGigStepsStatus),
    fork(getCustomizeGignetFormStatus),
    fork(saveCustomizeGignetFormStatus),
    fork(getFragnetStepsStatus),
    fork(getGignetStepInfoStatus),
    fork(saveGignetStepInfoStatus),
    fork(getGigInfoFormStatus),
    fork(saveGigInfoFormStatus),
    fork(watchFragnetStepInfoStatus),
    fork(watchSaveFragnetStepInfoFromSaga),
    fork(saveActivityInfoFormStatus),
    fork(saveLandingPageDataStatus),
    fork(getLandingPageDataStatus),
    fork(finishWorkflowStatus),
    fork(deleteProjActivityTableDataStatus),
    fork(getDiscussionDataStatus),
    fork(getLaminarDiscussionStatus),
    fork(getReferenceDataStatus),
    fork(getReferenceDropdownDataStatus),
    fork(saveReferenceStatus),
    fork(deleteReferenceStatus),
    fork(getUserDataStatus),
    fork(getPlansStatus),
    fork(watchGetfilterDataStatus),
    fork(saveDiscussionDataStatus),
    fork(getProjectInfoStatus),
    fork(completeGigWorkFlowsStatus),
    fork(updateGigInformationMenuStatus),
    // Laminar Scope
    fork(getLaminarScopeOverviewStatus),
    fork(watchPlanCompleteReference),
    fork(requestAssumptionStatus),
    fork(bypassManagerRequestAssumptionStatus),
    fork(saveRequestAssumptionStatus),
    fork(getMissingIOTableStatus),
    fork(updateMissingIOTableStatus),
    fork(saveGigInstructionStatus),
    fork(saveOutputChecklistDataStatus),
    fork(saveLogTimeDataStatus),
    fork(saveEditRequiredInputStatus),
    fork(getRequiredInputDropdownOptionsStatus),
    fork(getAssumptionArchiveTableStatus),
    fork(saveGigLogTimeHistoryStatus),

    // Laminar Scope Actions Section
    fork(assignGigRequestStatus),
    fork(gigTransferRequestStatus),
    fork(gigTransferRequestCancellationStatus),
    fork(updateGigStatusStatus),
    fork(getGigStatusStatus),
    fork(getActionDropdownStatus),
    fork(getUsersDropdownStatus),

    // viewplan complete
    fork(watchSaveDisciplineDataFromSaga),
    // project dashboard
    fork(watchGetIterationsDataStatus),
    fork(getProjectDashboardStatus),
    fork(saveQuickLinksStatus),
    fork(getDropdownQuicklinksStatus),
    fork(watchProjectTeamUsersSaga),
    fork(watchPlotPlanSaving),

    fork(watchProjectDashboardWidgetsData),

    // project dashboard project info
    fork(getEngExecProjectInfoDropdownStatus),
    fork(getEngExecProjectInfoFormStatus),
    fork(saveEngExecProjectInfoFormStatus),

    // project dashboard wbs info
    fork(getEngExecWbsInfoDropdownStatus),
    fork(getEngExecWbsInfoTableStatus),
    fork(saveEngExecWbsInfoTableStatus),

    // laminar Sync P6
    fork(getP6SyncFormStatus),
    fork(getP6VersionsStatus),
    fork(exportP6SyncTableStatus),
    fork(getExcelExportEventTableDataStatus),
    fork(exportArchiveTableToExcelStatus),
    fork(addP6SyncIterationStatus),
    fork(pushP6ToLaminarStatus),
    // Engg Exe Reference Library
    fork(getEnggRefLibraryStatus),
    fork(getEnggManageRefLibraryStatus),
    fork(saveEnggManageRefLibraryStatus),
    // Engg Exe project home
    fork(getProjectHomeOverviewStatus),
    fork(getProjectHomeLogTmeStatus),
    fork(saveProjectHomeLogTimeStatus),
    fork(getProjectHomeMyWorkItemsStatus),
    // system notifications
    fork(getSystemNotificationsStatus),
    fork(getSystemNotificationLandingPage),
    fork(getProjectsForNotification),
    fork(saveSystemNotificationsStatus),
    fork(getMoreProjectForNotification),

    fork(getProjectHomeMyGigsStatus),

    // Master Heirarchy
    // fork(getMasterHeirarchyTableStatus),
    // fork(getMasterOutputTableStatus),
    // fork(saveMasterHeirarchyTableStatus),

    // master Data QA
    fork(getMasterQACountStatus),
    fork(getMasterQAEditModeStatus),
    fork(saveMasterQAEditModeStatus),
    fork(resetMasterQAChangesStatus),
    fork(getMasterDataTableQAStatus),
    fork(saveMasterQAChangesStatus),

    fork(getMasterDataTableStatus),
    fork(saveMasterDataTableStatus),
    fork(sendMasterToQAStatus),
    fork(getMasterPreviewDataStatus),
    fork(getMasterGigDashboardDataStatus),
    fork(getMasterDataTableStatusStatus),
    fork(changeMasterDataTableStatusStatus),
    // fork(pullRelatedGigsStatus),
    // fork(getChargeCodeByFragnetIdStatus),
    // help links
    fork(getHelpLinksStatus),
    fork(setHelpLinksStatus),
    // projectGigs Laminar Scope
    fork(getProjectGigsTableStatus),
    fork(massAssignGigRequestStatus),
    fork(updateActivityRelatedStatus),
    fork(autoCompleteGigsStatus),
    fork(saveSelectInputVersionStatus),
  ]);
}
