import { assumptionArchiveActionTypes } from '../actions';

const InitialState = {};

export const assumptionArchiveTableReducer = (state = InitialState, action) => {
  switch (action.type) {
    case assumptionArchiveActionTypes.GET_ASSUMPTION_ARCHIVE_TABLE:
      return { ...state};
    case assumptionArchiveActionTypes.GET_ASSUMPTION_ARCHIVE_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case assumptionArchiveActionTypes.GET_ASSUMPTION_ARCHIVE_TABLE_FAILURE:
      return { ...state, ...action.payload };
      
    default:
      return state;
  }

  
};