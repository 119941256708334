export const actionTypes = {
  GET_WORKFLOW_DATA: 'GET_WORKFLOW_DATA',
  GET_WORKFLOW_DATA_SUCCESS: 'GET_WORKFLOW_DATA_SUCCESS',
  GET_WORKFLOW_DATA_FAILURE: 'GET_WORKFLOW_DATA_FAILURE',
  SAVE_WORKFLOW_DATA: 'SAVE_WORKFLOW_DATA',
  SAVE_WORKFLOW_DATA_SUCCESS: 'SAVE_WORKFLOW_DATA_SUCCESS',
  SAVE_WORKFLOW_DATA_FAILURE: 'SAVE_WORKFLOW_DATA_FAILURE',
  COMPLETE_WORKFLOW_DATA: 'COMPLETE_WORKFLOW_DATA',
  COMPLETE_WORKFLOW_DETAILS_SUCCESS: 'COMPLETE_WORKFLOW_DETAILS_SUCCESS',
  COMPLETE_WORKFLOW_DETAILS_FAILURE: 'COMPLETE_WORKFLOW_DETAILS_FAILURE',
};

export const actions = {
  getWorkflowData: (content) => ({
    type: actionTypes.GET_WORKFLOW_DATA,
    payload: content,
  }),
  getWorkflowDataSucceeded: (content) => ({
    type: actionTypes.GET_WORKFLOW_DATA_SUCCESS,
    payload: content,
  }),
  getWorkflowDataFailed: (content) => ({
    type: actionTypes.GET_WORKFLOW_DATA_FAILURE,
    payload: content,
  }),
  saveWorkflowData: (content) => ({
    type: actionTypes.SAVE_WORKFLOW_DATA,
    payload: content,
  }),
  saveWorkflowDataSucceeded: (content) => ({
    type: actionTypes.SAVE_WORKFLOW_DATA_SUCCESS,
    payload: content,
  }),
  saveWorkflowDataFailed: (content) => ({
    type: actionTypes.SAVE_WORKFLOW_DATA_FAILURE,
    payload: content,
  }),
  workflowComplete: (content) => ({
    type: actionTypes.COMPLETE_WORKFLOW_DATA,
    payload: content,
  }),
  workflowCompleteDataSucceeded: (content) => ({
    type: actionTypes.COMPLETE_WORKFLOW_DETAILS_SUCCESS,
    payload: content,
  }),
  workflowCompleteDataFailed: (content) => ({
    type: actionTypes.COMPLETE_WORKFLOW_DETAILS_FAILURE,
    payload: content,
  }),
};
