import { put, takeEvery, call } from 'redux-saga/effects';
import servicelist from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';
import { helpLinksActionTypes, helpLinksActions, commonActions } from '../actions';

export function* getHelpLinksSaga(payload) {
  try {
    const responseData = yield call(AxiosInstance.get, servicelist('getHelpLinks'), {
      params: payload.data,
    });
    yield put(
      helpLinksActions.getHelpLinksSucceeded({
        isSucceeded: true,
        helpLinksData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    helpLinksActions.getHelpLinksFailed({ isSucceeded: false });
  }
}
export function* getHelpLinksStatus() {
  yield takeEvery([helpLinksActionTypes.GET_HELP_LINKS], getHelpLinksSaga);
}

export function* setHelpLinksSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.post, servicelist('getHelpLinks'), payload.data);
    yield put(
      helpLinksActions.setHelpLinksSucceeded({
        isSucceeded: true,
        saveData: response.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Your changes have been saved',
      })
    );

    yield put(helpLinksActions.getHelpLinks());
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      helpLinksActions.setHelpLinksFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* setHelpLinksStatus() {
  yield takeEvery([helpLinksActionTypes.SET_HELP_LINKS], setHelpLinksSaga);
}
