import { helpLinksActionTypes } from '../actions';

const InitialState = {};

export const helpLinksReducer = (state = InitialState, action) => {
  switch (action.type) {
    case helpLinksActionTypes.GET_HELP_LINKS:
      return { ...state };
    case helpLinksActionTypes.GET_HELP_LINKS_SUCCESS:
      return { ...state, ...action.payload };
    case helpLinksActionTypes.GET_HELP_LINKS_FAILURE:
      return { ...state, ...action.payload };

    case helpLinksActionTypes.SET_HELP_LINKS:
      return { ...state };
    case helpLinksActionTypes.SET_HELP_LINKS_SUCCESS:
      return { ...state, ...action.payload };
    case helpLinksActionTypes.SET_HELP_LINKS_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
