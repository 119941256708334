import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { enggRefLibraryActions, enggRefLibraryActiontypes, commonActions } from '../actions';

export function* getEnggRefLibrarySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('enggReferenceLibrary'), {
      params: {
        projectNumber: payload.data.projectNumber,
      },
    });

    yield put(
      enggRefLibraryActions.getReferenceLibrarySucceeded({
        isSucceeded: true,
        refLibraryData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggRefLibraryActions.getReferenceLibraryFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getEnggRefLibraryStatus() {
  yield takeEvery([enggRefLibraryActiontypes.GET_REFERENCE_LIBRARY], getEnggRefLibrarySaga);
}

// MANAGE-REFERENCE
export function* getEnggManageRefLibrarySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('manageReferenceLibraryForm'), {
      params: payload.data,
    });
    yield put(
      enggRefLibraryActions.getManageReferenceLibrarySucceeded({
        isSucceeded: true,
        manageReferenceLibraryData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    enggRefLibraryActions.getManageReferenceLibraryFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getEnggManageRefLibraryStatus() {
  yield takeEvery([enggRefLibraryActiontypes.GET_MANAGE_REFERENCE_LIBRARY], getEnggManageRefLibrarySaga);
}
// SAVE MANAGE REFERENCE DATA
export function* saveEnggManageRefLibrarySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { pageNumber } = payload.data;
    delete payload.data.pageNumber;
    const response = yield call(AxiosInstance.put, servicelist('saveManageReferenceLibraryForm'), payload.data);
    yield put(
      enggRefLibraryActions.saveManageReferenceLibrarySucceeded({
        isSucceeded: true,
        saveData: response.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Your form has been saved',
      })
    );
    yield put(enggRefLibraryActions.getReferenceLibrary({ projectNumber: payload.data.projectNumber }));
    yield put(
      enggRefLibraryActions.getManageReferenceLibrary({
        projectNumber: payload.data.projectNumber,
        pageNumber,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      enggRefLibraryActions.saveManageReferenceLibraryFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveEnggManageRefLibraryStatus() {
  yield takeEvery([enggRefLibraryActiontypes.SAVE_MANAGE_REFERENCE_LIBRARY], saveEnggManageRefLibrarySaga);
}
