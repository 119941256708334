import React from 'react';
import './toggleButton.css';
import PropTypes from 'prop-types';

function ToogleButton(props) {
  const { id, checked, label, handleValue, disabled, reference } = props;

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        checked={checked}
        onChange={(event) => handleValue(reference, event)}
        disabled={disabled}
      />
      <label title="" htmlFor={id} className="custom-control-label toggleSwitch">
        {label}
      </label>
    </div>
  );
}

ToogleButton.prototype = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  handleValue: PropTypes.func,
  disabled: PropTypes.bool,
  reference: PropTypes.any,
};

ToogleButton.defaultProps = {
  disabled: false,
};

export default ToogleButton;
