export const actionTypes = {
  GET_PROJ_ACTIVITY_AND_FRAG_STEPS: 'GET_FRAG_STEPS',
  GET_PROJ_ACTIVITY_AND_FRAG_STEPS_SUCCESS: 'GET_PROJ_ACTIVITY_AND_FRAG_STEPS_SUCCESS',
  GET_PROJ_ACTIVITY_AND_FRAG_STEPS_FAILURE: 'GET_PROJ_ACTIVITY_AND_FRAG_STEPS_FAILURE',
  FRAG_STEP_ACTIVE: 'FRAG_STEP_ACTIVE',
  FRAG_GIG_ACTIVE: 'FRAG_GIG_ACTIVE',
  CLEAR_FRAGNETS: 'CLEAR_FRAGNETS',
};

export const actions = {
  getProjActivityAndFragSteps: (data, id) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS,
    payload: data,
    id,
  }),

  getProjActivityAndFragStepsSucceeded: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS_SUCCESS,
    payload: content,
  }),

  getProjActivityAndFragStepsFailed: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS_FAILURE,
    payload: content,
  }),
  fragnentStepActive: (data) => ({
    type: actionTypes.FRAG_STEP_ACTIVE,
    payload: data,
  }),
  gigStepActive: (data) => ({
    type: actionTypes.FRAG_GIG_ACTIVE,
    payload: data,
  }),

  clearFragnets: () => ({
    type: actionTypes.CLEAR_FRAGNETS,
  }),
};
