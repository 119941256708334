import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { cloneDeep, get } from 'lodash';
import { BVModal } from '../../../baseComponents/BVModal';
import { projActivitiesActions, commonActions } from '../../../redux/actions';
import WbsCriteriaTable from './wbsCriteriaTable';
import GenerateActivityContainer from './generateActivityContainer';
import BVAutoSelectField from '../../../baseComponents/BVAutoSelectField';
import './styles.scss';
import { ICONS } from '../../../constants/assets';

function DefineActivities(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [disciplineData, setDisciplineData] = React.useState();
  const [showInfoModal, setShowInfoModal] = React.useState();
  const [discipline, setDiscipline] = React.useState();
  const [disableGenerateProjActLink, setDisableGenerateProjActLink] = React.useState(false);
  const [modifiedProjectActivityList, setModifiedProjectActivityList] = React.useState();
  const [isSearching, setIsSearching] = React.useState(false);

  const {
    getModifiedProjActivityList,
    saveProjActivityByDisciplineSucceeded,
    saveProjActivityByDiscipline,
    modalActivityDataSaved,
    projectActivityTableData,
    deleteProjActivityTableData,
    completeDefineActivity,
    isSuccessfullyDeleted,
    dosActivityData,
    projectNumber,
    setFormEditing,
    setDeleteStatus,
    menuId,
    submenuId,
    disciplines,
  } = props;

  React.useEffect(() => {
    const discipline =
      disciplines && disciplines.length && disciplines.length > 1
        ? disciplines.filter((discipline) => discipline !== 'Management' && discipline !== 'PJC')[0]
        : disciplines?.[0];
    getModifiedProjActivityList({ projectNumber, menuId, submenuId, discipline });
  }, []);

  React.useEffect(() => {
    if (modalActivityDataSaved) {
      saveProjActivityByDisciplineSucceeded({
        modalActivityDataSaved: false,
      });
      getModifiedProjActivityList({ projectNumber, menuId, submenuId });
    }
  }, [props.modalActivityDataSaved]);

  React.useEffect(() => {
    if (props.modifiedProjectActivityList) {
      setModifiedProjectActivityList(cloneDeep(props.modifiedProjectActivityList));
      const { disciplineDOS } = props.modifiedProjectActivityList;
      setDiscipline(disciplineDOS);
    }
  }, [props.modifiedProjectActivityList]);

  React.useEffect(() => {
    const data = [];
    props.disciplines.forEach((discipline) => data.push({ value: discipline, label: discipline }));
    setDisciplineData(data);
  }, [props.disciplines]);

  React.useEffect(() => {
    if (isSuccessfullyDeleted) {
      getModifiedProjActivityList({ projectNumber, menuId, submenuId });
      setShowInfoModal(false);
      setModifiedProjectActivityList(null);
      setFormEditing(false);
      setDeleteStatus({
        isSuccessfullyDeleted: false,
      });
    }
  }, [isSuccessfullyDeleted]);

  const handleComplete = (status) => {
    const manhourBudgetStatus = 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b';
    completeDefineActivity(getPayload(menuId, submenuId, projectNumber, status, discipline));
    !status && completeDefineActivity(getPayload(menuId, manhourBudgetStatus, projectNumber, status, discipline));
  };

  const getPayload = (menuId, submenuId, projectNumber, status, discipline) => ({
    projectNumber,
    menuId,
    submenuId,
    isComplete: status,
    discipline,
  });

  const openModal = () => !disableGenerateProjActLink && setShowModal(true);

  const handleCancel = () => {
    setShowModal(!showModal);
    modifiedProjectActivityList ? setShowInfoModal(true) : setShowInfoModal(false);
  };

  const saveModalData = () => {
    saveProjActivityByDiscipline(dosActivityData, { menuId, submenuId, projectNumber }, setShowModal);
  };

  const handleProceed = () => {
    deleteProjActivityTableData({ projectNumber });
    handleComplete(false);
  };

  const loadModalContent = () =>
    showModal && showInfoModal ? (
      <div className="defineActivityInfoModal">
        Project Activities have already been generated. Continuing will clear ALL Project Activity information. Proceed?
      </div>
    ) : (
      <WbsCriteriaTable {...props} />
    );

  const modalTitle = () => (
    <div className="defineActivity-modal-title">
      {showInfoModal ? 'Generate Project Activities' : 'Select Applicable WBS Criteria'}
    </div>
  );

  return (
    <>
      <div className="defineActivity-Container">
        <div className="defineActivity_section1">
          <div className="defineActivity-title">
            <span>Define Activities</span>
          </div>

          <div
            className={`defineActivity-link ${
              disableGenerateProjActLink ? 'defineActivity-link-disabled' : 'defineActivity-link-enabled'
            }`}
          >
            <span onClick={openModal} className="generateProjectActivityLink">
              Generate Project Activities
            </span>
          </div>
        </div>
        <div className="defineActivity_section2">
          {modifiedProjectActivityList ? (
            <GenerateActivityContainer
              modifiedProjectActivityList={modifiedProjectActivityList}
              setModifiedProjectActivityList={setModifiedProjectActivityList}
              setDisableGenerateProjActLink={setDisableGenerateProjActLink}
              setShowInfoModal={setShowInfoModal}
              isComplete={props.isComplete}
            />
          ) : (
            <div className="defineActivity-body-content">
              "To view activity table, you must first Generate Project Activities"
            </div>
          )}
        </div>

        <BVModal
          showModal={showModal}
          title={modalTitle()}
          closeButton
          size={showInfoModal ? 'xs' : 'xl'}
          toggleModal={() => setShowModal(!showModal)}
          modalContent={loadModalContent()}
          buttonData={[
            {
              buttonClick: () => handleCancel(),
              buttonLabel: 'Cancel',
            },
            {
              buttonClick: showInfoModal ? handleProceed : saveModalData,
              isPrimaryButton: true,
              buttonLabel: showInfoModal ? 'Proceed' : 'Save',
            },
          ]}
        />
      </div>
    </>
  );
}

DefineActivities = reduxForm({
  form: 'DefineActivities',
  destroyOnUnmount: false,
})(DefineActivities);

export const mapStateToProps = (state) => ({
  dosActivityData: get(state, 'projActivitiesInformation.dosActivityData'),
  modalActivityDataSaved: get(state, 'projActivitiesInformation.modalActivityDataSaved'),
  modifiedProjectActivityList: get(state, 'projActivitiesInformation.modifiedProjectActivityList'),
  projectNumber: get(state, 'projectSetupReducer.projectDetails.projectNumber', ''),
  menuId: get(state, 'projectSetupReducer.projectDetails.selectedMenu', ''),
  submenuId: get(state, 'projectSetupReducer.projectDetails.submenuId', ''),
  disciplines: get(state, 'projectSetupReducer.submenuList.disciplines'),
  isSuccessfullyDeleted: get(state, 'projActivitiesInformation.isSuccessfullyDeleted'),
  projectActivityTableData: get(state, 'projActivitiesInformation.projectActivityTableData'),
});

export const mapDispatchToProps = (dispatch) => ({
  saveProjActivityByDiscipline: (data, metaInfo, setShowModal) =>
    dispatch(projActivitiesActions.saveProjActivityByDiscipline(data, metaInfo, setShowModal)),
  getModifiedProjActivityList: (data) => dispatch(projActivitiesActions.getModifiedProjActivityList(data)),
  saveProjActivityByDisciplineSucceeded: (content) =>
    dispatch(projActivitiesActions.saveProjActivityByDisciplineSucceeded(content)),
  deleteProjActivityTableData: (data) => dispatch(projActivitiesActions.deleteProjActivityTableData(data)),
  setFormEditing: (data) => dispatch(commonActions.formEditing({ formEditing: data })),
  completeDefineActivity: (data) => dispatch(projActivitiesActions.completeDefineActivity(data)),
  setDeleteStatus: (data) => dispatch(projActivitiesActions.deleteProjActivityTableDataSuccess(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefineActivities));
