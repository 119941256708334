import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { get } from 'lodash';
import Logout from './logout';
import './styles.scss';

function UserProfilePopup(props) {
  const { showUserProfile, userProfileRef, user } = props;
  const { userFirstName, userLastName, userEmail } = user;
  const dispatch = useDispatch();

  const getInitials = (name) => (name && name.length > 0 ? name.charAt(0).toUpperCase() : '');

  return (
    <Container
      ref={userProfileRef}
      className={showUserProfile ? 'dropdown-userProfile-container' : ''}
      style={
        showUserProfile
          ? {
              display: 'block',
              position: 'absolute',
              top: '56px',
              right: '16px',
              width: 'auto',
            }
          : {}
      }
    >
      <div className="user-initials-block">
        <div className="user-initials">{`${getInitials(userFirstName)}${getInitials(userLastName)}`}</div>
      </div>
      {user && <div className="user-name">{`${userFirstName} ${userLastName}`}</div>}

      <div className="user-email">{userEmail}</div>
      <div className="line" />
      <div>
        <Logout userId={user.id} />
      </div>
    </Container>
  );
}

UserProfilePopup.propTypes = {
  showUserProfile: PropTypes.bool.isRequired,
  userProfileRef: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: get(state, 'authReducer.loginResponse.user', ''),
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfilePopup));
