import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { systemNotificationsActionTypes, systemNotificationsActions, commonActions } from '../actions';
import {
  getAllNotification,
  markAsReadNotification,
  deleteNotification,
  getAllProjects,
} from '../../services/serviceList';

export function* getSystemNotificationsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    if (payload.data.userId) {
      delete payload.data.userId;
    } else {
    }
    const responseData = yield call(AxiosInstance.get, getAllNotification('notification'), {
      params: payload.data,
    });
    yield put(
      systemNotificationsActions.getSystemNotificationsSucceeded({
        isSucceeded: true,
        systemNotificationsData: {
          totalRecords: responseData.data?.data?.totalRecords,
          isNotifications: responseData.data?.data?.notifications?.length ? true : false,
          notifications: responseData.data?.data?.notifications,
        },
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    systemNotificationsActions.getSystemNotificationsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getSystemNotificationsStatus() {
  yield takeEvery([systemNotificationsActionTypes.GET_SYSTEM_NOTIFICATIONS], getSystemNotificationsSaga);
}

export function* getSystemNotificationLandingPageWithPagination(payload) {
  try {
    yield put(commonActions.showNotificationLoader(true));
    if (payload.data.userId) {
      delete payload.data.userId;
    } else {
    }

    const responseData = yield call(AxiosInstance.get, getAllNotification('notification'), {
      params: payload.data,
    });
    yield put(
      systemNotificationsActions.getSystemNotificationsSucceeded({
        isSucceeded: true,
        systemNotificationsData: {
          totalRecords: responseData.data?.data?.totalRecords,
          isNotifications: responseData.data?.data?.notifications?.length ? true : false,
          notifications: responseData.data?.data?.notifications,
        },
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    systemNotificationsActions.getSystemNotificationsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showNotificationLoader(false));
}

export function* getSystemNotificationLandingPage() {
  yield takeEvery(
    [systemNotificationsActionTypes.GET_SYSTEM_NOTIFICATIONS_ON_LANDING_PAGE],
    getSystemNotificationLandingPageWithPagination
  );
}

export function* getProjectsForNotificationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));

    const responseData = yield call(AxiosInstance.get, getAllProjects('notification'), {
      params: payload.payload,
    });

    yield put(
      systemNotificationsActions.getSystemNotificationsSucceeded({
        isSucceeded: true,
        systemNotificationsData: {
          totalProjects: responseData?.data?.data?.totalRecords,
          projects: responseData?.data?.data?.projects,
        },
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    systemNotificationsActions.getSystemNotificationsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectsForNotification() {
  yield takeEvery([systemNotificationsActionTypes.GET_PROJECT_FOR_NOTIFICATION], getProjectsForNotificationSaga);
}

export function* getMoreProjectsForNoificationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));

    const responseData = yield call(AxiosInstance.get, getAllProjects('notification'), {
      params: payload.payload,
    });

    yield put(
      systemNotificationsActions.getMoreProjectNotificationSucceeded({
        isSucceeded: true,
        systemNotificationsData: {
          totalProjects: responseData?.data?.data?.totalRecords,
          projects: responseData?.data?.data?.projects,
        },
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    systemNotificationsActions.getSystemNotificationsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getMoreProjectForNotification() {
  yield takeEvery(
    [systemNotificationsActionTypes.GET_MORE_PROJECTS_FOR_NOTIFICATION],
    getMoreProjectsForNoificationSaga
  );
}

export function* saveSystemNotificationsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    let getPayload;

    let fetchMore;

    if (payload.data.getPayload) {
      getPayload = payload.data.getPayload;
      delete payload.data.getPayload;
    } else {
    }

    if (payload.data.fetchMore) {
      fetchMore = payload.data.fetchMore;
      delete payload.data.fetchMore;
    } else {
    }

    let response;

    /**
     * type -> denotes wheather to call mark / delete notification
     *
     */
    if (payload.data?.type === 'Mark_Notification') {
      delete payload.data?.type;
      response = yield call(AxiosInstance.put, markAsReadNotification('notification'), payload.data);
    } else if (payload.data?.type === 'Delete_Notification') {
      delete payload.data?.type;
      response = yield call(AxiosInstance.delete, deleteNotification('notification'), {
        data: payload.data,
      });
    } else {
    }
    yield put(
      systemNotificationsActions.saveSystemNotificationsSucceeded({
        isSucceeded: true,
        saveData: response.data,
      })
    );
    // yield put(
    //   commonActions.infoPopup({
    //     messageId: 'Your changes have been saved',
    //   })
    // );
    if (getPayload) {
      yield put(systemNotificationsActions.getSystemNotifications(getPayload));
    } else {
    }

    if (fetchMore) {
      yield put(
        systemNotificationsActions.getProjectsRelatedToNotification({
          page: fetchMore.page,
          limit: fetchMore.limit,
        })
      );
    } else {
    }
    //
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      systemNotificationsActions.saveSystemNotificationsFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveSystemNotificationsStatus() {
  yield takeEvery([systemNotificationsActionTypes.SAVE_SYSTEM_NOTIFICATIONS], saveSystemNotificationsSaga);
}
