import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import { manhourBudget } from '../../services/serviceList';
import { importManhrsActions, importManhrsActionTypes, commonActions } from '../actions';

export function* getImportManhrsInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, manhourBudget('projectSetup', payload?.data?.projectNumber), {
      params: {
        projectNumber: payload.data.projectNumber,
        submenuId: payload.data.submenuId,
      },
    });

    yield put(
      importManhrsActions.getManhrsInfoFormSucceeded({
        isSucceeded: true,
        tableData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    importManhrsActions.getManhrsInfoFormFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getManhrsInfoStatus() {
  yield takeEvery([importManhrsActionTypes.GET_MANHRS_INFO_FORM], getImportManhrsInfoSaga);
}

export function* saveManhrsInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, manhourBudget('projectSetup', payload?.data?.projectNumber), payload.data);
    yield put(
      importManhrsActions.saveManhrsInfoFormSucceeded({
        isSucceeded: true,
        savedForm: responseData,
      })
    );
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    yield put(importManhrsActions.getManhrsInfo(payload.data));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(importManhrsActions.saveManhrsInfoFormFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* saveManhrsInfoStatus() {
  yield takeEvery([importManhrsActionTypes.SAVE_MANHRS_INFO_FORM], saveManhrsInfoSaga);
}
