const ROLE_PERMISSIONS = {
  EDIT: 2,
  VIEW: 1,
  NONE: 0,
};

const SPECIAL_CHARACTER_EXPRESSION = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const REG_EXPRESSIONS = {
  URL_CHECK: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$%&'\(\)\*\+,;=.]+$/,
};

const DATE_FORMAT = {
  DDMMMYYYY_HYPHEN: 'DD-MMM-YYYY', // Date - Mon - Year (eg: 25-Dec-1997)
  MMDDYYYY_HYPHEN: 'MM-DD-YYYY', // Date - Mon - Year (eg: 25-Dec-1997)
  MMDDYYYY_FULL_TIME_HYPHEN: 'MM-DD-YYYY HH:mm:ss', // 05-26-2021 00:00:00
  DDMMYYYY_MERIDIAN_TIME_HYPHEN: 'DD-MMM-YYYY hh:mm A', // 25-Dec-1997 12:00A.M
  DDMMYYYY_MERIDIAN_TIME_HYPHEN_WITH_SPACE: 'DD-MMM-YYYY   hh:mm A', // 25-Dec-1997   12:00A.M
  DAY_NAME: 'dddd',
  DO_NUMBERED_DATE: 'Do',
  SHORT_MONTH_DATE: 'MMM Do',
  SHORT_MONTH_DATE_YEAR: 'MMM DD, YYYY',
  MMM_SPACE_DD: 'MMM D',
  ll: 'll', // Month name, day of month, year (eg: Sep 4, 1986)
  CALENDER_MIN_DATE: '01-JAN-2000',
  CALENDER_MAX_DATE: '01-JAN-2100',
  ISO_8601: 'YYYY-MM-DD HH:mm:ss'
};

const TOP_MENU_ID = {
  PROJECT_HOME: '76564cfa-f3ab-4c58-a545-b4f79c6ed143',
  PROJECT_INFORMATION: '5fcd1674-7760-4df0-84ac-2ea780dfc3f5',
  WBS_INFORMATION: '08c96ee1-9722-4613-8c46-68e0e9205e94',
  MANHOUR_BUDGETS: '3514b4bd-c8d3-4c85-a5d7-211c0acfa81d',
  PROJECT_ACTIVITIES: 'c7a3a315-aa7b-456f-b1d5-47fe5eb9625e',
  INPUT_OUTPUT: '9ca71c4a-cfbb-40d8-84ec-3c746c20ad01',
  REFERENCE_LIBRARY: '237a56bc-ec95-41aa-a7dd-d0763d233e55',
  WORKFLOW_RULES: 'd9244c12-f11c-420b-b3d6-b71b00ef41e0',
  ASSIGN_PROJECTLEADS: '7f07ff30-d409-4768-885a-f82b120ef7d9',
  GIG_WORKFLOW: 'b6f1f968-f912-40eb-82fe-bf06dc0a11d9',
  NON_LAMINAR_OUTPUT: 'dd7971d9-5685-46c5-8eb3-b9e8e70cc271',
  P6_SEND: 'e006e82a-389d-4b9f-8f32-20a07f242583',
  VIEW_APPROVE_PROJECT_PLAN: '5c2776bf-b453-43b1-bcde-723fe466d222',
  MY_PROJECTS: 'e75fdf90-b6eb-4bc7-87cf-02f41f4bff94',
  P6_SEND_RETRIEVE: '898b60e3-6b60-4c7e-bc1b-fa0a25c3aa7f',
  MY_PROJECT_HOME: 'cc99f743-2abc-4aa8-ac27-e25be185e00e',
  PROJECT_DASHBOARD: '0056c76e-ed73-4040-bc19-381ac1a9fe30',
  ENGG_REFERENCE_LIBRARY: '8094b1ba-0046-46d1-a3a9-56125f259fc8',
  CHANGE_MANAGEMENT: '0d968cb9-3364-4550-9ec4-206a9e62446b',
  ENGG_INPUT_OUTPUT: '7dd9975c-ad4e-4d55-a4f0-4bdef8332d80',
  MASTER_DATA_QA: '98a2158a-15b5-4276-bbea-df1b2cbda5c9',
  HEIRARCHY: '1f57cf8b-7db3-4ff7-9a71-e542a70a35df',
  ACTIVITIES: '68358a54-94d5-477b-ad2e-7aad85ad1ba1',
  FRAGNET_STEPS: '131d9acf-97d0-4792-b29e-98dd570e76d9',
  GIGS: '9c8fe680-9ce2-47ee-803f-b557bd2ecb25',
  MASTER_INPUT_OUTPUTS: '0fcdb024-7ee8-47a2-b6d0-6c00d4195c6a',
  REFERENCES: 'b0685ca3-c945-48f6-a2cc-4e4b9864b571',
  ENGG_LAMINAR_SCOPE: '6de854e7-22b0-47d3-b473-6e3727ea0935',
};

const INPUT_TYPES = {
  TEXT: 'string',
  NUMBER: 'number',
  DATE: 'date',
  SELECT: 'select',
  TEXTAREA: 'textarea',
  RADIO: 'radiobuttons',
  CHECKBOX1: 'Checkbox (Y/N)',
  CHECKBOX: 'checkbox',
  TOGGLE: 'toggle',
  TYPEAHEAD: 'typeahead',
  LINK: 'Link',
  TEXTBOX: 'Textbox',
  NUMERICAL: 'Numerical value',
  SLIDER: 'slider',
};

const GIGS_MDM = {
  EARNING_RULE: 'earningRule',
  DURATION_RULE: 'durationRule',
};

const INPUT_MENU = {
  Piping: '40e8aea5-57d1-41d7-bb1f-bdca173551da',
  Process: '031497fd-e95c-4f12-9d31-be5564c08e91',
  Structural: 'fa2f536e-1785-452d-b7a8-5839fc60487d',
  Electrical: 'b0054934-3061-4417-ba02-47845fc51813',
  Equipment: '05df51e4-b2f7-4330-b6b8-a330b5fb1f2a',
  'I&C': '2763925b-119d-40b4-bc72-fd578f9b1125',
};

const GIG_STATUS = {
  NOT_READY: 'Not-Ready',
  READY: 'Ready to Start',
  IN_PROGRESS: 'In-Progress',
  UNKNOWN: 'Unknown',
  ON_HOLD: 'On-Hold',
  COMPLETED: 'Completed',
};

const ASSIGN_TEAM_MENU_ID = '7f07ff30-d409-4768-885a-f82b120ef7d9';

const DPS_ROLES = ['Piping', 'Process', 'Structural', 'Electrical', 'Equipment', 'I&C'];
const PLI_ROLES = ['Management'];

const PROJECTINFO_MENU_ID = '5fcd1674-7760-4df0-84ac-2ea780dfc3f5';
const PROJECTINFO_SUB_MENU_ID = 'e74da6c2-d017-4cf8-b019-d1ba74ceea36';

const LOGIN_TYPES = {
  INITIAL_STATE: '',
  SSO_LOGIN: 'SSO_LOGIN',
  AD_LOGIN: 'AD_LOGIN',
};

const LOCAL_STORAGE_KEY = {
  FEATURE_TOGGLING_KEY: 'REACT_APP_FEATURE_TOGGLING_API_KEY',
  ENCRYPTION_KEY: 'REACT_APP_ENCRYPTION_KEY',
};

export {
  SPECIAL_CHARACTER_EXPRESSION,
  ROLE_PERMISSIONS,
  INPUT_MENU,
  REG_EXPRESSIONS,
  TOP_MENU_ID,
  INPUT_TYPES,
  GIG_STATUS,
  ASSIGN_TEAM_MENU_ID,
  DPS_ROLES,
  PLI_ROLES,
  PROJECTINFO_MENU_ID,
  PROJECTINFO_SUB_MENU_ID,
  LOGIN_TYPES,
  DATE_FORMAT,
  LOCAL_STORAGE_KEY,
  GIGS_MDM,
};
