import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { authActionTypes, authActions, commonActions } from '../actions';
import { LOGIN_TYPES } from '../../constants/common';
import { setItemToLocalStorage, removeItemFromLocalStorage } from '../../helpers/localStorage';

const loginDataKey = 'loginData';
export function* loginSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(axios.post, servicelist('loginService'), payload.data);
    setItemToLocalStorage(loginDataKey, JSON.stringify(get(response, 'data', '')));
    yield put(
      authActions.loginAuthSucceeded({
        isLoggedIn: true,
        loginType: LOGIN_TYPES.SSO_LOGIN,
        loginResponse: response.data,
      })
    );
    //
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(authActions.loginAuthFailed({ isLoggedIn: false, loginType: LOGIN_TYPES.INITIAL_STATE }));
  }
  yield put(commonActions.showLoader(false));
}

export function* loginStatus() {
  yield takeEvery([authActionTypes.LOGIN_AUTH], loginSaga);
}
export function* logoutSaga() {
  try {
    yield put(commonActions.showLoader(true));
    removeItemFromLocalStorage(loginDataKey);
    yield put(
      authActions.logoutAuthSucceeded({
        isLoggedIn: false,
        loginType: LOGIN_TYPES.INITIAL_STATE,
        loginResponse: {},
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(authActions.logoutAuthFailed({ isLoggedIn: true, loginType: LOGIN_TYPES.SSO_LOGIN }));
  }
  yield put(commonActions.showLoader(false));
}

export function* logoutStatus() {
  yield takeEvery([authActionTypes.LOGOUT_AUTH], logoutSaga);
}

export function* msalSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(axios.post, servicelist('adLoginService'), payload.data);
    yield put(
      authActions.msalAuthSucceeded({
        isLoggedIn: true,
        loginType: LOGIN_TYPES.AD_LOGIN,
        loginResponse: response.data,
      })
    );
    //
  } catch (e) {
    if (get(e, 'response.status', '') === 403) {
      yield put(
        commonActions.infoPopup({
          messageId: get(e, 'response.data.message', 'Something went wrong. A system error has occurred.'),
          theme: 'alert-danger',
          hide: false,
        })
      );
    } else {
      yield put(
        commonActions.infoPopup({
          messageId: 'Something went wrong. A system error has occurred.',
          theme: 'alert-danger',
        })
      );
    }
    yield put(commonActions.showLoader(false));
    yield put(authActions.msalAuthFailed({ isLoggedIn: false, loginType: LOGIN_TYPES.INITIAL_STATE }));
  }
  yield put(commonActions.showLoader(false));
}

export function* msalStatus() {
  yield takeEvery([authActionTypes.MSAL_AUTH], msalSaga);
}

export function* msalLogoutSaga() {
  try {
    yield put(commonActions.showLoader(true));
    removeItemFromLocalStorage(loginDataKey);
    yield put(
      authActions.msalLogoutAuthSucceeded({
        isLoggedIn: false,
        loginType: LOGIN_TYPES.INITIAL_STATE,
        loginResponse: {},
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(authActions.msalLogoutAuthFailed({ isLoggedIn: true, loginType: LOGIN_TYPES.AD_LOGIN }));
  }
  yield put(commonActions.showLoader(false));
}

export function* msalLogoutStatus() {
  yield takeEvery([authActionTypes.MSAL_LOGOUT_AUTH], msalLogoutSaga);
}
export function* refreshTokenSaga(payload) {
  // api call
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(axios.post, servicelist('refreshToken'), payload.data.refreshToken);
    if (payload.data.originalRequest) {
      yield call(AxiosInstance(payload.data.originalRequest));
    }
    yield put(
      authActions.refreshTokenSucceeded({
        isTokenRefreshed: true,
        loginResponse: response.data,
      })
    );
    //
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // yield put(authActions.loginAuthFailed({ isLoggedIn: false , loginType: LOGIN_TYPES.INITIAL_STATE,}));
    // log user out
  }
  yield put(commonActions.showLoader(false));
}

export function* refreshTokenStatus() {
  yield takeEvery([authActionTypes.REFRESH_TOKEN], refreshTokenSaga);
}
