export const actionTypes = {
  GET_ITERATIONS: 'GET_ITERATIONS',
  GET_ITERATIONS_SUCCESS: 'GET_ITERATIONS_SUCCESS',
  GET_ITERATIONS_FAILURE: 'GET_ITERATIONS_FAILURE',
  // Quick Links widget
  GET_QUICKLINKS: 'GET_QUICKLINKS',
  GET_QUICKLINKS_SUCCESS: 'GET_QUICKLINKS_SUCCESS',
  GET_QUICKLINKS_FAILURE: 'GET_QUICKLINKS_FAILURE',
  SAVE_QUICKLINKS: 'SAVE_QUICKLINKS',
  SAVE_QUICKLINKS_SUCCESS: 'SAVE_QUICKLINKS_SUCCESS',
  SAVE_QUICKLINKS_FAILURE: 'SAVE_QUICKLINKS_FAILURE',
  GET_DROPDOWN_QUICKLINKS: 'GET_DROPDOWN_QUICKLINKS',
  GET_DROPDOWN_QUICKLINKS_SUCCESS: 'GET_DROPDOWN_QUICKLINKS_SUCCESS',
  GET_DROPDOWN_QUICKLINKS_FAILURE: 'GET_DROPDOWN_QUICKLINKS_FAILURE',
  GET_PROJECT_TEAM_USERS: 'GET_PROJECT_TEAM_USERS',
  GET_PROJECT_TEAM_USERS_SUCCESS: 'GET_PROJECT_TEAM_USERS_SUCCESS',
  GET_PROJECT_TEAM_USERS_FAILURE: 'GET_PROJECT_TEAM_USERS_FAILURE',
  GET_PROJECT_TEAM_MENUITEMS: 'GET_PROJECT_TEAM_MENUITEMS',
  SAVE_PLOT_PLAN: 'SAVE_PLOT_PLAN',
  GET_PROJECT_DASHBOARD_WIDGETS_DATA: 'GET_PROJECT_DASHBOARD_WIDGETS_DATA',
  GET_PROJECT_DASHBOARD_WIDGETS_DATA_SUCCESS: '  GET_PROJECT_DASHBOARD_WIDGETS_DATA_SUCCESS',

  // Project Info
  GET_ENGEXEC_PROJECT_INFO_DROPDOWN: 'GET_ENGEXEC_PROJECT_INFO_DROPDOWN',
  GET_ENGEXEC_PROJECT_INFO_DROPDOWN_SUCCESS: 'GET_ENGEXEC_PROJECT_INFO_DROPDOWN_SUCCESS',
  GET_ENGEXEC_PROJECT_INFO_DROPDOWN_FAILURE: 'GET_ENGEXEC_PROJECT_INFO_DROPDOWN_FAILURE',
  GET_ENGEXEC_PROJECT_INFO_FORM: 'GET_ENGEXEC_PROJECT_INFO_FORM',
  GET_ENGEXEC_PROJECT_INFO_FORM_SUCCESS: 'GET_ENGEXEC_PROJECT_INFO_FORM_SUCCESS',
  GET_ENGEXEC_PROJECT_INFO_FORM_FAILURE: 'GET_ENGEXEC_PROJECT_INFO_FORM_FAILURE',
  SAVE_ENGEXEC_PROJECT_INFO_FORM: 'SAVE_ENGEXEC_PROJECT_INFO_FORM',
  SAVE_ENGEXEC_PROJECT_INFO_FORM_SUCCESS: 'SAVE_ENGEXEC_PROJECT_INFO_FORM_SUCCESS',
  SAVE_ENGEXEC_PROJECT_INFO_FORM_FAILURE: 'SAVE_ENGEXEC_PROJECT_INFO_FORM_FAILURE',

  // WBS Info
  GET_ENGEXEC_WBS_INFO_DROPDOWN: 'GET_ENGEXEC_WBS_INFO_DROPDOWN',
  GET_ENGEXEC_WBS_INFO_DROPDOWN_SUCCESS: 'GET_ENGEXEC_WBS_INFO_DROPDOWN_SUCCESS',
  GET_ENGEXEC_WBS_INFO_DROPDOWN_FAILURE: 'GET_ENGEXEC_WBS_INFO_DROPDOWN_FAILURE',
  GET_ENGEXEC_WBS_INFO_TABLE: 'GET_ENGEXEC_WBS_INFO_TABLE',
  GET_ENGEXEC_WBS_INFO_TABLE_SUCCESS: 'GET_ENGEXEC_WBS_INFO_TABLE_SUCCESS',
  GET_ENGEXEC_WBS_INFO_TABLE_FAILURE: 'GET_ENGEXEC_WBS_INFO_TABLE_FAILURE',
  SAVE_ENGEXEC_WBS_INFO_TABLE: 'SAVE_ENGEXEC_WBS_INFO_TABLE',
  SAVE_ENGEXEC_WBS_INFO_TABLE_SUCCESS: 'SAVE_ENGEXEC_WBS_INFO_TABLE_SUCCESS',
  SAVE_ENGEXEC_WBS_INFO_TABLE_FAILURE: 'SAVE_ENGEXEC_WBS_INFO_TABLE_FAILURE',
};

export const actions = {
  getIterations: (data) => ({
    type: actionTypes.GET_ITERATIONS,
    data,
  }),
  getIterationsSuccess: (data) => ({
    type: actionTypes.GET_ITERATIONS_SUCCESS,
    payload: data,
  }),
  getIterationsFaliure: (data) => ({
    type: actionTypes.GET_ITERATIONS_FAILURE,
    payload: data,
  }),

  // Quick Links widget
  getProjectDashboard: (data) => ({
    type: actionTypes.GET_QUICKLINKS,
    data,
  }),
  getProjectDashboardSuccess: (data) => ({
    type: actionTypes.GET_QUICKLINKS_SUCCESS,
    payload: data,
  }),
  getProjectDashboardFaliure: (data) => ({
    type: actionTypes.GET_QUICKLINKS_FAILURE,
    payload: data,
  }),
  saveQuickLinks: (data) => ({
    type: actionTypes.SAVE_QUICKLINKS,
    data,
  }),
  saveQuickLinksSuccess: (data) => ({
    type: actionTypes.SAVE_QUICKLINKS_SUCCESS,
    payload: data,
  }),
  saveQuickLinksFaliure: (data) => ({
    type: actionTypes.SAVE_QUICKLINKS_FAILURE,
    payload: data,
  }),
  getDropdownQuicklinks: (data) => ({
    type: actionTypes.GET_DROPDOWN_QUICKLINKS,
    data,
  }),
  getDropdownQuicklinksSuccess: (data) => ({
    type: actionTypes.GET_DROPDOWN_QUICKLINKS_SUCCESS,
    payload: data,
  }),
  getDropdownQuicklinksFaliure: (data) => ({
    type: actionTypes.GET_DROPDOWN_QUICKLINKS_FAILURE,
    payload: data,
  }),
  getProjectTeamUsers: (data) => ({
    type: actionTypes.GET_PROJECT_TEAM_USERS,
    data,
  }),

  getProjectTeamMenuItem: (data) => ({
    type: actionTypes.GET_PROJECT_TEAM_MENUITEMS,
    data,
  }),

  getProjectDashboardWidgetsData: (data) => ({
    type: actionTypes.GET_PROJECT_DASHBOARD_WIDGETS_DATA,
    data,
  }),
  // image widget
  savePlotPlan: (data) => ({
    type: actionTypes.SAVE_PLOT_PLAN,
    data,
  }),

  // Project Info

  getEngExecProjectInfoDropdown: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN,
    data,
  }),
  getEngExecProjectInfoDropdownSuccess: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN_SUCCESS,
    payload: data,
  }),
  getEngExecProjectInfoDropdownFaliure: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN_FAILURE,
    payload: data,
  }),
  getEngExecProjectInfoForm: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_FORM,
    data,
  }),
  getEngExecProjectInfoFormSuccess: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_FORM_SUCCESS,
    payload: data,
  }),
  getEngExecProjectInfoFormFaliure: (data) => ({
    type: actionTypes.GET_ENGEXEC_PROJECT_INFO_FORM_FAILURE,
    payload: data,
  }),
  saveEngExecProjectInfoForm: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM,
    data,
  }),
  saveEngExecProjectInfoFormSuccess: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM_SUCCESS,
    payload: data,
  }),
  saveEngExecProjectInfoFormFaliure: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM_FAILURE,
    payload: data,
  }),

  // WBS Info

  getEngExecWbsInfoDropdown: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN,
    data,
  }),
  getEngExecWbsInfoDropdownSuccess: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN_SUCCESS,
    payload: data,
  }),
  getEngExecWbsInfoDropdownFaliure: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN_FAILURE,
    payload: data,
  }),
  getEngExecWbsInfoTable: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_TABLE,
    data,
  }),
  getEngExecWbsInfoTableSuccess: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_TABLE_SUCCESS,
    payload: data,
  }),
  getEngExecWbsInfoTableFaliure: (data) => ({
    type: actionTypes.GET_ENGEXEC_WBS_INFO_TABLE_FAILURE,
    payload: data,
  }),
  saveEngExecWbsInfoTable: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE,
    data,
  }),
  saveEngExecWbsInfoTableSuccess: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE_SUCCESS,
    payload: data,
  }),
  saveEngExecWbsInfoTableFaliure: (data) => ({
    type: actionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE_FAILURE,
    payload: data,
  }),
};
