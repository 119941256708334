import { masterDataGigDashboardActionTypes } from '../actions';
const InitialState = {};

export const masterDataGigDashboardReducer = (state = InitialState, action) => {
  switch (action.type) {
    case masterDataGigDashboardActionTypes.GET_MASTER_GIG_DASHBOARD_DATA:
      return { ...state };
    case masterDataGigDashboardActionTypes.GET_MASTER_GIG_DASHBOARD_DATA_SUCCESS:
      return { ...state, ...action.data };
    case masterDataGigDashboardActionTypes.GET_MASTER_GIG_DASHBOARD_DATA_FAILURE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
