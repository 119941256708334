import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { libraryActions, libraryActionTypes, commonActions } from '../actions';

export function* getReferenceLibraryFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('referenceLibraryForm'), {
      params: {
        projectNumber: payload.data.projectNumber,
        submenuId: payload.data.submenuId,
        rights: payload.data.rights,
      },
    });

    yield put(
      libraryActions.getReferenceLibraryFormSucceeded({
        isSucceeded: true,
        libraryData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    libraryActions.getReferenceLibraryFormFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getReferenceLibraryFormStatus() {
  yield takeEvery([libraryActionTypes.GET_REFERENCE_LIBRARY_FORM], getReferenceLibraryFormSaga);
}

export function* saveReferenceLibraryFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.data.body ? payload.data.body : {};

    const responseData = payload.data.id
      ? yield call(AxiosInstance.put, servicelist('referenceLibraryForm'), {
          ...payload.data.body,
          id: payload.data.id,
        })
      : yield call(AxiosInstance.post, servicelist('referenceLibraryForm'), payload.data.body);

    yield put(
      libraryActions.saveReferenceLibraryFormSucceeded({
        isSucceeded: true,
        isFormSaved: true,
        savedForm: responseData.data,
      })
    );
    // after a succes save call a get call is done.
    yield put(
      libraryActions.getReferenceLibraryForm({
        projectNumber: payload.data.body.projectNumber,
        submenuId: payload.data.body.submenuId,
      })
    );
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    libraryActions.saveReferenceLibraryFormFailed({ isSucceeded: false, isFormSaved: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveReferenceLibraryFormStatus() {
  yield takeEvery([libraryActionTypes.SAVE_REFERENCE_LIBRARY_FORM], saveReferenceLibraryFormSaga);
}

export function* getReferenceLibraryCategorySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('referenceLibraryCategory'), {
      params: {
        projectNumber: payload.data.projectNumber,
        submenuId: payload.data.submenuId,
      },
    });

    yield put(
      libraryActions.getReferenceLibraryCategorySucceeded({
        isSucceeded: true,
        categoryData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    libraryActions.getReferenceLibraryCategoryFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getReferenceLibraryCategoryStatus() {
  yield takeEvery([libraryActionTypes.GET_REFERENCE_LIBRARY_CATEGORY], getReferenceLibraryCategorySaga);
}
