export const actionTypes = {
  GET_MANHRS_INFO_FORM: 'GET_MANHRS_INFO_FORM',
  GET_MANHRS_INFO_FORM_SUCCESS: 'GET_MANHRS_INFO_FORM_SUCCESS',
  GET_MANHRS_INFO_FORM_FAILURE: 'GET_MANHRS_INFO_FORM_FAILURE',
  SAVE_MANHRS_INFO_FORM: 'SAVE_MANHRS_INFO_FORM',
  SAVE_MANHRS_INFO_FORM_SUCCESS: 'SAVE_MANHRS_INFO_FORM_SUCCESS',
  SAVE_MANHRS_INFO_FORM_FAILURE: 'SAVE_MANHRS_INFO_FORM_FAILURE',
};

export const actions = {
  getManhrsInfo: (data) => ({
    type: actionTypes.GET_MANHRS_INFO_FORM,
    data,
  }),
  getManhrsInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_MANHRS_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getManhrsInfoFormFailed: (content) => ({
    type: actionTypes.GET_MANHRS_INFO_FORM_FAILURE,
    payload: content,
  }),
  saveManhrsInfoForm: (data) => ({
    type: actionTypes.SAVE_MANHRS_INFO_FORM,
    data,
  }),
  saveManhrsInfoFormSucceeded: (content) => ({
    type: actionTypes.SAVE_MANHRS_INFO_FORM_SUCCESS,
    payload: content,
  }),
  saveManhrsInfoFormFailed: (content) => ({
    type: actionTypes.SAVE_MANHRS_INFO_FORM_FAILURE,
    payload: content,
  }),
};
