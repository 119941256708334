export const manhrsbudgetlistmock = {
  descipline: 'PIPING',
  activities: [
    {
      _id: '346udhkfhd98e45e34y345hsjfhw3rr',
      name: 'Tie-In List and details',
      key: 'Tie-In List and details',
      isBudgetCompleted: false, //Flag will be updated on basis of Define Activity related configuration like primary/secondary and tricery selection and sub-activity population
      isActivityCompleted: true, //Flag will be updated on basis of Mark As Completed click here in same screen
    },
    {
      _id: '346udhkfhd98e45e34y345hsjfhw3rr',
      name: 'Stream & Condensate Trace Details',
      key: 'Stream & Condensate Trace Details',
      isBudgetCompleted: false,
      isActivityCompleted: true,
    },
    {
      _id: '346udhkfhd98e45e34y345hsjfhw3rr',
      name: 'Facility And Equipment Arrangement Drawings',
      key: 'Facility And Equipment Arrangement Drawings',
      isBudgetCompleted: true,
      isActivityCompleted: false,
    },
    {
      _id: '346udhkfhd98e45e34y345hsjfhw3rr',
      name: 'Paging Plans & Sections(2D)',
      key: 'Paging Plans & Sections(2D)',
      isBudgetCompleted: true,
      isActivityCompleted: true,
    },
    {
      _id: '346udhkfhd98e45e34y345hsjfhw3rr',
      name: 'Piping Studies',
      key: 'Piping Studies',
      isBudgetCompleted: true,
      isActivityCompleted: true,
    },
  ],
};
