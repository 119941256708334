import { systemNotificationsActionTypes } from '../actions';

const InitialState = {};

export const systemNotificationsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case systemNotificationsActionTypes.GET_SYSTEM_NOTIFICATIONS:
      return { ...state };
    case systemNotificationsActionTypes.GET_SYSTEM_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isSucceeded: action.payload.isSucceeded,
        systemNotificationsData: {
          ...state.systemNotificationsData,
          ...action.payload.systemNotificationsData,
        },
      };
    case systemNotificationsActionTypes.GET_SYSTEM_NOTIFICATIONS_FAILURE:
      return { ...state, ...action.payload };
    case systemNotificationsActionTypes.GET_PROJECT_FOR_NOTIFICATION:
      return { ...state, ...action.payload };
    case systemNotificationsActionTypes.GET_MORE_PROJECTS_FOR_NOTIFICATION:
      return {
        ...state,
      };
    case systemNotificationsActionTypes.GET_MORE_PROJECT_FOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isSucceeded: action.payload.isSucceeded,
        systemNotificationsData: {
          ...state.systemNotificationsData,
          totalProjects: action.payload.systemNotificationsData.totalProjects,
          projects: [...state.systemNotificationsData.projects, ...action.payload.systemNotificationsData.projects],
        },
      };

    case systemNotificationsActionTypes.SAVE_SYSTEM_NOTIFICATIONS:
      return { ...state };
    case systemNotificationsActionTypes.SAVE_SYSTEM_NOTIFICATIONS_SUCCESS:
      return { ...state, ...action.payload };
    case systemNotificationsActionTypes.SAVE_SYSTEM_NOTIFICATIONS_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
