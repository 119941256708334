import React from 'react';

export default function BVSelect(props) {
  const { input, selectedValue, meta, children, isNotNative, errorText, options, onTouch = true, ...custom } = props;
  let isError = meta && meta.touched && meta.error ? meta.error : errorText || ''; // to distinguish between redux field and normal input filed
  if (onTouch === false) {
    isError = meta.error ? meta.error : errorText || ''; // to distinguish between redux field and normal input filed
  }

  return (
    <div className="selectContainer">
      <select
        // onChange={value => input.onChange(value)}
        className={`form-control ${isError ? 'errorTextBox' : ''}${custom.className ? custom.className : ''}`}
        {...input}
        {...custom}
        // value={selectedValue && selectedValue}
      >
        {options && options.length != 0 ? (
          <>
            <option value="" disabled>
              {custom.placeholder ? custom.placeholder : 'Select an Option'}
            </option>
            {options.map((option, index) => (
              <option value={option.key} key={index}>
                {option.name}
              </option>
            ))}
          </>
        ) : (
          <option value="" disabled>
            No Records Found
          </option>
        )}
      </select>
      {isError && (
        <span className="errorHelperText">
          {isError} {/* For error text */}
        </span>
      )}
    </div>
  );
}
