import projectActivities from '../../pages/projectActivities';
import { projActivitiesActionTypes } from '../actions';

const InitialState = {};

export const projActivitiesReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_FORM:
      return { ...state };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.CLEAR_PROJ_ACTIVITY_INFO_FORM:
      return { ...state, tableData: [] };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_TABLE_DATA:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.SET_PROJ_ACTIVITY_TABLE_DATA:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_TABLE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_PROJ_ACTIVITY_TABLE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_LIST:
      return { ...state };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_LIST_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_TABLE:
      return { ...state };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_TABLE_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.COMPLETE_DEFINE_ACTIVITY:
      return { ...state, ...action.data };
    case projActivitiesActionTypes.COMPLETE_DEFINE_ACTIVITY_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.COMPLETE_DEFINE_ACTIVITY_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case projActivitiesActionTypes.PROJ_ACTIVITY_TABLE_DATA_STATUS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
