import { get } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, masterDataManagerActionTypes, masterDataManagerActions } from '../actions';
import { TABLE_MASTER_TYPES } from '../../constants/masterData';

export function* getMasterDataTableStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType } = payload.data;
    const responseData = yield call(
      AxiosInstance.get,
      servicelist('masterTableLock').replace(':masterType', TABLE_MASTER_TYPES[tableType])
    );
    yield put(
      masterDataManagerActions.getMasterDataTableStatusSuccess({
        isSucceeded: true,
        masterTableStatus: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterDataTableStatusFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterDataTableStatusStatus() {
  yield takeEvery(masterDataManagerActionTypes.GET_MASTER_DATA_TABLE_STATUS, getMasterDataTableStatusSaga);
}

export function* changeMasterDataTableStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType, disableEdit } = payload.data;
    const responseData = disableEdit
      ? yield call(
          AxiosInstance.put,
          servicelist('masterTableLock').replace(':masterType', TABLE_MASTER_TYPES[tableType])
        )
      : yield call(
          AxiosInstance.post,
          servicelist('masterTableLock').replace(':masterType', TABLE_MASTER_TYPES[tableType])
        );
    yield put(
      masterDataManagerActions.changeMasterDataTableStatusSuccess({
        isSucceeded: true,
        masterTableStatus: responseData.data,
      })
    );
    if (disableEdit) {
      yield call(payload.data.closeModal, false);
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.changeMasterDataTableStatusFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* changeMasterDataTableStatusStatus() {
  yield takeEvery([masterDataManagerActionTypes.CHANGE_MASTER_DATA_TABLE_STATUS], changeMasterDataTableStatusSaga);
}

export function* getMasterDataTableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType, body } = payload.data;
    const responseData = yield call(AxiosInstance.post, servicelist(tableType), body);
    yield put(
      masterDataManagerActions.getMasterDataTableSuccess({
        isSucceeded: true,
        masterTableData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterDataTableFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterDataTableStatus() {
  yield takeEvery([masterDataManagerActionTypes.GET_MASTER_DATA_TABLE], getMasterDataTableSaga);
}

export function* getMasterDataQATableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType } = payload.data;
    const responseData = yield call(AxiosInstance.post, servicelist('getUpdateMasterQa'), { masterType: tableType });
    yield put(
      masterDataManagerActions.getMasterDataQATableSuccess({
        isSucceeded: true,
        tableData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterDataQATableFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterDataTableQAStatus() {
  yield takeEvery([masterDataManagerActionTypes.GET_MASTER_DATA_QA_TABLE], getMasterDataQATableSaga);
}

export function* saveMasterDataTableSaga(payload) {
  let responseData;
  try {
    yield put(commonActions.showLoader(true));
    const { tableType, body } = payload.data;
    delete body.fields.forEach((item) => delete item.errors); // Deleting errors property from payload
    responseData = yield call(AxiosInstance.put, servicelist(tableType), body);

    yield put(
      masterDataManagerActions.saveMasterDataTableSuccess({
        isSucceeded: true,
        masterTableData: responseData.data,
      })
    );
    yield put(masterDataManagerActions.getMasterDataTableStatus({ tableType }));
  } catch (e) {
    let hasErrors = false;
    if (e.response.data && e.response.data.data) {
      for (const row of e.response.data.data) {
        if (!hasErrors) hasErrors = row.hasOwnProperty('errors');
      }
    }
    if (hasErrors) {
      yield put(
        masterDataManagerActions.saveMasterDataTableFailure({
          isSucceeded: false,
          masterTableData: e.response.data,
        })
      );
      yield put(
        commonActions.infoPopup({
          messageId: 'Validation failed. Cannot save changes.',
          theme: 'alert-danger',
        })
      );
    } else {
      let errorMessage = 'Something went wrong. A system error has occurred.';
      if (e.response && e.response.data && e.response.data?.errors?.length > 0) {
        errorMessage = `${e.response.data.errors.join('\n')}`;
      }
      const infoPopupMessage = errorMessage;

      yield put(
        masterDataManagerActions.saveMasterDataTableFailure({
          isSucceeded: false,
        })
      );

      yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    }
  }
  yield put(commonActions.showLoader(false));
}

export function* saveMasterDataTableStatus() {
  yield takeEvery([masterDataManagerActionTypes.SAVE_MASTER_DATA_TABLE], saveMasterDataTableSaga);
}

export function* sendMasterToQASaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType, body, tableBody } = payload.data;
    const responseData = yield call(
      AxiosInstance.post,
      servicelist('sendMasterDataToQA').replace(':masterType', TABLE_MASTER_TYPES[tableType]),
      body
    );

    yield put(
      masterDataManagerActions.sendMasterToQASuccess({
        isSucceeded: true,
        // masterTableData: responseData.data,
      })
    );
    yield put(masterDataManagerActions.getMasterDataTableStatus({ tableType }));
    yield put(
      masterDataManagerActions.getMasterDataTable({
        tableType,
        body: tableBody,
      })
    );
    yield put(masterDataManagerActions.getMasterQACount());
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.sendMasterToQAFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* sendMasterToQAStatus() {
  yield takeEvery([masterDataManagerActionTypes.SEND_MASTER_TO_QA], sendMasterToQASaga);
}

export function* getMasterPreviewDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { tableType, body } = payload.data;
    const responseData = yield call(AxiosInstance.get, servicelist(tableType), {
      params: body,
    });
    yield put(
      masterDataManagerActions.getMasterPreviewDataSuccess({
        isSucceeded: true,
        previewData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterPreviewDataFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterPreviewDataStatus() {
  yield takeEvery([masterDataManagerActionTypes.GET_MASTER_PREVIEW_DATA], getMasterPreviewDataSaga);
}

// master Data QA
export function* getMasterQACountSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('masterQACount'), payload.data);

    yield put(
      masterDataManagerActions.getMasterQACountSuccess({
        isSucceeded: true,
        masterQACountData: responseData.data,
      })
    );
    yield put(
      commonActions.notificationCount({
        count: get(responseData.data, 'masterDataQARequestCount', 0),
        menu: 'masterDataQA',
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterQACountFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterQACountStatus() {
  yield takeEvery([masterDataManagerActionTypes.GET_MASTER_QA_COUNT], getMasterQACountSaga);
}

export function* getMasterQAEditModeSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getMasterDataQAEditMode'), payload.data);

    yield put(
      masterDataManagerActions.getMasterQAEditModeSuccess({
        isSucceeded: true,
        masterQAEditMode: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.getMasterQAEditModeFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getMasterQAEditModeStatus() {
  yield takeEvery([masterDataManagerActionTypes.GET_MASTER_QA_EDIT_MODE], getMasterQAEditModeSaga);
}

export function* saveMasterQAEditModeSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('saveMasterDataQAEditMode'), payload);

    yield put(
      masterDataManagerActions.saveMasterQAEditModeSuccess({
        isSucceeded: true,
        masterQAEditMode: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.saveMasterQAEditModeFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveMasterQAEditModeStatus() {
  yield takeEvery([masterDataManagerActionTypes.SAVE_MASTER_QA_EDIT_MODE], saveMasterQAEditModeSaga);
}

export function* resetMasterQAChangesSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.put, servicelist('resetMasterQAChanges'), payload);

    yield put(
      masterDataManagerActions.resetMasterQAChangesSuccess({
        isSucceeded: true,
        masterQAEditMode: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.resetMasterQAChangesFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* resetMasterQAChangesStatus() {
  yield takeEvery([masterDataManagerActionTypes.RESET_MASTER_QA_CHANGES], resetMasterQAChangesSaga);
}

export function* saveMasterQAChangesSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { reviewedRows } = payload.data;
    const responseData = yield call(AxiosInstance.put, servicelist('saveMasterQAChanges'), reviewedRows);

    yield put(
      masterDataManagerActions.saveMasterQAChangesSuccess({
        isSucceeded: true,
        masterQAEditMode: responseData.data,
      })
    );
    yield put(masterDataManagerActions.getMasterQAEditMode({}));
    yield put(masterDataManagerActions.getMasterQACount({}));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      masterDataManagerActions.saveMasterQAChangesFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveMasterQAChangesStatus() {
  yield takeEvery([masterDataManagerActionTypes.SAVE_MASTER_QA_CHANGES], saveMasterQAChangesSaga);
}
