export const actionTypes = {
  GET_REFERENCE_LIBRARY_FORM: 'GET_REFERENCE_LIBRARY_FORM',
  GET_REFERENCE_LIBRARY_FORM_SUCCESS: 'GET_REFERENCE_LIBRARY_FORM_SUCCESS',
  GET_REFERENCE_LIBRARY_FORM_FAILURE: 'GET_REFERENCE_LIBRARY_FORM_FAILURE',
  REFERENCE_LIBRARY_CLEAR: 'REFERENCE_LIBRARY_CLEAR',
  SAVE_REFERENCE_LIBRARY_FORM: 'SAVE_REFERENCE_LIBRARY_FORM',
  SAVE_REFERENCE_LIBRARY_FORM_SUCCESS: 'SAVE_REFERENCE_LIBRARY_FORM_SUCCESS',
  UPDATE_REFERENCE_LIBRARY_FORM_SUCCESS: 'UPDATE_REFERENCE_LIBRARY_FORM_SUCCESS',
  SAVE_REFERENCE_LIBRARY_FORM_FAILURE: 'SAVE_REFERENCE_LIBRARY_FORM_FAILURE',
  GET_REFERENCE_LIBRARY_CATEGORY: 'GET_REFERENCE_LIBRARY_CATEGORY',
  GET_REFERENCE_LIBRARY_CATEGORY_SUCCESS: 'GET_REFERENCE_LIBRARY_CATEGORY_SUCCESS',
  GET_REFERENCE_LIBRARY_CATEGORY_FAILURE: 'GET_REFERENCE_LIBRARY_CATEGORY_FAILURE',
};

export const actions = {
  getReferenceLibraryForm: (data) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_FORM,
    data,
  }),
  getReferenceLibraryFormSucceeded: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_FORM_SUCCESS,
    payload: content,
  }),
  getReferenceLibraryFormFailed: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_FORM_FAILURE,
    payload: content,
  }),
  referenceLibraryClear: () => ({
    type: actionTypes.REFERENCE_LIBRARY_CLEAR,
  }),
  saveReferenceLibraryForm: (data) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY_FORM,
    data,
  }),
  saveReferenceLibraryFormSucceeded: (content) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY_FORM_SUCCESS,
    payload: content,
  }),
  saveReferenceLibraryFormFailed: (content) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY_FORM_FAILURE,
    payload: content,
  }),
  getReferenceLibraryCategory: (data) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_CATEGORY,
    data,
  }),
  getReferenceLibraryCategorySucceeded: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_CATEGORY_SUCCESS,
    payload: content,
  }),
  getReferenceLibraryCategoryFailed: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_CATEGORY_FAILURE,
    payload: content,
  }),
  updateReferenceLibFormSuccess: (content) => ({
    type: actionTypes.UPDATE_REFERENCE_LIBRARY_FORM_SUCCESS,
    payload: content,
  }),
};
