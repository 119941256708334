import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { cloneDeep, get, uniqBy, padStart } from 'lodash';
import { projActivitiesActions, commonActions } from '../../../redux/actions';
import GenerateActivityTable from './generateActivityTable';
import DropdownWithCompletedInfo from '../../common/dropdownWithCompletedInfo';
import FormButtons from '../../common/formButtons';
import './styles.scss';
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import BVPagination from '../../../baseComponents/BVPagination';
import PageNumbers from '../../../baseComponents/BVPagination/pageNumbers';
import { ICONS } from '../../../constants/assets';

function GenerateActivityContainer(props) {
  const dispatch = useDispatch();

  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [property, setProperty] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddRow, setIsAddRow] = useState(false);
  const [completedProjectActivityCount, setCompletedProjectActivityCount] = useState(0);
  const [isSaved, setIsSaved] = useState(true);
  const [isResetEnable, setIsResetEnable] = useState(false);
  const [resetTableData, setResetTableData] = useState();
  const [activityTableData, setActivityTableData] = useState();
  const [isDefineActivityCompleted, setIsDefineActivityCompleted] = useState(false);
  const [isDisableComplete, setIsDisableComplete] = useState(true);
  const [isTableEmpty, setIsTableEmpty] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const {
    modifiedProjectActivityList,
    getModifiedProjActivityList,
    projectActivityTableData,
    saveModifiedProjActivityList,
    setModifiedProjectActivityList,
    saveProjActivityTableData,
    setDisableGenerateProjActLink,
    projActivityTableDataStatus,
    completeDefineActivity,
    setShowInfoModal,
    subMenus,
    showLoader,
    setFormEditing,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dropdownValue = queryParams.get('dropdown') || props.dropdownUrl;
  const isGlobalEditEnable = get(projectActivityTableData, 'isGlobalEditEnable', false);

  const setDropDownValuesInUrl = (key) => {
    if (dropdownValue) {
      queryParams.set('dropdown', key);
    } else {
      queryParams.append('dropdown', key);
    }
    history.push({ search: queryParams.toString() });
  };

  React.useEffect(() => {
    /* istanbul ignore else */
    if (modifiedProjectActivityList) {
      const { activityData, submenuId, projectNumber, disciplineDOS: discipline } = modifiedProjectActivityList;

      const dropDownData = {
        submenuId,
        projectNumber,
        discipline,
        activities: [],
      };

      activityData &&
        activityData.forEach((data) => {
          dropDownData.activities.push({
            name: data.dosActivity,
            key: data.refrenceKey,
            primary: data.primary,
            secondary: data.secondary,
            terciary: data.terciary,
            sortOrder: data.sortOrder,
            isActivityCompleted: data.isActivityCompleted,
            isBudgetCompleted: data.isBudgetCompleted,
            isActivityInGigworkflow: data.isActivityInGigworkflow,
            isActivityNotApplicable: data.isActivityNotApplicable ?? false,
            isCustomActivityCode: data.useCustomActivityCode ?? false
          });
        });

      setOptions(dropDownData.activities);

      let existsInUrl = false;
      let selectedOptionLocal = {};
      if (dropdownValue) {
        const dropdown = dropdownValue;
        const getDropDown = dropDownData.activities.filter((act) => act.key === dropdown);
        if (getDropDown && Array.isArray(getDropDown) && getDropDown.length) {
          setSelectedOption(getDropDown[0]);
          existsInUrl = true;
          selectedOptionLocal = getDropDown[0];
        }
      }

      /* istanbul ignore if */
      if (selectedOption || existsInUrl) {
        if (selectedOption) {
          const index = dropDownData.activities.findIndex((act) => act.name === selectedOption.name);
          setSelectedOption(dropDownData.activities[index]);
          setDropDownValuesInUrl(dropDownData.activities[index].key);
        } else {
          const index = dropDownData.activities.findIndex((act) => act.name === selectedOptionLocal.name);
          setSelectedOption(dropDownData.activities[index]);
          setDropDownValuesInUrl(dropDownData.activities[index].key);
        }
      } else {
        setSelectedOption(dropDownData.activities[0]);
        setDropDownValuesInUrl(dropDownData.activities[0].key);
      }

      setProperty('isActivityCompleted');
    }
  }, [modifiedProjectActivityList]);

  React.useEffect(() => {
    const { submenuId } = modifiedProjectActivityList;
    const submenu = subMenus.find((submenu) => submenu.id === submenuId);
    setIsDefineActivityCompleted(submenu.isComplete);
  }, [subMenus]);

  React.useEffect(() => {
    if (options && selectedOption) {
      const index = options.findIndex((option) => option.name === selectedOption.name);
      setSelectedOption(options[index]);
      setDropDownValuesInUrl(options[index].key);
    }
  }, [options]);

  React.useEffect(() => {
    const { activityData, projectNumber, submenuId, disciplineDOS: discipline } = modifiedProjectActivityList;
    setCurrentPage(0);
    setIsSearching(false);
    setSearchTerm('');
    const selectedOptionData =
      selectedOption && activityData.find((activity) => activity.dosActivity === selectedOption.name);
    selectedOptionData &&
      props.getProjActivityTableData({
        projectNumber,
        discipline,
        submenuId,
        refrenceKey: selectedOptionData.refrenceKey,
        page: 1,
        searchKey: '',
      });
  }, [selectedOption]);

  React.useEffect(() => {
    /* istanbul ignore else */
    if (projectActivityTableData) {
      setActivityTableData(cloneDeep(projectActivityTableData));
      projectActivityTableData.id === '' ? setIsSaved(false) : setIsSaved(true);
      setIsResetEnable(false);
      setResetTableData(cloneDeep(projectActivityTableData));
      setShowInfoModal(true);
      setIsTableEmpty(!projectActivityTableData.body.length);
    }
  }, [projectActivityTableData]);

  React.useEffect(() => {
    setFormEditing(!isSaved);
  }, [isSaved]);

  React.useEffect(() => {
    handleDisableComplete();
    if (activityTableData && activityTableData.pageNumber) {
      setCurrentPage(Number(activityTableData.pageNumber) - 1);
    }
  }, [isSaved, activityTableData]);

  React.useEffect(() => {
    completedProjectActivityCount > 0 ? setDisableGenerateProjActLink(true) : setDisableGenerateProjActLink(false);
  }, [completedProjectActivityCount]);

  React.useEffect(() => {
    if (projActivityTableDataStatus && projActivityTableDataStatus.status === 500) {
      setActivityTableData(null);
      setFormEditing(false);
    }
  }, [projActivityTableDataStatus]);

  React.useEffect(() => {
    if (isAddRow) handlePageChange({ selected: activityTableData.totalPages - 1 });
  }, [isAddRow]);

  const completeActivity = (selectedOption) => modifyDosActivityOption(selectedOption, true);

  const editActivity = (selectedOption) => {
    modifyDosActivityOption(selectedOption, false, true);
    handleComplete(false);
  };

  const handleNotApplicableChange = (selectedOption) => {
    // All this code just to flip a value should not be necessary
    const updatedOptions = [...options];
    const optionsIndex = updatedOptions.findIndex((opt) => opt.name === selectedOption.name);
    const updatedSelectedOption = updatedOptions[optionsIndex];
    updatedSelectedOption.isActivityNotApplicable = !updatedSelectedOption.isActivityNotApplicable;
    updatedOptions[optionsIndex] = updatedSelectedOption;
    setOptions(updatedOptions);
    setSelectedOption(updatedSelectedOption);


    saveActivityDropdownData();
  }

  const modifyDosActivityOption = (selectedOption, value, isEdit) => {
    const index = options.findIndex((opt) => opt.name === selectedOption.name);
    const newOptionsData = [...options];
    const newSelectedOptionData = { ...newOptionsData[index] };

    newSelectedOptionData[property] = value;
    options[index][property] = value;

    if (isEdit) {
      newSelectedOptionData.isBudgetCompleted = false;
      options[index].isBudgetCompleted = false;
    }

    newOptionsData[index] = newSelectedOptionData;

    setOptions(newOptionsData);
    setSelectedOption(newSelectedOptionData);
    setDropDownValuesInUrl(newSelectedOptionData.key);

    saveActivityDropdownData();
  };

  React.useEffect(() => {
    if (options) {
      const completedProjectActivityCount = options.reduce((prev, curr) => prev + (curr[property] === true || curr['isActivityNotApplicable'] ? 1 : 0), 0);
      setCompletedProjectActivityCount(completedProjectActivityCount);
    }
  }, [options]);

  const saveActivityDropdownData = () => {
    const { activityData, menuId, submenuId, projectNumber, disciplineDOS } = modifiedProjectActivityList;

    const payload = {
      menuId,
      submenuId,
      projectNumber,
      disciplineDOS,
      notSetResponseData: true,
      infoData: [],
    };

    options.map((option) => {
      const activity = activityData.find((act) => act.refrenceKey === option.key);
      activity.isActivityCompleted = option.isActivityCompleted;
      payload.infoData.push({
        dosActivity: activity.dosActivity,
        isActivityCompleted: activity.isActivityCompleted,
        isActivityInGigworkflow: option.isActivityInGigworkflow,
        isBudgetCompleted: option.isBudgetCompleted ? option.isBudgetCompleted : false,
        isActivityNotApplicable: option.isActivityNotApplicable ?? false,
      });
    });

    setModifiedProjectActivityList(cloneDeep(modifiedProjectActivityList));
    saveModifiedProjActivityList(payload);
  };

  const handleChange = (selectedOption) => {
    if (!isSaved) {
      dispatch(
        commonActions.infoPopup({ messageId: 'Please save your edits before proceeding.', theme: 'alert-danger' })
      );
    } else {
      const { projectNumber, menuId, submenuId } = modifiedProjectActivityList;
      getModifiedProjActivityList({ projectNumber, menuId, submenuId });
      setSelectedOption(selectedOption);
    }
  };

  const reCreateFullActivityName = () => {
    activityTableData.body.forEach((data) => {
      data.fullActivityName = `${activityTableData.dosActivity}-${data.activity}`;
    });
  };

  const handleSaveData = () => {
    reCreateFullActivityName();
    updateActivityTableData(activityTableData);
    setActivityTableData(cloneDeep(activityTableData));
    saveProjActivityTableData({
      ... activityTableData,
      isCustomActivityCode: selectedOption.isCustomActivityCode
    });
    setResetTableData(cloneDeep(activityTableData));
    setIsSaved(true);
    setIsResetEnable(false);
  };

  const updateActivityTableData = (activityTableData) => {
    activityTableData.body.forEach((data) => {
      data.fragnetNumberDOS = activityTableData.fragnetNumberDOS;
      data.subjectDOS = activityTableData.subjectDOS;
    });
    activityTableData.SerachParam = searchTerm;
  };

  const handleComplete = (status) => {
    !status && commonActions.infoPopup({ messageId: 'You can edit your Activities' });
    const { menuId, submenuId, projectNumber, disciplineDOS: discipline } = modifiedProjectActivityList;
    const manhourBudgetStatus = 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b';
    completeDefineActivity(getPayload(menuId, submenuId, projectNumber, status, discipline));
  };

  const getPayload = (menuId, submenuId, projectNumber, status, discipline) => ({
    projectNumber,
    menuId,
    submenuId,
    isComplete: status,
    discipline,
    showMessage: true,
  });

  const handleReset = () => {
    const data = cloneDeep(resetTableData);
    setActivityTableData(data);
    setIsResetEnable(false);
    setIsSaved(true);
  };

  const handleDisableComplete = () => {
    if (activityTableData && activityTableData.body) {

      const activityWithEmptyName = activityTableData.body.filter((a) => !a.activity);
      const isActivityHavingEmptyName = activityWithEmptyName.length > 0;
      
      const uniqueActivities = uniqBy(activityTableData.body, 'activity');
      const isHavingDuplicateActivity = uniqueActivities.length !== activityTableData.body.length;

      const activityWithEmptyCode = selectedOption.isCustomActivityCode && activityTableData.body.filter((activity) => !activity.customActivityCode || !activity.customCWACode);
      const isActivityHavingEmptyCode = selectedOption.isCustomActivityCode && activityWithEmptyCode.length > 0;

      //const customActivityCodes = selectedOption.isCustomActivityCode ?  activityTableData.body.map((activity) => activity?.customActivityCode && activity?.customActivityCode.toLowerCase()): [];
      //const isHavingDuplicateCode = selectedOption.isCustomActivityCode ? customActivityCodes.some((item, index) => item && customActivityCodes.indexOf(item) != index): false;

      //const customCWACodes = selectedOption.isCustomActivityCode ?  activityTableData.body.map((activity) => activity.customCWACode && activity?.customCWACode.toLowerCase()): [];
      //const isHavingCWADuplicateCode = selectedOption.isCustomActivityCode ?  customCWACodes.some((item, index) => item && customCWACodes.indexOf(item) != index): false;
      
	  // const uniqueActivitiesByCode = uniqBy(activityTableData.body, 'customActivityCode');
      // const isHavingDuplicateCode = selectedOption.isCustomActivityCode && uniqueActivitiesByCode.length !== activityTableData.body.length;

      // const activityCodeField = activityTableData.fields && activityTableData.fields.find((field) => field.key == 'customActivityCode');
      // let areActivityCodesValid = true;
      // for (let activity of activityTableData.body) {
      //   if (selectedOption.isCustomActivityCode && activityCodeField) {
      //     areActivityCodesValid = 
      //       areActivityCodesValid && 
      //         activity.customActivityCode &&
      //             activity.customActivityCode.length >= activityCodeField.validation.minLength && 
      //               activity.customActivityCode.length <= activityCodeField.validation.maxLength;
      //   }
      // }
      
      setIsDisableComplete(
        !isSaved ||
          isActivityHavingEmptyName ||
          isHavingDuplicateActivity ||
          searchTerm ||
          activityTableData.isCompleteDisable || 
          isActivityHavingEmptyCode
      );
    }
  };
  /* istanbul ignore next */
  const handlePageChange = (val, searchKey = '$') => {
    if (!isSaved) {
      dispatch(commonActions.infoPopup({ messageId: 'Save the form before leaving the page', theme: 'alert-danger' }));
    } else {
      //  index starting with 0
      const { activityData, projectNumber, submenuId, disciplineDOS: discipline } = modifiedProjectActivityList;

      const selectedOptionData =
        selectedOption && activityData.find((activity) => activity.dosActivity === selectedOption.name);
      const page = Number(val.selected);
      setCurrentPage(page);
      if (page + 1 != activityTableData.totalPages) {
        setIsAddRow(false);
      }
      const search = searchKey !== '' ? searchTerm : searchKey;
      selectedOptionData &&
        props.getProjActivityTableData({
          projectNumber,
          discipline,
          submenuId,
          refrenceKey: selectedOptionData.refrenceKey,
          page: page + 1,
          searchKey: search,
          // dosActivity: selectedOptionData.dosActivity,
        });
    }
  };
  /* istanbul ignore next */
  const openSearch = () => {
    setIsSearching(true);
  };
  /* istanbul ignore next */
  const clearSearch = () => {
    if (!isSaved) {
      dispatch(
        commonActions.infoPopup({ messageId: 'Please save your edits before proceeding.', theme: 'alert-danger' })
      );
    } else {
      setIsSearching(false);
      setSearchTerm('');
      handlePageChange({ selected: 0 }, '');
    }
  };
  /* istanbul ignore next */
  const handleSearchKeyDown = (event) => {
    if (!isSaved) {
      dispatch(
        commonActions.infoPopup({ messageId: 'Please save your edits before proceeding.', theme: 'alert-danger' })
      );
    } else if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      setCurrentPage(0);
      //  index starting with 0
      const { activityData, projectNumber, submenuId, disciplineDOS: discipline } = modifiedProjectActivityList;

      const selectedOptionData =
        selectedOption && activityData.find((activity) => activity.dosActivity === selectedOption.name);
      selectedOptionData &&
        props.getProjActivityTableData({
          projectNumber,
          discipline,
          submenuId,
          refrenceKey: selectedOptionData.refrenceKey,
          page: 1,
          searchKey: event.target.value,
        });
    }
  };
  return (
    <>
      <div className="genAct-dropdown">
        <DropdownWithCompletedInfo
          options={options}
          property={property}
          selectedOption={selectedOption}
          count={completedProjectActivityCount}
          total={options && options.length}
          completeActivity={completeActivity}
          editActivity={editActivity}
          handleChange={handleChange}
          disableComplete={isDisableComplete}
          searchTerm={searchTerm}
          isTableEmpty={isTableEmpty}
          handleOptionDisable={() => false}
          editStatus={props.isComplete}
          isNotApplicableShown={true}
          isNotApplicableDisabled={!isTableEmpty || (options?.length === completedProjectActivityCount && isDefineActivityCompleted)}
          handleNotApplicableChange={handleNotApplicableChange}
        />
        <div className="search-container">
          {!isSearching ? (
            <img
              src={ICONS.SEARCH}
              className="search-icon"
              alt="search-icon"
              role="presentation"
              onClick={openSearch}
            />
          ) : (
            <span className="search-input-container">
              <input
                type="text"
                autoComplete="off"
                className="search-input"
                placeholder="Search here..."
                // added to enable focus on search box when it opens
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={isSearching}
                onKeyDown={handleSearchKeyDown}
              />
              <img
                src={ICONS.CLOSEX}
                className="clear-icon"
                alt="clear-icon"
                role="presentation"
                onClick={clearSearch}
              />
              <span>
                {activityTableData && activityTableData.matchCount == undefined ? (
                  ''
                ) : activityTableData.matchCount === 0 ? (
                  <span className="red">No Matches Found</span>
                ) : (
                  <span className="green">{activityTableData.matchCount} Matches</span>
                )}
              </span>
            </span>
          )}
        </div>
      </div>
      <div className= {`genAct-table ${selectedOption?.isCustomActivityCode && activityTableData?.disciplineNameKey !== 'piping' && 'genAct-table-width-plus-20'}`}>
        {selectedOption && activityTableData && (
          <>
            <GenerateActivityTable
              tableData={activityTableData}
              selectedOption={{ ...selectedOption }}
              property={property}
              setIsSaved={setIsSaved}
              setIsAddRow={setIsAddRow}
              isAddRow={isAddRow}
              isSaved={isSaved}
              setIsResetEnable={setIsResetEnable}
              resetTableData={resetTableData}
              currentPage={currentPage}
              isTableDisabled={selectedOption[property] || selectedOption['isActivityNotApplicable']}
            />
            <FormButtons
              resetStatus={isResetEnable}
              resetAction={handleReset}
              saveStatus={!isSaved}
              saveAction={handleSaveData}
              completeStatus={options && options.length === completedProjectActivityCount && !isDefineActivityCompleted}
              completeAction={() => handleComplete(true)}
              formComplete={props.isComplete}
              isEditEnable={isGlobalEditEnable}
              editAction={() => handleComplete(false)}
            />
            <div className="defineActivity-pagination">
              {isSaved && (
                <BVPagination
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={10}
                  pageCount={activityTableData.totalPages}
                  marginPagesDisplayed={5}
                  forcePage={currentPage}
                />
              )}
              <PageNumbers
                pageCount={activityTableData.totalPages}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

GenerateActivityContainer = reduxForm({
  form: 'GenerateActivitySelect',
  touchOnChange: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(GenerateActivityContainer);

export const mapStateToProps = (state) => ({
  projectActivityTableData: get(state, 'projActivitiesInformation.projectActivityTableData'),
  subMenus: get(state, 'projectSetupReducer.submenuList.menuItems.subMenus'),
  projActivityTableDataStatus: get(state, 'projActivitiesInformation.projActivityTableDataStatus'),
});

export const mapDispatchToProps = (dispatch) => ({
  getProjActivityTableData: (data) => dispatch(projActivitiesActions.getProjActivityTableData(data)),
  getModifiedProjActivityList: (data) => dispatch(projActivitiesActions.getModifiedProjActivityList(data)),
  saveModifiedProjActivityList: (data) => dispatch(projActivitiesActions.saveModifiedProjActivityList(data)),
  saveProjActivityTableData: (data) => dispatch(projActivitiesActions.saveProjActivityTableData(data)),
  completeDefineActivity: (data) => dispatch(projActivitiesActions.completeDefineActivity(data)),
  setFormEditing: (data) => dispatch(commonActions.formEditing({ formEditing: data })),
  setProjActivityTableData: (data) => dispatch(projActivitiesActions.setProjActivityTableData(data)),
  showLoader: (data) => dispatch(commonActions.showLoader(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateActivityContainer);
