export const actionTypes = {
  GET_MASTER_GIG_DASHBOARD_DATA: 'GET_MASTER_GIG_DASHBOARD_DATA',
  GET_MASTER_GIG_DASHBOARD_DATA_SUCCESS: 'GET_MASTER_GIG_DASHBOARD_DATA_SUCCESS',
  GET_MASTER_GIG_DASHBOARD_DATA_FAILURE: 'GET_MASTER_GIG_DASHBOARD_DATA_FAILURE',
};

export const actions = {
  getMasterGigDashboardData: (data) => ({
    type: actionTypes.GET_MASTER_GIG_DASHBOARD_DATA,
    data,
  }),
  getMasterGigDashboardDataSuccess: (data) => ({
    type: actionTypes.GET_MASTER_GIG_DASHBOARD_DATA_SUCCESS,
    data,
  }),
  getMasterGigDashboardDataFailure: (data) => ({
    type: actionTypes.GET_MASTER_GIG_DASHBOARD_DATA_FAILURE,
    data,
  }),
};
