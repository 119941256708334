export const actionTypes = {
  INFO_POPUP: 'INFO_POPUP',
  HEADER_ALERT: 'HEADER_ALERT',
  NOTIFICATION_COUNT: 'NOTIFICATION_COUNT',
  FORM_EDITING: 'FORM_EDITING',
  SHOW_LOADER: 'SHOW_LOADER',
  MODAL_POPUP: 'MODAL_POPUP',
  ADD_FEATURE_FLAG: 'ADD_FEATURE_FLAG',
  REMOVE_FEATURE_FLAG: 'REMOVE_FEATURE_FLAG',
  SHOW_NOTIFICATION_LOADER: 'SHOW_NOTIFICATION_LOADER',
  NOTIFICATION_COUNT_NON_LAMINAR: 'NOTIFICATION_COUNT_NON_LAMINAR',
};

export const actions = {
  infoPopup: (data) => ({ type: actionTypes.INFO_POPUP, data }),
  headerAlert: (data) => ({ type: actionTypes.HEADER_ALERT, data }),
  notificationCount: (data) => ({ type: actionTypes.NOTIFICATION_COUNT, data }),
  setNotificationCountNonLaminar: (data) => ({ type: actionTypes.NOTIFICATION_COUNT_NON_LAMINAR, data }),
  formEditing: (formEditing) => ({
    type: actionTypes.FORM_EDITING,
    formEditing,
  }),
  modalPopup: (data) => ({ type: actionTypes.MODAL_POPUP, data }),
  showLoader: (data) => ({ type: actionTypes.SHOW_LOADER, data }),
  showNotificationLoader: (data) => ({ type: actionTypes.SHOW_NOTIFICATION_LOADER, data }),
  addFeatureFlag: (data) => ({ type: actionTypes.ADD_FEATURE_FLAG, payload: data }),
  removeFeatureFlag: (data) => ({ type: actionTypes.REMOVE_FEATURE_FLAG, payload: data }),
};
