export const actionTypes = {
  // Laminar Scope Overview
  GET_LAMINAR_SCOPE_OVERVIEW_FORM: 'GET_LAMINAR_SCOPE_OVERVIEW_FORM',
  GET_LAMINAR_SCOPE_OVERVIEW_FORM_SUCCESS: 'GET_LAMINAR_SCOPE_OVERVIEW_FORM_SUCCESS',
  GET_LAMINAR_SCOPE_OVERVIEW_FORM_FAILURE: 'GET_LAMINAR_SCOPE_OVERVIEW_FORM_FAILURE',
  REQUEST_ASSUMPTION: 'REQUEST_ASSUMPTION',
  REQUEST_ASSUMPTION_SUCCESS: 'REQUEST_ASSUMPTION_SUCCESS',
  REQUEST_ASSUMPTION_FAILURE: 'REQUEST_ASSUMPTION_FAILURE',
  BYPASS_MANAGER_REQUEST_ASSUMPTION: 'BYPASS_MANAGER_REQUEST_ASSUMPTION',
  BYPASS_MANAGER_REQUEST_ASSUMPTION_SUCCESS: 'BYPASS_MANAGER_REQUEST_ASSUMPTION_SUCCESS',
  BYPASS_MANAGER_REQUEST_ASSUMPTION_FAILURE: 'BYPASS_MANAGER_REQUEST_ASSUMPTION_FAILURE',
  SAVE_GIG_INSTRUCTION: 'SAVE_GIG_INSTRUCTION',
  SAVE_GIG_INSTRUCTION_SUCCESS: 'SAVE_GIG_INSTRUCTION_SUCCESS',
  SAVE_GIG_INSTRUCTION_FAILURE: 'SAVE_GIG_INSTRUCTION_FAILURE',
  SAVE_REQUEST_ASSUMPTION: 'SAVE_REQUEST_ASSUMPTION',
  SAVE_REQUEST_ASSUMPTION_SUCCESS: 'SAVE_REQUEST_ASSUMPTION_SUCCESS',
  SAVE_REQUEST_ASSUMPTION_FAILURE: 'SAVE_REQUEST_ASSUMPTION_FAILURE',
  GET_MISSING_IO_TABLE: 'GET_MISSING_IO_TABLE',
  GET_MISSING_IO_TABLE_SUCCESS: 'GET_MISSING_IO_TABLE_SUCCESS',
  GET_MISSING_IO_TABLE_FAILURE: 'GET_MISSING_IO_TABLE_FAILURE',
  UPDATE_MISSING_IO_TABLE: 'UPDATE_MISSING_IO_TABLE',
  UPDATE_MISSING_IO_TABLE_SUCCESS: 'UPDATE_MISSING_IO_TABLE_SUCCESS',
  UPDATE_MISSING_IO_TABLE_FAILURE: 'UPDATE_MISSING_IO_TABLE_FAILURE',
  SAVE_OUTPUT_CHECKLIST_DATA: 'SAVE_OUTPUT_CHECKLIST_DATA',
  SAVE_OUTPUT_CHECKLIST_DATA_SUCCESS: 'SAVE_OUTPUT_CHECKLIST_DATA_SUCCESS',
  SAVE_OUTPUT_CHECKLIST_DATA_FAILURE: 'SAVE_OUTPUT_CHECKLIST_DATA_FAILURE',
  SAVE_LOG_TIME_DATA: 'SAVE_LOG_TIME_DATA',
  SAVE_LOG_TIME_DATA_SUCCESS: 'SAVE_LOG_TIME_DATA_SUCCESS',
  SAVE_LOG_TIME_DATA_FAILURE: 'SAVE_LOG_TIME_DATA_FAILURE',
  SAVE_GIG_LOG_TIME_HISTORY: 'SAVE_GIG_LOG_TIME_HISTORY',
  SAVE_GIG_LOG_TIME_HISTORY_SUCCESS: 'SAVE_GIG_LOG_TIME_HISTORY_SUCCESS',
  SAVE_GIG_LOG_TIME_HISTORY_FAILURE: 'SAVE_GIG_LOG_TIME_HISTORY_FAILURE',

  ASSIGN_GIG_REQUEST: 'ASSIGN_GIG_REQUEST',
  ASSIGN_GIG_REQUEST_SUCCESS: 'ASSIGN_GIG_REQUEST_SUCCESS',
  ASSIGN_GIG_REQUEST_FAILURE: 'ASSIGN_GIG_REQUEST_FAILURE',
  GIGTRANSFER_REQUEST: 'GIGTRANSFER_REQUEST',
  GIGTRANSFER_REQUEST_SUCCESS: 'GIGTRANSFER_REQUEST_SUCCESS',
  GIGTRANSFER_REQUEST_FAILURE: 'GIGTRANSFER_REQUEST_FAILURE',
  GIGTRANSFER_REQUEST_CANCELLATION: 'GIGTRANSFER_REQUEST_CANCELLATION',
  GIGTRANSFER_REQUEST_CANCELLATION_SUCCESS: 'GIGTRANSFER_REQUEST_CANCELLATION_SUCCESS',
  GIGTRANSFER_REQUEST_CANCELLATION_FAILURE: 'GIGTRANSFER_REQUEST_CANCELLATION_FAILURE',
  UPDATE_GIG_STATUS: 'UPDATE_GIG_STATUS',
  UPDATE_GIG_STATUS_SUCCESS: 'UPDATE_GIG_STATUS_SUCCESS',
  UPDATE_GIG_STATUS_FAILURE: 'UPDATE_GIG_STATUS_FAILURE',
  GET_GIG_STATUS: 'GET_GIG_STATUS',
  GET_GIG_STATUS_SUCCESS: 'GET_GIG_STATUS_SUCCESS',
  GET_GIG_STATUS_FAILURE: 'GET_GIG_STATUS_FAILURE',
  GET_ACTION_DROPDOWN: 'GET_ACTION_DROPDOWN',
  GET_ACTION_DROPDOWN_SUCCESS: 'GET_ACTION_DROPDOWN_SUCCESS',
  GET_ACTION_DROPDOWN_FAILURE: 'GET_ACTION_DROPDOWN_FAILURE',
  CLEAR_ACTION_DROPDOWN: 'CLEAR_ACTION_DROPDOWN',
  GET_USERS_DROPDOWN: 'GET_USERS_DROPDOWN',
  GET_USERS_DROPDOWN_SUCCESS: 'GET_USERS_DROPDOWN_SUCCESS',
  GET_USERS_DROPDOWN_FAILURE: 'GET_USERS_DROPDOWN_FAILURE',
  GET_REQUIRED_INPUT_DROPDOWN_OPTIONS: 'GET_REQUIRED_INPUT_DROPDOWN_OPTIONS',
  GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_SUCCESS: 'GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_SUCCESS',
  GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_FAILURE: 'GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_FAILURE',
  SAVE_EDIT_REQUIRED_INPUT: 'SAVE_EDIT_REQUIRED_INPUT',
  SAVE_EDIT_REQUIRED_INPUT_SUCCESS: 'SAVE_EDIT_REQUIRED_INPUT_SUCCESS',
  SAVE_EDIT_REQUIRED_INPUT_FAILURE: 'SAVE_EDIT_REQUIRED_INPUT_FAILURE',
  SAVE_SELECT_INPUT_VERSION: 'SAVE_SELECT_INPUT_VERSION',
  SAVE_SELECT_INPUT_VERSION_SUCCESS: 'SAVE_SELECT_INPUT_VERSION_SUCCESS',
  SAVE_SELECT_INPUT_VERSION_FAILURE: 'SAVE_SELECT_INPUT_VERSION_FAILURE',
};

export const actions = {
  getLaminarScopeOverviewForm: (data, refreshActions) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM,
    data,
    refreshActions,
  }),
  getLaminarScopeOverviewFormSucceeded: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM_SUCCESS,
    payload: content,
  }),
  getLaminarScopeOverviewFormFailed: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM_FAILURE,
    payload: content,
  }),

  requestAssumption: (data) => ({
    type: actionTypes.REQUEST_ASSUMPTION,
    data,
  }),

  requestAssumptionSucceeded: (content) => ({
    type: actionTypes.REQUEST_ASSUMPTION_SUCCESS,
    payload: content,
  }),

  requestAssumptionFailed: (content) => ({
    type: actionTypes.REQUEST_ASSUMPTION_FAILURE,
    payload: content,
  }),

  saveRequestAssumption: (data) => ({
    type: actionTypes.SAVE_REQUEST_ASSUMPTION,
    data,
  }),

  saveRequestAssumptionSucceeded: (content) => ({
    type: actionTypes.SAVE_REQUEST_ASSUMPTION_SUCCESS,
    payload: content,
  }),

  saveRequestAssumptionFailed: (content) => ({
    type: actionTypes.SAVE_REQUEST_ASSUMPTION_FAILURE,
    payload: content,
  }),

  bypassManagerRequestAssumption: (data) => ({
    type: actionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION,
    data,
  }),

  bypassManagerRequestAssumptionSucceeded: (content) => ({
    type: actionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION_SUCCESS,
    payload: content,
  }),

  bypassManagerRequestAssumptionFailed: (content) => ({
    type: actionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION_FAILURE,
    payload: content,
  }),


  getMissingIOTable: (data) => ({
    type: actionTypes.GET_MISSING_IO_TABLE,
    data,
  }),

  getMissingIOTableSucceeded: (content) => ({
    type: actionTypes.GET_MISSING_IO_TABLE_SUCCESS,
    payload: content,
  }),

  getMissingIOTableFailed: (content) => ({
    type: actionTypes.GET_MISSING_IO_TABLE_FAILURE,
    payload: content,
  }),

  updateMissingIOTable: (data) => ({
    type: actionTypes.UPDATE_MISSING_IO_TABLE,
    data,
  }),

  updateMissingIOTableSucceeded: (content) => ({
    type: actionTypes.UPDATE_MISSING_IO_TABLE_SUCCESS,
    payload: content,
  }),

  updateMissingIOTableFailed: (content) => ({
    type: actionTypes.UPDATE_MISSING_IO_TABLE_FAILURE,
    payload: content,
  }),

  saveGigInstruction: (content) => ({
    type: actionTypes.SAVE_GIG_INSTRUCTION,
    payload: content,
  }),

  saveGigInstructionSucceeded: (content) => ({
    type: actionTypes.SAVE_GIG_INSTRUCTION_SUCCESS,
    payload: content,
  }),

  saveGigInstructionFailed: (content) => ({
    type: actionTypes.SAVE_GIG_INSTRUCTION_FAILURE,
    payload: content,
  }),

  saveOutputChecklistData: (content) => ({
    type: actionTypes.SAVE_OUTPUT_CHECKLIST_DATA,
    payload: content,
  }),

  saveOutputChecklistDataSucceeded: (content) => ({
    type: actionTypes.SAVE_OUTPUT_CHECKLIST_DATA_SUCCESS,
    payload: content,
  }),

  saveOutputChecklistDataFailed: (content) => ({
    type: actionTypes.SAVE_OUTPUT_CHECKLIST_DATA_FAILURE,
    payload: content,
  }),

  saveLogTimeData: (content) => ({
    type: actionTypes.SAVE_LOG_TIME_DATA,
    payload: content,
  }),

  saveLogTimeDataSucceeded: (content) => ({
    type: actionTypes.SAVE_LOG_TIME_DATA_SUCCESS,
    payload: content,
  }),

  saveLogTimeDataFailed: (content) => ({
    type: actionTypes.SAVE_LOG_TIME_DATA_FAILURE,
    payload: content,
  }),

  saveGigLogTimeHistory: (content) => ({
    type: actionTypes.SAVE_GIG_LOG_TIME_HISTORY,
    payload: content,
  }),

  saveGigLogTimeHistorySucceeded: (content) => ({
    type: actionTypes.SAVE_GIG_LOG_TIME_HISTORY_SUCCESS,
    payload: content,
  }),

  saveGigLogTimeHistoryFailed: (content) => ({
    type: actionTypes.SAVE_GIG_LOG_TIME_HISTORY_FAILURE,
    payload: content,
  }),

  // Laminar Scope Actions Section
  assignGigRequest: (content, workItem, refreshData) => ({
    type: actionTypes.ASSIGN_GIG_REQUEST,
    payload: content,
    workItem,
    refreshData,
  }),

  assignGigRequestSucceeded: (content) => ({
    type: actionTypes.ASSIGN_GIG_REQUEST_SUCCESS,
    payload: content,
  }),

  assignGigRequestFailed: (content) => ({
    type: actionTypes.ASSIGN_GIG_REQUEST_FAILURE,
    payload: content,
  }),

  gigTransferRequest: (content, refreshData) => ({
    type: actionTypes.GIGTRANSFER_REQUEST,
    payload: content,
    refreshData,
  }),

  gigTransferRequestSucceeded: (content) => ({
    type: actionTypes.GIGTRANSFER_REQUEST_SUCCESS,
    payload: content,
  }),

  gigTransferRequestFailed: (content) => ({
    type: actionTypes.GIGTRANSFER_REQUEST_FAILURE,
    payload: content,
  }),

  gigTransferRequestCancellation: (content, refreshData, myProjectHome) => ({
    type: actionTypes.GIGTRANSFER_REQUEST_CANCELLATION,
    payload: content,
    refreshData,
    myProjectHome,
  }),

  gigTransferRequestCancellationSucceeded: (content) => ({
    type: actionTypes.GIGTRANSFER_REQUEST_CANCELLATION_SUCCESS,
    payload: content,
  }),

  gigTransferRequestCancellationFailed: (content) => ({
    type: actionTypes.GIGTRANSFER_REQUEST_CANCELLATION_FAILURE,
    payload: content,
  }),

  updateGigStatus: (content, refreshData, refreshFragSteps) => ({
    type: actionTypes.UPDATE_GIG_STATUS,
    payload: content,
    refreshData,
    refreshFragSteps,
  }),

  updateGigStatusSucceeded: (content) => ({
    type: actionTypes.UPDATE_GIG_STATUS_SUCCESS,
    payload: content,
  }),

  updateGigStatusFailed: (content) => ({
    type: actionTypes.UPDATE_GIG_STATUS_FAILURE,
    payload: content,
  }),

  getGigStatus: (content) => ({
    type: actionTypes.GET_GIG_STATUS,
    payload: content,
  }),

  getGigStatusSucceeded: (content) => ({
    type: actionTypes.GET_GIG_STATUS_SUCCESS,
    payload: content,
  }),

  getGigStatusFailed: (content) => ({
    type: actionTypes.GET_GIG_STATUS_FAILURE,
    payload: content,
  }),

  getActionDropdown: (content) => ({
    type: actionTypes.GET_ACTION_DROPDOWN,
    payload: content,
  }),

  getActionDropdownSucceeded: (content) => ({
    type: actionTypes.GET_ACTION_DROPDOWN_SUCCESS,
    payload: content,
  }),

  getActionDropdownFailed: (content) => ({
    type: actionTypes.GET_ACTION_DROPDOWN_FAILURE,
    payload: content,
  }),

  clearActionDropdown: () => ({
    type: actionTypes.CLEAR_ACTION_DROPDOWN,
  }),

  getUsersDropdown: (content) => ({
    type: actionTypes.GET_USERS_DROPDOWN,
    payload: content,
  }),

  getUsersDropdownSucceeded: (content) => ({
    type: actionTypes.GET_USERS_DROPDOWN_SUCCESS,
    payload: content,
  }),

  getUsersDropdownFailed: (content) => ({
    type: actionTypes.GET_USERS_DROPDOWN_FAILURE,
    payload: content,
  }),
  //
  getRequiredInputDropdownOptions: (content) => ({
    type: actionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS,
    payload: content,
  }),

  getRequiredInputDropdownOptionsSucceeded: (content) => ({
    type: actionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_SUCCESS,
    payload: content,
  }),

  getRequiredInputDropdownOptionsFailed: (content) => ({
    type: actionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS_FAILURE,
    payload: content,
  }),
  //
  saveEditRequiredInput: (content) => ({
    type: actionTypes.SAVE_EDIT_REQUIRED_INPUT,
    payload: content,
  }),

  saveEditRequiredInputSucceeded: (content) => ({
    type: actionTypes.SAVE_EDIT_REQUIRED_INPUT_SUCCESS,
    payload: content,
  }),

  saveEditRequiredInputFailed: (content) => ({
    type: actionTypes.SAVE_EDIT_REQUIRED_INPUT_FAILURE,
    payload: content,
  }),

  saveSelectInputVersion: (data) => ({
    type: actionTypes.SAVE_SELECT_INPUT_VERSION,
    data,
  }),

  saveSelectInputVersionSucceeded: (content) => ({
    type: actionTypes.SAVE_SELECT_INPUT_VERSION_SUCCESS,
    payload: content,
  }),

  saveSelectInputVersionFailed: (content) => ({
    type: actionTypes.SAVE_SELECT_INPUT_VERSION_FAILURE,
    payload: content,
  }),
};
