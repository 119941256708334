import { put, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, projectGigsTableActions, projectGigsTableActionTypes } from '../actions';

export function* getProjectGigsTableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { body } = payload.data;
    const responseData = yield call(AxiosInstance.post, servicelist('projectGigsTable'), body);
    yield put(
      projectGigsTableActions.getProjectGigsTableSucceeded({
        isSucceeded: true,
        projectGigsTableData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectGigsTableActions.getProjectGigsTableFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectGigsTableStatus() {
  yield takeEvery([projectGigsTableActionTypes.GET_PROJECT_GIGS_TABLE], getProjectGigsTableSaga);
}

export function* massAssignGigRequestSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const projectGigPayload = get(payload, 'data.projectGigPayload', {});
    delete payload.data.projectGigPayload;
    const responseData = yield call(AxiosInstance.post, servicelist('assignGigRequest'), payload.data);
    yield put(
      projectGigsTableActions.massAssignGigSucceeded({
        isSucceeded: true,
        assignGigResponse: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Gig Assigned Successfully',
      })
    );

    yield put(
      projectGigsTableActions.getProjectGigsTable({
        body: projectGigPayload,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      projectGigsTableActions.massAssignGigFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* massAssignGigRequestStatus() {
  yield takeEvery([projectGigsTableActionTypes.MASS_ASSIGN_GIGS], massAssignGigRequestSaga);
}
