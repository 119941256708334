import axios from 'axios';
import { get } from 'lodash';
import { InteractionRequiredAuthError } from 'msal';
import { authActions, commonActions } from '../../redux/actions';
import store from '../../redux/store';
import { getItemFromLocalStorage, setItemToLocalStorage } from '../../helpers/localStorage';
import servicelist from '../serviceList';
import { LOGIN_TYPES } from '../../constants/common';
import { loginRequest, msalInstance, tokenRequestForGraphApi } from '../../constants/config/msalConfig';

const AxiosInstance = axios.create({
  timeout: 40000,
});

// timeout need to be optimized mail api taking more time for temp kept 40000

const acquireTokenSilentCall = async (response) => {
  // fetch loginDetails from API
  const res = await axios.post(servicelist('adLoginService'), {
    userEmail: get(response, 'account.userName', ''),
    accessToken: get(response, 'accessToken', ''),
  });

  setItemToLocalStorage(
    'msalExpiry',
    JSON.stringify({
      adTokenExpiryTime: response.expiresOn,
    })
  );
  store.dispatch(
    authActions.msalAuthSucceeded({
      isLoggedIn: true,
      loginType: LOGIN_TYPES.AD_LOGIN,
      loginResponse: res.data,
    })
  );
  return res.data.token;
};

// request interceptor
AxiosInstance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    let isExpiriedSession = false;
    let token = get(state, 'authReducer.loginResponse.token', '');
    const expiresOn = JSON.parse(getItemFromLocalStorage('msalExpiry'));
    if (expiresOn !== null) {
      isExpiriedSession = new Date() * 1000 > new Date(expiresOn.adTokenExpiryTime) * 1000;
    }
    if (token === '' || isExpiriedSession) {
      // Normal LOGIN
      if (getItemFromLocalStorage('loginData')) {
        const loginData = JSON.parse(getItemFromLocalStorage('loginData'));
        token = get(loginData, 'token', '');
        if (token !== '') {
          // restore login response from localStorage for userDetails
          store.dispatch(
            authActions.loginAuthSucceeded({
              isLoggedIn: true,
              loginResponse: loginData,
            })
          );
        }
      } else {
        try {
          // AD LOGIN
          // if (isExpiriedSession) {
          //   store.dispatch(
          //     commonActions.infoPopup({
          //       messageId: 'Token expired relogin required',
          //       theme: 'alert-warning',
          //       hide: false,
          //     })
          //   );
          // }
          const response = await msalInstance.acquireTokenSilent(tokenRequestForGraphApi);
          if (msalInstance.getAccount()) {
            token = await acquireTokenSilentCall(response);
          }
        } catch (err) {
          if (err.name === 'InteractionRequiredAuthError') {
            const response = await msalInstance.acquireTokenPopup(loginRequest);
            token = await acquireTokenSilentCall(response);
          }
        }
      }
    }
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response interceptor
AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const state = store.getState();
    const originalRequest = error.config;
    const responseStatus = get(error, 'response.status', false);
    const projectNumber = get(state, 'projectSetupReducer.projectDetails.projectNumber', false);
    if (responseStatus === 401 && !originalRequest._retry && get(error, 'response.data.status', '') !== 407) {
      originalRequest._retry = true;
      if (error instanceof InteractionRequiredAuthError) {
        try {
          const response = await msalInstance.acquireTokenPopup(loginRequest);
          const token = await acquireTokenSilentCall(response);
          originalRequest.headers.Authorization = token;
          return axios(originalRequest);
        } catch (err) {
          window.location.replace((window.location.protocol + '//' + window.location.host + '/'));
        }
      } else {
        return axios
          .post(servicelist('refreshToken'), {
            refreshToken: get(state, 'authReducer.loginResponse.token', ''),
          })
          .then(
            (response) => {
              const token = get(response, 'data.token', '');
              const responseData = get(response, 'data', '');
              if (responseData !== '') {
                // restore login response from localstorage for userdetails
                store.dispatch(
                  authActions.loginAuthSucceeded({
                    isLoggedIn: true,
                    loginResponse: responseData,
                  })
                );
                if (process.env.REACT_APP_USER_EMAIL_LOGIN === 'true') {
                  setItemToLocalStorage('loginData', JSON.stringify(responseData));
                }
              }
              originalRequest.headers.Authorization = token;
              return axios(originalRequest);
            },
            (error) => {
              window.location.replace((window.location.protocol + '//' + window.location.host + '/'));
            }
          );
      }
    }
    if (responseStatus === 401 && originalRequest._retry && get(error, 'response.data.status', '') !== 407) {
      return window.location.replace((window.location.protocol + '//' + window.location.host + '/'));
    }
    if (responseStatus === 409 && get(error, 'response.data.key', '') === 'managerDeclineRedirect') {
      store.dispatch(
        commonActions.infoPopup({
          messageId: get(error, 'response.data.message', ''),
          theme: 'alert-danger',
        })
      );
      setTimeout(() => window.location.replace(`/engineeringExecution/change-management/${projectNumber}`), 2000);
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
