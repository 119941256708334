export const actionTypes = {
  GET_ITERATION_LIST: 'GET_ITERATION_LIST',
  GET_ITERATION_LIST_SUCCESS: 'GET_ITERATION_LIST_SUCCESS',
  GET_ITERATION_LIST_FAILURE: 'GET_ITERATION_LIST_FAILURE',
  ADD_P6_ITERATION: 'ADD_P6_ITERATION',
  ADD_P6_ITERATION_SUCCESS: 'ADD_P6_ITERATION_SUCCESS',
  ADD_P6_ITERATION_FAILURE: 'ADD_P6_ITERATION_FAILURE',
  RELEASE_P6_ITERATION: 'RELEASE_P6_ITERATION',
  RELEASE_P6_ITERATION_SUCCESS: 'RELEASE_P6_ITERATION_SUCCESS',
  RELEASE_P6_ITERATION_FAILURE: 'RELEASE_P6_ITERATION_FAILURE',
  GET_P6_FORM: 'GET_P6_FORM',
  GET_P6_FORM_SUCCESS: 'GET_P6_FORM_SUCCESS',
  GET_P6_FORM_FAILURE: 'GET_P6_FORM_FAILURE',
  SEND_P6_NOTIFICATION: 'SEND_P6_NOTIFICATION',
  SEND_P6_NOTIFICATION_SUCCESS: 'SEND_P6_NOTIFICATION_SUCCESS',
  SEND_P6_NOTIFICATION_FAILURE: 'SEND_P6_NOTIFICATION_FAILURE',
  EXPORT_P6_TABLE: 'EXPORT_P6_TABLE',
  EXPORT_P6_TABLE_SUCCESS: 'EXPORT_P6_TABLE_SUCCESS',
  EXPORT_P6_TABLE_FAILURE: 'EXPORT_P6_TABLE_FAILURE',
  GET_P6_PROJECT_LIST: 'GET_P6_PROJECT_LIST',
  GET_P6_PROJECT_LIST_SUCCESS: 'GET_P6_PROJECT_LIST_SUCCESS',
  GET_P6_PROJECT_LIST_FAILURE: 'GET_P6_PROJECT_LIST_FAILURE',
  CLEAR_P6_DATA: 'CLEAR_P6_DATA',
};

export const actions = {
  getIterationList: (data) => ({
    type: actionTypes.GET_ITERATION_LIST,
    data,
  }),
  getIterationListSucceeded: (content) => ({
    type: actionTypes.GET_ITERATION_LIST_SUCCESS,
    payload: content,
  }),

  getIterationListFailed: (content) => ({
    type: actionTypes.GET_ITERATION_LIST_FAILURE,
    payload: content,
  }),

  clearP6Data: (content) => ({
    type: actionTypes.CLEAR_P6_DATA,
    payload: content,
  }),

  addP6Iteration: (data) => ({
    type: actionTypes.ADD_P6_ITERATION,
    data,
  }),
  addP6IterationSucceeded: (content) => ({
    type: actionTypes.ADD_P6_ITERATION_SUCCESS,
    payload: content,
  }),

  addP6IterationFailed: (content) => ({
    type: actionTypes.ADD_P6_ITERATION_FAILURE,
    payload: content,
  }),

  releaseP6Iteration: (data) => ({
    type: actionTypes.RELEASE_P6_ITERATION,
    data,
  }),
  releaseP6IterationSucceeded: (content) => ({
    type: actionTypes.RELEASE_P6_ITERATION_SUCCESS,
    payload: content,
  }),

  releaseP6IterationFailed: (content) => ({
    type: actionTypes.RELEASE_P6_ITERATION_FAILURE,
    payload: content,
  }),

  getP6form: (data) => ({
    type: actionTypes.GET_P6_FORM,
    data,
  }),

  getP6formsucceeded: (content) => ({
    type: actionTypes.GET_P6_FORM_SUCCESS,
    payload: content,
  }),

  getP6formfailed: (content) => ({
    type: actionTypes.GET_P6_FORM_FAILURE,
    payload: content,
  }),

  sendP6Notification: (content) => ({
    type: actionTypes.SEND_P6_NOTIFICATION,
    payload: content,
  }),
  sendP6notificationsucceeded: (content) => ({
    type: actionTypes.SEND_P6_NOTIFICATION_SUCCESS,
    payload: content,
  }),

  sendP6notificationfailed: (content) => ({
    type: actionTypes.SEND_P6_NOTIFICATION_FAILURE,
    payload: content,
  }),

  exportP6table: (data) => ({
    type: actionTypes.EXPORT_P6_TABLE,
    data,
  }),
  exportP6tablesucceeded: (content) => ({
    type: actionTypes.EXPORT_P6_TABLE_SUCCESS,
    payload: content,
  }),
  exportP6tablefailed: (content) => ({
    type: actionTypes.EXPORT_P6_TABLE_FAILURE,
    payload: content,
  }),
  getP6ProjectList: (data) => ({
    type: actionTypes.GET_P6_PROJECT_LIST,
    data,
  }),

  getP6ProjectListSucceeded: (content) => ({
    type: actionTypes.GET_P6_PROJECT_LIST_SUCCESS,
    payload: content,
  }),

  getP6ProjectListFailed: (content) => ({
    type: actionTypes.GET_P6_PROJECT_LIST_FAILURE,
    payload: content,
  }),
};
