import React from 'react';
import { Field } from 'redux-form';

import { get } from 'lodash';
import BVSelect from '../../baseComponents/BVSelect';
import BVTextField from '../../baseComponents/BVTextField';
import { ICONS } from '../../constants/assets';
import { INPUT_TYPES } from '../../constants/common';

export default function ActivityManhrsRow(props) {
  const isBudgetCompleted = get(props.selectedOption, 'isBudgetCompleted', false);

  const { rowData, fields, additionalFields, editPermission } = { ...props };

  return fields.map((row, index) => (
    <tr id="addr0" key={row}>
      {rowData != undefined &&
        rowData[index] != undefined &&
        additionalFields.map((field, idx) => (
          <td key={idx} className={field.key == 'dosActivity' ? 'headcol firstcol' : ''}>
            {editPermission && field.key == 'dosActivity' ? (
              <Field
                name={`${row}.${field.key}`}
                type={field.type}
                options={field.titleMap}
                disabled
                className={`form-control formInline  ${field.key}`}
                component={BVTextField}
                hoverText={rowData[index][field.key]}
              />
            ) : editPermission && (field.key == 'totalENGHours' || field.key == 'totalDSGNHours') ? (
              <Field
                format={(value) => (!value ? 0 : Number(value))}
                name={`${row}.${field.key}`}
                type={field.type}
                options={field.titleMap}
                disabled
                className="form-control formInline"
                component={BVTextField}
              />
            ) : editPermission ? (
              <Field
                format={(value) => (!value ? 0 : Number(value))}
                parse={(value) => (!value ? 0 : Number(value))}
                name={`${row}.${field.key}`}
                type={field.type}
                options={field.titleMap}
                disabled={isBudgetCompleted || !rowData[index].isEditable}
                className="form-control formInline"
                onChange={(e) => props.onSelectChange(index, e, field.type, field.key)}
                component={field.type == INPUT_TYPES.TEXT || field.type == INPUT_TYPES.NUMBER ? BVTextField : BVSelect}
              />
            ) : (
              rowData[index][field.key]
            )}
          </td>
        ))}
    </tr>
  ));
}
