import React from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import { ICONS, IMAGES } from '../../constants/assets';
import { projectSetupActions, commonActions, ioActions } from '../../redux/actions';
import { ROLE_PERMISSIONS, INPUT_MENU, TOP_MENU_ID } from '../../constants/common';
import { get } from 'lodash';

function SideMenuList(props) {
  const { optionsList, discipline, showDiscipline, heading, disableSelection, selectedMenu } = {
    ...props,
  };
  const [selected, setSelected] = React.useState('');
  const [selectedEditable, setSelectedEditable] = React.useState('');
  const [viewMenus, setViewMenus] = React.useState([]);
  const [editableMenus, setEditableMenus] = React.useState([]);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const selectedMenuInUrl = queryParams.get('selectedSubMenu');

  React.useEffect(() => {
    categorizeMenus();
  }, [optionsList]);

  const categorizeMenus = () => {
    const editableMenus = [];
    const viewMenus = [];
    optionsList.map((data) => {
      if (data.rights !== undefined) {
        if (data.rights === ROLE_PERMISSIONS.VIEW) {
          viewMenus.push(data);
        } else if (data.rights === ROLE_PERMISSIONS.EDIT) {
          editableMenus.push(data);
        }
      } else {
        editableMenus.push(data);
      }
    });

    let isFoundInEditable = false;
    let isExistsMenuInEditable = editableMenus.filter((ite) => ite.submenuid == selectedMenuInUrl);
    if (isExistsMenuInEditable && Array.isArray(isExistsMenuInEditable) && isExistsMenuInEditable.length) {
      isFoundInEditable = true;
    }

    let isFoundInView = false;
    let isExistsMenuInView = viewMenus.filter((ite) => ite.submenuid == selectedMenuInUrl);
    if (isExistsMenuInView && Array.isArray(isExistsMenuInView) && isExistsMenuInView.length) {
      isFoundInView = true;
    }

    if (selectedMenuInUrl && (isFoundInEditable || isFoundInView)) {
      if (editableMenus.length > 0 && isFoundInEditable) {
        /**
         * when editable menu length is greater than zero
         * and if the params for menu exists in the url
         * then check that if that menu exists in editable then show or make default
         */
        let isExistsMenu = editableMenus.filter((ite) => ite.submenuid == selectedMenuInUrl);
        if (isExistsMenu && Array.isArray(isExistsMenu) && isExistsMenu.length) {
          selectSubMenu(isExistsMenu[0]);
        } else {
          selectSubMenu(editableMenus[0]);
        }
      } else if (viewMenus.length > 0 && isFoundInView) {
        let isExistsMenu = viewMenus.filter((ite) => ite.submenuid == selectedMenuInUrl);
        if (isExistsMenu && Array.isArray(isExistsMenu) && isExistsMenu.length) {
          selectSubMenu(isExistsMenu[0]);
        } else {
          selectSubMenu(viewMenus[0]);
        }
      }
    } else {
      /**
       * if that url menu doesnt exists in both go with the default values
       *
       */
      if (editableMenus.length > 0) {
        selectSubMenu(editableMenus[0]);
      } else if (viewMenus.length > 0) {
        selectSubMenu(viewMenus[0]);
      }
    }

    setEditableMenus(editableMenus);
    setViewMenus(viewMenus);
  };

  const { menuList } = props;

  const { routeProjectNumber } = useParams();

  React.useEffect(() => {
    if (menuList?.menuItems?.length) {
      getCountOfLaminarBasedOnDiscipline();
    }
  }, [menuList]);

  const getCountOfLaminarBasedOnDiscipline = () => {
    const ioMenuExists = menuList?.menuItems?.filter((item) => item.id === '9ca71c4a-cfbb-40d8-84ec-3c746c20ad01');
    let correctDiscipline = props.discipline;
    if (props.discipline === 'Management' && props.disciplineChange) {
      correctDiscipline = props.disciplineChange;
    }
    if (ioMenuExists?.length && correctDiscipline) {
      props.getIOForm({
        projectNumber: routeProjectNumber,
        submenuId: 'eabf4e34-33a7-43bc-a578-a346e984b16b',
        type: 'getNonLaminar',
        isOnlyNotification: true,
      });
      props.getIOForm({
        projectNumber: routeProjectNumber,
        submenuId: INPUT_MENU[correctDiscipline],
        type: 'getIOInput',
        isOnlyNotification: true,
      });
    } else {
      props.getIOForm({
        projectNumber: routeProjectNumber,
        submenuId: 'eabf4e34-33a7-43bc-a578-a346e984b16b',
        type: 'getNonLaminar',
        isOnlyNotification: true,
      });
    }
  };

  const selectSubMenu = (submenuItem) => {
    if (submenuItem.label && !props.formEditing) {
      // renders everytime when there is change in options and formediting condition is used to prevent selection of tabs when user is editing

      // prevents submenu change on api reload
      if (!selected) {
        // set the active submenu
        props.onClickSubmenu({
          submenuId: submenuItem.submenuid,
          submenuName: submenuItem.label,
          submenuRights: submenuItem.rights,
        });
        setSelected(submenuItem.label);
        setSubMenuInUrl(submenuItem.submenuid);
        if (submenuItem.rights !== undefined) {
          submenuItem.rights > ROLE_PERMISSIONS.VIEW ? setSelectedEditable(true) : setSelectedEditable(false);
        } else {
          setSelectedEditable(true);
        }
      }
    }
  };

  const setSubMenuInUrl = (label) => {
    // let urlParams = `?selectedSubMenu=${label}`
    if (selectedMenuInUrl) {
      queryParams.set('selectedSubMenu', label);
    } else {
      queryParams.append('selectedSubMenu', label);
    }
    history.push({ search: queryParams.toString() });
  };

  const handleTabClick = (data) => {
    // handles the click event of side tabs
    if (props.formEditing) {
      // prevention of changing tabs when user is editing
      props.sendMessageDispatch({
        messageId: 'Save the form before leaving the page',
        theme: 'alert-danger',
      });
    } else {
      props.onClickSubmenu({
        submenuId: data.submenuid,
        submenuName: data.label,
        submenuRights: data.rights,
      }); // for pushing the selcted submenu id to store
      setSelected(data.label); // selects which sidemeu content to display
      queryParams.delete('dropdown');
      setSubMenuInUrl(data.submenuid);
      if (data.rights !== undefined) {
        data.rights > ROLE_PERMISSIONS.VIEW ? setSelectedEditable(true) : setSelectedEditable(false);
      } else {
        setSelectedEditable(true);
      }
    }
  };

  return (
    <div>
      <div className="SideMenuListDiv">
        <div
          className={`sidelistContainer ${
            selectedEditable ? `${props.heading === 'P6 SEND/RETRIEVE' ? 'noFooter' : ''}` : 'noFooter'
          } `}
        >
          <ul className="list-group" value={selected}>
            <li className="list-group-item">
              <span className="listHeading">{showDiscipline ? `${props.heading} / ${discipline}` : props.heading}</span>
            </li>
            {editableMenus.map((data, index) => (
              <li
                key={index}
                name="editableMenus"
                className={`sidemenu-item list-group-item ${data.label === selected ? 'active' : ''}`}
                style={
                  viewMenus.length !== 0 && index == editableMenus.length - 1
                    ? {
                        borderBottom: 'solid 2px #002b5c',
                      }
                    : {}
                }
                onClick={() => handleTabClick(data)}
              >
                <p className="SideMenuListLabel">{data.label}</p>
                {!disableSelection ? (
                  data.isComplete ? (
                    data.label === selected ? (
                      <img src={ICONS.CHECK_CIRCLE_WHITE} className="sideMenuIcon" />
                    ) : (
                      <img src={ICONS.CHECK_CIRCLE} className="sideMenuIcon" />
                    )
                  ) : data.label === selected ? (
                    <span className="sideMenuIcon circle-white"> </span>
                  ) : (
                    <span className="sideMenuIcon circle-blue"> </span>
                  )
                ) : null}
              </li>
            ))}
            {viewMenus.map((data, index) => (
              <li
                key={index}
                name="viewOnlyMenus"
                className={`sidemenu-item list-group-item ${data.label === selected ? 'active' : ''}`}
                onClick={() => handleTabClick(data)}
              >
                <p className="SideMenuListLabel">{data.label}</p>
              </li>
            ))}
            {editableMenus.map((data, index) => (data.label === selected ? data.action : null))}
          </ul>

          <div className="sidebar-image">
            <img src={IMAGES.CORNER_ARC} />
            <img src={IMAGES.LOGO} />
          </div>
        </div>
        {optionsList.map((data, index) => (
          <TabContainer heading={heading} isSelected={data.label === selected} key={index}>
            {data.content}
          </TabContainer>
        ))}
      </div>
    </div>
  );
}

function TabContainer(props) {
  return props.isSelected ? (
    <div className={`form-container ${props.heading === 'P6 SEND/RETRIEVE' ? 'p6MenuContainer' : ''}`}>
      {props.children}
    </div>
  ) : null;
}

export const mapStateToProps = (state) => ({
  formEditing: state.commonData && state.commonData.formEditing,
  menuList: get(state, 'projectSetupReducer.menuList', {}),
  discipline: get(state, 'projectSetupReducer.menuList.disciplines[0]', false),
  disciplineChange: get(state, 'projectSetupReducer.menuList.disciplines[1]', false),
});

export const mapDispatchToProps = (dispatch) => ({
  onClickSubmenu: (projectDetails) => dispatch(projectSetupActions.projectDetails(projectDetails)),
  sendMessageDispatch: (data) => dispatch(commonActions.infoPopup(data)),
  getIOForm: (data) => dispatch(ioActions.getIOForm(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenuList));
