import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { commonActions } from '../../redux/actions';

function InfoPopup(props) {
  React.useEffect(() => {
    props.messageId &&
      get(props, 'hide', true) &&
      setTimeout(() => {
        props.clearMessageDispatch({ messageId: '' });
      }, 5000);
  }, [props.messageId]);

  return (
    <div
      className={`alert ${props.theme ? props.theme : 'alert-success'}`}
      role="alert"
      style={{
        left: '23vw',
        position: 'fixed',
        top: '143px',
        width: '60%',
        zIndex: 2000,
        whiteSpace: 'pre-wrap',
      }}
    >
      <span
        name="clearMessageId"
        style={{ float: 'right', cursor: 'pointer' }}
        onClick={() => props.clearMessageDispatch({ messageId: '' })}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g fill="none" fillRule="evenodd">
            <g fill={`${props.theme === 'alert-success' ? '#4aad50' : '#f31226'}`}>
              <g>
                <path
                  d="M13.657 13.656C15.157 12.156 16 10.122 16 8s-.843-4.156-2.343-5.656c-.646-.647-1.392-1.171-2.2-1.559C10.387.273 9.207 0 8 0 5.878 0 3.843.842 2.343 2.344.843 3.844 0 5.878 0 8s.843 4.156 2.343 5.656C3.843 15.158 5.878 16 8 16s4.156-.842 5.657-2.344zm-8.303-9.01L8 7.294l2.646-2.648c.094-.094.221-.146.354-.146.133 0 .26.052.354.146.094.094.147.222.147.354 0 .132-.053.26-.147.354L8.707 8l2.647 2.646c.094.094.147.222.147.354 0 .132-.053.26-.147.354-.094.094-.221.146-.354.146-.133 0-.26-.052-.354-.146L8 8.708l-2.646 2.646c-.094.094-.221.146-.354.146-.133 0-.26-.052-.354-.146-.094-.094-.147-.222-.147-.354 0-.132.053-.26.147-.354L7.293 8 4.646 5.354c-.047-.046-.083-.102-.109-.162C4.512 5.13 4.5 5.066 4.5 5s.013-.13.038-.192c.026-.06.062-.116.109-.162.046-.046.102-.084.162-.108.061-.026.126-.038.192-.038s.13.012.191.038c.061.024.116.062.163.108z"
                  transform="translate(-123 -185) translate(123 185)"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
      {props.messageId}
    </div>
  );
}
InfoPopup.defaultProps = {
  hide: true,
};

const mapStateToProps = (state) => ({
  messageId: state.commonData && state.commonData.globalAlert.messageId,
  hide: get(state.commonData.globalAlert, 'hide'),
});

const mapDispatchToProps = (dispatch) => ({
  clearMessageDispatch: (data) => {
    dispatch(commonActions.infoPopup(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoPopup);
