import { get } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { viewApproveProjectActions, viewApproveProjectActionTypes, p6Actions, commonActions } from '../actions';

export function* getPlansSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    yield put(
      viewApproveProjectActions.getViewPlanReference({
        projectNumber: payload.data.projectNumber,
        discipline: payload.data.discipline,
        iterationId: payload.data.iterationId,
        iterationName: payload.data.iterationName,
        userEmail: payload.data.userEmail,
      })
    );

    let { discipline } = payload.data;
    if (!discipline && payload?.data?.category !== 'eng-exe') {
      const menuResponse = yield call(AxiosInstance.get, servicelist('getProjectMenu'), {
        params: { projectNumber: payload.data.projectNumber, stateKey: 'setUpInProgress' },
      });
      discipline = get(menuResponse, 'data.disciplines[0]', '');
    }
    const responseData = yield call(
      AxiosInstance.post,
      servicelist(payload?.data?.category === 'eng-exe' ? 'getGanttDataProjectDashboard' : 'getViewPlans'),
      {
        projectNumber: payload.data.projectNumber,
        discipline,
        iterationId: payload.data.iterationId,
        iterationName: payload.data.iterationName,
        userEmail: payload.data.userEmail,
        filter: payload.data.filter,
        searchTerm: payload.data.searchTerm,
        pageNumber: payload.data?.pageNumber,
      }
    );

    yield put(
      viewApproveProjectActions.getPlansSuccess({
        isSucceeded: true,
        plansList: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      viewApproveProjectActions.getPlansFaliure({
        isSucceeded: false,
        plansList: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getPlansStatus() {
  yield takeEvery([viewApproveProjectActionTypes.GET_PLANS], getPlansSaga);
}

export function* getFilterDataFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getFilterDataForGnatt'), {
      params: { projectNumber: payload.data.projectNumber },
    });
    yield put(
      viewApproveProjectActions.getFilterDataSuccess({
        isSucceeded: true,
        ganttFilterData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      viewApproveProjectActions.getFilterDataFailure({
        isSucceeded: false,
        ganttFilterData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchGetfilterDataStatus() {
  yield takeEvery([viewApproveProjectActionTypes.GET_FILTER_DATA], getFilterDataFromSaga);
}

export function* SaveDisciplineDataFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.post,
      servicelist('saveViewPlanCompleteReference'),
      payload.data.completedData
    );

    if (responseData.data.status !== 500) {
      yield put(
        viewApproveProjectActions.saveDisciplineDataSuccess({
          isSucceeded: true,
          savedData: responseData.data,
        })
      );

      // getting p6 iteration List
      yield put(
        p6Actions.getIterationList({
          projectNumber: get(payload, 'data.getIterationPayload.projectNumber', ''),
          menuId: get(payload, 'data.getIterationPayload.menuId', ''),
          discipline: get(payload, 'data.getIterationPayload.discipline', ''),
          category: get(payload, 'data.getIterationPayload.category', ''),
          userEmail: get(payload, 'data.getIterationPayload.userEmail', ''),
          iterationId: get(payload, 'data.getIterationPayload.iterationId', ''),
          iterationName: get(payload, 'data.getIterationPayload.iterationName', ''),
        })
      );
    } else {
      yield put(
        viewApproveProjectActions.saveDisciplineDataFailure({
          isSucceeded: false,
          savedData: null,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      viewApproveProjectActions.saveDisciplineDataFailure({
        isSucceeded: false,
        savedData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchSaveDisciplineDataFromSaga() {
  yield takeEvery([viewApproveProjectActionTypes.SAVE_DISCIPLINE_DATA], SaveDisciplineDataFromSaga);
}

export function* getPlanCompleteReferenceSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('getPlanCompleteReference'), {
      ...payload.data,
    });
    yield put(
      viewApproveProjectActions.getViewPlanReferenceSuccess({
        isSucceeded: true,
        planCompleteReference: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      viewApproveProjectActions.getViewPlanReferenceSuccess({
        isSucceeded: false,
        planCompleteReference: {},
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchPlanCompleteReference() {
  yield takeEvery([viewApproveProjectActionTypes.GET_PLAN_COMPLETE_REFERENCE], getPlanCompleteReferenceSaga);
}
