import { put, takeEvery, call } from 'redux-saga/effects';
import { get } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { commonActions, landingActions, landingActionTypes } from '../actions';

export function* getProjectSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('getProjects'), payload.payload); // incase we need the error data from the api
    yield put(
      landingActions.getProjectSuccess({
        isSucceeded: true,
        stateValue: get(payload, 'payload.stateValue', ''),
        data: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      landingActions.getProjectFaliure({
        isSucceeded: false,
        projectList: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectStatus() {
  yield takeEvery([landingActionTypes.GET_PROJECT], getProjectSaga);
}
