import { put, takeEvery, call } from 'redux-saga/effects';
import { isEmpty, compact } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { projectHomeSteps } from '../../helpers/common';
import { projectHomeActions, projectHomeActionTypes, commonActions } from '../actions';

export function* getProjectHomeContentSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('projectHomeContent'), {
      params: payload.data,
    });
    let payloadObj;
    const resObj = responseData.data;
    if (!isEmpty(resObj.subSections)) {
      payloadObj = resObj.subSections.map((item, ind) => {
        if (ind > 5) {
          return { ...payload.data, step: item.subSectionKey };
        }
      });
      payloadObj = compact(payloadObj);
    }

    yield put(
      projectHomeActions.getProjectHomeContentSucceeded({
        isSucceeded: true,
        homeContent: responseData.data,
      })
    );
    yield projectHomeSteps(payloadObj);
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projectHomeActions.getProjectHomeContentFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectHomeContentStatus() {
  yield takeEvery([projectHomeActionTypes.GET_PROJECT_HOME_CONTENT], getProjectHomeContentSaga);
}
