import React from 'react';
import { connect, useSelector } from 'react-redux';
import { FieldArray, getFormValues, reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { cloneDeep, get, isEqual } from 'lodash';
import {
  commonActions,
  projActivitiesActions,
  projectInformationActions,
  projectSetupActions,
} from '../../redux/actions';
import { ICONS } from '../../constants/assets';
import ToolTip from '../common/toolTip';

import './activityManhrs.scss';
import ActivityManhrsRow from './activityManhrsRow';
import FormButtons from '../common/formButtons';
import { ROLE_PERMISSIONS } from '../../constants/common';

import { projectActivitiesValidation as validate } from '../../validations/projectActivities/projectActivities';
import SelectMenu from './ActivitySelect/index';
import useFeatureFlags from '../../customHooks/usefeatureFlags';

function ActivityManhrsTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [editPermission, setEditPermission] = React.useState(false);
  const [firstRowValidation, setFirstRowValidation] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [completedCount, setCompletedCount] = React.useState(0);
  const [saveEnable, setSaveEnable] = React.useState(props.dirty);
  const [manHourListItems, setManHourListItems] = React.useState([]);
  const [globalFlags, setGlobalFlags] = React.useState([]);
  const enabledFlags = useSelector((state) => get(state, 'commonData.enabledFlags', []));
  const [activityTableData, setActivityTableData] = React.useState();
  useFeatureFlags(['44175', 'F-DISPTHRS-24082021']);

  const headers = get(props.projectActivityTableData, 'headers', []);
  const firstRow = get(props.projectActivityTableData, 'firstRow', []);
  const additionalFields = get(props.projectActivityTableData, 'fields', []);
  const rowData = get(props.updatedInput, 'projectActivityTable', []);
  const isBudgetCompleted = get(selectedOption, 'isBudgetCompleted', false);
  const isGlobalEditEnable = get(props.projectActivityTableData, 'body.isGlobalEditEnable', false);
  const isMasterData = get(props.projectActivityTableData, 'isMasterData', false);

  const projectActivityFormValues = useSelector((state) =>
    get(state, 'form.ProjActivitiesTable.values.projectActivityTable', [])
  );

  React.useEffect(() => {
    props.clearProjectActivityInformation();
    if (props.permissionLevel === ROLE_PERMISSIONS.EDIT) {
      setEditPermission(true);
    }
  }, []);

  React.useEffect(() => {
    if (props.manHourListItems != undefined && props.manHourListItems.activityData) {
      const count = props.manHourListItems.activityData.filter((item) => item.isBudgetCompleted);
      setManHourListItems(props.manHourListItems.activityData);
      setCompletedCount(count.length);
    }
  }, [props.manHourListItems]);

  React.useEffect(() => {
    const saveEnabled  = props.dirty || isMasterData;
    props.setFormEditing(saveEnabled); // locking the menu and submenu if user changes anything in table
    setSaveEnable(saveEnabled);
  }, [props.dirty, isMasterData]);

  React.useEffect(() => {
    const formError = get(props.formErrors, 'projectActivityTable[0]', {});
    setFirstRowValidation(Object.keys(formError));
  }, [props.formErrors]);

  React.useEffect(() => {
    if (enabledFlags && !isEqual(enabledFlags, globalFlags)) setGlobalFlags(enabledFlags);
  }, [enabledFlags]);

  React.useEffect(() => {
    // Feature 44175 implemented
    const clonedActivityTableData = cloneDeep(props.projectActivityTableData);

    if (globalFlags.includes('44175')) {

      let activityData = get(props.projectActivityTableData, 'body.body', []);

      const dosActivity = get(activityData[0], 'dosActivity', '');
      if (activityData && activityData.length === 1 && !activityData[0].totalENGHours && !activityData[0].totalDSGNHours) {
        let newArray = [{ ...activityData[0], ...firstRow[0], dosActivity }];

        const keyArray = [
          'ousDsgnHours',
          'ousEngHours',
          'usDsgnHours',
          'usEngHours',
          'totalDSGNHours',
          'totalENGHours',
        ];

        keyArray.forEach((key) => {
          if (newArray[0][key]) newArray[0][key] = Number(newArray[0][key]);
        });

        clonedActivityTableData.body.body = newArray;
      }
    }

    setActivityTableData(clonedActivityTableData);
  }, [props.projectActivityTableData, globalFlags]);

  React.useEffect(() => {
    resetFieldValues(activityTableData); // whenever a change is made in api response we are resetting the fields with the new updated api data
  }, [activityTableData]);

  const resetFieldValues = (activityData) => {
    // resetting field values with the api response
    const tempList = get(activityData, 'body.body', []);
    props.initialize({ projectActivityTable: tempList });
  };

  const saveTableData = () => {
    const saveBody = props.updatedInput.projectActivityTable;
    saveBody.map((row) => delete row.isUserAdded);

    const saveId = get(activityTableData, 'body.id', null);

    const payload = {
      projectNumber: props.projectDetails.projectNumber,
      menuId: props.projectDetails.selectedMenu,
      submenuId: props.projectDetails.submenuId,
      dosActivity: selectedOption.dosActivity,
      refrenceKey: selectedOption.refrenceKey,
      body: saveBody,
      id: saveId,
    };

    props.saveProjectActivityInformation(payload);
  };

  const completeProjectActivityTable = (status) => {
    props.infoPopupDispatch({ messageId: '' });
    const payload = {
      projectNumber: props.projectDetails.projectNumber,
      menuId: props.projectDetails.selectedMenu,
      submenuId: props.projectDetails.submenuId,
      isComplete: status,
    };

    props.completeProjectActivityInfoForm(payload);
  };

  const changeValues = (idx, e, type, fieldKey) => {
    const tempData = props.updatedInput && props.updatedInput.projectActivityTable;

    if (fieldKey == 'usEngHours') {
      props.change(
        `projectActivityTable[${idx}]totalENGHours`,
        Number(e.target.value) + Number(tempData[idx].ousEngHours || 0)
      );
    } else if (fieldKey == 'ousEngHours') {
      props.change(
        `projectActivityTable[${idx}]totalENGHours`,
        Number(e.target.value) + Number(tempData[idx].usEngHours || 0)
      );
    } else if (fieldKey == 'usDsgnHours') {
      props.change(
        `projectActivityTable[${idx}]totalDSGNHours`,
        Number(e.target.value) + Number(tempData[idx].ousDsgnHours || 0)
      );
    } else if (fieldKey == 'ousDsgnHours') {
      props.change(
        `projectActivityTable[${idx}]totalDSGNHours`,
        Number(e.target.value) + Number(tempData[idx].usDsgnHours || 0)
      );
    }
  };

  const calculateRemainingHrs = (val, key) => {
    const tableData = projectActivityFormValues;
    const totalHours = tableData.reduce(function (prev, current) {
      return prev + (isNaN(current[key]) ? 0 : Number(current[key]));
    }, 0);

    return isNaN(val) ? 0 - totalHours : val - totalHours;
  };

  return (
    <>
      <div style={{ padding: '26px 30px' }}>
        <div className="d-flex justify-content-between align-items-center">
          <span className="projectActivityHeaderTitle">Manhour Budgets</span>
        </div>
        <SelectMenu
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          completeStatus={props.valid && !saveEnable && selectedOption != null}
          editStatus={props.isComplete}
          discipline={props.discipline}
        />
        <div className={`activitiesTable ${isBudgetCompleted ? 'completed' : ''}`}>
          {selectedOption == null ? (
            <p className="emptyMessage">
              To view manhours table, you must first Mark as Completed at least one Activity in the Define Activities
              section
            </p>
          ) : (
            <form>
              <table className="table table-hover">
                <thead>
                  <tr>
                    {headers.map((headCell, index) => (
                      <th
                        key={index}
                        className={headCell.key == 'dosActivity' ? 'headcol' : ''}
                        // onClick={createSortHandler(headCell.key)}  sorting is not in scope yet.
                      >
                        <div className="headerContainer">
                          <div>{headCell.value}</div>
                          {/* <div>
                            {orderBy === headCell.key ? (
                              <img src={order === 'desc' ? ICONS.SORT_UP : ICONS.SORT_DOWN} />
                            ) : (
                              <img src={ICONS.SORT_ARROWS} />
                            )}
                          </div> */}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {firstRow.map((row, index) => (
                    <tr key={index}>
                      {additionalFields.map((field, idx) => (
                        <td
                          key={idx}
                          className={`firstRow ${firstRowValidation.indexOf(field.key) !== -1 ? 'showInvalid' : ''} ${
                            field.key == 'dosActivity'
                              ? 'headcol font-weight-bold'
                              : field.key == 'totalENGHours' || field.key == 'totalDSGNHours'
                              ? 'font-weight-bold'
                              : ''
                          }`}
                        >
                          <span>{row[field.key]} </span>
                          {globalFlags.includes('F-DISPTHRS-24082021')
                            ? editPermission &&
                              !['dosActivity', 'totalENGHours', 'totalDSGNHours'].includes(field.key) &&
                              !props.isComplete &&
                              !isBudgetCompleted && (
                                <span className="hint-budget">{`( ${calculateRemainingHrs(
                                  row[field.key],
                                  field.key
                                )} hrs remaining )`}</span>
                              )
                            : firstRowValidation.indexOf(field.key) !== -1 && (
                                <ToolTip hover={"Please check your values as they don't sum up to totals!"} theme="red">
                                  <img src={ICONS.ERROR_TRIANGLE} />
                                </ToolTip>
                              )}
                        </td>
                      ))}
                    </tr>
                  ))}

                  <FieldArray
                    name="projectActivityTable"
                    component={ActivityManhrsRow}
                    onSelectChange={changeValues}
                    editPermission={editPermission}
                    rowData={rowData}
                    additionalFields={additionalFields}
                    selectedOption={selectedOption}
                  />
                </tbody>
              </table>
            </form>
          )}
        </div>

        {editPermission && (
          <FormButtons
            formComplete={props.isComplete}
            editAction={() => completeProjectActivityTable(false)}
            resetStatus={props.dirty}
            resetAction={props.reset}
            saveStatus={saveEnable}
            saveAction={saveTableData}
            isEditEnable={isGlobalEditEnable}
            completeStatus={
              props.valid && !props.dirty && completedCount == manHourListItems.length && manHourListItems.length > 0
            }
            completeAction={() => completeProjectActivityTable(true)}
          />
        )}
      </div>
    </>
  );
}

ActivityManhrsTable = reduxForm({
  form: 'ProjActivitiesTable',
  validate,
  touchOnChange: true,
})(ActivityManhrsTable);

export const mapStateToProps = (state) => ({
  updatedInput: getFormValues('ProjActivitiesTable')(state) ? getFormValues('ProjActivitiesTable')(state) : {},
  formErrors: getFormSyncErrors('ProjActivitiesTable')(state) ? getFormSyncErrors('ProjActivitiesTable')(state) : {},
  formEditing: get(state, 'commonData.formEditing'),
  projectDetails: get(state, 'projectSetupReducer.projectDetails'),
  projectActivityTableData: get(state, 'projActivitiesInformation.tableData'),
  manHourListItems: get(state, 'projActivitiesInformation.modifiedProjectActivityList'),
});

export const mapDispatchToProps = (dispatch) => ({
  clearProjectActivityInformation: () => dispatch(projActivitiesActions.clearProjActivityInfo()),
  saveProjectActivityInformation: (data) => dispatch(projActivitiesActions.saveProjActivityInfoForm(data)),
  setFormEditing: (data) => dispatch(commonActions.formEditing({ formEditing: data })),
  completeProjectActivityInfoForm: (data) => dispatch(projectSetupActions.completeSubMenu(data)),
  infoPopupDispatch: (data) => dispatch(commonActions.infoPopup(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityManhrsTable);
