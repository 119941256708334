export const data = [
  {
    id: 'A1108098',
    label: 'lorem ipsum',
    value: 'loremIpsum',
    referenceSection: 'Project-Specific Document',
    descipline: 'Management',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'B1108098',
    label: 'First Ref',
    value: 'firstRef',
    referenceSection: 'Client Document',
    descipline: 'lorem ipsum',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'C1108098',
    label: 'Second Ref',
    value: 'secondRef',
    referenceSection: 'Calculation Template',
    descipline: 'Electrical',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'D1108098',
    label: 'Third Ref',
    value: 'thirdRef',
    referenceSection: 'Procedures',
    descipline: 'Process',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'E1108098',
    label: 'Fourth Ref',
    value: 'fourthRef',
    referenceSection: 'Client Document',
    descipline: 'lorem ipsum',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'F1108098',
    label: 'Fifth Ref',
    value: 'fifthRef',
    referenceSection: 'Client Document',
    descipline: 'lorem ipsum',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'G1108098',
    label: 'Six Ref',
    value: 'sixRef',
    referenceSection: 'Calculation Template',
    descipline: 'Electrical',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'H1108098',
    label: 'Seventh Ref',
    value: 'seventhRef',
    referenceSection: 'Procedures',
    descipline: 'Process',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'I1108098',
    label: 'Eighth Ref',
    value: 'eighthRef',
    referenceSection: 'Training',
    descipline: 'Process',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'J1108098',
    label: 'Ninth Ref',
    value: 'ninethRef',
    referenceSection: 'Typical Details',
    descipline: 'Process',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'K1108098',
    label: 'Tenth ref',
    value: 'tenthRef',
    referenceSection: 'Project-Specific Document',
    descipline: 'Management',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'L1108098',
    label: 'Eleventh Ref',
    value: 'eleventhRef',
    referenceSection: 'Project-Specific Document',
    descipline: 'Management',
    link: 'http://gigReferenceLongTitleOne.com',
  },
  {
    id: 'M1108098',
    label: 'Twelth Ref',
    value: 'twelthRef',
    referenceSection: 'Client Document',
    descipline: 'lorem ipsum',
    link: 'http://gigReferenceLongTitleOne.com',
  },
];
