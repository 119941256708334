const { getAESAction } = require('../../utils/cryptos');

// These environment variables are utilizing the decrypt wrapper function at run time
const BASE_URL = process.env.REACT_APP_BASE_URL;
const FUNCTIONS_URL = BASE_URL;
const PROJECT_SETUP_URL = BASE_URL;

export const FEATURE_FLAG_URL = 'https://engig-ff-api.azurewebsites.net/api/isEnabled';

const endpointPrefix = {
  projectSetup: 'api/v1/project-setup/projects',
  notification: 'api/v1/common/notification',
};
const endpoints = {
  loginService: 'api/v1/login',
  adLoginService: 'api/v1/adLogin',
  refreshToken: 'api/v1/refreshToken',
  getCreateProjectFields: 'api/v1/projects/modal',
  getProjectInfo: 'api/v1/projectInfo',
  createProject: 'api/v1/projects',
  getProjects: 'api/v1/projectsList',
  getProjectMenu: 'api/v1/menuItems',
  laminarScopeOverview: 'api/v1/laminarScopeOverview',
  laminarScopeGigInfo: 'api/v1/laminarScopeGigInfo',
  getGigInfoDropdown: 'api/v1/getGigInfoDropdown',
  laminarDiscussion: 'api/v1/laminarDiscussion',
  projectHomeContent: 'api/v1/projectSetup',
  getLaminarScopeOverviewForm: 'api/v1/getLaminarScopeOverviewForm',
  ProjectForm: 'api/v1/projectForm',
  completeProjectInfoForm: 'api/v1/projectForm/complete',
  AdditionalFieldForm: 'api/v1/additionalFieldForm',
  referenceLibraryForm: 'api/v1/projectSpecificDoc',
  referenceLibraryCategory: 'api/v1/categoryProjectSpecificDoc',
  getWorkflowData: 'api/v1/workFlow',
  workflowComplete: 'api/v1/complete',
  userData: 'api/v1/getusers',
  addLeads: 'api/v1/addleads',
  leadList: 'api/v1/leadList',
  complete: 'api/v1/complete',
  assignedRoles: 'api/v1/projectLeadRoles',
  saveassignedRoles: 'api/v1/projectLeadRoles',
  AssignLeadscomplete: 'api/v1/projectForm/complete',
  getAddRole: 'api/v1/getRoles',
  getAddRoleMaster: 'api/v1/rolesFormData',
  saveRoleFields: 'api/v1/saveRoles',
  finishWorkflow: 'api/v1/finishGigWorkflow',
  notifyAssignLeads: 'api/v1/emailNotification',
  getIOInput: 'api/v1/project/inputs/list',
  getIOOutputInstances: 'api/v1/project/outputs/instance/list',
  getP6Iterations: 'api/v1/p6/iterations/list',
  addP6Iteration: 'api/v1/p6/iterations/add',
  releaseP6Iteration: 'api/v1/p6/iterations/release',
  p6send: 'api/v1/getP6SendForm',
  p6Export: 'api/v1/exportToExcel',
  getNonLaminar: 'api/v1/project/nonlaminaroutputs/list',
  completeNonLaminar: 'api/v1/project/nonlaminaroutputs/submit',
  getViewPlans: 'api/v1/preliminaryDisciplinePlan/viewPlan',
  getPlanModalData: 'api/v1/preliminaryDisciplinePlan/getModalData',
  getFilterDataForGnatt: 'api/v1/preliminaryDisciplinePlan/getFilterData',
  saveModalData: 'api/v1/preliminaryDisciplinePlan/saveModalData',
  getPlanCompleteReference: 'api/v1/preliminaryDisciplinePlan/viewPlanCompleteReference',
  saveViewPlanCompleteReference: 'api/v1/preliminaryDisciplinePlan/saveViewPlanCompleteReference',

  saveGigInstruction: 'api/v1/saveGigInstructionsWidget',
  getLaminarGigReference: 'api/v1/getLaminarGigReference',
  getLaminarGigReferenceDropdown: 'api/v1/getGigReferenceDropdown',
  saveGigReference: 'api/v1/saveGigReferenceWidget',
  getRequestAssumptionModal: 'api/v1/requestAssumption',
  findMissingIOModal: 'api/v1/laminarScope/missingInputs',
  saveLogTimeData: 'api/v1/saveLogTimeWidget',
  saveOutputChecklistData: 'api/v1/saveOutputCheckListWidget',
  // projectDashboad endpoints
  getProjectDashboardIterations: 'api/v1/projectDashboard/iterations',
  getGanttDataProjectDashboard: 'api/v1/projectDashboard/gantt',
  getProjectDashboardGanttModal: 'api/v1/projectDashboard/ganttModalData',
  saveGanttModalData: 'api/v1/projectDashboard/saveGanttModalData',
  // Quick Links widget
  getProjectDashboard: 'api/v1/projectDashboard/overview',
  saveQuicklinks: 'api/v1/projectDashboard/addQuickLinks',
  getDropdownQuicklinks: 'api/v1/getReferencesLirary',
  // projectTeams
  getProjectTeamUsers: 'api/v1/getProjectTeamUsers',
  getProjectTeamSubmenus: 'api/v1/getProjectTeamSubmenus',
  // Engg Execution Input Output
  enggOutputTable: 'api/v1/enggExec/outputs/list',
  enggOutputTableFilter: 'api/v1/enggExec/outputs/list/filter',
  outputAsyncValidate: 'api/v1/enggExec/outputs/validation',
  enggInputTable: 'api/v1/enggExec/inputs/list',
  saveEnggOutputTable: 'api/v1/enggExec/outputs',
  setAssuptionOutput: 'api/v1/enggExec/outputs/setAssumption',
  declineAssumptionOutput: 'api/v1/enggExec/outputs/declineAssumption',
  enterValueOutput: 'api/v1/enggExec/outputs/addOutputLink',
  enggOutputDropdown: 'api/v1/enggExec/outputs/list/dropdown',

  // project info engineering execution
  getEnggProjectInfoDropdown: 'api/v1/getEnggProjectInfoDropdown',
  enggProjectInfoForm: 'api/v1/enggProjectInfo',

  // WBS info engineering execution
  getEnggWbsInfoDropdown: 'api/v1/getEnggWbsInfoDropdown',
  enggWbsInfoTable: 'api/v1/enggWbsInfo',

  // project image widget
  savePlotPlan: 'api/v1/projectDashboard/updateProjectPlan',

  // project dashboard overview
  projectDashboardOverView: 'api/v1/projectDashboard/overview',

  // Gig Actions widget
  assignGigRequest: 'api/v1/assignGigs',
  getusersbygroup: 'api/v1/getusersbygroup',
  transferReasonDropdown: 'api/v1/transferReasonsDropdown',
  holdReasonDropdown: 'api/v1/holdReasonsDropdown',
  gigTransferRequest: 'api/v1/laminarScope/request-transfer',
  gigTransferRequestCancellation: 'api/v1/laminarScope/request-transfer-cancel',

  // Engg Execution Reference library
  manageReferenceLibraryForm: 'api/v1/getManageReferencesLirary',
  saveManageReferenceLibraryForm: 'api/v1/editManageReferencesLirary',

  // Camunda Endpoints
  getP6ProjectFiles: 'projectSetup/getProjectDetails/p6',
  updateGigStatus: 'api/rest/gig/actions/update-progress-status',
  // Node endpoint
  updateGigStatusNode: 'api/v1/updateGigStatus',
  getGigStatus: 'api/rest/gig/current-status',
  // Engg Exe P6
  getP6SyncTable: 'api/v1/enggExec/p6/version/data',
  getP6Versions: 'api/v1/enggExec/p6/versions',
  pushp6Updates: 'api/v1/enggExec/p6/version/data',

  // engg exe reference library
  enggReferenceLibrary: 'api/v1/getReferencesLirary',
  totalReferenceTransactiondata: 'api/v1/totalReferenceTransactiondata',

  // engg exe Project Home
  getEnggExeProjectHomeOverview: 'api/v1/myProjectHome/overview',
  getEnggExeProjectHomeMyGigs: 'api/v1/myProjectHome/my-gigs',
  getEnggExeProjectHomeLogTime: 'api/v1/getNonGigActivity',
  saveEnggExeProjectHomeLogTime: 'api/v1/saveNonGigActivity',
  saveEnggExeProjectHomeRequestAssumption: 'api/v1/myProjectHome/saveRequestAssumption',
  getEnggExeProjectHomeMyWorkItems: 'api/v1/myProjectHome/my-work-items',

  // master Heirarchy
  heirarchySubtype: 'api/v1/masterSubtype',
  heirarchySubjects: 'api/v1/masterSubjects',
  hierarchyGigSubjects: 'api/v1/masterGigSubjects',
  heirarchyDisciplines: 'api/v1/masterDisciplines',
  masterOutput: 'api/v1/masterOutput',
  masterInput: 'api/v1/masterInput',

  // master PreviewGigDashboard
  previewGigDashboard: 'api/v1/previewGigDashboard',

  // Master Gigs
  masterDataGig: 'api/v1/masterDataGig',
  masterGigType: 'api/v1/masterGigType',

  // Master References
  masterReferences: 'api/v1/masterReferences',
  masterGigReferences: 'api/v1/masterGigReferences',

  // master Activities
  masterActivitiesFragnets: 'api/v1/masterDataFragnet',
  masterActivitiesDOS: 'api/v1/masterDosActivities',

  // master Fragnets
  masterFragnetSteps: 'api/v1/masterFragnetSteps',
  masterFragnetStepType: 'api/v1/masterFragnetStepType',
  masterWfVersions: 'api/v1/masterWfVersions',
  masterFragnetLogicTies: 'api/v1/masterFragnetLogicTies',
  getChargeCodeByFragnetId: 'api/v1/getChargeCodeByFragnetId',
  getGigsByFragnetId: 'api/v1/getGigsByFragnetId',

  // master preview workflow
  fragnetWorkflowPreview: 'api/v1/getPreviewWorkflow/fragnetWorkflow',
  gigWorkflowPreview: 'api/v1/getPreviewWorkflow/gignetWorkflow',
  previewLogicTies: 'api/v1/getPreviewWorkflow/fragnetLogicTies',

  masterDataValidate: 'api/v1/masterDataValidate',
  masterTableLock: 'api/v1/masterTableLock/:masterType',
  sendMasterDataToQA: 'api/v1/sendMasterDataToQA/:masterType',
  exportMasterTableToExcel: 'api/v1/exportMasterTableToExcel/:masterType',

  // master QA
  masterQACount: 'api/v1/masterDataQaCount',
  getMasterDataQAEditMode: 'api/v1/masterDataQaEditMode',
  saveMasterDataQAEditMode: 'api/v1/masterDataQaEditMode',
  resetMasterQAChanges: 'api/v1/masterDataQaEditMode',
  saveMasterQAChanges: 'api/v1/saveMasterDataQa',

  getUpdateMasterQa: 'api/v1/getUpdateMasterQa',
  outputBackendMaster: 'api/v1/getUpdateMasterQa/outputBackendMaster',
  inputBackendMaster: 'api/v1/getUpdateMasterQa/inputBackendMaster',
  gigReferencesBackendMaster: 'api/v1/getUpdateMasterQa/gigReferencesBackendMaster',

  // Help links
  getHelpLinks: 'api/v1/helplinks',

  // required input edit popup
  getRequiredInputDropdownOptions: 'api/v1/enggExec/requiredInputs/outputs/list',
  saveEditRequiredInput: 'api/v1/enggExec/requiredInputs/addInput',

  // projectGigs Laminar Scope
  projectGigsTable: 'api/v1/viewProjectGigs',
  // select input version modal save api
  replaceInputs: 'api/v1/replaceInputs',
  // projectHome Steps api
  projectHomeContentStep: 'api/v1/projectSetupByStep',
  // projectplan validations
  logicTiesValidate: 'api/v1/validateLogicTie',
  secretKeyValidate: 'api/v1/validateSecretKey',
  config: 'api/v1/config',
  validateUrl: 'api/v1/valid-url',
};

const servicelist = (serviceName) => BASE_URL + endpoints[serviceName];

export const camundaService = (serviceName) =>
  getAESAction(process.env.REACT_APP_CAMUNDA_BASE_URL, '') + endpoints[serviceName];

export const getWbsInformation = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/wbs-information`;

export const saveWbsInformation = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/wbs-information`;

export const updateWbsInformation = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/wbs-information/${formId}`;

/**
 * PLI Manhour Budget
 */
export const manhourBudget = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/manhour-budget`;

/**
 * project activity define api
 */
export const projectActivityServiceList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities`;

export const saveProjectActivity = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/generate`;

export const getDefineActivites = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/define`;

export const addDefineActivities = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities`;

export const editDefineActivities = (serviceName, projectNumber, id) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/${id}`;

/**
 * modified activity list
 */
export const getActivityModifiedList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/modified-list`;

export const saveActivityModifiedList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/complete`;

export const deleteActivity = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities`;

export const completeProjectForms = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-forms/complete`;

/**
 * Project Activities Manhour budget
 */
export const addAndGetManHourBudget = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/manhour-budget`;

export const editManHourBudget = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-activities/manhour-budget/${formId}`;
/**
 *
 * GigWorkFlows API Change
 */
export const getGigWorkFlowsDropdown = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/dos-activities`;
export const getGigWorkFlowsActivityInformationForm = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-activity-information`;
export const getGigWorkFlowsLandingPage = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows`;
export const getGigWorkFlowsOverView = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/overview`;
export const getGigWorkFlowsCustomizeFragnet = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customize-fragnet`;
export const saveGigWorkFlowsActivityInformationForm = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-activity-information`;
export const updateGigWorkFlowsActivityInformationForm = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-activity-information/${formId}`;
export const getGigWorkFlowsFragnetSteps = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customize-fragnet/fragnet-steps`;
export const saveGigWorkFlowsCustomizeFragnet = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customize-fragnet`;
export const updateGigWorkFlowsCustomizeFragnet = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customize-fragnet/${formId}`;
export const getGigWorkFlowsCustomizedGigSteps = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customized-gig-steps`;
export const getGigWorkFlowsCustomizeGigTypes = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customize-gignet-steps`;
export const saveGigWorkFlowsCustomizedGignetInfo = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/customized-gignet-info`;
export const getGigWorkFlowsFragnetStepsInformation = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/fragnet-steps-information`;
export const updateGigWorkFlowsFragnetStepsInformation = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/fragnet-steps-information/${formId}`;
export const getGigWorkFlowsGignetStepInformation = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gignet-step-information`;
export const updateGigWorkFlowsGignetStepsInformation = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gignet-step-information/${formId}`;
export const getGigWorkFlowsGigTypeForms = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-type-form`;
export const completeGigWorkFlowsProjectForms = (serviceName, projectNumber, menuId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/project-forms/gig-workflows/${menuId}`;
export const saveGigWorkFlowsGigInformation = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-information`;
export const updateGigWorkFlowsGigInformation = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-information/${formId}`;
export const getGigWorkFlowsGigInfoFragnetSteps = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/fragnet-steps`;
export const updateGigWorkFlowsGigInfoMenuStatus = (serviceName, projectNumber, menuId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/gig-information/menu-items/${menuId}`;
export const autoCompleteGigs = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/auto-complete-gigs`;
export const finishGigWorkflow = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/gig-workflows/finish-gig-workflows`;
// Input and outputs
export const getOutputList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/outputs`;
export const submitOutputList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/complete-outputs`;
export const saveOutputData = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/outputs`;
export const updateOutputData = (serviceName, projectNumber, formId) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/outputs/${formId}`;

export const saveInputData = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/inputs`;

export const getInputList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/inputs`;
export const submitInputList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/complete-inputs`;

export const getNonEngigOutputList = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/outputs`;

export const saveNonEngigOutput = (serviceName, projectNumber) =>
  `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/inputs-outputs/outputs?type=non-engig`;

export const getIterationChangeReportUrl = (serviceName, projectNumber, iteration) =>
    `${PROJECT_SETUP_URL}${endpointPrefix[serviceName]}/${projectNumber}/iteration/${iteration}/iterationChangeReport`;
/**
 * Notification apis
 */

export const getAllNotification = (serviceName) => `${BASE_URL}${endpointPrefix[serviceName]}/list`;

export const markAsReadNotification = (serviceName) => `${BASE_URL}${endpointPrefix[serviceName]}/mark-as-read`;
export const deleteNotification = (serviceName) => `${BASE_URL}${endpointPrefix[serviceName]}/delete`;

export const getAllProjects = (serviceName) => `${BASE_URL}${endpointPrefix[serviceName]}/project-list`;

const ENGG_URL = getAESAction(process.env.REACT_APP_ENGG_BASE_URL, '') || BASE_URL;
// change logs

export const changeLogTable = (projectNumber) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs`;
export const saveChangeLog = (projectNumber) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs`;
export const updateChangeLog = (
  projectNumber,
  changeLogId,
  markAsComplete,
  isAssessmentUpdate,
  isPrimarySection,
  discipline,
  isGlobalComplete
) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}${
    markAsComplete ? '?markAsComplete=true' : ''
  }${isAssessmentUpdate ? '?isAssessmentUpdate=true' : ''}${isPrimarySection ? '&isPrimarySection=true' : ''}${
    isAssessmentUpdate ? `&discipline=${discipline}` : ''
  }${isGlobalComplete ? '&isGlobalComplete=true' : ''}`;
export const updateChangeLogProjectPlan = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment`;
export const updateLogicTies = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/logic-ties`;
export const updatePotentialImpactedGigs = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/potentialImpactedGigs`;
export const inputOutputDataGeneration = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/input-output`;

export const getChangeAssessmentMenu = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/menus`;
export const createAssessmentData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/wbs`;
export const updateAssessmentData = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/wbs/${assessmentId}`;
export const addDosActivityData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/project-activity`;
export const saveDosActivityData = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/project-activity/${assessmentId}`;
export const getProjectActivityItemData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/project-activity/define`;
export const getProjectActivityDropDownList = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/project-activities/define-list`;

export const changeLogItem = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}`;
export const changeInstanceForm = (version) =>
  `${FUNCTIONS_URL}api/v1/change-management/change-instance-form/${version ?? ''}`;
export const changeInstanceNotify = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/notify`;
export const changeInstanceBeginAssesment = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment`;

export const getGigActivityInfoData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/activity-information`;
export const updateGigAIData = (projectNumber, changeLogId, activityInformationId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/activity-information/${activityInformationId}`;
export const saveGigAIData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/activity-information`;
export const getCustomizeGignetStepsData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-gigsteps`;
export const getCustomizeGignetData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-gignet`;
export const getGigInfoMenu = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/menu`;
export const getGigInfoStepData = (projectNumber, changeLogId, fragnetStepId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/fragnet-steps/${fragnetStepId}`;
export const getGigTypeFormData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/gig-type`;
export const addNewGigToFragnet = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-gignet/add-new-gig`;
export const updateFragnetStepsData = (projectNumber, changeLogId, fragnetStepInformationId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/fragnet-step-information/${fragnetStepInformationId}`;
export const saveFragnetStepsData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/fragnet-step-information`;
  // The retrieving/updating of the history of editing the gig log time within the project dashboard
  export const saveLogTimeHistory = (projectNumber, gigInstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/gigs/${gigInstanceId}/save-log-time-history`;
  // This endpoint is connected to when the user on a request assumption bypasses the manager by checking the
  // checkbox on the modal that bypasses manager permissions for a Request Assumption
  export const bypassManagerRequestAssumption = (projectNumber) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/bypass-manager-request-assumption`;

export const reviseValue = (projectNumber, outputInstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/outputs/${outputInstanceId}/revise-value`;
  /**
   * This handles the previous laminar API export functionality in terms of storage of handling 
   * records being inserted into the database and excel export, in addition to new functionality which
   * historically records the actions in the P6 Send/Retrieve section of Engineering Execution and 
   * stores them in the database.
  */
  export const excelExport = (projectNumber) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/excel-export`;
  // Gets the export archive table data that contains the events for exports, etc.
  export const getExportArchiveTableEvents = (projectNumber) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/get-export-archive-table-events`;
  // This handles the user desiring multiple tables in P6/change management in Engineering Execution exported
  export const exportArchiveTableToExcel = (projectNumber) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/export-archive-table-to-excel`;
  /* End point for the assumption archive table to be called to the engineering execution API */
  // I must process all of the Assumptions for this current project
export const assumptionArchiveTable = (projectNumber) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/assumption-archive-table`;

export const setReviseAssumption = (projectNumber, outputInstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/outputs/${outputInstanceId}/revise-assumption`;

export const getQualityWidgetForm = () => `${ENGG_URL}api/v1/engg-exec/quality-confirmation-widget-form`;
export const saveQualityConfirmationWidget = (projectNumber, giginstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/gigs/${giginstanceId}/quality-confirmation-widget`;
export const updateQualityConfirmationWidget = (projectNumber, giginstanceId, id) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/gigs/${giginstanceId}/quality-confirmation-widget/${id}`;
export const getQualityConfirmationData = (projectNumber, giginstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/gigs/${giginstanceId}/quality-confirmation-widget`;
export const getWidgetRelatedGigs = (projectNumber, giginstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/gigs/${giginstanceId}/quality-confirmation-widget/related-gigs`;
export const reworkAssement = (projectNumber, iterationId, outputInstanceId, id) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/iterations/${iterationId}/outputs/${outputInstanceId}/assessment/${id}`;

export const getGigCustomizeFragnetData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-fragnet`;
export const getGigFragnetSteps = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/fragnet-steps`;
export const updateGigCFData = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/gig-workflow/customize-fragnet/${assessmentId}`;
export const saveGigCFData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-fragnet`;
export const updateGigCGData = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-gignet/${assessmentId}`;
export const saveGigCGData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customize-gignet`;

export const getGigFragnetStepsInformation = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/fragnet-step-information`;

export const getOutputChecklistHistory = (projectNumber, iterationId, giginstanceId, outputInstanceId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/iterations/${iterationId}/gigs/${giginstanceId}/outputs/${outputInstanceId}/revisions`;

export const getWbsInfoChangeAssessment = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/wbs`;

export const beginReworkProcess = (projectNumber, giginstanceId, id) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/gig/${giginstanceId}/quality-confirmation-widget/${id}/begin-rework-process`;

export const getReworkImpactDecisionData = (projectNumber, workitemId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/work-items/${workitemId}`;
export const saveReworkImpactDecision = (projectNumber, workitemId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/work-items/${workitemId}`;

export const getWokitemDataFromChangelog = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/work-items/rework-impact-decision`;
// assessmentId is optional
export const gigInfoFragnetSteps = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/fragnet-steps${
    assessmentId ? `/${assessmentId}` : ''
  }`;
export const gigInfoUpdate = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/gigs${
    assessmentId ? `/${assessmentId}` : ''
  }`;
export const autoCompleteFS = (projectNumber, changeLogId, assessmentId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gig-information/auto-complete/${assessmentId}`;

export const saveChangeApprovalStatus = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/approval-status`;
export const assessmentEdit = (projectNumber, changeLogId, discipline) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/edit/assessment?discipline=${discipline}`;

export const exportP6Data = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/export`;

// type = "fragnet-steps" | "logic-ties" | "resource-info"
export const getP6ChangeDataTables = (projectNumber, changeLogId, type, pageNumber) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/p6/${type}${pageNumber ? `?pageNumber=${pageNumber}` : null}`;

export const workItemStatusChange = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/work-items`;
export const P6RetrieveChangeData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/iteration`;
export const P6IterationsChangeData = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/iterations`;
export const getP6IterationsTableData = (projectNumber, changeLogId, type, iterationId, pageNumber) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/p6/${type}${
    iterationId ? `?iterationId=${iterationId}&iteration=true` : null
  }${pageNumber ? `&pageNumber=${pageNumber}` : null}`;

// Implement change in execution
export const implementChange = (projectNumber, changeLogId, iterationId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/iteration/${iterationId}/implement-changes`;
export const getWorkItem = (projectNumber, workItemId) =>
  `${ENGG_URL}api/v1/engg-exec/projects/${projectNumber}/work-items/${workItemId}`;
// Merge data api call
export const getPaMergeChanges = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/project-activity/merge-changes`;
export const fragnetStepMergeUpdate = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/fragnetStepInfoMergeChange`;
export const customizeGignetMergeUpdate = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/customizeMergeChange`;
export const gigTypeMergeUpdate = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gigTypeMergeChange`;
export const gigInfoMergeUpdate = (projectNumber, changeLogId) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/change-logs/${changeLogId}/assessment/gig-workflow/gigInformationMergeChange`;
export const getAddGigFields = (projectNumber) =>
  `${FUNCTIONS_URL}api/v1/change-management/projects/${projectNumber}/add-gigs-fields`;

export const queueJobStatus = (projectNumber) => `${BASE_URL}api/v1/queue-job-status?projectNumber=${projectNumber}`;

export default servicelist;
