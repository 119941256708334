import { get } from 'lodash';
import { commonActionTypes } from '../actions';

export const initialState = {
  formEditing: false,
  globalAlert: { messageId: '', theme: 'alert-success' },
  headerAlert: { messageId: '', theme: 'alert-warning' },
  notificationCount: { count: 0, menu: '' },
  notificationCountNonLaminar: { count: 0, menu: '' },
  showLoader: 0,
  showNotificationLoader: 0,
  enabledFlags: [],
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActionTypes.INFO_POPUP:
      return {
        ...state,
        globalAlert: {
          messageId: action.data.messageId,
          theme: action.data.theme !== undefined ? action.data.theme : 'alert-success',
          hide: get(action.data, 'hide', true),
        },
      };
    case commonActionTypes.HEADER_ALERT:
      return {
        ...state,
        headerAlert: {
          messageId: action.data.messageId,
          theme: action.data.theme !== undefined ? action.data.theme : 'alert-warning',
        },
      };
    case commonActionTypes.MODAL_POPUP:
      return {
        ...state,
        modalPopup: {
          isEdited: action.data.isEdited,
          showModal: action.data.showModal,
          menu: action.data.menu,
        },
      };
    case commonActionTypes.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: {
          count: action.data.count,
          menu: action.data.menu,
        },
      };
    case commonActionTypes.SHOW_NOTIFICATION_LOADER:
      let val = 0;
      if (action.data === true) {
        val = (state.showNotificationLoader || 0) + 1;
      } else {
        val = (state.showNotificationLoader > 0 ? state.showNotificationLoader : 1) - 1;
      }
      return { ...state, showNotificationLoader: val };
    case commonActionTypes.NOTIFICATION_COUNT_NON_LAMINAR:
      return {
        ...state,
        notificationCountNonLaminar: {
          count: action.data.count,
          menu: action.data.menu,
        },
      };
    case commonActionTypes.FORM_EDITING:
      return { ...state, ...action.formEditing };
    case commonActionTypes.SHOW_LOADER: {
      let val = 0;
      if (action.data === true) {
        val = (state.showLoader || 0) + 1;
      } else {
        val = (state.showLoader > 0 ? state.showLoader : 1) - 1;
      }
      return { ...state, showLoader: val };
    }

    case commonActionTypes.ADD_FEATURE_FLAG: {
      const data = [...state.enabledFlags];

      if (data.includes(action.payload)) {
        return { ...state };
      }
      return { ...state, enabledFlags: [...data, action.payload] };
    }

    case commonActionTypes.REMOVE_FEATURE_FLAG: {
      const data = [...state.enabledFlags];
      const findIndex = data.findIndex((item) => item === action.payload);
      if (data.includes(action.payload)) {
        data.splice(findIndex, 1);
        return { ...state, enabledFlags: data };
      }
      return { ...state };
    }

    default:
      return state;
  }
};
