import { get } from 'lodash';
import { landingActionTypes } from '../actions';

const InitialState = {
  projectList: {
    totalPagesEngineeringExecution: 0,
    totalPagesProjectSetup: 0,
    projects: { 'Engineering Execution': [], 'Setup in Progress': [] },
  },
};

export const landingReducer = (state = InitialState, action) => {
  switch (action.type) {
    case landingActionTypes.GET_PROJECT:
      return { ...state };

    case landingActionTypes.GET_PROJECT_SUCCESS: {
      const landingData = action.payload;
      if (landingData.stateValue === 'Engineering Execution') {
        return {
          ...state,
          projectList: {
            ...state.projectList,
            isSucceeded: true,
            totalPagesEngineeringExecution: get(landingData, 'data.totalPagesEngineeringExecution', 0),
            projects: {
              ...state.projectList.projects,
              'Engineering Execution': get(landingData, 'data.projects', []),
            },
          },
        };
      }
      if (landingData.stateValue === 'Setup in Progress') {
        return {
          ...state,
          projectList: {
            ...state.projectList,
            isSucceeded: true,
            totalPagesProjectSetup: get(landingData, 'data.totalPagesProjectSetup', 0),
            projects: {
              ...state.projectList.projects,
              'Setup in Progress': get(landingData, 'data.projects', []),
            },
          },
        };
      }
    }

    case landingActionTypes.GET_PROJECT_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
