import { call, put, select, takeEvery } from 'redux-saga/effects';
import { get, isArray, isEmpty } from 'lodash';
import { commonActions, projectSetupActionTypes, projectSetupActions, projectDashboardActions } from '../actions';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist, { completeProjectForms } from '../../services/serviceList';
import { TOP_MENU } from '../../constants/assets';

export function* getCreateProjectFieldsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    let responseData; // incase we need the error data from the api
    responseData = yield call(AxiosInstance.get, servicelist('getCreateProjectFields'));
    yield put(
      projectSetupActions.getCreateProjectFieldsSucceded({
        isSucceeded: true,
        createdProjectFields: responseData.data,
      })
    );
    yield call(payload.data.showModal, true);
  } catch (e) {
    const errorResponse = get(e, 'response.data', {});
    yield put(
      commonActions.infoPopup({
        messageId: get(errorResponse, 'errorMessage', 'An Error has Occured'),
        theme: 'alert-danger',
      })
    );
    yield put(projectSetupActions.getCreateProjectFieldsFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getCreateProjectFieldsStatus() {
  yield takeEvery([projectSetupActionTypes.GET_CREATE_PROJECT_FIELDS], getCreateProjectFieldsSaga);
}

export function* createProjectSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { history, form } = payload.data;
    const responseData = yield call(AxiosInstance.post, servicelist('createProject'), form);
    yield put(
      projectSetupActions.createProjectSucceded({
        isSucceeded: true,
        createdProject: responseData.data,
      })
    );
    yield put(projectSetupActions.projectDetails(responseData.data));
    yield put(
      commonActions.infoPopup({
        messageId: `A New Project has been created with Name: ${responseData.data.projectName}  and Number: ${responseData.data.projectNumber}`,
      })
    );
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData.data.projectNumber,
        stateKey: responseData.data.stateKey,
        history,
      })
    );
  } catch (e) {
    const errorResponse = get(e, 'response.data', {});
    if (errorResponse.errorCode === 'P001') {
      yield put(
        commonActions.infoPopup({
          messageId: errorResponse.errorMessage,
          theme: 'alert-danger',
        })
      );
    } else {
      yield put(
        commonActions.infoPopup({
          messageId: 'An Error has Occured',
          theme: 'alert-danger',
        })
      );
    }

    yield put(projectSetupActions.createProjectFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* createProjectStatus() {
  yield takeEvery([projectSetupActionTypes.CREATE_PROJECT], createProjectSaga);
}

export function* getProjectMenuSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { history } = payload.data;
    const payloadData = payload.data;
    delete payloadData.history;
    const projectBaseUrl = get(payload, 'data.projectBaseUrl', '');
    delete payloadData.projectBaseUrl;
    const responseData = yield call(
      AxiosInstance.get,
      servicelist(get(payload, 'data.category', '') === 'projectTeams' ? 'getProjectTeamSubmenus' : 'getProjectMenu'),
      {
        params: payloadData,
      }
    );

    // project teams get menuList
    if (get(payload, 'data.category', '') === 'projectTeams') {
      yield put(
        projectDashboardActions.getProjectTeamMenuItem({
          isSucceeded: true,
          menuList: responseData.data,
        })
      );
    } else {
      yield put(
        projectSetupActions.getProjectMenuSucceeded({
          isSucceeded: true,
          menuList: responseData.data,
        })
      );
    }
    if (history) {
      const menuItem = get(responseData, 'data.menuItems[0].id', '');
      const projectNumber = get(payload, 'data.projectNumber', '');
      const appBaseUrl = window.location.protocol + '//' + window.location.host;
      let projectBasePath = '';
      if (get(payload.data, 'stateKey', '') === 'enggExecution') {
        projectBasePath = 'engineeringExecution';
      } else if (get(payload.data, 'stateKey', '') === 'dataManager') {
        projectBasePath = 'masterData';
      } else projectBasePath = 'projectSetup';
      if (menuItem) {
        // selecting the frst menu id and assigning it in the store
        yield put(projectSetupActions.projectDetails({ selectedMenu: menuItem }));
        // routing the first selected Menu
        if (projectBasePath === 'masterData') {
          yield call(history.push, `/${projectBasePath}/${TOP_MENU[menuItem].url}/`);
        } else {
          if (projectBaseUrl === '' || (projectBaseUrl === appBaseUrl)) {
            yield call(history.push, `/${projectBasePath}/${TOP_MENU[menuItem].url}/${projectNumber}`);
          } else {
            yield call (() => { window.location = `${projectBaseUrl}/${projectBasePath}/${TOP_MENU[menuItem].url}/${projectNumber}`});
            return;
          }
        }
      }
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // project teams get menuList
    if (get(payload, 'data.category', '') === 'projectTeams') {
      yield put(
        projectDashboardActions.getProjectTeamMenuItem({
          isSucceeded: false,
          menuList: [],
        })
      );
    } else {
      yield put(projectSetupActions.getProjectMenuFailed({ isSucceeded: false, menuList: null }));
    }
    yield put(
      commonActions.infoPopup({
        messageId: 'An Error has Occured',
        theme: 'alert-danger',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectMenuStatus() {
  yield takeEvery([projectSetupActionTypes.GET_PROJECT_MENU], getProjectMenuSaga);
}

export function* getProjectSubMenuSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const getMenu = (state) => get(state, 'projectSetupReducer.menuList.menuItems', {});
    const mainMenu = yield select(getMenu);
    if (isEmpty(mainMenu)) {
      const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', '');
      const stateKey = yield select(getStateKey);
      yield put(
        projectSetupActions.getProjectMenu({
          projectNumber: payload.submenuList.projectNumber,
          stateKey,
        })
      );
    }
    const responseData = yield call(AxiosInstance.get, servicelist('getProjectMenu'), {
      params: {
        projectNumber: payload.submenuList.projectNumber,
        menuId: payload.submenuList.menuId,
        laminarUserRole: payload.submenuList.laminarUserRole,
      },
    });
    yield put(
      projectSetupActions.getProjectSubMenuSucceeded({
        isSucceeded: true,
        submenuList: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(projectSetupActions.getProjectSubMenuFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectSubMenuStatus() {
  yield takeEvery([projectSetupActionTypes.GET_PROJECT_SUBMENU], getProjectSubMenuSaga);
}

export function* completeProjectFormMenuSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const getDisciplineArray = (state) => get(state, 'projectSetupReducer.submenuList.disciplines', []);
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', '');
    let discipline = '';
    const submenuId = get(payload, 'payload.submenuId', '');
    const disciplineArray = yield select(getDisciplineArray);
    const getsubmenus = (state) => get(state, 'projectSetupReducer.submenuList.menuItems.subMenus', false);
    const submenus = yield select(getsubmenus);
    const submenu = submenus.filter((item) => submenuId === item.id);

    if (disciplineArray) {
      if (disciplineArray.length > 1 && submenu[0].discipline) {
        // Added submenuDiscipline check for Project Output submenu in Input/output menu
        discipline = submenu[0].discipline;
      } else {
        const [firstElement] = disciplineArray;
        discipline = firstElement;
      }
    }

    const stateKey = yield select(getStateKey);

    if (discipline) {
      payload.payload.discipline = discipline;
    }

    const responseData = yield call(
      // assigning the response data to the variable
      AxiosInstance.put,
      completeProjectForms('projectSetup', payload.payload.projectNumber),
      payload.payload
    );
    yield put(
      projectSetupActions.completeSubMenuSucceeded({
        isSucceeded: true,
        completeResponse: responseData.data.data,
      })
    );
    if (payload.payload.isComplete) {
      yield put(commonActions.infoPopup({ messageId: 'Your form has been Completed' }));
    } else {
      yield put(commonActions.infoPopup({ messageId: 'You can edit your form' }));
    }

    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData.data.data.projectNumber,
        stateKey,
      })
    ); // making the menu call and making the submenu call
    if (submenuId !== '') {
      yield put(projectSetupActions.getProjectSubMenu(payload.payload));
    }
  } catch (e) {
    yield put(commonActions.infoPopup({ messageId: 'An Error has Occured', theme: 'alert-danger' }));
    yield put(
      projectSetupActions.completeSubMenuFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* completeProjectFormMenuStatus() {
  yield takeEvery([projectSetupActionTypes.COMPLETE_PROJECT_FORM_MENU], completeProjectFormMenuSaga);
}

export function* completeSubMenuSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const getDisciplineArray = (state) => get(state, 'projectSetupReducer.submenuList.disciplines', []);
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', '');
    let discipline;
    const submenuId = get(payload, 'payload.submenuId', '');
    const disciplineArray = yield select(getDisciplineArray);

    if (disciplineArray) {
      const getsubmenus = (state) => get(state, 'projectSetupReducer.submenuList.menuItems.subMenus', false);
      const submenus = yield select(getsubmenus);
      const submenu = submenus.filter((item) => submenuId === item.id);

      if (submenu.length && submenu[0].discipline) {
        discipline = submenu[0].discipline;
      } else {
        discipline = disciplineArray;
      }

      if (!isArray(discipline) && disciplineArray.includes('Management')) {
        discipline = discipline === 'Management' ? [discipline] : [discipline, 'Management'];
      }
    }

    const stateKey = yield select(getStateKey);

    if (discipline) {
      payload.payload.discipline = discipline;
    }

    const responseData = yield call(
      // assigning the response data to the variable
      AxiosInstance.put,
      servicelist('completeProjectInfoForm'),
      payload.payload
    );
    yield put(
      projectSetupActions.completeSubMenuSucceeded({
        isSucceeded: true,
        completeResponse: responseData.data,
      })
    );
    if (payload.payload.isComplete) {
      yield put(commonActions.infoPopup({ messageId: 'Your form has been Completed' }));
    } else {
      yield put(commonActions.infoPopup({ messageId: 'You can edit your form' }));
    }
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData.data.projectNumber,
        stateKey,
      })
    ); // making the menu call and making the submenu call
    if (submenuId !== '') {
      yield put(projectSetupActions.getProjectSubMenu(payload.payload));
    }
  } catch (e) {
    yield put(commonActions.infoPopup({ messageId: 'An Error has Occured', theme: 'alert-danger' }));
    yield put(
      projectSetupActions.completeSubMenuFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* completeSubMenuStatus() {
  yield takeEvery([projectSetupActionTypes.COMPLETE_SUBMENU], completeSubMenuSaga);
}

export function* getProjectInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload.data;
    const responseData = yield call(AxiosInstance.get, servicelist('getProjectInfo'), {
      params: {
        projectNumber,
      },
    });

    yield put(
      projectSetupActions.getProjectInfoSucceeded({
        isSucceeded: true,
        projectInfo: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(projectSetupActions.getProjectInfoFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectInfoStatus() {
  yield takeEvery([projectSetupActionTypes.GET_PROJECT_INFO], getProjectInfoSaga);
}
