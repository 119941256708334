import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, isEqual } from 'lodash';
import { BVModal } from '../../baseComponents/BVModal';
import { projectSetupActions } from '../../redux/actions';
import useFeatureFlags from '../../customHooks/usefeatureFlags';

function FormButtons(props) {
  const [showReset, setShowReset] = React.useState(false);
  const [showNavigationModal, setNavigationModal] = React.useState(false);
  const {
    formComplete,
    editAction,
    resetStatus,
    resetAction,
    saveStatus,
    saveAction,
    completeStatus,
    completeAction,
    isResetVisible,
    resetLabel,
    saveLabel,
    isSaveVisible,
    isCompleteVisible,
    isEnableApprove,
    permissionGroup,
    getProjectInfo,
    projectNumber,
    projectDetails,
    projectInfo,
    history,
    isEditEnable,
    applyButtonLabel,
  } = { ...props };
  const { submenuList, isShowAlertModal } = props;
  const [getmanHrBudget, setManHrbudget] = React.useState(false);
  const [dpsWbs, setDpsWbs] = React.useState(false);
  const [getwbsInfo, setWBSInfo] = React.useState(false);
  const [popupFlag, setPopupflag] = React.useState(false);
  const [openModal, setOpenModel] = React.useState(false);

  // const openModal = true;
  // const isEnableApprove = true;

  const [continueMessage, setcontinueMessage] = React.useState('');
  const [commonResetMessage, setcommonResetMessage] = React.useState('');

  const resetContinue = () => {
    setShowReset(!showReset);
    resetAction();
  };

  const formContinue = () => {
    setShowReset(!showReset);
    completeAction();
  };

  // feature toggling
  useFeatureFlags(['F-BRNDCHNG-24082021']);
  const [enabledFeatureFlags, setEnabledFeatureFlags] = React.useState([]);
  const globalEnabledFeatureFlagsData = useSelector((state) => get(state, 'commonData.enabledFlags', []));

  React.useEffect(() => {
    if (!isEqual(globalEnabledFeatureFlagsData, enabledFeatureFlags)) {
      setEnabledFeatureFlags(globalEnabledFeatureFlagsData);
    }
  }, [globalEnabledFeatureFlagsData]);

  React.useEffect(() => {
    projectInfo &&
      projectInfo.stateKey === 'enggExecution' &&
      projectDetails.stateKey === 'setUpInProgress' &&
      setNavigationModal(true);
  }, [projectInfo]);

  React.useEffect(() => {
    isCompleteValue();
    const list = get(submenuList, 'menuItems.subMenus', []);
    const editableSubmenus = list.filter((item) => item.rights > 1);
    const popupShowArray = editableSubmenus.filter((item) => item.isComplete === true);
    const isPli = permissionGroup.includes('pli');

    if (popupShowArray.length == editableSubmenus.length - 1) {
      setPopupflag(true);
    } else {
      setPopupflag(false);
    }
    
    if (props.match.path === '/projectSetup/workflowRules/:routeProjectNumber' && !props.resetStatus) {
      setcontinueMessage(
        'Statusing this Section complete will allow Discipline Leads to leverage this Workflow. No further edits will be allowed to the Workflow Rules. Continue?'
      );
    } else {
      setcommonResetMessage('Reset will revert all displayed information to the last saved version. Continue?');
    }
    
    if (
      props.match.path === '/projectSetup/workflowRules/:routeProjectNumber' && !props.resetStatus
    ) {
      setOpenModel(true);
    } else if (props.match.path === '/projectSetup/view-approve-project-plan/:routeProjectNumber') {
      if (isEnableApprove) {
        setOpenModel(true);
        setcontinueMessage(
          'Upon Final Approval, Discipline Leads and Management will proceed to reviewing Inputs/Outputs as the final step in Project Setup. You cannot undo this Status. Please select Proceed to confirm or Cancel.'
        );
      } else {
        setOpenModel(true);
        setcontinueMessage(
          'Selecting Continue will status this version of the plan Complete for your Discipline. You cannot undo this Status.'
        );
      }
    } else {
      setOpenModel(false);
    }

    if (!isPli && props.match.path === '/projectSetup/inputOutput/:routeProjectNumber' && !props.resetStatus) {
      setOpenModel(true);
      setDpsWbs(true);
      setcontinueMessage(
        'Statusing this Section complete marks the end of Project Setup for your Discipline. No further edits will be allowed in Project Setup. Continue?'
      );
    }
  }, [props]);

  const isCompleteValue = () => {
    if (get(props, 'menuList.menuItems', []).length > 0) {
      const ManhourBudget = ((props.menuList.menuItems || []).find((item) => item.menuName === 'Manhour Budget') || {})
        .isComplete;

      const wbsInfo = ((props.menuList.menuItems || []).find((item) => item.menuName === 'WBS Information') || {})
        .isComplete;
      setManHrbudget(ManhourBudget);
      setWBSInfo(wbsInfo);
    }
  };

  const handleSaveAction = () => {
    saveAction();
    getProjectInfo({ projectNumber });
  };

  const setNavigationModalContent = () =>
    `The Baseline Project Plan has been approved by Management
    and all Inputs/Outputs are Completed. Project Setup is now Complete.
    You will now be navigated to the ${
      enabledFeatureFlags.includes('F-BRNDCHNG-24082021') ? 'EnGig' : 'Laminar'
    } Home Page where you can enter
    the Project in Engineering Execution.`;

  const handleNavigationButton = () => history.replace('/landing');

  const handleReset = (e) => {
    e.preventDefault();
    setShowReset(!showReset);
    // on clicking reset not showing alert modal hard reset changes
    if (!isShowAlertModal) {
      props.resetAction();
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleSaveAction();
  };
  return (
    <div
      className="formButtonsDiv"
      style={{
        width: '100%',
        height: '79px',
        backgroundColor: '#1e6ec5',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 4,
      }}
      className="fixed-action-bar"
    >
      {isShowAlertModal && (
        <BVModal
          showModal={showReset}
          title={openModal ? (isEnableApprove ? 'Confirm Approval' : 'Status Plan Complete') : 'Reset Page'}
          closeButton
          toggleModal={() => setShowReset(!showReset)}
          modalContent={openModal ? continueMessage : commonResetMessage}
          buttonData={[
            {
              buttonClick: () => setShowReset(!showReset),
              buttonLabel: 'Cancel',
            },
            {
              buttonClick: openModal ? formContinue : resetContinue,
              isPrimaryButton: true,
              buttonLabel: applyButtonLabel || 'Continue',
            },
          ]}
        />
      )}

      {formComplete ? (
        <button
          type="button"
          disabled={!isEditEnable}
          className={isEditEnable ? 'completeButtonActive' : 'completeButtonDisabled'}
          onClick={editAction}
        >
          Edit
        </button>
      ) : (
        <>
          {isResetVisible && (
            <button
              type="button"
              className={`reset-button ${resetStatus ? '' : 'buttonDisabled'}`}
              disabled={!resetStatus}
              onClick={handleReset}
            >
              {resetLabel}
            </button>
          )}
          {isSaveVisible && (
            <button
              type="button"
              className={`save-button ${saveStatus ? '' : 'buttonDisabled'}`}
              disabled={!saveStatus}
              onClick={handleSave}
            >
              {saveLabel}
            </button>
          )}
          {isCompleteVisible && (
            <button
              type="button"
              className={completeStatus ? 'completeButtonActive' : 'completeButtonDisabled'}
              disabled={!completeStatus}
              onClick={
                (openModal && (dpsWbs || getmanHrBudget || getwbsInfo) && popupFlag) ||
                props.match.path === '/projectSetup/workflowRules/:routeProjectNumber' ||
                props.match.path === '/projectSetup/view-approve-project-plan/:routeProjectNumber'
                  ? () => setShowReset(!showReset)
                  : completeAction
              }
            >
              {isEnableApprove ? 'Approve' : 'Complete'}
            </button>
          )}
        </>
      )}

      <BVModal
        showModal={showNavigationModal}
        modalContent={setNavigationModalContent()}
        buttonData={[
          {
            buttonClick: () => handleNavigationButton(),
            isPrimaryButton: true,
            buttonLabel: 'OK',
          },
        ]}
      />
    </div>
  );
}

FormButtons.defaultProps = {
  isResetVisible: true,
  isSaveVisible: true,
  isCompleteVisible: true,
  resetLabel: 'Reset',
  saveLabel: 'Save',
  isShowAlertModal: true,
  isEditEnable: true,
  applyButtonLabel: 'Continue',
};

export const mapDispatchToProps = (dispatch) => ({
  getProjectInfo: (data) => dispatch(projectSetupActions.getProjectInfo(data)),
});

export const mapStateToProps = (state) => ({
  menuList: get(state, 'projectSetupReducer.menuList', {}),
  submenuList: get(state, 'projectSetupReducer.submenuList', {}),
  projectNumber: get(state, 'projectSetupReducer.projectDetails.projectNumber', ''),
  permissionGroup: get(state, 'projectSetupReducer.menuList.permissionGroups', []),
  projectDetails: get(state, 'projectSetupReducer.projectDetails', {}),
  projectInfo: get(state, 'projectSetupReducer.projectInfo', {}),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormButtons));
