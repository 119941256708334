import { isEmpty, compact, get, cloneDeep } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { format } from 'prettier';
import { ICONS } from '../constants/assets';
import { INPUT_TYPES, LOCAL_STORAGE_KEY, DATE_FORMAT } from '../constants/common';
import servicelist, { FEATURE_FLAG_URL } from '../services/serviceList';
import store from '../redux/store';
import AxiosInstance from '../services/commons/Interceptor';
import { commonActions, projectHomeActions } from '../redux/actions';

export const getFieldPlaceHolder = (type) => {
  const fieldType = type ? type.toString().toLowerCase() : '';
  switch (fieldType) {
    case INPUT_TYPES.TEXT:
      return 'Type here';
    case INPUT_TYPES.NUMBER:
      return 'Enter Value here';
    case INPUT_TYPES.SELECT:
      return 'Please Select';
    default:
      return 'Type here';
  }
};

export const handleStringConcat = (val) => {
  const data = compact(val);
  if (isEmpty(data)) {
    return '-';
  }

  return data.reduce((acc, curr) => `${acc}, ${curr}`);
};

export const getGigStatusImage = (data) => {
  switch (data) {
    case 'In-Progress':
      return ICONS.IN_PROGRESS;
    case 'Completed':
      return ICONS.ICON_COMPLETED;
    case 'Ready to Start':
      return ICONS.READY;
    case 'Not-Ready':
      return ICONS.NOT_READY;
    case 'On-Hold':
      return ICONS.ON_HOLD;
    case 'Unknown':
      return ICONS.UNKNOWN;
    case 'Not Started':
      return ICONS.NOT_STARTED;
    case 'Sort by Forecast Start Date':
      return ICONS.ICON_FORECAST_CALENDAR;
    default:
      return ICONS.UNKNOWN;
  }
};

export const findUserPermission = (data) => {
  if (!isEmpty(data)) {
    const results = data.some((item) => item.includes('dps') || item.includes('pli'));
    return results;
  }
  return false;
};

export function camelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const onlyNums = (value) => value.replace(/[^\d]/g, '');

// removeSpecialChar and convert to camelcase
// clean up should be hadled in single regex
export const specialCamelCase = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/[^a-zA-Z-0-9 ]/g, '');

/**
 * Generates date and time in human readable format
 * @param {number} unixTimestamp holds the date in epoch unix timestamp
 * @param {String} format holds the human readable format in which the epoch timestamp needs to be converted to
 * @return {date} returns date in human readable format
 */
export const getLocalDate = (unixTimestamp, format) => moment.unix(unixTimestamp).format(format);

export const getDate = (timeStamp, type) =>
  moment.utc(timeStamp).local().format(DATE_FORMAT.DDMMYYYY_MERIDIAN_TIME_HYPHEN);

export const getConfigData = async () => {
  const { data } = await AxiosInstance.get(servicelist('config'));
  if (data) {
    localStorage.setItem(LOCAL_STORAGE_KEY.FEATURE_TOGGLING_KEY, JSON.stringify(data.featureTogglingApiKey));
    localStorage.setItem(LOCAL_STORAGE_KEY.ENCRYPTION_KEY, JSON.stringify(data.configEncryptionKey));
  }
};

export const fetchFeatureFlagStatus = async (str) => {
  const env = process.env.NODE_ENV;
  const state = store.getState();
  const enabledFlags = get(state, 'commonData.enabledFlags', []);
  try {
    if (!localStorage.getItem(LOCAL_STORAGE_KEY.FEATURE_TOGGLING_KEY)) {
      await getConfigData();
    }

    const featureTogglingApiKey = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.FEATURE_TOGGLING_KEY));

    const res = await axios.get(FEATURE_FLAG_URL, {
      params: {
        environment: env,
        feature: str,
      },
      headers: {
        'x-functions-key': featureTogglingApiKey,
      },
    });
    const isEnabledFlag = get(res, 'data.enabled', false);

    if (isEnabledFlag && !enabledFlags.includes(str)) {
      store.dispatch(commonActions.addFeatureFlag(str));
    }
    if (!isEnabledFlag && enabledFlags.includes(str)) {
      store.dispatch(commonActions.removeFeatureFlag(str));
    }
    return isEnabledFlag;
  } catch (err) {
    return false;
  }
};

export const featureFlag = (str) => {
  // str requires array of inputs
  if (str.length > 1) {
    const fetchRequestArray = str.map((item) => fetchFeatureFlagStatus(item));
    Promise.all(fetchRequestArray);
  } else {
    fetchFeatureFlagStatus(str[0]);
  }
};

export const fetchProjectHomeStepsStatus = async (str) => {
  try {
    const responseData = await AxiosInstance.get(servicelist('projectHomeContentStep'), {
      params: str,
    });
    const isStepData = get(responseData, 'data', false);

    if (isStepData) {
      store.dispatch(projectHomeActions.getProjectHomeContentStep(isStepData));
    }

    return;
  } catch (err) {
    return store.dispatch(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
  }
};

export const projectHomeSteps = (str) => {
  // str requires array of inputs
  if (str.length > 1) {
    const projectHomeStepsArray = str.map((item) => fetchProjectHomeStepsStatus(item));
    Promise.all(projectHomeStepsArray);
  } else {
    fetchProjectHomeStepsStatus(str[0]);
  }
};

export const filterZeroValuesObj = (val, filterVal, keystoInclude) => {
  const filteredData = cloneDeep(val);
  filteredData.forEach((item, index) => {
    for (const key in item) {
      if (filterVal.includes(item[key]) && keystoInclude.includes(key)) {
        delete filteredData[index][key];
      }
    }
  });
  return filteredData;
};

export const validateUrl = async (url) => {
  const { data } = await AxiosInstance.post(servicelist('validateUrl'), { url });
  return data.isValid;
};

export const getImplementationInProgressHeaderAlert = (projectInfo) => {
  let messageId = projectInfo.implementationInProgressName
    ? `Change Instance ${projectInfo.implementationInProgressName} `
    : 'A change instance ';
  messageId +=
    'is currently being Implemented, some user actions will be limited until the schedule update process is complete.';
  const warning = {
    messageId,
    theme: 'alert-warning',
  };
  return warning;
};
