export const actionTypes = {
  GET_PROJECT_HOME_CONTENT: 'GET_PROJECT_HOME_CONTENT',
  GET_PROJECT_HOME_CONTENT_SUCCESS: 'GET_PROJECT_HOME_CONTENT_SUCCESS',
  GET_PROJECT_HOME_CONTENT_FAILURE: 'GET_PROJECT_HOME_CONTENT_FAILURE',
  GET_PROJECT_HOME_CONTENT_STEP: 'GET_PROJECT_HOME_CONTENT_STEP',
};

export const actions = {
  getProjectHomeContent: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_CONTENT,
    data,
  }),
  getProjectHomeContentSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_HOME_CONTENT_SUCCESS,
    payload: content,
  }),
  getProjectHomeContentFailed: (content) => ({
    type: actionTypes.GET_PROJECT_HOME_CONTENT_FAILURE,
    payload: content,
  }),
  getProjectHomeContentStep: (data) => ({
    type: actionTypes.GET_PROJECT_HOME_CONTENT_STEP,
    data,
  }),
};
