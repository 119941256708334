import { enggExeProjectHomeActionTypes } from '../actions';

const InitialState = {};

export const enggExeProjectHomeReducer = (state = InitialState, action) => {
  switch (action.type) {
    // Overview
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_OVERVIEW:
      return { ...state };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_OVERVIEW_SUCCESS:
      return { ...state, ...action.payload };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_OVERVIEW_FAILURE:
      return { ...state, ...action.payload };

    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_LOG_TIME_DATA:
      return { ...state };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_LOG_TIME_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_LOG_TIME_DATA_FAILURE:
      return { ...state, ...action.payload };

    case enggExeProjectHomeActionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA:
      return { ...state };
    case enggExeProjectHomeActionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case enggExeProjectHomeActionTypes.SAVE_PROJECT_HOME_LOG_TIME_DATA_FAILURE:
      return { ...state, ...action.payload };

    // My Gigs
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_GIGS:
      return { ...state };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_GIGS_SUCCESS:
      return { ...state, ...action.payload };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_GIGS_FAILURE:
      return { ...state, ...action.payload };

    // MY WORK ITEMS
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS:
      return { ...state };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS_SUCCESS:
      return { ...state, ...action.payload };
    case enggExeProjectHomeActionTypes.GET_PROJECT_HOME_MY_WORK_ITEMS_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
