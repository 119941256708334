// Added commonActions import, which is used in your saga
import { commonActions, assumptionArchiveActions, assumptionArchiveActionTypes } from '../actions';
import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
import {
    assumptionArchiveTable,
} from '../../services/serviceList';

export function* getAssumptionArchiveTableSaga(payload) {
    try {
        yield put(commonActions.showLoader(true));
        const { projectNumber } = payload.data;
        // Gets all of the filtering, sorting, and pagination data
        const { body } = payload.data;
       // Make a post call to the engineering execution API with this created endpoint from service list
        const responseData = yield call(AxiosInstance.post, assumptionArchiveTable(projectNumber), body);
        yield put(
          assumptionArchiveActions.getAssumptionArchiveTableSucceeded({
            isSucceeded: true,
            // Retrieves all of the data from the db
            rawAssumptionData: responseData.data.rawData,
            assumptionArchiveTableData: responseData.data.data,
            dataState: responseData.data.dataState,
            totalRecords: responseData.data.totalRecords,
          })
        );
      } catch (e) {
        yield put(
          commonActions.infoPopup({
            messageId: 'Something went wrong. A system error has occurred.',
            theme: 'alert-danger',
          })
        );
        yield put(
          assumptionArchiveActions.getAssumptionArchiveTableFailed({
            isSucceeded: false,
          })
        );
      }
      yield put(commonActions.showLoader(false));
    }

export function* getAssumptionArchiveTableStatus() {
    yield takeEvery([assumptionArchiveActionTypes.GET_ASSUMPTION_ARCHIVE_TABLE], getAssumptionArchiveTableSaga);
}