import { libraryActionTypes } from '../actions';

const InitialState = {};

export const referenceLibraryReducer = (state = InitialState, action) => {
  switch (action.type) {
    case libraryActionTypes.GET_REFERENCE_LIBRARY_FORM:
      return { ...state };
    case libraryActionTypes.GET_REFERENCE_LIBRARY_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case libraryActionTypes.GET_REFERENCE_LIBRARY_FORM_FAILURE:
      return { ...state, ...action.payload };
    case libraryActionTypes.SAVE_REFERENCE_LIBRARY_FORM:
      return { ...state, ...action.data };
    case libraryActionTypes.SAVE_REFERENCE_LIBRARY_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case libraryActionTypes.SAVE_REFERENCE_LIBRARY_FORM_FAILURE:
      return { ...state, ...action.payload };
    case libraryActionTypes.UPDATE_REFERENCE_LIBRARY_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case libraryActionTypes.REFERENCE_LIBRARY_CLEAR:
      return { ...state, libraryData: null };
    case libraryActionTypes.GET_REFERENCE_LIBRARY_CATEGORY:
      return { ...state };
    case libraryActionTypes.GET_REFERENCE_LIBRARY_CATEGORY_SUCCESS:
      return { ...state, ...action.payload };
    case libraryActionTypes.GET_REFERENCE_LIBRARY_CATEGORY_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
