import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';
import './style.scss';
import moment from 'moment';
import { ICONS, IMAGES } from '../../constants/assets';
import { commonActions, systemNotificationsActions } from '../../redux/actions';
import HelpPopup from '../help/helpPopup';
import NotificationsPopup from '../notifications/notificationsPopup';
import UserProfilePopup from '../userProfile/userProfilePopup';
import useGetProjectInfo from '../common/api/useGetProjectInfo';
import { getImplementationInProgressHeaderAlert } from '../../helpers/common';
import { DATE_FORMAT } from '../../constants/common';

function ProjectNavbar(props) {
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [noUnReadNotification, setNoUnReadNotification] = useState(true);
  const [systemNotificationsPopupData, setSystemNotificationsPopupData] = useState({});
  const [permissionGroups, setPermissionGroups] = useState([]);

  const helpRef = useRef(null);
  const notificationRef = useRef(null);
  const userProfileRef = useRef(null);

  const { data: projectInfo } = useGetProjectInfo({
    projectNumber: props.projectNumber,
  });

  // Workaround for infinite loop that occurs when headerAlertDispatch is called,
  // which somehow triggers a mutation of projectSetupReducer.menuList.permissionGroups
  useEffect(() => {
    if (!isEqual(props.permissionGroups, permissionGroups)) {
      setPermissionGroups(props.permissionGroups);
    }
  }, [props.permissionGroups]);

  useEffect(() => {
    if (projectInfo?.isImplementationInProgress) {
      if (
        projectInfo?.implementationFailedAndRollbackFailed &&
        (permissionGroups.includes('pjc') || permissionGroups.includes('pli') || permissionGroups.includes('m'))
      ) {
        props.headerAlertDispatch({
          messageId: `Schedule Implementation & Rollback has Failed for Change Instance ${projectInfo.implementationInProgressName}, please review notifications for additional details and contact support.`,
          theme: 'alert-danger',
        });
      } else if (
        projectInfo?.implementationBackupFailed &&
        (permissionGroups.includes('pjc') || permissionGroups.includes('pli') || permissionGroups.includes('m'))
      ) {
        props.headerAlertDispatch({
          messageId: `Implementation Error: The backup process has failed to complete for Change Instance ${
            projectInfo.implementationInProgressName ? projectInfo.implementationInProgressName : ''
          }, contact technical support. This message was triggered on ${projectInfo?.implementationDate}.`,
          theme: 'alert-danger',
        });
      } else if (projectInfo?.dataVerificationUnsuccessful) {
        props.headerAlertDispatch({
          messageId: `Implementation ${
            projectInfo.implementationInProgressName ? projectInfo.implementationInProgressName : ''
          } is missing data. Please contact technical support. This message was generated on ${
            projectInfo.dataVerificationTime
              ? moment.utc(projectInfo.dataVerificationTime).local().format(DATE_FORMAT.MMDDYYYY_FULL_TIME_HYPHEN)
              : projectInfo?.implementationDate
          }`,
          theme: 'alert-danger',
        });
      } else if (projectInfo?.dataVerificationInProgress) {
        props.headerAlertDispatch({
          messageId: `Change Instance ${
            projectInfo.implementationInProgressName ? projectInfo.implementationInProgressName : ''
          } is successfully implemented into Execution. Automatic data verification is now in progress and this banner message will disappear when successfully completed.`,
          theme: 'alert-warning',
        });
      } else {
        props.headerAlertDispatch(getImplementationInProgressHeaderAlert(projectInfo));
      }
    } else {
      props.headerAlertDispatch({ messageId: '' });
    }
  }, [
    projectInfo?.isImplementationInProgress,
    projectInfo?.implementationFailedAndRollbackFailed,
    projectInfo?.implementationBackupFailed,
    permissionGroups,
  ]);

  const getInitials = (name) => (name?.length ? name.charAt(0).toUpperCase() : '');
  useEffect(() => {
    function handleClickOutside(event) {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotification(false);
      } else if (helpRef.current && !helpRef.current.contains(event.target)) {
        setShowHelpPopup(false);
      } else if (userProfileRef.current && !userProfileRef.current.contains(event.target)) {
        setShowUserProfile(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [notificationRef, helpRef, userProfileRef]); /// /if clicked outside the container..it closes

  useEffect(() => {
    if (props?.user?.id) {
      props.getSystemNotificationsData({
        userId: get(props.user, 'id', ''),
        isRead: false,
        page: 1,
        limit: 20,
        // projectNumber: get(projectDetails, 'projectNumber', '99999'),
      });
    }
  }, [props?.user?.id]);

  useEffect(() => {
    if (!isEmpty(props.systemNotificationsPopupData.notifications)) {
      setNoUnReadNotification(false);
    }
    setSystemNotificationsPopupData(props.systemNotificationsPopupData);
  }, [props.systemNotificationsPopupData]);

  const handleClickHelpIcon = () => {
    setShowHelpPopup(!showHelpPopup);
  };

  const bellIconClick = () => {
    setShowNotification(!showNotification);
  };

  const handleClickUserProfile = () => {
    setShowUserProfile(!showUserProfile);
  };

  const iconClick = () => {
    if (props.formEditing || get(props.modalPopup, 'isEdited', false)) {
      // If the selected item is Master Data QA, then we display the former message, else the latter.
      const messageId =
        props.notificationMenu === 'masterDataQA'
          ? 'Apply to Master Database before leaving the page'
          : 'Save the form before leaving the page';
      const messageDispatchProperties = { messageId, theme: 'alert-danger' };
      props.sendMessageDispatch(messageDispatchProperties);
      if (get(props.modalPopup, 'isEdited', false)) {
        props.modalPopupDispatch({
          showModal: true,
          isEdited: true,
        });
      }
    } else {
      props.history.push('/landing');
    }
  };
  return (
    <nav className="navbar navbar-light bg-light flex-nowrap unselectable">
      <div className="navbar-logo d-flex align-items-center">
        <img
          onClick={iconClick}
          role="presentation"
          id="navimg"
          className="img-fluid"
          src={IMAGES.LAMINAR_ENGIG_LOGO}
          draggable={false}
          alt="logo"
        />
        {/* <img id="navimg" src={IMAGES.LOGO}  alt="" />
        <span className="divider-block" />
        <span className="engig-logo-wrapper" role="presentation" onClick={iconClick}>
          <img className="engig-logo" src={IMAGES.ENGIG_LOGO} alt="engig logo" />
        </span> */}
      </div>

      {props.headerAlert.messageId && (
        <div
          className={`alert ${props.headerAlert.theme ?? 'alert-warning'}`}
          style={{
            marginBottom: 'inherit',
            padding: '0.25rem',
            maxHeight: '56px',
            overflow: 'hidden',
          }}
        >
          {(props.headerAlert.theme === 'alert-warning' || props.headerAlert.theme === 'alert-danger') && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 17 16"
              style={{ marginRight: '10px', verticalAlign: 'top' }}
            >
              <g fill="none" fillRule="evenodd">
                <g fill={`${props.headerAlert.theme === 'alert-warning' ? '#664d03' : '#842029'}`}>
                  <g>
                    <path
                      d="M7.02 1.58c.1-.176.242-.323.415-.424C7.607 1.054 7.802 1 8 1c.198 0 .394.054.566.156.172.101.314.248.413.424l6.855 11.93c.457.795-.091 1.806-.98 1.806H1.146c-.89 0-1.437-1.012-.98-1.807L7.02 1.58zM7 12.248c0-.27.106-.53.294-.723.187-.191.441-.299.707-.299.265 0 .519.108.706.3.188.191.293.451.293.722 0 .272-.105.532-.293.723-.187.192-.441.3-.706.3-.266 0-.52-.108-.707-.3C7.106 12.78 7 12.52 7 12.248zM8 5.091c-.127 0-.252.027-.367.08-.116.052-.22.129-.304.225-.085.096-.149.209-.188.331-.04.123-.054.253-.041.382l.35 3.585c.014.14.078.268.18.362.101.093.233.145.37.145.136 0 .268-.052.37-.145.101-.094.165-.223.18-.362l.35-3.585c.012-.129-.002-.259-.042-.382-.039-.122-.103-.235-.188-.331-.085-.096-.188-.173-.304-.225-.115-.053-.24-.08-.366-.08z"
                      transform="translate(-345 -277) translate(345.5 277)"
                    />
                  </g>
                </g>
              </g>
            </svg>
          )}
          {props.headerAlert.messageId}
        </div>
      )}

      <div className="header-menu">
        {props?.isLoggedIn && (
          <>
            <img
              src={ICONS.QUESTION}
              id="helpIcon"
              className="navbar-icons"
              onClick={handleClickHelpIcon}
              draggable={false}
              alt=""
            />
            <span className="iconContainerNavBar">
              <img src={ICONS.BELL} id="notificationIcon" onClick={bellIconClick} draggable={false} alt="" />
              {!noUnReadNotification && <img src={ICONS.ICON_RED_DOT} className="unReadDot" draggable={false} alt="" />}
            </span>
            <button type="button" id="userProfileIcon" className="user-profile" onClick={handleClickUserProfile}>
              <div className="user-initials">
                {`${getInitials(props.user.userFirstName)}${getInitials(props.user.userLastName)}`}
              </div>
            </button>
          </>
        )}
      </div>
      {showHelpPopup && <HelpPopup helpRef={helpRef} showHelpPopup={showHelpPopup} />}
      {showNotification && (
        <NotificationsPopup
          notificationRef={notificationRef}
          showNotification={showNotification}
          setNoUnReadNotification={setNoUnReadNotification}
          systemNotificationsPopupData={systemNotificationsPopupData}
          user={props.user}
          /* Allows us to access current project number user is on */
          projectNumber= {props.projectNumber}
        />
      )}
      {showUserProfile && <UserProfilePopup userProfileRef={userProfileRef} showUserProfile={showUserProfile} />}
    </nav>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: get(state, 'authReducer.isLoggedIn', ''),
  formEditing: get(state, 'commonData.formEditing', ''),
  modalPopup: get(state, 'commonData.modalPopup', {}),
  user: get(state, 'authReducer.loginResponse.user', ''),
  notificationMenu: state.commonData && state.commonData.notificationCount.menu,
  systemNotificationsPopupData: get(state, 'systemNotificationsReducer.systemNotificationsData', {}),
  headerAlert: get(state, 'commonData.headerAlert', {}),
  projectNumber: get(state, 'projectSetupReducer.projectDetails.projectNumber', ''),
  permissionGroups: get(state, 'projectSetupReducer.menuList.permissionGroups', []),
});

const mapDispatchToProps = (dispatch) => ({
  sendMessageDispatch: (data) => dispatch(commonActions.infoPopup(data)),
  modalPopupDispatch: (data) => dispatch(commonActions.modalPopup(data)),
  getSystemNotificationsData: (data) => dispatch(systemNotificationsActions.getSystemNotifications(data)),
  headerAlertDispatch: (data) => dispatch(commonActions.headerAlert(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectNavbar));
