import { get, cloneDeep } from 'lodash';
import { projectHomeActionTypes } from '../actions';

const InitialState = {};

export const projectHomeReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projectHomeActionTypes.GET_PROJECT_HOME_CONTENT:
      return { ...state };
    case projectHomeActionTypes.GET_PROJECT_HOME_CONTENT_SUCCESS: {
      let data = cloneDeep(action.payload);
      if (data.homeContent) {
        const subSections = get(data, 'homeContent.subSections', []).map((item, idx) => {
          if (idx > 5) {
            return { ...item, isLoading: true };
          }
          return { ...item };
        });
        data = { ...data, homeContent: { ...data.homeContent, subSections: [...subSections] } };
      }

      return { ...state, ...data };
    }
    case projectHomeActionTypes.GET_PROJECT_HOME_CONTENT_FAILURE:
      return { ...state, ...action.payload };
    case projectHomeActionTypes.GET_PROJECT_HOME_CONTENT_STEP: {
      const stepObj = action.data;
      const projectHomeSubSections = get(state, 'homeContent.subSections', []).map((obj) => {
        if (obj.subSectionKey === stepObj.subSectionKey) {
          return stepObj;
        }
        return obj;
      });
      return {
        ...state,
        homeContent: { ...state.homeContent, subSections: projectHomeSubSections },
      };
    }
    default:
      return state;
  }
};
