import { act } from '@testing-library/react';

export const actionTypes = {
  GET_P6_SYNC_FORM: 'GET_P6_SYNC_FORM',
  GET_P6_SYNC_FORM_SUCCESS: 'GET_P6_FORM_SYNC_SUCCESS',
  GET_P6_SYNC_FORM_FAILURE: 'GET_P6_FORM_SYNC_FAILURE',
  EXPORT_P6_SYNC_TABLE: 'EXPORT_P6_SYNC_TABLE',
  EXPORT_P6_SYNC_TABLE_SUCCESS: 'EXPORT_P6_SYNC_TABLE_SUCCESS',
  EXPORT_P6_SYNC_TABLE_FAILURE: 'EXPORT_P6_SYNC_TABLE_FAILURE',
  GET_EXCEL_EXPORT_EVENT_TABLE_DATA: 'GET_EXCEL_EXPORT_EVENT_TABLE_DATA',
  GET_EXCEL_EXPORT_EVENT_TABLE_DATA_SUCCESS: 'GET_EXCEL_EXPORT_EVENT_TABLE_DATA_SUCCESS',
  GET_EXCEL_EXPORT_EVENT_TABLE_DATA_FAILURE: 'GET_EXCEL_EXPORT_EVENT_TABLE_DATA_FAILURE',
  EXPORT_ARCHIVE_TABLE_TO_EXCEL: 'EXPORT_ARCHIVE_TABLE_TO_EXCEL',
  EXPORT_ARCHIVE_TABLE_TO_EXCEL_SUCCESS: 'EXPORT_ARCHIVE_TABLE_TO_EXCEL_SUCCESS',
  EXPORT_ARCHIVE_TABLE_TO_EXCEL_FAILURE: 'EXPORT_ARCHIVE_TABLE_TO_EXCEL_FAILURE',
  GET_P6_VERSIONS: 'GET_P6_VERSIONS',
  GET_P6_VERSIONS_SUCCESS: 'GET_P6_VERSIONS_SUCCESS',
  GET_P6_VERSIONS_FAILURE: 'GET_P6_VERSIONS_FAILURE',
  ADD_P6_SYNC_ITERATION: 'ADD_P6_SYNC_ITERATION',
  ADD_P6_SYNC_ITERATION_SUCCESS: 'ADD_P6_SYNC_ITERATION_SUCCESS',
  ADD_P6_SYNC_ITERATION_FAILURE: 'ADD_P6_SYNC_ITERATION_FAILURE',
  PUSH_P6_TO_LAMINAR: 'PUSH_P6_TO_LAMINAR',
  PUSH_P6_TO_LAMINAR_SUCCESS: 'PUSH_P6_TO_LAMINAR_SUCCESS',
  PUSH_P6_TO_LAMINAR_FAILURE: 'PUSH_P6_TO_LAMINAR_FAILURE',
};

export const actions = {
  getP6SyncForm: (data) => ({
    type: actionTypes.GET_P6_SYNC_FORM,
    data,
  }),
  getP6SyncFormSucceeded: (content) => ({
    type: actionTypes.GET_P6_SYNC_FORM_SUCCESS,
    payload: content,
  }),

  getP6SyncFormFailed: (content) => ({
    type: actionTypes.GET_P6_SYNC_FORM_FAILURE,
    payload: content,
  }),

  getExcelExportEventTableData: (data) => ({
    type: actionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA,
    data,
  }),
  getExcelExportEventTableDataSucceeded: (content) => ({
    type: actionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA_SUCCESS,
    payload: content,
  }),
  getExcelExportEventTableDataFailed: (content) => ({
    type: actionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA_FAILURE,
    payload: content,
  }),
  exportArchiveTableToExcel: (data) => ({
    type: actionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL,
    data,
  }),
  exportArchiveTableToExcelSucceeded: (content) => ({
    type: actionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL_SUCCESS,
    payload: content,
  }),
  exportArchiveTableToExcelFailed: (content) => ({
    type: actionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL_FAILURE,
    payload: content,
  }),
  exportP6synctable: (data) => ({
    type: actionTypes.EXPORT_P6_SYNC_TABLE,
    data,
  }),
  exportP6synctablesucceeded: (content) => ({
    type: actionTypes.EXPORT_P6_SYNC_TABLE_SUCCESS,
    payload: content,
  }),
  exportP6synctablefailed: (content) => ({
    type: actionTypes.EXPORT_P6_SYNC_TABLE_FAILURE,
    payload: content,
  }),
  getP6versions: (data) => ({
    type: actionTypes.GET_P6_VERSIONS,
    data,
  }),

  getP6versionssucceeded: (content) => ({
    type: actionTypes.GET_P6_VERSIONS_SUCCESS,
    payload: content,
  }),

  getP6versionsfailed: (content) => ({
    type: actionTypes.GET_P6_SYNC_FORM_FAILURE,
    payload: content,
  }),

  addP6SyncIteration: (data) => ({
    type: actionTypes.ADD_P6_SYNC_ITERATION,
    data,
  }),
  addP6SyncIterationSucceeded: (content) => ({
    type: actionTypes.ADD_P6_SYNC_ITERATION_SUCCESS,
    payload: content,
  }),

  addP6SyncIterationFailed: (content) => ({
    type: actionTypes.ADD_P6_SYNC_ITERATION_FAILURE,
    payload: content,
  }),

  pushP6ToLaminar: (data) => ({
    type: actionTypes.PUSH_P6_TO_LAMINAR,
    data,
  }),

  pushP6ToLaminarSucceeded: (content) => ({
    type: actionTypes.PUSH_P6_TO_LAMINAR_SUCCESS,
    payload: content,
  }),

  pushP6ToLaminarFailed: (content) => ({
    type: actionTypes.PUSH_P6_TO_LAMINAR_FAILURE,
    payload: content,
  }),
};
