import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loader from './assets/common/icon-loading.svg';
import InfoPopup from './components/common/infoPopup';
// code splitting on routes
const Login = lazy(() => import('./pages/login'));
const MyLanding = lazy(() => import('./pages/landing'));
const ProjectHome = lazy(() => import('./pages/projectHome'));
const WbsInformation = lazy(() => import('./pages/wbsInformation'));
const ReferenceLibrary = lazy(() => import('./pages/referenceLibrary'));
const ImportManhrs = lazy(() => import('./pages/importManhrs'));
const projectActivities = lazy(() => import('./pages/projectActivities'));
const inputOutput = lazy(() => import('./pages/inputOutput'));
const ProjectTopbar = lazy(() => import('./components/projectTopbar'));
// const InfoPopup = lazy(() => import('./components/common/infoPopup'));
const AssignProjectLeads = lazy(() => import('./pages/assignProjectLeads'));
const ProjectInformation = lazy(() => import('./pages/projectInformation'));
const WorkflowRules = lazy(() => import('./pages/workflowRules'));
const p6send = lazy(() => import('./pages/P6'));
const GigWorkflow = lazy(() => import('./pages/gigWorkflow'));
const NonLaminarOutput = lazy(() => import('./pages/nonLaminarOutput'));
const ViewApproveProjectPlan = lazy(() => import('./pages/viewApproveProjectPlan'));
const EnggReferenceLibrary = lazy(() => import('./pages/enggExeReferenceLibrary'));
const EnggInputOutput = lazy(() => import('./pages/enggInputOutput'));
const EnggChangeManagement = lazy(() => import('./pages/enggChangeManagement'));
const EnggCMChangeInstanceForm = lazy(() => import('./pages/enggCMChangeInstanceForm'));
const EnggMyProjectHome = lazy(() => import('./pages/enggMyProjectHome'));
const LaminarScope = lazy(() => import('./pages/laminarScope'));
const laminarSyncP6 = lazy(() => import('./pages/laminarSyncP6'));
const ProjectDashboard = lazy(() => import('./pages/projectDashboard'));
// const EnggExeProjectHome = lazy(() => import('./pages/EnggExeProjectHome'));
const NotificationsPage = lazy(() => import('./pages/notifications'));
const MasterDataQA = lazy(() => import('./pages/masterDataQA'));
const MasterTables = lazy(() => import('./pages/masterTables'));
const MasterGigDashboard = lazy(() => import('./pages/masterGigDashboard'));
const PreviewFragnetWorkflowPage = lazy(() => import('./pages/previewFragnetWorkflow'));
const PreviewGigWorkflowPage = lazy(() => import('./pages/previewGigWorkflow'));
const PreviewLogicTiesPage = lazy(() => import('./pages/previewLogicTies'));

const EnggLaminarScope = lazy(() => import('./pages/enggLaminarScope'));

// //dead code
// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) => {
//       localStorage.getItem('user') ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//       );
//     }}
//   />
// );

const Routes = (props) => {
  const { messageId, theme } = props;
  return (
    <Suspense
      fallback={
        <div className="global-loader">
          <img className="loader-img" src={Loader} alt="loader" />
        </div>
      }
    >
      {messageId && <InfoPopup theme={theme} />}
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/id/:secretKey" component={Login} />
        <Route path="/landing" component={MyLanding} />
        <Route exact path={['/projectSetup', '/engineeringExecution']} component={ProjectTopbar} />
        <Route path="/projectSetup/projectHome/:routeProjectNumber" component={ProjectHome} />
        <Route path="/projectSetup/projectInformation/:routeProjectNumber" component={ProjectInformation} />
        <Route path="/projectSetup/wbsInformation/:routeProjectNumber" component={WbsInformation} />
        <Route path="/projectSetup/manhourBudgets/:routeProjectNumber" component={ImportManhrs} />
        <Route path="/projectSetup/projectActivities/:routeProjectNumber" component={projectActivities} />
        <Route path="/projectSetup/inputOutput/:routeProjectNumber" component={inputOutput} />
        <Route path="/projectSetup/assignProjectLeads/:routeProjectNumber" component={AssignProjectLeads} />
        <Route path="/projectSetup/referenceLibrary/:routeProjectNumber" component={ReferenceLibrary} />
        <Route path="/projectSetup/workflowRules/:routeProjectNumber" component={WorkflowRules} />
        <Route path="/projectSetup/p6send/:routeProjectNumber" component={p6send} />
        <Route path="/projectSetup/gigworkflow/:routeProjectNumber" component={GigWorkflow} />
        <Route path="/projectSetup/nonLaminarOutput/:routeProjectNumber" component={NonLaminarOutput} />
        <Route path="/projectSetup/view-approve-project-plan/:routeProjectNumber" component={ViewApproveProjectPlan} />

        <Route path="/engineeringExecution/my-project-home/:routeProjectNumber" component={EnggMyProjectHome} />
        <Route path="/engineeringExecution/laminarScope/:routeProjectNumber" component={LaminarScope} />
        <Route path="/engineeringExecution/project-dashboard/:routeProjectNumber" component={ProjectDashboard} />
        {/* <Route path="/engineeringExecution/enggProjectHome" component={EnggExeProjectHome} /> */}
        <Route path="/engineeringExecution/reference-library/:routeProjectNumber" component={EnggReferenceLibrary} />
        <Route path="/engineeringExecution/p6-send-retrieve/:routeProjectNumber" component={laminarSyncP6} />
        <Route path="/engineeringExecution/enggInputOutput/:routeProjectNumber" component={EnggInputOutput} />
        <Route path="/engineeringExecution/change-management/:routeProjectNumber" component={EnggChangeManagement} />
        <Route
          path="/engineeringExecution/changeInstanceForm/:routeProjectNumber/:changeInstanceId"
          component={EnggCMChangeInstanceForm}
        />
        <Route
          path="/engineeringExecution/changeInstanceForm/:routeProjectNumber"
          component={EnggCMChangeInstanceForm}
        />
        <Route path="/notifications" component={NotificationsPage} />
        {/* Master Data Flow */}
        <Route path="/masterData/masterDataQA" component={MasterDataQA} />
        <Route
          path={[
            '/masterData/heirarchy',
            '/masterData/activities',
            '/masterData/fragnetSteps',
            '/masterData/gigs',
            '/masterData/references',
            '/masterData/inputOutputs',
          ]}
          component={MasterTables}
        />
        <Route path="/masterData/gigDashboard/:gigId" component={MasterGigDashboard} />
        <Route
          path="/masterData/previewFragnetWorkflow/:fragnetId/:fragnetStepId"
          component={PreviewFragnetWorkflowPage}
        />
        <Route path="/masterData/previewGigWorkflow/:fragnetStepId/:gigId" component={PreviewGigWorkflowPage} />
        <Route path="/masterData/previewLogicTies/:fragnetStepId" component={PreviewLogicTiesPage} />
        <Route path="/engineeringExecution/enggLaminarScope/:routeProjectNumber" component={EnggLaminarScope} />
        {/* auto redirect from invalid urls to landing page */}
        {/* <Route component={() => <Redirect to="/landing" />} /> */}
      </Switch>
    </Suspense>
  );
};

export default Routes;
