import { ioActionTypes } from '../actions';

const InitialState = {};

export const projectInputOutput = (state = InitialState, action) => {
  switch (action.type) {
    case ioActionTypes.GET_IO_FORM:
      return { ...state };
    case ioActionTypes.GET_IO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case ioActionTypes.GET_IO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case ioActionTypes.SAVE_IO_FORM:
      return { ...state, ...action.data };
    case ioActionTypes.SAVE_IO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case ioActionTypes.SAVE_IO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case ioActionTypes.GET_IO_CHANGE:
      return { ...state };
    case ioActionTypes.GET_IO_CHANGE_SUCCESS:
      return { ...state, ...action.payload };
    case ioActionTypes.GET_IO_CHANGE_FAILURE:
      return { ...state, ...action.payload };
    case ioActionTypes.CLEAR_IO_FORM:
      return { ...state, ioTableData: {} };
    case ioActionTypes.GET_IO_DROPDOWN:
      return { ...state };
    case ioActionTypes.GET_IO_DROPDOWN_SUCCESS:
      return { ...state, ...action.payload };
    case ioActionTypes.GET_IO_DROPDOWN_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
