import { wbsActionTypes } from '../actions';

const InitialState = {};

export const wbsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case wbsActionTypes.GET_WBS_INFO_FORM:
      return { ...state };
    case wbsActionTypes.GET_WBS_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case wbsActionTypes.GET_WBS_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case wbsActionTypes.SAVE_WBS_INFO_FORM:
      return { ...state, ...action.data };
    case wbsActionTypes.SAVE_WBS_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case wbsActionTypes.SAVE_WBS_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
