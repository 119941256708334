const CryptoJS = require('crypto-js');
const { LOCAL_STORAGE_KEY } = require('../src/constants/common');

/**
 * @param   {string} pass the string pass to be encrypted or decrypted
 *
 * @param   {string} action the action (ENCRYPT || DECRYPT)
 * @returns {string}
 */
const getAESAction = (pass, action) => {
  const key = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.ENCRYPTION_KEY));
  if (pass && key) {
    if (action === 'E') {
      return CryptoJS.AES.encrypt(pass, key).toString();
    }

    return CryptoJS.AES.decrypt(pass, key).toString(CryptoJS.enc.Utf8);
  }
  return '';
};

module.exports = { getAESAction };
