export const actionTypes = {
  GET_SYSTEM_NOTIFICATIONS: 'GET_SYSTEM_NOTIFICATIONS',
  GET_SYSTEM_NOTIFICATIONS_SUCCESS: 'GET_SYSTEM_NOTIFICATIONS_SUCCESS',
  GET_SYSTEM_NOTIFICATIONS_FAILURE: 'GET_SYSTEM_NOTIFICATIONS_FAILURE',
  GET_SYSTEM_NOTIFICATIONS_ON_LANDING_PAGE: 'GET_SYSTEM_NOTIFICATIONS_ON_LANDING_PAGE',
  GET_PROJECT_FOR_NOTIFICATION: 'GET_PROJECT_FOR_NOTIFICATION',
  GET_MORE_PROJECTS_FOR_NOTIFICATION: 'GET_MORE_PROJECTS_FOR_NOTIFICATION',
  GET_MORE_PROJECT_FOR_NOTIFICATION_SUCCESS: 'GET_MORE_PROJECT_FOR_NOTIFICATION_SUCCESS',

  SAVE_SYSTEM_NOTIFICATIONS: 'SAVE_SYSTEM_NOTIFICATIONS',
  SAVE_SYSTEM_NOTIFICATIONS_SUCCESS: 'SAVE_SYSTEM_NOTIFICATIONS_SUCCESS',
  SAVE_SYSTEM_NOTIFICATIONS_FAILURE: 'SAVE_SYSTEM_NOTIFICATIONS_FAILURE',
};

export const actions = {
  getSystemNotifications: (data) => ({
    type: actionTypes.GET_SYSTEM_NOTIFICATIONS,
    data,
  }),
  getSystemNotificationLandingPage: (data) => ({
    type: actionTypes.GET_SYSTEM_NOTIFICATIONS_ON_LANDING_PAGE,
    data,
  }),
  getSystemNotificationsSucceeded: (content) => ({
    type: actionTypes.GET_SYSTEM_NOTIFICATIONS_SUCCESS,
    payload: content,
  }),
  getSystemNotificationsFailed: (content) => ({
    type: actionTypes.GET_SYSTEM_NOTIFICATIONS_FAILURE,
    payload: content,
  }),
  getProjectsRelatedToNotification: (data) => ({
    type: actionTypes.GET_PROJECT_FOR_NOTIFICATION,
    payload: data,
  }),
  getMoreProjectForNotification: (data) => ({
    type: actionTypes.GET_MORE_PROJECTS_FOR_NOTIFICATION,
    payload: data,
  }),
  getMoreProjectNotificationSucceeded: (data) => ({
    type: actionTypes.GET_MORE_PROJECT_FOR_NOTIFICATION_SUCCESS,
    payload: data,
  }),

  saveSystemNotifications: (data) => ({
    type: actionTypes.SAVE_SYSTEM_NOTIFICATIONS,
    data,
  }),
  saveSystemNotificationsSucceeded: (content) => ({
    type: actionTypes.SAVE_SYSTEM_NOTIFICATIONS_SUCCESS,
    payload: content,
  }),
  saveSystemNotificationsFailed: (content) => ({
    type: actionTypes.SAVE_SYSTEM_NOTIFICATIONS_FAILURE,
    payload: content,
  }),
};
