import { get } from 'lodash';
import { put, takeEvery, call } from 'redux-saga/effects';
import servicelist, {
  exportArchiveTableToExcel,
  getExportArchiveTableEvents,
  excelExport,
} from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';
import { commonActions, p6SyncActions, p6SyncActionTypes } from '../actions';


export function* getP6SyncFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getP6SyncTable'), {
      params: payload.data,
    });
    yield put(
      p6SyncActions.getP6SyncFormSucceeded({
        isSuccceeded: true,
        p6SyncData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      p6SyncActions.getP6SyncFormFailed({
        isSuccceeded: false,
        p6SyncData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getP6SyncFormStatus() {
  yield takeEvery([p6SyncActionTypes.GET_P6_SYNC_FORM], getP6SyncFormSaga);
}

export function* getP6VersionsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getP6Versions'), {
      params: payload.data,
    });
    yield put(
      p6SyncActions.getP6versionssucceeded({
        isSuccceeded: true,
        p6Versions: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      p6SyncActions.getP6versionsfailed({
        isSuccceeded: false,
        p6Versions: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getP6VersionsStatus() {
  yield takeEvery([p6SyncActionTypes.GET_P6_VERSIONS], getP6VersionsSaga);
}

export function* exportP6SyncTableSaga(payload) {
  // incase we need the error data from the api
  try {
    yield put(commonActions.showLoader(true));
    const paramObj = get(payload, 'data.params', {});
    // This action has certain parameters 
    const responseData = yield call(AxiosInstance.get, excelExport(paramObj.projectNumber), {
        params: paramObj,
        responseType: 'blob',
      });
    
      if(paramObj.scheduleEventType === 'Project Schedule Manual Export') {
        // Only in the case the the user executes this action is the excel file written to the browser
        const url = window.URL.createObjectURL(new Blob([responseData.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${paramObj.fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();

        // calling the iteration list again
        yield put(
          p6SyncActions.getP6versions({
            projectNumber: get(paramObj, 'projectNumber', ''),
            menuId: get(paramObj, 'menuId', ''),
          })
        );
      }
      yield put(
      p6SyncActions.exportP6synctablesucceeded({
        isSucceeded: true,
        data: responseData.data,
      })
    );
      yield put(commonActions.showLoader(false));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? response.errorMsg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(p6SyncActions.exportP6synctablefailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* exportP6SyncTableStatus() {
  yield takeEvery([p6SyncActionTypes.EXPORT_P6_SYNC_TABLE], exportP6SyncTableSaga);
}

export function* getExcelExportEventTableDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload.data;
    const responseData = yield call(AxiosInstance.get, getExportArchiveTableEvents(projectNumber));
      yield put(
      p6SyncActions.getExcelExportEventTableDataSucceeded({
        isSucceeded: true,
        excelArchiveEventTable: responseData?.data?.data,
      })
    );
      yield put(commonActions.showLoader(false));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? response.errorMsg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(p6SyncActions.getExcelExportEventTableDataFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getExcelExportEventTableDataStatus() {
  yield takeEvery([p6SyncActionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA], getExcelExportEventTableDataSaga);
}
export function* exportArchiveTableToExcelSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload.data;
    const responseData = yield call(AxiosInstance.get, exportArchiveTableToExcel(projectNumber), {
      params: payload.data,
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([responseData.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${payload.data.fileName}.zip`);
    document.body.appendChild(link);
    link.click();
yield put(
      p6SyncActions.exportArchiveTableToExcelSucceeded({
        isSucceeded: true,
        excelArchiveEventTableZip: responseData?.data?.data,
      })
    );
      yield put(commonActions.showLoader(false));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? response.errorMsg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(p6SyncActions.exportArchiveTableToExcelFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* exportArchiveTableToExcelStatus() {
  yield takeEvery([p6SyncActionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL], exportArchiveTableToExcelSaga);
}


export function* addP6SyncIterationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('getP6SyncTable'), payload.data);
    yield put(
      p6SyncActions.addP6SyncIterationSucceeded({
        isSuccceeded: true,
        p6Versions: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(p6SyncActions.addP6SyncIterationFailed({ isSuccceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* addP6SyncIterationStatus() {
  yield takeEvery([p6SyncActionTypes.ADD_P6_SYNC_ITERATION], addP6SyncIterationSaga);
}

export function* pushP6ToLaminarSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.put, servicelist('pushp6Updates'), payload.data);
    yield put(
      p6SyncActions.pushP6ToLaminarSucceeded({
        isSuccceeded: true,
        p6Versions: responseData.data,
      })
    );
    yield put(
      p6SyncActions.getP6versions({
        projectNumber: get(payload, 'data.projectNumber', ''),
        menuId: get(payload, 'data.menuId', ''),
        fileType: get(payload, 'data.fileType', ''),
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: e?.response?.data?.message,
        theme: 'alert-warning',
      })
    );
    yield put(
      p6SyncActions.pushP6ToLaminarFailed({
        isSuccceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* pushP6ToLaminarStatus() {
  yield takeEvery([p6SyncActionTypes.PUSH_P6_TO_LAMINAR], pushP6ToLaminarSaga);
}
