import React from 'react';

import './toolTip.css';

function ToolTip(props) {
  const { children, hover } = { ...props };

  return (
    <div className="tooltipContent">
      {children}

      {hover && <span className={`tooltipText ${props.theme ? props.theme : ''}`}>{hover}</span>}
    </div>
  );
}

export default ToolTip;
