import { viewApproveProjectActionTypes } from '../actions';

const InitialState = {};

export const viewApproveProjectReducer = (state = InitialState, action) => {
  switch (action.type) {
    case viewApproveProjectActionTypes.GET_PLANS:
      return { ...state };
    case viewApproveProjectActionTypes.GET_PLANS_SUCCESS:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.GET_PLANS_FAILURE:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.GET_PROJECT_PLAN_DATA:
      return { ...state, projectPlan: action.payload };
    case viewApproveProjectActionTypes.GET_PROJECT_PLAN_DATA_ALL:
      return { ...state, projectPlanAll: action.payload };
    case viewApproveProjectActionTypes.GET_FILTER_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.GET_FILTER_DATA_FAILURE:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.SAVE_DISCIPLINE_DATA:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.SAVE_DISCIPLINE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.SAVE_DISCIPLINE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case viewApproveProjectActionTypes.GET_PLAN_COMPLETE_REFERENCE_SUCCESS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
