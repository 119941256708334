import { put, takeEvery, call } from 'redux-saga/effects';
import AxiosInstance from '../../services/commons/Interceptor';
// import servicelist from '../../services/serviceList';
import { getWbsInformation, saveWbsInformation, updateWbsInformation } from '../../services/serviceList';
import { commonActions, wbsActions, wbsActionTypes } from '../actions';

export function* getWbsInfoFormSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, getWbsInformation('projectSetup', payload.data.projectNumber), {
      params: { submenuId: payload.data.submenuId },
    });
    yield put(
      wbsActions.getWbsInfoFormSucceeded({
        isSucceeded: true,
        wbsData: responseData.data.data,
        toolTipData: responseData.data.toolTipData,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(wbsActions.getWbsInfoFormFailed({ isSucceeded: false, wbsData: null }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getWbsInfoFormStatus() {
  yield takeEvery([wbsActionTypes.GET_WBS_INFO_FORM], getWbsInfoFormSaga);
}

export function* saveWbsInfoFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.data.body ? payload.data.body : {};
    const responseData = payload.data.id
      ? yield call(
          AxiosInstance.put,
          updateWbsInformation('projectSetup', payload.data.body.projectNumber, payload.data.id),
          {
            ...payloadData,
            id: payload.data.id,
          }
        )
      : yield call(
          AxiosInstance.post,
          saveWbsInformation('projectSetup', payload.data.body.projectNumber),
          payloadData
        );
    yield put(
      wbsActions.saveWbsInfoFormSucceeded({
        isSucceeded: true,
        savedForm: responseData.data.data,
      })
    );
    // after a succes save call a get call is done.
    yield put(
      wbsActions.getWbsInfoForm({
        projectNumber: responseData.data.data.projectNumber,
        submenuId: responseData.data.data.submenuId,
      })
    );

    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;

    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    yield put(wbsActions.saveWbsInfoFormFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* saveWbsInfoFormStatus() {
  yield takeEvery([wbsActionTypes.SAVE_WBS_INFO_FORM], saveWbsInfoFormSaga);
}
