import { put, takeEvery, call, select } from 'redux-saga/effects';
import { cloneDeep, omit, get, isEmpty, pick } from 'lodash';
import servicelist, {
  getGigWorkFlowsDropdown,
  getGigWorkFlowsActivityInformationForm,
  getGigWorkFlowsLandingPage,
  getGigWorkFlowsOverView,
  getGigWorkFlowsCustomizeFragnet,
  saveGigWorkFlowsActivityInformationForm,
  updateGigWorkFlowsActivityInformationForm,
  getGigWorkFlowsFragnetSteps,
  saveGigWorkFlowsCustomizeFragnet,
  updateGigWorkFlowsCustomizeFragnet,
  getGigWorkFlowsCustomizedGigSteps,
  getGigWorkFlowsCustomizeGigTypes,
  saveGigWorkFlowsCustomizedGignetInfo,
  getGigWorkFlowsFragnetStepsInformation,
  updateGigWorkFlowsFragnetStepsInformation,
  getGigWorkFlowsGignetStepInformation,
  updateGigWorkFlowsGignetStepsInformation,
  getGigWorkFlowsGigTypeForms,
  completeGigWorkFlowsProjectForms,
  saveGigWorkFlowsGigInformation,
  updateGigWorkFlowsGigInformation,
  getGigWorkFlowsGigInfoFragnetSteps,
  updateGigWorkFlowsGigInfoMenuStatus,
  autoCompleteGigs,
  finishGigWorkflow,
} from '../../services/serviceList';
import { gigWorkflowActions, gigWorkflowActionTypes, commonActions, projectSetupActions } from '../actions';
import AxiosInstance from '../../services/commons/Interceptor';

// Gig workflows - Get DoS Activities
export function* getDropdownDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, discipline } = payload.data;
    if (discipline !== '') {
      const responseData = yield call(AxiosInstance.get, getGigWorkFlowsDropdown('projectSetup', projectNumber), {
        params: { discipline },
      });
      yield put(
        gigWorkflowActions.getDropdownDataSucceeded({
          isSucceeded: true,
          landingDropdownData: responseData?.data?.data,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.getDropdownDataFailed({ isSucceeded: false, landingDropdownData: null });
  }
  yield put(commonActions.showLoader(false));
}

export function* getDropdownDataStatus() {
  yield takeEvery(gigWorkflowActionTypes.GET_DROPDOWN_DATA, getDropdownDataSaga);
}

// Step 3
// Gig Workflows - FragnetStep Information - Get fragnet steps information
export function* getFragnetStepInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, submenuId, discipline, activityId, refrenceKey } = payload?.data;

    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsFragnetStepsInformation('projectSetup', projectNumber),
      {
        params: { submenuId, discipline, activityId, refrenceKey },
      }
    );
    yield put(
      gigWorkflowActions.getFragnetStepInfoSuccess({
        isSucceeded: true,
        fragnetStepInfo: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getFragnetStepInfoFailure({
        isSucceeded: false,
        fragnetStepInfo: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchFragnetStepInfoStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_FRAGNET_STEP_INFO], getFragnetStepInfoSaga);
}
// Gig workflows - Get Gig Work Flows Overview
export function* getSubActivityListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, discipline, refrenceKey, menuId } = payload.data;
    const responseData = yield call(AxiosInstance.get, getGigWorkFlowsOverView('projectSetup', projectNumber), {
      params: {
        discipline,
        refrenceKey,
        menuId,
      },
    });
    yield put(
      gigWorkflowActions.getSubActivityListSucceeded({
        isSucceeded: true,
        subActivityList: cloneDeep(responseData?.data?.data),
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.getSubActivityListFailed({ isSucceeded: false, subActivityList: null });
  }
  yield put(commonActions.showLoader(false));
}

export function* getSubActivityListStatus() {
  yield takeEvery(gigWorkflowActionTypes.GET_SUB_ACTIVITY_LIST, getSubActivityListSaga);
}

// Step 2
// Gig workflows - Get Customize Fragnet
export function* getCustomizeFragnetDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, discipline, refrenceKey, activityId, submenuId } = payload.data;
    const responseData = yield call(AxiosInstance.get, getGigWorkFlowsCustomizeFragnet('projectSetup', projectNumber), {
      params: {
        discipline,
        refrenceKey,
        submenuId,
        activityId,
      },
    });
    if (responseData?.data?.statusCode === 500) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Unable to get data',
          theme: 'alert-danger',
        })
      );
    } else {
      yield put(
        gigWorkflowActions.getCustomizeFragnetDataSucceeded({
          isSucceeded: true,
          customizeFragnetData: responseData?.data?.data,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.getCustomizeFragnetDataFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getCustomizeFragnetDataStatus() {
  yield takeEvery(gigWorkflowActionTypes.GET_CUSTOMIZE_FRAGNET_DATA, getCustomizeFragnetDataSaga);
}
// Gig workflows - Customize Fragnet Get Fragnet Steps - API that calls when clicking Apply
export function* fetchFragnetStepsStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload.data;
    const omitList = ['projectNumber'];
    const getPayload = cloneDeep(omit(payload.data, omitList));
    const responseData = yield call(AxiosInstance.get, getGigWorkFlowsFragnetSteps('projectSetup', projectNumber), {
      params: getPayload,
    });

    if (responseData?.data?.statusCode === 500) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Unable to fetch Fragnet Steps',
          theme: 'alert-danger',
        })
      );
    } else {
      if (responseData?.data?.data?.fragnetSteps && responseData?.data?.data?.fragnetSteps.length === 0) {
        yield put(
          commonActions.infoPopup({
            messageId: 'No Fragnet Steps Available',
            theme: 'alert-danger',
          })
        );
      }

      yield put(
        gigWorkflowActions.fetchFragnetStepsSucceeded({
          isSucceeded: true,
          newFragnetSteps: responseData?.data?.data,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.fetchFragnetStepsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* fetchFragnetStepsStatus() {
  yield takeEvery(gigWorkflowActionTypes.FETCH_FRAGNET_STEPS, fetchFragnetStepsStatusSaga);
}

// Gig Workflows - FragnetStep Information - Save, Update fragnet Steps Information
export function* saveFragnetStepInfoFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, id } = payload?.data?.fragmentStepInfo[0];
    const responseData = id
      ? yield call(
          AxiosInstance.put,
          updateGigWorkFlowsFragnetStepsInformation('projectSetup', projectNumber, id),
          payload?.data?.fragmentStepInfo[0]
        )
      : yield call(
          AxiosInstance.post,
          getGigWorkFlowsFragnetStepsInformation('projectSetup', projectNumber),
          payload?.data?.fragmentStepInfo[0]
        );
    if (responseData?.data?.statusCode === 500) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Unable to save data',
          theme: 'alert-danger',
        })
      );
    } else {
      yield put(
        commonActions.infoPopup({
          messageId: 'Successfully save FragnetStep Information Data',
        })
      );
    }

    yield put(
      gigWorkflowActions.saveFragnetStepInfoSuccess({
        isSucceeded: true,
        saveFragnetStepInfo: responseData?.data?.data,
      })
    );
    // getFragnetStepInfo to fetch the details
    const getPayload = pick(payload?.data?.fragmentStepInfo[0], [
      'activityId',
      'discipline',
      'projectNumber',
      'refrenceKey',
      'submenuId',
    ]);
    yield put(gigWorkflowActions.getFragnetStepInfo(getPayload));
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;

    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    yield put(
      gigWorkflowActions.saveFragnetStepInfoFailure({
        isSucceeded: true,
        saveFragnetStepInfo: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchSaveFragnetStepInfoFromSaga() {
  yield takeEvery([gigWorkflowActionTypes.SAVE_FRAGNET_STEP_INFO], saveFragnetStepInfoFromSaga);
}
// Gig workflows - Save, Update Customize Fragnet
export function* saveCustomizeFragnetDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, id } = payload?.data;
    let responseData;
    if (id) {
      responseData = yield call(
        AxiosInstance.put,
        updateGigWorkFlowsCustomizeFragnet('projectSetup', projectNumber, payload?.data?.id),
        payload?.data
      );
    } else {
      delete payload?.data?.id;
      responseData = yield call(
        AxiosInstance.post,
        saveGigWorkFlowsCustomizeFragnet('projectSetup', projectNumber),
        payload?.data
      );
    }

    if (responseData?.data?.statusCode === 500) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Unable to save data',
          theme: 'alert-danger',
        })
      );
    } else {
      yield put(
        commonActions.infoPopup({
          messageId: 'Successfully save Customize Fragnet Data',
        })
      );
      // removing assigning of save response data to customizeFragnetData since Get customize api is calling after this..
      yield put(
        gigWorkflowActions.saveCustomizeFragnetDataSucceeded({
          isSucceeded: true,
          // customizeFragnetData: responseData?.data?.data,
        })
      );

      const getPayload = pick(payload?.data, ['activityId', 'discipline', 'projectNumber', 'refrenceKey', 'submenuId']);
      yield put(gigWorkflowActions.getCustomizeFragnetData(getPayload));
    }
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;

    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    gigWorkflowActions.saveCustomizeFragnetDataFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveCustomizeFragnetDataStatus() {
  yield takeEvery(gigWorkflowActionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA, saveCustomizeFragnetDataSaga);
}

// Step 1
// Gig workflows - Get Activity information form
export function* getActivityInfoFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, discipline, refrenceKey, activityId, submenuId } = payload.data;
    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsActivityInformationForm('projectSetup', projectNumber),
      {
        params: {
          submenuId,
          discipline,
          refrenceKey,
          activityId,
        },
      }
    );
    yield put(
      gigWorkflowActions.getActivityInfoFormSucceeded({
        isSucceeded: true,
        activityInformationData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getActivityInfoFormFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getActivityInfoFormStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_ACTIVITY_INFO_FORM], getActivityInfoFormSaga);
}
// Gig workflows - Save & Update Activity Information
export function* saveActivityInfoFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { id } = payload?.data;
    const { projectNumber, discipline, refrenceKey, activityId, isCompleted, menuId, submenuId } = payload?.data?.body;
    const bodyPayloadData = payload?.data?.body ? payload.data.body : {};
    const responseData = payload?.data?.id
      ? yield call(
          AxiosInstance.put,
          updateGigWorkFlowsActivityInformationForm('projectSetup', projectNumber, payload.data.id),
          { ...bodyPayloadData, id }
        )
      : yield call(
          AxiosInstance.post,
          saveGigWorkFlowsActivityInformationForm('projectSetup', projectNumber),
          bodyPayloadData
        );
    if (responseData?.data?.statusCode === 500) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Unable to save data',
          theme: 'alert-danger',
        })
      );
    } else {
      yield put(
        commonActions.infoPopup({
          messageId: 'Successfully save Activity Information Data',
        })
      );
      // after the save/update call get activity information form api if it's not a complete action.
      if (!isCompleted)
        yield call(getActivityInfoFormSaga, {
          data: {
            projectNumber,
            discipline,
            refrenceKey,
            activityId,
            menuId,
            submenuId,
            id,
          },
        });
    }
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;
    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    gigWorkflowActions.saveActivityInfoFormFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveActivityInfoFormStatus() {
  yield takeEvery(gigWorkflowActionTypes.SAVE_ACTIVITY_INFO_FORM, saveActivityInfoFormSaga);
}

// Landing Page
// Gig workflows - Save gig LandingPage
export function* saveLandingPageDataSaga(payload) {
  try {
    const isFinish = get(payload, 'data.isFinish', false);
    delete payload.data.isFinish;
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.post,
      getGigWorkFlowsLandingPage('projectSetup', payload?.data?.projectNumber),
      payload?.data
    );

    yield put(
      gigWorkflowActions.saveLandingPageDataSuccess({
        isSucceeded: true,
        landingPageData: cloneDeep(responseData?.data?.data),
      })
    );
    yield put(
      gigWorkflowActions.getDropdownData({
        projectNumber: get(payload, 'data.projectNumber', ''),
        discipline: get(payload, 'data.discipline', ''),
      })
    );
    if (isFinish) {
      yield put(
        projectSetupActions.getProjectMenu({
          projectNumber: get(payload, 'data.projectNumber', ''),
          stateKey: 'setUpInProgress',
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.saveLandingPageDataFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveLandingPageDataStatus() {
  yield takeEvery(gigWorkflowActionTypes.SAVE_LANDING_PAGE_DATA, saveLandingPageDataSaga);
}
// Gig workflows - Get Landing Page Information
export function* getLandingPageDataSaga(payload) {
  try {
    const submenuId = get(payload, 'data.submenuId', '');
    const activityId = get(payload, 'data.activityId', '');
    const refrenceKey = get(payload, 'data.refrenceKey', '');
    const currentLandingData = get(payload, 'data.clonedLandingData', {});
    const isFinish = get(payload, 'data.isFinish', false);

    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsLandingPage('projectSetup', payload.data.projectNumber),
      { params: { discipline: payload?.data?.discipline, menuId: payload?.data?.menuId } }
    );
    if (Object.keys(responseData?.data?.data).length === 0) {
      yield put(
        gigWorkflowActions.getLandingPageDataSuccess({
          isSucceeded: true,
          landingPageData: null,
        })
      );
    } else {
      yield put(
        gigWorkflowActions.getLandingPageDataSuccess({
          isSucceeded: true,
          landingPageData: cloneDeep(responseData?.data?.data),
        })
      );
    }
    if (submenuId && !isEmpty(currentLandingData)) {
      const clonedLandingData = responseData.data.data;
      clonedLandingData[refrenceKey][activityId] = currentLandingData[refrenceKey][activityId];
      delete clonedLandingData.cutomizeStepId;
      clonedLandingData.isComplete = false;
      clonedLandingData.isFinish = isFinish;
      yield put(gigWorkflowActions.saveLandingPageData(clonedLandingData));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.getLandingPageDataFailure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getLandingPageDataStatus() {
  yield takeEvery(gigWorkflowActionTypes.GET_LANDING_PAGE_DATA, getLandingPageDataSaga);
}

// Step 4
// Gig workflows - Get Customize gig steps
export function* getCustomizedGigStepsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;
    const omitList = ['projectNumber', 'dosActivity'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));
    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsCustomizedGigSteps('projectSetup', projectNumber),
      {
        params: getPayload,
      }
    );
    yield put(
      gigWorkflowActions.getCustomizedGigStepsSucceeded({
        isSucceeded: true,
        customizedGigStepsData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getCustomizedGigStepsFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getCustomizedGigStepsStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_CUSTOMIZED_GIG_STEPS], getCustomizedGigStepsSaga);
}

// Gig workflows - Get Customize gig types
export function* getCustomizeGignetFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;
    const omitList = ['projectNumber', 'dosActivity'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));

    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsCustomizeGigTypes('projectSetup', projectNumber),
      {
        params: getPayload,
      }
    );
    yield put(
      gigWorkflowActions.getCustomizeGignetFormSucceeded({
        isSucceeded: true,
        gigInformationFormData: responseData?.data?.data,
      })
    );

    yield put(commonActions.showLoader(false));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getCustomizeGignetFormFailed({
        isSucceeded: false,
      })
    );
    yield put(commonActions.showLoader(false));
  }
}

export function* getCustomizeGignetFormStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_CUSTOMIZE_GIGNET_FORM], getCustomizeGignetFormSaga);
}

// Gig workflows - Save Customized gignet
export function* saveCustomizeGignetFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;

    const responseData = yield call(
      AxiosInstance.post,
      saveGigWorkFlowsCustomizedGignetInfo('projectSetup', projectNumber),
      payload?.data
    );

    yield put(
      gigWorkflowActions.saveCustomizeGignetFormSucceeded({
        isSucceeded: true,
        savedCustomizeGignetForm: responseData?.data?.data,
      })
    );
    delete payload?.data?.fields;

    yield put(gigWorkflowActions.getCustomizeGignetForm(payload?.data));

    if (payload?.status === 'save') {
      yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    } else {
      if (payload?.data?.isComplete) {
        yield put(commonActions.infoPopup({ messageId: 'Your form has been completed' }));
      } else {
        yield put(commonActions.infoPopup({ messageId: 'You can edit your form.' }));
      }
      yield put(gigWorkflowActions.getCustomizedGigSteps(payload?.data));
    }
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;
    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    yield put(
      gigWorkflowActions.saveCustomizeGignetFormFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveCustomizeGignetFormStatus() {
  yield takeEvery([gigWorkflowActionTypes.SAVE_CUSTOMIZE_GIGNET_FORM], saveCustomizeGignetFormSaga);
}

// Step 5 Gig workflows - Get Fragnet Steps
export function* getFragnetStepsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;

    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsGigInfoFragnetSteps('projectSetup', projectNumber),
      { params: payload?.data }
    );

    yield put(
      gigWorkflowActions.getFragnetStepsSucceeded({
        isSucceeded: true,
        fragnetStepsData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getFragnetStepsFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getFragnetStepsStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_FRAGNET_STEPS], getFragnetStepsSaga);
}

// step 5 Gig workflows - update Gig Information menu status
export function* updateGigInformationMenuStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, activityId } = payload?.data;
    const omitList = ['fragnetStepId', 'dosActivity', 'isFragnetStep', 'gigID'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));

    const responseData = yield call(
      AxiosInstance.put,
      updateGigWorkFlowsGigInfoMenuStatus('projectSetup', projectNumber, activityId),
      getPayload
    );
    yield put(
      gigWorkflowActions.updateGigInfoMenuStatusSucceeded({
        isSucceeded: true,
      })
    );
    // call Gig workflows - Get Fragnet Steps API to show the information
    yield* getFragnetStepsSaga({ data: getPayload });
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.updateGigInfoMenuStatusFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* updateGigInformationMenuStatus() {
  yield takeEvery([gigWorkflowActionTypes.UPDATE_GIGINFO_MENU_STATUS], updateGigInformationMenuStatusSaga);
}

// Gig workflows - Get Gignet Step information
export function* getGignetStepInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;
    const omitList = ['projectNumber', 'dosActivity'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));

    const responseData = yield call(
      AxiosInstance.get,
      getGigWorkFlowsGignetStepInformation('projectSetup', projectNumber),
      {
        params: getPayload,
      }
    );
    yield put(
      gigWorkflowActions.getGignetStepInfoSucceeded({
        isSucceeded: true,
        gignetStepInfoData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getGignetStepInfoFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getGignetStepInfoStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_GIGNET_STEP_INFO], getGignetStepInfoSaga);
}

// Gig workflows - Save & Update Gignet Steps information
export function* saveUpdateGignetStepInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, id } = payload?.data;
    const responseData = id
      ? yield call(
          AxiosInstance.put,
          updateGigWorkFlowsGignetStepsInformation('projectSetup', projectNumber, id),
          payload?.data
        )
      : yield call(
          AxiosInstance.post,
          getGigWorkFlowsGignetStepInformation('projectSetup', projectNumber),
          payload?.data
        );
    yield put(
      gigWorkflowActions.saveGignetStepInfoSucceeded({
        isSucceeded: true,
      })
    );
    delete payload?.data?.steps;

    payload.data.dontDisableLoader = true;
    const omitList = [
      'isComplete',
      'totalAvailableBudget',
      'totalAvailableDuration',
      'totalBudget',
      'totalDuration',
      'dontDisableLoader',
      'id',
    ];
    const getPayload = cloneDeep(omit(payload?.data, omitList));
    yield* getGignetStepInfoSaga({ data: getPayload });
    if (!payload?.refreshMenu) {
      yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    } else {
      if (payload?.data?.isComplete) {
        yield put(commonActions.infoPopup({ messageId: 'Your form has been completed' }));
      } else {
        yield put(commonActions.infoPopup({ messageId: 'You can edit your form.' }));
      }

      // call Gig workflows - update Gig Information menu status to update MenuItem Data for All steps
      yield* updateGigInformationMenuStatusSaga({ data: getPayload });
    }
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : e.response.data.message;
    }
    const infoPopupMessage = errorMessage;

    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));

    yield put(
      gigWorkflowActions.saveGignetStepInfoFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveGignetStepInfoStatus() {
  yield takeEvery([gigWorkflowActionTypes.SAVE_GIGNET_STEP_INFO], saveUpdateGignetStepInfoSaga);
}

export function* autoCompleteGigsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.post,
      autoCompleteGigs('projectSetup', payload.data.projectNumber),
      payload?.data
    );

    yield put(
      gigWorkflowActions.autoCompleteGigsSucceeded({
        isSucceeded: true,
      })
    );
    delete payload?.data?.steps;

    payload.data.dontDisableLoader = true;
    const omitList = [
      'isComplete',
      'totalAvailableBudget',
      'totalAvailableDuration',
      'totalBudget',
      'totalDuration',
      'dontDisableLoader',
    ];
    const getPayload = cloneDeep(omit(payload?.data, omitList));
    yield* getGignetStepInfoSaga({ data: getPayload });

    yield put(commonActions.infoPopup({ messageId: 'You auto completed all related gigs.' }));

    // call Gig workflows - update Gig Information menu status to update MenuItem Data for All steps
    yield* updateGigInformationMenuStatusSaga({ data: getPayload });
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.autoCompleteGigsFailure({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* autoCompleteGigsStatus() {
  yield takeEvery([gigWorkflowActionTypes.AUTO_COMPLETE_GIGS], autoCompleteGigsSaga);
}
// Gig workflows - Get Gig Type Form
export function* getGigInfoFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber } = payload?.data;
    const omitList = ['projectNumber', 'dosActivity'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));

    const responseData = yield call(AxiosInstance.get, getGigWorkFlowsGigTypeForms('projectSetup', projectNumber), {
      params: getPayload,
    });

    yield put(
      gigWorkflowActions.getGigInfoFormSucceeded({
        isSucceeded: true,
        gigInformationFormData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.getGigInfoFormFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getGigInfoFormStatus() {
  yield takeEvery([gigWorkflowActionTypes.GET_GIG_INFO_FORM], getGigInfoFormSaga);
}

// Gig workflows - Save & Update Gig Information
export function* saveGigInfoFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { id } = payload?.data;
    const { projectNumber } = payload?.data?.request;
    const responseData = id
      ? yield call(AxiosInstance.put, updateGigWorkFlowsGigInformation('projectSetup', projectNumber, id), {
          subSections: payload?.data?.subSections,
          ...payload?.data?.request,
          id,
          isComplete: payload?.data?.isComplete,
        })
      : yield call(AxiosInstance.post, saveGigWorkFlowsGigInformation('projectSetup', projectNumber), {
          subSections: payload?.data?.subSections,
          ...payload?.data?.request,
          isComplete: payload?.data?.isComplete,
        });
    yield put(
      gigWorkflowActions.saveGigInfoFormSucceeded({
        isSucceeded: true,
      })
    );
    if (payload?.data?.type === 'save') {
      yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    } else {
      if (payload?.data?.isComplete) {
        yield put(commonActions.infoPopup({ messageId: 'Your form has been completed' }));
      } else {
        yield put(commonActions.infoPopup({ messageId: 'You can edit your form.' }));
      }

      // call Gig workflows - update Gig Information menu status to update MenuItem Data for All steps
      yield* updateGigInformationMenuStatusSaga({ data: payload?.data?.request });
    }
    yield* getGigInfoFormSaga({ data: payload?.data?.request });
  } catch (e) {
    let errorMessage = '';
    if (e.response && e.response.data && e.response.data.message) {
      errorMessage =
        e.response.data.errors.length > 0 ? `${e.response.data.errors.join('\n')}` : 'An Error has occurred';
    }
    const infoPopupMessage = errorMessage;

    yield put(commonActions.infoPopup({ messageId: infoPopupMessage, theme: 'alert-danger' }));
    yield put(
      gigWorkflowActions.saveGigInfoFormFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveGigInfoFormStatus() {
  yield takeEvery([gigWorkflowActionTypes.SAVE_GIG_INFO_FORM], saveGigInfoFormSaga);
}

export function* finishWorkflowSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('finishWorkflow'), payload.data);
    yield put(
      gigWorkflowActions.finishWorkflowSucceeded({
        isSucceeded: true,
        fragnetStepsData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      gigWorkflowActions.finishWorkflowFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* finishWorkflowStatus() {
  yield takeEvery([gigWorkflowActionTypes.FINISH_WORKFLOW], finishWorkflowSaga);
}

// Gig Workflows - Update Activity related Data
export function* updateActivityRelatedSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.put,
      getGigWorkFlowsLandingPage('projectSetup', payload?.data?.projectNumber),
      payload?.data
    );

    if (responseData.status === 200)
      yield put(
        gigWorkflowActions.updateActivityRelatedDataSucceeded({
          isSucceeded: true,
        })
      );
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: get(payload, 'data.projectNumber', ''),
        stateKey: 'setUpInProgress',
      })
    );
  } catch (e) {
    yield put(
      gigWorkflowActions.updateActivityRelatedDataFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* updateActivityRelatedStatus() {
  yield takeEvery(gigWorkflowActionTypes.UPDATE_ACTIVITY_RLATED_DATA, updateActivityRelatedSaga);
}
/// Gig workflows - Project forms Completion
export function* completeGigWorkFlowsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, menuId } = payload?.data;
    const omitList = ['menuId'];
    const getPayload = cloneDeep(omit(payload?.data, omitList));

    const responseData = yield call(
      AxiosInstance.put,
      completeGigWorkFlowsProjectForms('projectSetup', projectNumber, menuId),
      getPayload
    );
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', false);
    const stateKey = yield select(getStateKey);
    yield put(
      gigWorkflowActions.completeGigWorkFlowsSuccess({
        isSucceeded: true,
        completeResponse: responseData?.data?.data,
      })
    );
    if (payload?.data?.isComplete) {
      yield put(
        commonActions.infoPopup({
          messageId: 'Your form has been Completed',
        })
      );
    } else {
      yield put(commonActions.infoPopup({ messageId: 'You can edit your form' }));
    }
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData?.data?.data?.projectNumber,
        stateKey,
      })
    );

    yield put(projectSetupActions.getProjectSubMenu(payload?.data));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    gigWorkflowActions.completeGigWorkFlowsFaliure({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* completeGigWorkFlowsStatus() {
  yield takeEvery([gigWorkflowActionTypes.COMPLETE_GIG_WORKFLOWS], completeGigWorkFlowsSaga);
}
