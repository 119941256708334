import { combineReducers } from 'redux';
import { reducer as formReducer, actionTypes } from 'redux-form';
import { isEmpty } from 'lodash';
import { authReducer } from './auth';
import { projectSetupReducer } from './projectSetup';
import { projectHomeReducer } from './projectHome';
import { projectInfoReducer } from './projectInformation';
import { wbsReducer } from './wbsInformation';
import { projectInputOutput } from './inputOutput';
import { referenceLibraryReducer } from './referenceLibrary';
import { importManhrsReducer } from './importManhrs';
import { projActivitiesReducer } from './projectActivities';
import { assignLeadsReducer } from './assignLeadsReducer';
import { commonReducer } from './common';
import { workflowDataReducer } from './workflowData';
import { landingReducer } from './landing';
import { p6Reducer } from './p6';
import { laminarScopeGigInfoReducer } from './gigInfo';
import { laminarScopeReducer } from './laminarScope';
import { projActivityAndFragStepsReducer } from './projActivityAndFragSteps';
import { gigWorkflowReducer } from './gigWorkflow';
import { discussionReducer } from './discussionData';
import { gigReferenceReducer } from './gigReferenceReducer';
import { viewApproveProjectReducer } from './viewApproveProject';
import { p6SyncReducer } from './laminarSyncP6';
import { projectDashboardReducer } from './projectDashboard';
import { enggRefLibraryReducer } from './enggExeRefLibrary';
import { enggExeProjectHomeReducer } from './enggExeProjectHome';
import { masterDataManagerReducer as masterDataManager } from './masterDataManager';
import { masterDataGigDashboardReducer } from './masterGigDashboard';
import { systemNotificationsReducer } from './systemNotifications';
import { helpLinksReducer } from './helpLinks';
import { actionTypes as authActionTypes } from '../actions/auth';
import { projectGigsTableReducer } from './enggLaminarScope';
import { assumptionArchiveTableReducer } from './IOAssumptionArchive';

const allReducers = combineReducers({
  authReducer,
  projectSetupReducer,
  projectHomeReducer,
  projectInformationForm: projectInfoReducer,
  wbsInformation: wbsReducer,
  projectInputOutput,
  workflowDataReducer,
  referenceLibrary: referenceLibraryReducer,
  importManhrsInformation: importManhrsReducer,
  projActivitiesInformation: projActivitiesReducer,
  assignLeadsInformation: assignLeadsReducer,
  commonData: commonReducer,
  gigWorkflowInformation: gigWorkflowReducer,
  discussionReducer,
  gigReferenceReducer,
  laminarScopeGigInfo: laminarScopeGigInfoReducer,
  laminarScope: laminarScopeReducer,
  projActivityAndFragSteps: projActivityAndFragStepsReducer,
  viewApproveProject: viewApproveProjectReducer,
  form: formReducer.plugin({
    AssignLeads: (state, action) => {
      if (action.type === actionTypes.UNREGISTER_FIELD) {
        if (!isEmpty(state.values)) {
          const {
            values: { [action.payload.name]: unregistered, ...values },
          } = state;
          return { ...state, values };
        }
      }
      // force resgister redux form values
      if (action.type === 'CUSTOM_REGISTER_FIELD') {
        if (!isEmpty(state.values)) {
          const values = {
            ...state.values,
            [action.payload.name]: '',
          };

          return { ...state, values };
        }
      }
      return state;
    },
  }),
  landingReducer,
  p6Reducer,
  p6SyncReducer,
  projectDashboard: projectDashboardReducer,
  enggRefLibraryReducer,
  enggExeProjectHomeReducer,
  masterDataManager,
  masterDataGigDashboardReducer,
  systemNotificationsReducer,
  helpLinksReducer,
  projectGigsTableReducer,
  // Needed for the reducer to work
  assumptionArchiveTableReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === authActionTypes.LOGOUT_AUTH || action.type === authActionTypes.MSAL_LOGOUT_AUTH) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
// Creating FormReducer plugin to handle when reduxform field unregister,  deleting field from redux form values
