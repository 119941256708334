import { ICONS } from '../../constants/assets';

export const getNotificationStatusIcon = (data) => {
  switch (data) {
    case 'gigAssign':
      return {
        src: ICONS.ICON_GIG_ASSIGNMENT,
      };
    case 'Discussion':
      return {
        src: ICONS.ICON_DISCUSSION,
      };
    case 'Assumptions':
      return {
        src: ICONS.ICON_ASSUMPTION,
      };
    case 'Transfer Request':
      return {
        src: ICONS.ICON_TRANSFER_REQUEST,
      };
    case 'HOLD':
      return {
        src: ICONS.ON_HOLD,
      };
    case 'Change Management':
      return {
        src: ICONS.ICON_CHANGE_MANAGEMENT,
      };
    case 'Error':
      return {
        src: ICONS.ERROR_TRIANGLE,
        style: {
          'border-radius': 'inherit',
          'filter': 'drop-shadow(3px 3px 3px #aaa)',
          'height': '20px',
        }
      }
    default:
      return {
        src: ICONS.ICON_ARROW_RIGHT,
      };
  }
};
