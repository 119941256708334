export const createNotificationMessage = (notificationObj, projectName) => {
  const { nData, nAssignedBy, type, category, gig, status, reason, instance, projectActivity, nFrom } = notificationObj;

  let message = '';
  const userName = nAssignedBy !== undefined && nAssignedBy !== 'undefined' ? nAssignedBy : nFrom;
  switch (type) {
    case 'type1': // On Hold
      message = `<i>${gig}</i> placed <b>On Hold</b> by ${userName}`;
      break;
    case 'type2': // Assigned
      message = `<i>${gig}</i> has been <b>Assigned</b> to you by ${userName}`;
      break;
    case 'type3': // Discussion Thread
      message = `You've been tagged in the <b>Discussion</b> thread on ${gig} by ${userName}`;
      break;
    case 'type4': // Assumption
      message = nData; // `${nAssignedBy} has <b>${category} an Assumption<b> for ${instance}`;
      break;
    case 'type5': //
      message = `Multiple gigs have been <b>Assigned</b> to you by ${userName}`;
      break;
    case 'type6':
      message = `<b>${category}</b> is required for ${projectActivity} due to ${reason} on ${gig} initiated by ${userName}`;
      break;
    case 'type7':
      message = `${gig} placed <b>Gig ${status}</b> by ${userName}`;
      break;
    case 'type8': // Transfer request
      message = `${userName} has <b>Requested a Transfer</b> of <i>${gig}</i>`;
      break;
    default:
      message = nData;
  }
  return message;
};
