import { assignActionTypes } from '../actions';

const InitialState = {
  addRoleDataMaster: {},
};

export const assignLeadsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case assignActionTypes.GET_ASSIGN_LEADS_INFO_FORM:
      return { ...state };
    case assignActionTypes.GET_ASSIGN_LEADS_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case assignActionTypes.GET_ASSIGN_LEADS_INFO_FAILURE:
      return { ...state, ...action.payload };
    case assignActionTypes.GET_ASSIGN_LEADS_USERS:
      return { ...state, users: action.payload };
    case assignActionTypes.FETCH_INITIAL_USERS: {
      return {
        ...state,
        initialUsers: action.payload,
        cloneInitialUsers: action.payload,
      };
    }

    case assignActionTypes.GET_ASSIGN_ROLES:
      return { ...state };
    case assignActionTypes.GET_ASSIGN_ROLES_SUCCESS:
      return { ...state, addingRolesData: action.payload.addingRolesData };
    case assignActionTypes.GET_ASSIGN_ROLES_FAILURE:
      return { ...state, ...action.payload };
    case assignActionTypes.SAVE_ASSIGN_ROLES:
      return { ...state };

    case assignActionTypes.SAVE_ASSIGN_ROLES_FAILED:
      return { ...state, ...action.payload };
    case assignActionTypes.GET_ADD_ROLE_MASTER:
      return { ...state, addRoleDataMaster: action.payload };

    case assignActionTypes.GET_ADD_ROLES_MASTER_SUCCESS:
      return { ...state, addRoleDataMaster: action.payload };

    case assignActionTypes.GET_ADD_ROLES_MASTER_FAILURE:
      return { ...state, addRoleDataMaster: action.payload };

    case assignActionTypes.SAVE_ROLE_DATA:
      return { ...state, saveRoleFields: action.payload };

    case assignActionTypes.SAVE_ROLE_DATA_SUCCESS: {
      const cloneData = [...state.initialUsers];
      cloneData[0].subSections[0].fields = [
        ...cloneData[0].subSections[0].fields,
        action.payload.saveRoleData.subSections[0].fields[0],
      ];

      return { ...state, initialUsers: cloneData };
    }

    case assignActionTypes.SAVE_ROLE_DATA_FAILURE:
      return { ...state, saveRoleFields: action.payload };
    default:
      return state;
  }
};
