import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { UncontrolledTooltip } from 'reactstrap';
import { isEmpty, get } from 'lodash';
import { helpLinksActions } from '../../redux/actions';
import { ICONS } from '../../constants/assets';
import EditLinksModal from '../projectDashboard/widgets/quickLinks/editLinksModal'; // reusing component
import './styles.scss';

function HelpPopup(props) {
  const [scrollableIndex, setScrollableIndex] = useState([]);
  const [showEditLinks, setShowEditLinks] = useState(false);
  const { showHelpPopup, helpRef, helpLinksData } = props;
  const loggedInUser = useSelector((state) => get(state, 'authReducer.loginResponse.user', ''));

  useEffect(() => {
    props.getHelpLinksData();
  }, []);

  const manageRef = (el, index) => {
    if (!el) return;
    const { clientWidth, scrollWidth } = el;
    if (scrollWidth > clientWidth) {
      if (!scrollableIndex.includes(index)) {
        setScrollableIndex([...scrollableIndex, index]);
      }
    }
  };

  return (
    <Container
      ref={showEditLinks ? null : helpRef}
      className="dropdown-HelpPopup-container"
      style={
        showHelpPopup
          ? {
              display: 'block',
              width: '152px',
              minHeight: 'auto',
              maxHeight: 'auto',
              paddingRight: '0px',
              paddingLeft: '0px',
              position: 'absolute',
              top: '56px',
              right: '95px',
            }
          : {}
      }
    >
      {loggedInUser.laminarUserRole === 'dataAdmin' && (
        <div className="popup-header">
          <img
            className="edit-help-icon"
            role="presentation"
            src={ICONS.PENCIL_BLUE}
            alt="edit"
            onClick={() => setShowEditLinks(true)}
          />
        </div>
      )}
      <div className={loggedInUser.laminarUserRole === 'dataAdmin' ? 'helpLinks-container' : 'no-edit-icon'}>
        {!isEmpty(helpLinksData.helplinks) &&
          Array.isArray(helpLinksData.helplinks) &&
          helpLinksData.helplinks.map((help, index) => (
            <div className="help-popup-links" key={`helpLinks${index}`}>
              <a
                href={help.link}
                target="_blank"
                rel="noreferrer"
                id={`helpLinks${index}`}
                ref={(el) => manageRef(el, index)}
              >
                {help.name}
                {scrollableIndex.includes(index) && (
                  <UncontrolledTooltip placement="top" target={`helpLinks${index}`} className="help-link-tooltip">
                    {help.name}
                  </UncontrolledTooltip>
                )}
              </a>
            </div>
          ))}
        {(isEmpty(helpLinksData.helplinks) || !Array.isArray(helpLinksData.helplinks)) && (
          <p className="empty-notice">Help coming soon!</p>
        )}
      </div>
      <EditLinksModal showModal={showEditLinks} onToggleModal={setShowEditLinks} editHelpLinkModal />
    </Container>
  );
}

HelpPopup.propTypes = {
  showHelpPopup: PropTypes.bool,
  helpRef: PropTypes.object,
  helpLinksData: PropTypes.object.isRequired,
  getHelpLinksData: PropTypes.func.isRequired,
};

HelpPopup.defaultProps = {
  showHelpPopup: false,
};

const mapStateToProps = (state) => ({
  helpLinksData: get(state, 'helpLinksReducer.helpLinksData', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getHelpLinksData: (data) => dispatch(helpLinksActions.getHelpLinks(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HelpPopup));
