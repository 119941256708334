import { gigWorkflowActions, gigWorkflowActionTypes } from '../actions';

const InitialState = {};

export const gigWorkflowReducer = (state = InitialState, action) => {
  switch (action.type) {
    case gigWorkflowActionTypes.GET_DROPDOWN_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_DROPDOWN_DATA_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_SUB_ACTIVITY_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_SUB_ACTIVITY_LIST_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SET_CURRENT_ACTIVITY_DATA:
      return { ...state, ...action.data };
    case gigWorkflowActionTypes.GET_CUSTOMIZE_FRAGNET_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_CUSTOMIZE_FRAGNET_DATA_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.FETCH_FRAGNET_STEPS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.FETCH_FRAGNET_STEPS_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_ACTIVITY_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_ACTIVITY_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_FRAGNET_STEP_INFO:
      return { ...state };
    case gigWorkflowActionTypes.GET_FRAGNET_STEP_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_FRAGNET_STEP_INFO_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_FRAGNET_STEP_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_FRAGNET_STEP_INFO_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.UPDATE_ACTIVITY_RLATED_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.UPDATE_ACTIVITY_RLATED_DATA_FAILURE:
      return { ...state, ...action.payload };

    case gigWorkflowActionTypes.SAVE_ACTIVITY_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_ACTIVITY_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };

    // step 4
    case gigWorkflowActionTypes.GET_CUSTOMIZED_GIG_STEPS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_CUSTOMIZED_GIG_STEPS_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_CUSTOMIZE_GIGNET_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_CUSTOMIZE_GIGNET_FORM_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_CUSTOMIZE_GIGNET_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_CUSTOMIZE_GIGNET_FORM_FAILURE:
      return { ...state, ...action.payload };
    // step 5
    case gigWorkflowActionTypes.UPDATE_GIGINFO_MENU_STATUS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.UPDATE_GIGINFO_MENU_STATUS_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_FRAGNET_STEPS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_FRAGNET_STEPS_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_GIGNET_STEP_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_GIGNET_STEP_INFO_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_GIGNET_STEP_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_GIGNET_STEP_INFO_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_GIG_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_GIG_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.CLEAR_GIG_INFO_FORM:
      return {
        ...state,
        gigInformationFormData: {},
      };
    case gigWorkflowActionTypes.SAVE_GIG_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_GIG_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_LANDING_PAGE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.SAVE_LANDING_PAGE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_LANDING_PAGE_DATA_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.GET_LANDING_PAGE_DATA_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.FINISH_WORKFLOW_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.FINISH_WORKFLOW_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.CLEAR_GIG_STEPS:
      return {
        ...state,
        fragnetStepsData: {},
        gigInformationFormData: {},
        savedCustomizeGignetForm: {},
        customizedGigStepsData: {},
        customizeFragnetData: {},
        saveFragnetStepInfo: {},
        newFragnetSteps: {},
        subActivityList: {},
        fragnetStepInfo: [],
        currentActivity: {},
        activityInformationData: {},
      };
    case gigWorkflowActionTypes.AUTO_COMPLETE_GIGS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.AUTO_COMPLETE_GIGS_FAILURE:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.CLEAR_GIG_DROPDOWN_INFO:
      return {
        ...state,
        landingDropdownData: [],
      };
    case gigWorkflowActionTypes.COMPLETE_GIG_WORKFLOWS:
      return { ...state, ...action.data };
    case gigWorkflowActionTypes.COMPLETE_GIG_WORKFLOWS_SUCCESS:
      return { ...state, ...action.payload };
    case gigWorkflowActionTypes.COMPLETE_GIG_WORKFLOWS_FAILURE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
