import { put, takeEvery, call, select } from 'redux-saga/effects';
import { get } from 'lodash';
import AxiosInstance from '../../services/commons/Interceptor';
import { getDiscussionData } from '../../mocks/gigOverView';
import { users } from '../../mocks/users';
import servicelist from '../../services/serviceList';
import { discussionActions, discussionActionsTypes, commonActions } from '../actions';
import { getItemFromLocalStorage } from '../../helpers/localStorage';

export function* getDiscussionSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const responseData = getDiscussionData;

    yield put(
      discussionActions.getDiscussionDataSucceeded({
        isSucceeded: true,
        discussionData: responseData,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      discussionActions.getDiscussionDataFailed({
        isSucceeded: false,
        discussionData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getDiscussionDataStatus() {
  yield takeEvery([discussionActionsTypes.GET_DISCUSSION_DATA], getDiscussionSaga);
}

export function* getLaminarDiscussionSaga(data) {
  try {
    yield put(commonActions.showLoader(true));
    // const getToken = (state) => get(state, 'authReducer.loginResponse.token', '');
    // const token = yield select(getToken) === '' ? yield select(getToken) : getItemFromLocalStorage('token');
    const responseData = yield call(AxiosInstance.get, servicelist('laminarDiscussion'), {
      params: data.payload,
      // headers: {
      //   Authorization: token,
      // },
    });
    yield put(
      discussionActions.getLaminarDiscussionSucceeded({
        laminarDiscussionData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      discussionActions.getLaminarDiscussionFailed({
        laminarDiscussionData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getLaminarDiscussionStatus() {
  yield takeEvery([discussionActionsTypes.GET_LAMINAR_DISCUSSION], getLaminarDiscussionSaga);
}

export function* getUserDataSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const responseData = users;
    yield put(
      discussionActions.getUserDataSucceeded({
        isSucceeded: true,
        usersData: responseData,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      discussionActions.getUserDataFailed({
        isSucceeded: false,
        usersData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getUserDataStatus() {
  yield takeEvery([discussionActionsTypes.GET_USER_DATA], getUserDataSaga);
}

export function* saveDiscussionSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield commonActions.showLoader(true);
    const payloadData = payload.payload || {};
    const responseData = yield call(AxiosInstance.post, servicelist('laminarDiscussion'), payloadData);

    if (responseData) {
      yield put(
        discussionActions.saveDiscussionDataSucceeded({
          isSucceeded: true,
          discussionDataSave: responseData,
        })
      );
    }

    yield put(commonActions.infoPopup({ messageId: 'Your Comment has been saved' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      discussionActions.saveDiscussionDataFailed({
        isSucceeded: false,
        discussionDataSave: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveDiscussionDataStatus() {
  yield takeEvery([discussionActionsTypes.SAVE_DISCUSSION_DATA], saveDiscussionSaga);
}
