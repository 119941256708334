export const actionTypes = {
  GET_PROJECT_GIGS_TABLE: 'GET_PROJECT_GIGS_TABLE',
  GET_PROJECT_GIGS_TABLE_SUCCESS: 'GET_PROJECT_GIGS_TABLE_SUCCESS',
  GET_PROJECT_GIGS_TABLE_FAILURE: 'GET_PROJECT_GIGS_TABLE_FAILURE',
  MASS_ASSIGN_GIGS: 'MASS_ASSIGN_GIGS',
  MASS_ASSIGN_GIGS_SUCCESS: 'MASS_ASSIGN_GIGS_SUCCESS',
  MASS_ASSIGN_GIGS_FAILURE: 'MASS_ASSIGN_GIGS_FAILURE',
};

export const actions = {
  getProjectGigsTable: (data) => ({
    type: actionTypes.GET_PROJECT_GIGS_TABLE,
    data,
  }),
  getProjectGigsTableSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_GIGS_TABLE_SUCCESS,
    payload: content,
  }),
  getProjectGigsTableFailed: (content) => ({
    type: actionTypes.GET_PROJECT_GIGS_TABLE_FAILURE,
    payload: content,
  }),
  massAssignGigs: (data) => ({
    type: actionTypes.MASS_ASSIGN_GIGS,
    data,
  }),
  massAssignGigSucceeded: (content) => ({
    type: actionTypes.MASS_ASSIGN_GIGS_SUCCESS,
    payload: content,
  }),
  massAssignGigFailed: (content) => ({
    type: actionTypes.MASS_ASSIGN_GIGS_FAILURE,
    payload: content,
  }),
};
