import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './quickLinks.scss';

import { get, isEqual, isEmpty } from 'lodash';
import { ICONS } from '../../../../constants/assets';
import { projectDashboardActions, commonActions, helpLinksActions } from '../../../../redux/actions';
import BVAutoSelectField from '../../../../baseComponents/BVAutoSelectField';
import BVTextField from '../../../../baseComponents/BVTextField';

import { BVModal } from '../../../../baseComponents/BVModal';
import { validateUrl } from '../../../../helpers/common';

function EditLinksModal(props) {
  const { showModal, onToggleModal, editHelpLinkModal } = props;

  const [linkItems, setLinksItems] = useState([]);
  const [newLinks, setNewLinks] = useState([]);
  const [newLibrary, setNewLibrary] = useState([]);
  const [linkItemErrors, setLinksItemErrors] = useState([]);
  const [newLinkErrors, setNewLinkErrors] = useState([]);
  const [newLibraryErrors, setNewLibraryErrors] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const dispatch = useDispatch();

  const quickLinks = useSelector((state) =>
    get(state, 'projectDashboard.projectDashboardWidgetsData.quickLinks.linksData', [])
  );
  const dropdownQuickLinks = useSelector((state) => get(state, 'projectDashboard.quickLinksDropdown[0].body', []));
  const projectNumber = useSelector((state) => get(state, 'projectSetupReducer.projectDetails.projectNumber', []));
  const menuId = useSelector((state) => get(state, 'projectSetupReducer.projectDetails.selectedMenu', []));
  const discipline = useSelector((state) => get(state, 'projectSetupReducer.menuList.disciplines[0]', null));
  const getDropdownQuicklinks = (data) => dispatch(projectDashboardActions.getDropdownQuicklinks(data));
  const saveQuickLinks = (data) => dispatch(projectDashboardActions.saveQuickLinks(data));
  const alertMessage = (data) => dispatch(commonActions.infoPopup(data));

  // help links
  const helpLinks = useSelector((state) => get(state, 'helpLinksReducer.helpLinksData.helplinks', []));
  const saveHelpLinks = (data) => dispatch(helpLinksActions.setHelpLinks(data));

  const dropdownStyles = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    if (!editHelpLinkModal && isEqual(quickLinks, linkItems) && isEmpty(newLinks) && isEmpty(newLibrary)) {
      setIsSaveEnabled(false);
    } else if (editHelpLinkModal && isEqual(helpLinks, linkItems) && isEmpty(newLinks) && isEmpty(newLibrary)) {
      setIsSaveEnabled(false);
    } else {
      setIsSaveEnabled(true);
    }
  }, [quickLinks, linkItems, newLinks, newLibrary]);

  const closeModal = () => {
    setIsSaveEnabled(false);
    onToggleModal(false);
  };

  useEffect(() => {
    dispatch(commonActions.modalPopup({ isEdited: showModal && isSaveEnabled, showModal: false }));
    return () => dispatch(commonActions.modalPopup({ isEdited: false, showModal: false }));
  }, [showModal, isSaveEnabled]);

  const handleSave = () => {
    if (isSaveEnabled) {
      // checks for newLinks validation on submit if link field left untouched
      const tempNewLinkErrors = [...newLinkErrors];
      newLinks.forEach((item, idx) => {
        let nameError = '';
        let linkError = '';
        if (item.name === undefined || (typeof item.name === 'string' && item.name.trim() === '')) {
          nameError = 'Value is Required';
        }
        if (item.link === undefined || (typeof item.link === 'string' && item.link.trim() === '')) {
          linkError = 'Value is Required';
        }
        tempNewLinkErrors[idx] = {
          ...tempNewLinkErrors[idx],
          [`name_error`]: nameError,
          [`link_error`]: linkError,
        };
      });
      setNewLinkErrors(tempNewLinkErrors);

      const finalLinkErrors = linkItemErrors.filter(
        (error) => !isEmpty(get(error, 'name_error', '')) || !isEmpty(get(error, 'link_error', ''))
      );
      const finalNewLinkErrors = tempNewLinkErrors.filter(
        (error) => !isEmpty(get(error, 'name_error', '')) || !isEmpty(get(error, 'link_error', ''))
      );
      const finalLibErrors = newLibraryErrors.filter((error) => error !== '');

      if (finalLinkErrors.length === 0 && finalNewLinkErrors.length === 0 && finalLibErrors.length === 0) {
        const linksData = editHelpLinkModal ? [...linkItems, ...newLinks] : [...linkItems, ...newLibrary, ...newLinks];
        const postData = editHelpLinkModal
          ? { linksData }
          : {
              projectNumber,
              discipline,
              menuId,
              linksData,
              isQuickLink: true,
            };

        editHelpLinkModal ? saveHelpLinks(postData.linksData) : saveQuickLinks(postData);
        closeModal();
      } else {
        alertMessage({
          messageId: 'Please enter valid data',
          theme: 'alert-danger',
        });
      }
    }
  };

  const handleAddLink = () => {
    const tempRows = [
      ...newLinks,
      {
        name: '',
        link: '',
      },
    ];
    setNewLinks(tempRows);
    setItemsCount(itemsCount + 1);
  };

  const handleAddLibrary = () => {
    const tempRows = [...newLibrary, null];
    setNewLibrary(tempRows);
    const tempErrors = [...newLibraryErrors, ''];
    setNewLibraryErrors(tempErrors);
    setItemsCount(itemsCount + 1);
  };

  const handleDelete = (index, type) => {
    if (type === 'linkItem') {
      const tempRows = [...linkItems];
      tempRows.splice(index, 1);
      setLinksItems(tempRows);
    } else if (type === 'newLink') {
      const tempRows = [...newLinks];
      const tempErrors = [...newLinkErrors];
      tempRows.splice(index, 1);
      tempErrors.splice(index, 1);
      setNewLinks(tempRows);
      setNewLinkErrors(tempErrors);
    } else if (type === 'newLibrary') {
      const tempRows = [...newLibrary];
      const tempErrors = [...newLibraryErrors];
      tempRows.splice(index, 1);
      tempErrors.splice(index, 1);
      setNewLibrary(tempRows);
      setNewLibraryErrors(tempErrors);
    }
    setItemsCount(itemsCount - 1);
  };

  const validateDropdown = (index) => {
    const tempErrors = [...newLibraryErrors];
    if (newLibrary[index] === null) {
      tempErrors[index] = 'Value Required';
    } else {
      tempErrors[index] = '';
    }
    setNewLibraryErrors(tempErrors);
  };

  const handleChangeDropdown = (selectedOption, idx) => {
    const tempRows = [...newLibrary];
    tempRows[idx] = {
      ...tempRows[idx],
      name: selectedOption.name,
      id: selectedOption.id,
      link: selectedOption.link,
    };
    setNewLibrary(tempRows);

    const tempErrors = [...newLibraryErrors];
    if (tempRows[idx] === null) {
      tempErrors[idx] = 'Value Required';
    } else {
      tempErrors[idx] = '';
    }
    setNewLibraryErrors(tempErrors);
  };

  // checks for validation
  const validateRow = async (idx, e, type) => {
    const { target } = e;
    const { value, name } = target;
    let tempRows = [];

    if (type === 'linkItem') {
      tempRows = [...linkItemErrors];
    } else if (type === 'newLink') {
      tempRows = [...newLinkErrors];
    }

    let error = '';

    if (name === 'link') {
      const urlIsValid = await validateUrl(value);
      if (!urlIsValid) {
        error = 'URL is invalid';
      }
    }

    if (value === undefined || (typeof value === 'string' && value.trim() === '')) {
      error = 'Value is Required';
    }

    tempRows[idx] = {
      ...tempRows[idx],
      [`${target.name}_error`]: error,
    };

    if (type === 'linkItem') {
      setLinksItemErrors(tempRows);
    } else if (type === 'newLink') {
      setNewLinkErrors(tempRows);
    }
  };

  const handleValueChange = (idx, e, type) => {
    const { target } = e;
    const { value } = target;
    if (type === 'linkItem') {
      const tempRows = [...linkItems];
      tempRows[idx] = {
        ...tempRows[idx],
        [target.name]: value,
      };
      setLinksItems(tempRows);
    } else if (type === 'newLink') {
      const tempRows = [...newLinks];
      tempRows[idx] = {
        ...tempRows[idx],
        [target.name]: value,
      };
      setNewLinks(tempRows);
    }
    validateRow(idx, e, type);
  };

  React.useEffect(() => {
    if (showModal && !editHelpLinkModal) {
      getDropdownQuicklinks({ projectNumber });
      setLinksItems(quickLinks);
      setItemsCount(quickLinks.length);
      setNewLinks([]);
      setNewLibrary([]);
      setLinksItemErrors([]);
      setNewLinkErrors([]);
      setNewLibraryErrors([]);
    }

    if (showModal && editHelpLinkModal) {
      setLinksItems(helpLinks);
      setItemsCount(helpLinks?.length);
      setNewLinks([]);
      setLinksItemErrors([]);
      setNewLinkErrors([]);
    }
  }, [showModal]);

  return (
    <BVModal
      showModal={showModal}
      title={editHelpLinkModal ? 'EDIT HELP LINKS (MAX 5)*' : 'EDIT QUICK LINKS (MAX 10)*'}
      isOutSideDismiss={false}
      toggleModal={() => closeModal()}
      contentClassName="edit-links-content-modal"
      dialogClassName="edit-links-dialog"
      closeButton
      buttonData={[
        {
          buttonClick: closeModal,
          buttonLabel: 'Cancel',
        },
        {
          buttonClick: handleSave,
          isPrimaryButton: true,
          buttonLabel: 'Save',
          isDisabled: !isSaveEnabled,
        },
      ]}
    >
      <div className="edit-links-content">
        <div className="link-items-container">
          <div className="link-items">
            {linkItems?.map((item, index) => (
              <div className="item-row" key={index}>
                <span className="field-label">Title:</span>
                <BVTextField
                  type="text"
                  name="name"
                  value={item.name}
                  onChange={(e) => handleValueChange(index, e, 'linkItem')}
                  onBlur={(e) => handleValueChange(index, e, 'linkItem')}
                  errorText={get(linkItemErrors, `[${index}].name_error`, '')}
                />
                <span className="field-label link">Link:</span>
                <BVTextField
                  type="text"
                  name="link"
                  value={item.link}
                  onChange={(e) => handleValueChange(index, e, 'linkItem')}
                  onBlur={(e) => handleValueChange(index, e, 'linkItem')}
                  errorText={get(linkItemErrors, `[${index}].link_error`, '')}
                />
                <img
                  onClick={() => handleDelete(index, 'linkItem')}
                  src={ICONS.TRASH}
                  alt="deleteIcon"
                  role="presentation"
                  className="delete-icon"
                />
              </div>
            ))}
          </div>

          {newLibrary.length > 0 && (
            <div className="new-links">
              <div className="field-label">Add Link from Reference Library:</div>
              {newLibrary.map((item, index) => (
                <div className="item-row" key={index}>
                  <span className="item-dropdown">
                    <BVAutoSelectField
                      name="libraryLink"
                      options={dropdownQuickLinks}
                      value={newLibrary[index]}
                      placeholder="Select or Type Link Name"
                      multipleValues={false}
                      className="librarySelect "
                      classNamePrefix="librarySelect"
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      valueKey="id"
                      labelKey="name"
                      onChange={(selected) => handleChangeDropdown(selected, index)}
                      autoFocus
                      onBlur={() => validateDropdown(index)}
                      errorText={newLibraryErrors[index]}
                      menuPortalTarget={document.body}
                      styles={dropdownStyles}
                    />
                  </span>
                  <img
                    onClick={() => handleDelete(index, 'newLibrary')}
                    src={ICONS.TRASH}
                    alt="deleteIcon"
                    role="presentation"
                    className="delete-icon"
                  />
                </div>
              ))}
            </div>
          )}

          {newLinks.length > 0 && (
            <div className="new-links">
              {!editHelpLinkModal ? <div className="field-label">Create New Link:</div> : ''}
              {newLinks.map((item, index) => (
                <div className="item-row" key={index}>
                  <span className="field-label">Title:</span>
                  <BVTextField
                    type="text"
                    name="name"
                    value={item.name}
                    onChange={(e) => handleValueChange(index, e, 'newLink')}
                    onBlur={(e) => handleValueChange(index, e, 'newLink')}
                    autoFocus
                    errorText={get(newLinkErrors, `[${index}].name_error`, '')}
                  />
                  <span className="field-label link">Link:</span>
                  <BVTextField
                    type="text"
                    name="link"
                    value={item.link}
                    onChange={(e) => handleValueChange(index, e, 'newLink')}
                    onBlur={(e) => handleValueChange(index, e, 'newLink')}
                    errorText={get(newLinkErrors, `[${index}].link_error`, '')}
                  />
                  <img
                    onClick={() => handleDelete(index, 'newLink')}
                    src={ICONS.TRASH}
                    alt="deleteIcon"
                    role="presentation"
                    className="delete-icon"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="add-fields">
          <button
            onClick={handleAddLink}
            className="add-button create-link"
            type="button"
            disabled={editHelpLinkModal ? itemsCount > 4 : itemsCount > 9}
          >
            <img
              src={editHelpLinkModal && itemsCount > 4 ? ICONS.ADD_FIELD_GRAY : ICONS.ADD_FIELD}
              alt="addIcon"
              className="add-icon"
            />
            <span className="add-label">Create New Link</span>
          </button>
          {!editHelpLinkModal && (
            <button
              onClick={handleAddLibrary}
              className="add-button create-library"
              type="button"
              disabled={itemsCount > 9}
            >
              <img src={ICONS.ADD_FIELD} alt="addIcon" className="add-icon" />{' '}
              <span className="add-label">Add Link From Reference Library</span>
            </button>
          )}
        </div>
        <div className="footer-notice">
          *If {editHelpLinkModal ? 5 : 10} Links are already listed, you must Delete 1 before you can{' '}
          {editHelpLinkModal ? 'Create' : 'Create/Add'} New Link
        </div>
      </div>
    </BVModal>
  );
}

export default EditLinksModal;
