import { put, takeEvery, call } from 'redux-saga/effects';
import servicelist from '../../services/serviceList';
import { gigReferenceAction, gigReferenceActionType, commonActions } from '../actions';
import AxiosInstance from '../../services/commons/Interceptor';

export function* getReferenceSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // try catch method instead of if and else due to failing of saga from 500 internal error
    let responseData = {};
    responseData = yield call(AxiosInstance.get, servicelist('getLaminarGigReference'), {
      params: payload.payload,
    });
    yield put(
      gigReferenceAction.getReferenceDataSucceeded({
        isSucceeded: true,
        gigReferenceData: responseData.data.body,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      gigReferenceAction.getReferenceDataFailed({
        isSucceeded: false,
        gigReferenceData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getReferenceDataStatus() {
  yield takeEvery([gigReferenceActionType.GET_REFERENCE_DATA], getReferenceSaga);
}

export function* getReferenceDropdownSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // try catch method instead of if and else due to failing of saga from 500 internal error
    let responseData = {};
    // responseData = yield call(AxiosInstance.get, servicelist('getLaminarGigReferenceDropdown'), {
    //   params: payload.payload,
    // });
    const a = payload.payload.projectNumber;
    const url = servicelist('totalReferenceTransactiondata');
    const mylink = `${url}?projectNumber=${a}`;
    responseData = yield call(AxiosInstance.post, mylink);
    yield put(
      gigReferenceAction.getReferenceDropdownSucceeded({
        isSucceeded: true,
        gigReferenceDropdownData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      gigReferenceAction.getReferenceDropdownFailed({
        isSucceeded: false,
        gigReferenceDropdownData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getReferenceDropdownDataStatus() {
  yield takeEvery([gigReferenceActionType.GET_REFERENCE_DROPDOWN_DATA], getReferenceDropdownSaga);
}

export function* saveReferenceData(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // try catch method instead of if and else due to failing of saga from 500 internal error
    const paramPayload = {
      projectNumber: payload.payload.projectNumber,
      gigID: payload.payload.data[0].gigID,
      activityId: payload.payload.data[0].activityId,
    };

    let responseData = {};
    responseData = yield call(AxiosInstance.post, servicelist('saveGigReference'), {
      projectNumber: payload.payload.projectNumber,
      body: payload.payload.data,
    });
    yield put(
      gigReferenceAction.saveReferenceDataSucceeded({
        isSucceeded: true,
        savedReferenceData: responseData.data,
      })
    );
    yield put(gigReferenceAction.getReferenceData(paramPayload));
    yield put(commonActions.infoPopup({ messageId: 'Your Reference has been saved' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      gigReferenceAction.saveReferenceDataFailed({
        isSucceeded: false,
        savedReferenceData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveReferenceStatus() {
  yield takeEvery([gigReferenceActionType.SAVE_REFERENCE_DATA], saveReferenceData);
}

export function* deletedReferenceData(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // try catch method instead of if and else due to failing of saga from 500 internal error
    const paramPayload = {
      projectNumber: payload.payload.projectNumber,
      gigID: payload.payload.data.gigID,
      activityId: payload.payload.data.activityId,
    };

    let responseData = {};
    responseData = yield call(AxiosInstance.post, servicelist('saveGigReference'), {
      projectNumber: payload.payload.projectNumber,
      body: payload.payload.data,
      deletedItems: true,
    });

    yield put(
      gigReferenceAction.deleteReferenceDataSucceeded({
        isSucceeded: true,
        deleteReferenceData: responseData.data,
      })
    );
    yield put(gigReferenceAction.getReferenceData(paramPayload));
    yield put(commonActions.infoPopup({ messageId: 'Your Reference has been deleted' }));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      gigReferenceAction.deleteReferenceDataFailed({
        isSucceeded: false,
        deleteReferenceData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* deleteReferenceStatus() {
  yield takeEvery([gigReferenceActionType.DELETE_REFERENCE_DATA], deletedReferenceData);
}
