import { act } from '@testing-library/react';
import { getLaminarScopeGigInfoActionTypes } from '../actions';

const InitialState = {};
// const upadteSubSections = (gigInfoData, gigInfoObj) => {
//   const obj = gigInfoObj[0];
//   return gigInfoData.map((item) => {
//     return {
//       ...item,
//       fields: obj[item.subSectionKey]
//         ? item.fields.map((field) => {
//             return {
//               ...field,
//               value: obj[item.subSectionKey][field.key] ? obj[item.subSectionKey][field.key] : field.value,
//             };
//           })
//         : null,
//     };
//   });
// };

export const laminarScopeGigInfoReducer = (state = InitialState, action) => {
  switch (action.type) {
    case getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO:
      return { ...state };
    case getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO_FAILURE:
      return { ...state, ...action.payload };

    case getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_SUCCESS:
      return {
        ...state,
        gigInfoDropDownData: action.payload,
      };
    case getLaminarScopeGigInfoActionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_FAILURE:
      return {
        ...state,
        gigInfoDropDownData: action.payload,
      };

    case getLaminarScopeGigInfoActionTypes.MOVE_WITHOUT_FULL_PAGE:
      return {
        ...state,
        saveTab: action.payload,
      };
    // case getLaminarScopeGigInfoActionTypes.EDIT_GET_LAMINAR_SCOPE_GIG_INFO:
    //   return {
    //     ...state,
    //     gigInfoData: [...state.gigInfoData].map((gig) => {
    //       return {
    //         ...gig,
    //         fields: [...gig.fields].map((field) => {
    //           let obj = { ...field };
    //           if (obj.hasOwnProperty('isEditable')) {
    //             obj.isEditable = true;
    //           }
    //           return obj;
    //         }),
    //       };
    //     }),
    //   };

    default:
      return state;
  }
};
