import React from 'react';
import { ICONS } from '../../../constants/assets/index';
import BVAutoSelectField from '../../../baseComponents/BVAutoSelectField';
import BVTextField from '../../../baseComponents/BVTextField';
import './styles.scss';

function GenerateActivityTableRow(props) {
  const {
    rowData,
    handleDeleteRow,
    fields,
    index,
    isCompleted,
    updateTableData,
    selectedOption,
    resetTableData,
    setIsSaved,
    setIsResetEnable,
  } = props;

  const [data, setData] = React.useState();

  React.useEffect(() => {
    setData(rowData);
  }, [rowData]);

  const handleChange = (value, key, type) => {
    const newRowData = { ...data };
    newRowData[key] = type === 'select' ? value : (key == 'customCWACode' || key == 'customActivityCode') ? value.target.value.replace(/ +/g, "") : value.target.value;
    setData(newRowData);
    updateTableData(newRowData, index);
    // checkValue(newRowData[key], key);
  };

  // const checkValue = (value, key) => {
  //   if (value == resetTableData.body[index][key]) {
  //     setIsResetEnable(false);
  //     setIsSaved(true);
  //   }
  // };

  const checkEmptyTitleMap = (field) => (field.titleMap[0] && Array.isArray(field.titleMap[0].label) ? true : false);

  const renderField = (field) => {
    return field.type === 'select' ? (
      <BVAutoSelectField
        input={{
          value: !field.isDisabled && data[field.key],
          onBlur: () => {},
          onChange: (value) => handleChange(value, field.key, field.type),
        }}
        suggestions={field.titleMap}
        placeholder="Name Here"
        disabled={isCompleted || field.isDisabled || checkEmptyTitleMap(field)}
        multipleValues={true}
        className="genetateActivityTableRow-select"
        classNamePrefix="genetateActivityTableRow-select"
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    ) : (
      <BVTextField
        input={{
          value: !field.isDisabled && data[field.key],
          onBlur: () => {},
          onChange: (value) => handleChange(value, field.key, field.type),
        }}
        placeholder="Type Here"
        className="genetateActivityTableRow-input"
        classNamePrefix="genetateActivityTableRow-input"
      />
    );
  };

  const renderFieldCondition = (field) =>
    field.key === 'activity' ||
    (field.key.includes('primary') && selectedOption.primary.show) ||
    (field.key.includes('secondary') && selectedOption.secondary.show) ||
    (field.key.includes('terciary') && selectedOption.terciary.show) ||
    (field.key == 'customCWACode' && selectedOption.isCustomActivityCode) ||
    (field.key == 'customActivityCode' && selectedOption.isCustomActivityCode);

  return (
    <>
      <tr key={`genAct-table-body-row-${index}`} className="genAct-table-body-row">
      {fields &&
          fields.map(
            (field) =>
              field && renderFieldCondition(field) && (
                <td>
                  {(
                    <>
                      {field.type === 'select' && data && renderField(field)}
                      {(isCompleted && field.type === 'string' && field.key == 'activity') && data && data.activity}
                      {(isCompleted && field.type === 'string' && field.key == 'customActivityCode') && data && data.customActivityCode}
                      {(isCompleted && field.type === 'string' && field.key == 'customCWACode') && data && data.customCWACode}
                      {!isCompleted && field.type === 'string' && data && renderField(field)}
                    </>
                  )}
                </td>
              )
          )}
        <td>
          {!isCompleted && (
            <img
              src={ICONS.TRASH}
              onClick={() => handleDeleteRow(index)}
              className="genAct-table-body-row-column-delete"
            />
          )}
        </td>
      </tr>
      <hr className="genetateActivityTableRow-hr"></hr>
    </>
  );
}

export default GenerateActivityTableRow;
