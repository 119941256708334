export const actionTypes = {
  GET_REFERENCE_DATA: 'GET_REFERENCE_DATA',
  GET_REFERENCE_DATA_SUCCESS: 'GET_REFERENCE_DATA_SUCCESS',
  GET_REFERENCE_DATA_FAILURE: 'GET_REFERENCE_DATA_FAILURE',

  SAVE_REFERENCE_DATA: 'SAVE_REFERENCE_DATA',
  SAVE_REFERENCE_DATA_SUCCESS: 'SAVE_REFERENCE_DATA_SUCCESS',
  SAVE_REFERENCE_DATA_FAILURE: 'SAVE_REFERENCE_DATA_FAILURE',

  DELETE_REFERENCE_DATA: 'DELETE_REFERENCE_DATA',
  DELETE_REFERENCE_DATA_SUCCESS: 'DELETE_REFERENCE_DATA_SUCCESS',
  DELETE_REFERENCE_DATA_FAILURE: 'DELETE_REFERENCE_DATA_FAILURE',

  SET_EDIT_REFERENCE_FLAG: 'SET_EDIT_REFERENCE_FLAG',

  GET_REFERENCE_DROPDOWN_DATA: 'GET_REFERENCE_DROPDOWN_DATA',
  GET_REFERENCE_DROPDOWN_SUCCESS: 'GET_REFERENCE_DROPDOWN_SUCCESS',
  GET_REFERENCE_DROPDOWN_FAILURE: 'GET_REFERENCE_DROPDOWN_FAILURE',
};

export const actions = {
  deleteReferenceData: (content) => ({
    type: actionTypes.DELETE_REFERENCE_DATA,
    payload: content,
  }),
  deleteReferenceDataSucceeded: (content) => ({
    type: actionTypes.DELETE_REFERENCE_DATA_SUCCESS,
    payload: content,
  }),
  deleteReferenceDataFailed: (content) => ({
    type: actionTypes.DELETE_REFERENCE_DATA_FAILURE,
    payload: content,
  }),

  getReferenceData: (content) => ({
    type: actionTypes.GET_REFERENCE_DATA,
    payload: content,
  }),
  getReferenceDataSucceeded: (content) => ({
    type: actionTypes.GET_REFERENCE_DATA_SUCCESS,
    payload: content,
  }),
  getReferenceDataFailed: (content) => ({
    type: actionTypes.GET_REFERENCE_DATA_FAILURE,
    payload: content,
  }),

  getReferenceDropdownData: (content) => ({
    type: actionTypes.GET_REFERENCE_DROPDOWN_DATA,
    payload: content,
  }),
  getReferenceDropdownSucceeded: (content) => ({
    type: actionTypes.GET_REFERENCE_DROPDOWN_SUCCESS,
    payload: content,
  }),
  getReferenceDropdownFailed: (content) => ({
    type: actionTypes.GET_REFERENCE_DROPDOWN_FAILURE,
    payload: content,
  }),

  saveReferenceData: (content) => ({
    type: actionTypes.SAVE_REFERENCE_DATA,
    payload: content,
  }),
  saveReferenceDataSucceeded: (content) => ({
    type: actionTypes.SAVE_REFERENCE_DATA_SUCCESS,
    payload: content,
  }),
  saveReferenceDataFailed: (content) => ({
    type: actionTypes.SAVE_REFERENCE_DATA_FAILURE,
    payload: content,
  }),

  setReferenceEditFlag: (content) => ({
    type: actionTypes.SET_EDIT_REFERENCE_FLAG,
    payload: content,
  }),
};
