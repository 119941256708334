const hasWindow = typeof window !== 'undefined';

export function setItemToLocalStorage(key, value) {
  if (hasWindow) localStorage.setItem(key, value);
}
export function getItemFromLocalStorage(key) {
  if (hasWindow) return localStorage.getItem(key);
  return null;
}

export function removeItemFromLocalStorage(key) {
  if (hasWindow) localStorage.removeItem(key);
}
export function removeAllItemsFromLocalStorage(key) {
  if (hasWindow) localStorage.clear();
}
