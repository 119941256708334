import { put, takeEvery, call } from 'redux-saga/effects';
import { commonActions, projectInformationActions, projectInformationActionTypes } from '../actions';
import servicelist from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';

export function* getProjectInfoFormSaga(payload) {
  let responseData;
  yield put(commonActions.showLoader(true));
  try {
    responseData = yield call(AxiosInstance.get, servicelist('ProjectForm'), {
      params: {
        projectNumber: payload.data.projectNumber,
        menuId: payload.data.menuId,
        submenuId: payload.data.submenuId,
      },
    });
    yield put(
      projectInformationActions.getProjectInfoFormSucceeded({
        isSucceeded: true,
        formData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(projectInformationActions.getProjectInfoFormFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjectInfoFormStatus() {
  yield takeEvery([projectInformationActionTypes.GET_PROJECT_INFO_FORM], getProjectInfoFormSaga);
}

export function* getAdditionalFieldFormSaga() {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('AdditionalFieldForm')); // incase we need the error data from the api
    yield put(
      projectInformationActions.getAdditionalFieldFormSucceeded({
        isSucceeded: true,
        additionalField: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      projectInformationActions.getAdditionalFieldFormFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getAdditionalFieldFormStatus() {
  yield takeEvery([projectInformationActionTypes.GET_ADDITIONAL_FIELD_FORM], getAdditionalFieldFormSaga);
}

export function* saveProjectFormSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.data.body ? payload.data.body : {};
    const responseData = payload.data.id // assigning the response data to the variable
      ? yield call(AxiosInstance.put, servicelist('ProjectForm'), {
          ...payloadData,
          id: payload.data.id,
        })
      : yield call(AxiosInstance.post, servicelist('ProjectForm'), payloadData);
    yield put(
      projectInformationActions.saveProjectFormSucceeded({
        isSucceeded: true,
        savedForm: responseData.data,
      })
    );
    yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
    yield put(projectInformationActions.getProjectInfoForm(payloadData));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(projectInformationActions.saveProjectFormFailed({ isSucceeded: false }));
  }
  yield put(commonActions.showLoader(false));
}

export function* saveProjectFormStatus() {
  yield takeEvery([projectInformationActionTypes.SAVE_PROJECT_FORM], saveProjectFormSaga);
}
