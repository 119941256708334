import { authActionTypes } from '../actions';
import { LOGIN_TYPES } from '../../constants/common';

export const initialState = {
  isLoggedIn: false,
  loginType: LOGIN_TYPES.INITIAL_STATE,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN_AUTH:
      return { ...state };
    case authActionTypes.LOGIN_AUTH_SUCCESS:
      return { ...state, ...action.payload };
    case authActionTypes.LOGIN_AUTH_FAILURE:
      return { ...state, ...action.payload };
    case authActionTypes.LOGOUT_AUTH:
      return { ...state };
    case authActionTypes.LOGOUT_AUTH_SUCCESS:
      return { ...state };
    case authActionTypes.LOGOUT_AUTH_FAILURE:
      return { ...state };
    case authActionTypes.MSAL_AUTH:
      return { ...state };
    case authActionTypes.MSAL_AUTH_SUCCESS:
      return { ...state, ...action.payload };
    case authActionTypes.MSAL_AUTH_FAILURE:
      return { ...state, ...action.payload };
    case authActionTypes.MSAL_LOGOUT_AUTH:
      return { ...state };
    case authActionTypes.MSAL_LOGOUT_AUTH_SUCCESS:
      return { ...state, ...action.payload };
    case authActionTypes.MSAL_LOGOUT_AUTH_FAILURE:
      return { ...state, ...action.payload };
    case authActionTypes.REFRESH_TOKEN:
      return { ...state };
    case authActionTypes.REFRESH_TOKEN_SUCCESS:
      return { ...state, loginResponse: { ...state.loginResponse, ...action.payload.loginResponse } };
    default:
      return state;
  }
};
