export const actionTypes = {
  GET_REFERENCE_LIBRARY: 'GET_REFERENCE_LIBRARY',
  GET_REFERENCE_LIBRARY_SUCCESS: 'GET_REFERENCE_LIBRARY_SUCCESS',
  GET_REFERENCE_LIBRARY_FAILURE: 'GET_REFERENCE_LIBRARY_FAILURE',
  SAVE_REFERENCE_LIBRARY: 'SAVE_REFERENCE_LIBRARY',
  SAVE_REFERENCE_LIBRARY_SUCCESS: 'SAVE_REFERENCE_LIBRARY_SUCCESS',
  SAVE_REFERENCE_LIBRARY_FAILURE: 'SAVE_REFERENCE_LIBRARY_FAILURE',
  // MANAGE_REFERENCE
  GET_MANAGE_REFERENCE_LIBRARY: 'GET_MANAGE_REFERENCE_LIBRARY',
  GET_MANAGE_REFERENCE_LIBRARY_SUCCESS: 'GET_MANAGE_REFERENCE_LIBRARY_SUCCESS',
  GET_MANAGE_REFERENCE_LIBRARY_FAILURE: 'GET_MANAGE_REFERENCE_LIBRARY_FAILURE',
  // SAVE MANAGE REFERENCE
  SAVE_MANAGE_REFERENCE_LIBRARY: 'SAVE_MANAGE_REFERENCE_LIBRARY',
  SAVE_MANAGE_REFERENCE_LIBRARY_SUCCESS: 'SAVE_MANAGE_REFERENCE_LIBRARY_SUCCESS',
  SAVE_MANAGE_REFERENCE_LIBRARY_FAILURE: 'SAVE_MANAGE_REFERENCE_LIBRARY_FAILURE',
};

export const actions = {
  getReferenceLibrary: (data) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY,
    data,
  }),
  getReferenceLibrarySucceeded: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_SUCCESS,
    payload: content,
  }),
  getReferenceLibraryFailed: (content) => ({
    type: actionTypes.GET_REFERENCE_LIBRARY_FAILURE,
    payload: content,
  }),
  saveReferenceLibrary: (data) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY,
    data,
  }),
  saveReferenceLibrarySucceeded: (content) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY_SUCCESS,
    payload: content,
  }),
  saveReferenceLibraryFailed: (content) => ({
    type: actionTypes.SAVE_REFERENCE_LIBRARY_FAILURE,
    payload: content,
  }),
  // MANAGE_REFERENCE
  getManageReferenceLibrary: (data) => ({
    type: actionTypes.GET_MANAGE_REFERENCE_LIBRARY,
    data,
  }),
  getManageReferenceLibrarySucceeded: (content) => ({
    type: actionTypes.GET_MANAGE_REFERENCE_LIBRARY_SUCCESS,
    payload: content,
  }),
  getManageReferenceLibraryFailed: (content) => ({
    type: actionTypes.GET_MANAGE_REFERENCE_LIBRARY_FAILURE,
    payload: content,
  }),
  saveManageReferenceLibrary: (data) => ({
    type: actionTypes.SAVE_MANAGE_REFERENCE_LIBRARY,
    data,
  }),
  saveManageReferenceLibrarySucceeded: (content) => ({
    type: actionTypes.SAVE_MANAGE_REFERENCE_LIBRARY_SUCCESS,
    payload: content,
  }),
  saveManageReferenceLibraryFailed: (content) => ({
    type: actionTypes.SAVE_MANAGE_REFERENCE_LIBRARY_FAILURE,
    payload: content,
  }),
};
