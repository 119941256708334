export const actionTypes = {
  GET_IO_FORM: 'GET_IO_FORM',
  GET_IO_FORM_SUCCESS: 'GET_IO_FORM_SUCCESS',
  GET_IO_FORM_FAILURE: 'GET_IO_FORM_FAILURE',
  SAVE_IO_FORM: 'SAVE_IO_FORM',
  SAVE_IO_FORM_SUCCESS: 'SAVE_IO_FORM_SUCCESS',
  SAVE_IO_FORM_FAILURE: 'SAVE_IO_FORM_FAILURE',
  GET_IO_CHANGE: 'GET_IO_CHANGE',
  GET_IO_CHANGE_SUCCESS: 'GET_IO_CHANGE_SUCCESS',
  GET_IO_CHANGE_FAILURE: 'GET_IO_CHANGE_FAILURE',
  CLEAR_IO_FORM: 'CLEAR_IO_FORM',
  GET_IO_DROPDOWN: 'GET_IO_DROPDOWN',
  GET_IO_DROPDOWN_SUCCESS: 'GET_IO_DROPDOWN_SUCCESS',
  GET_IO_DROPDOWN_FAILURE: 'GET_IO_DROPDOWN_FAILURE',
};

export const actions = {
  getIOForm: (data) => ({
    type: actionTypes.GET_IO_FORM,
    data,
  }),
  getIOFormSucceeded: (content) => ({
    type: actionTypes.GET_IO_FORM_SUCCESS,
    payload: content,
  }),
  getIOFormFailed: (content) => ({
    type: actionTypes.GET_IO_FORM_FAILURE,
    payload: content,
  }),
  saveIOForm: (data) => ({
    type: actionTypes.SAVE_IO_FORM,
    data,
  }),
  saveIOFormSucceeded: (content) => ({
    type: actionTypes.SAVE_IO_FORM_SUCCESS,
    payload: content,
  }),
  saveIOFormFailed: (content) => ({
    type: actionTypes.SAVE_IO_FORM_FAILURE,
    payload: content,
  }),
  getIOChange: (data) => ({
    type: actionTypes.GET_IO_CHANGE,
    data,
  }),
  getIOChangeSucceeded: (content) => ({
    type: actionTypes.GET_IO_CHANGE_SUCCESS,
    payload: content,
  }),
  getIOChangeFailed: (content) => ({
    type: actionTypes.GET_IO_CHANGE_FAILURE,
    payload: content,
  }),
  clearIOForm: (content) => ({
    type: actionTypes.CLEAR_IO_FORM,
  }),
  getIODropdown: (content) => ({
    type: actionTypes.GET_IO_DROPDOWN,
    payload: content,
  }),
  getIODropdownSucceeded: (content) => ({
    type: actionTypes.GET_IO_DROPDOWN_SUCCESS,
    payload: content,
  }),
  getIODropdownFailed: (content) => ({
    type: actionTypes.GET_IO_DROPDOWN_FAILURE,
    payload: content,
  }),
};
