import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect, useDispatch } from 'react-redux';
import './style.css';
import { get } from 'lodash';
import { withRouter, useHistory, useLocation, useParams } from 'react-router-dom';
import { commonActions, projActivitiesActions } from '../../../redux/actions';
import { ICONS } from '../../../constants/assets';

function SelectMenu(props) {
  const [selectedOption, setSelectedOption] = useState(null);

  const [activityItems, setActivityItems] = useState();
  const [completedCount, setCompletedCount] = useState(0);
  const manHourListItems = get(props.manHourListItems, 'activityData', []);
  const isBudgetCompleted = get(props.selectedOption, 'isBudgetCompleted', false);

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const selectedDropdown = decodeURIComponent(queryParams.get('dropdown'));

  const setDropDownValuesInUrl = (key) => {
    const data = encodeURIComponent(key);
    if (selectedDropdown) {
      queryParams.set('dropdown', data);
    } else {
      queryParams.append('dropdown', data);
    }
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    getDropdownData();

    return () => {
      setSelectedOption(null);
      props.setSelectedOption(null);
    };
  }, []);

  useEffect(() => {
    setActivityItems(manHourListItems);
    const count = manHourListItems.filter((item) => item.isBudgetCompleted);

    const activeItems = manHourListItems.filter((item) => item.isActivityCompleted);
    /* istanbul ignore else */
    if (selectedDropdown) {
      const isFound = activeItems.filter((item) => item.dosActivity == selectedDropdown);
      /* istanbul ignore else */
      if (isFound.length > 0 && selectedOption == null) {
        handleSelectChange(isFound[0]);
        return;
      }
    }
    if (activeItems.length > 0 && selectedOption == null) {
      handleSelectChange(activeItems[0]);
    }
    setCompletedCount(count.length);
  }, [props.manHourListItems]);

  const selectStyle = {
    control: (base) => ({
      ...base,
      // This line disable the blue border
      border: '1px solid #ced4da',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #ced4da',
      },
    }),
  };
  const completeItem = (isComplete) => {
    const selectedOptionIndex = activityItems
      .map(function (e) {
        return e.dosActivity;
      })
      .indexOf(selectedOption.dosActivity);

    manHourListItems[selectedOptionIndex].isBudgetCompleted = isComplete;
    props.setSelectedOption(manHourListItems[selectedOptionIndex]);
    setSelectedOption(manHourListItems[selectedOptionIndex]);

    const payload = {
      projectNumber: props.projectDetails.projectNumber,
      // Manhour Budgets submenu id to indicate where this request is coming from, NOT which submenu data to fetch
      submenuId: 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b', // Manhour Budgets
      menuId: props.projectDetails.selectedMenu,
      infoData: manHourListItems,
    };
    props.completeListItem(payload);
  };

  const getDropdownData = () => {
    props.getManhourBudgetList({
      projectNumber: props.projectDetails.projectNumber,
      // Manhour Budgets submenu id to indicate where this request is coming from, NOT which submenu data to fetch
      submenuId: 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b', // Manhour Budgets
      menuId: props.projectDetails.selectedMenu,
      discipline: props.discipline,
    });
  };

  const handleSelectChange = (selected) => {
    getDropdownData();
    props.setSelectedOption(selected);
    setSelectedOption(selected);
    setDropDownValuesInUrl(selected.dosActivity);

    props.clearProjectActivityInformation();
    /* istanbul ignore else */
    if (selected) {
      props.getProjectActivityInformation({
        // intial table call
        // dosActivity: selected.dosActivity,
        refrenceKey: selected.refrenceKey,
        projectNumber: get(props.projectDetails, 'projectNumber', ''),
        submenuId: get(props.projectDetails, 'submenuId'),
        menuId: get(props.projectDetails, 'selectedMenu'),
        activitySubMenuId: '3bfdb421-64a3-4800-b476-7949ab9f58e3', // Define Activities submenu id
      });
    }
  };

  const renderEditButton = () => {
    if (props.isEditable) {
      return isBudgetCompleted ? (
        <button
          className="activityComplete activityEdit"
          onClick={() => completeItem(false)}
          disabled={props.editStatus}
        >
          <img src={ICONS.CHECK_CIRCLE_GREEN} />
          <span className="completeText">Edit</span>
        </button>
      ) : (
        <button className="activityComplete" onClick={() => completeItem(true)} disabled={!props.completeStatus}>
          <span className="circleBlue" />
          <span className="completeText">Mark as Completed</span>
        </button>
      );
    }
  };

  return (
    <div className="activitySelectContainer manhours">
      <div>
        <Select
          className="activitySelect "
          classNamePrefix="activitySelect"
          placeholder="Select Option"
          value={selectedOption} // set selected value
          options={activityItems} // set list of the data
          getOptionLabel={(option) => (
            <div className="selectOption">
              {option.dosActivity}
              {option.isBudgetCompleted ? <img src={ICONS.CHECK_CIRCLE_GREEN} /> : ''}
            </div>
          )}
          getOptionValue={(option) => option.refrenceKey}
          isOptionDisabled={(option) => !option.isActivityCompleted}
          onChange={handleSelectChange} // assign onChange function
          styles={selectStyle}
        />

        <p className="completedStatus">
          <span>{completedCount}</span>/{manHourListItems.length} Completed
        </p>

        {renderEditButton()}
      </div>
    </div>
  );
}

export const mapStateToProps = (state) => ({
  formEditing: get(state, 'commonData.formEditing'),
  projectDetails: get(state, 'projectSetupReducer.projectDetails', {}),
  manHourListItems: get(state, 'projActivitiesInformation.modifiedProjectActivityList'),
  isEditable: get(state, 'projActivitiesInformation.tableData.body.isEditable', false),
});

export const mapDispatchToProps = (dispatch) => ({
  clearProjectActivityInformation: () => dispatch(projActivitiesActions.clearProjActivityInfo()),
  getProjectActivityInformation: (data) => dispatch(projActivitiesActions.getProjActivityInfo(data)),
  getManhourBudgetList: (data) => dispatch(projActivitiesActions.getModifiedProjActivityList(data)),
  completeListItem: (data) => dispatch(projActivitiesActions.saveModifiedProjActivityList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectMenu);
