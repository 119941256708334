import { act } from 'react-test-renderer';

export const actionTypes = {
  GET_PROJ_ACTIVITY_INFO_FORM: 'GET_PROJ_ACTIVITY_INFO_FORM',
  GET_PROJ_ACTIVITY_INFO_FORM_SUCCESS: 'GET_PROJ_ACTIVITY_INFO_FORM_SUCCESS',
  GET_PROJ_ACTIVITY_INFO_FORM_FAILURE: 'GET_PROJ_ACTIVITY_INFO_FORM_FAILURE',
  SAVE_PROJ_ACTIVITY_INFO_FORM: 'SAVE_PROJ_ACTIVITY_INFO_FORM',
  SAVE_PROJ_ACTIVITY_INFO_FORM_SUCCESS: 'SAVE_PROJ_ACTIVITY_INFO_FORM_SUCCESS',
  SAVE_PROJ_ACTIVITY_INFO_FORM_FAILURE: 'SAVE_PROJ_ACTIVITY_INFO_FORM_FAILURE',
  GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE: 'GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE',
  GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS: 'GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS',
  GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE: 'GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE',
  SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE: 'SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE',
  SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS: 'SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS',
  SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE: 'SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE',
  GET_MODIFIED_PROJ_ACTIVITY_LIST: 'GET_MODIFIED_PROJ_ACTIVITY_LIST',
  GET_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS: 'GET_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS',
  GET_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE: 'GET_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE',
  SAVE_MODIFIED_PROJ_ACTIVITY_LIST: 'SAVE_MODIFIED_PROJ_ACTIVITY_LIST',
  SAVE_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS: 'SAVE_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS',
  SAVE_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE: 'SAVE_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE',
  GET_PROJ_ACTIVITY_TABLE_DATA: 'GET_PROJ_ACTIVITY_TABLE_DATA',
  SET_PROJ_ACTIVITY_TABLE_DATA: 'SET_PROJ_ACTIVITY_TABLE_DATA',
  GET_PROJ_ACTIVITY_TABLE_DATA_SUCCESS: 'GET_PROJ_ACTIVITY_TABLE_DATA_SUCCESS',
  GET_PROJ_ACTIVITY_TABLE_DATA_FAILURE: 'GET_PROJ_ACTIVITY_TABLE_DATA_FAILURE',
  SAVE_PROJ_ACTIVITY_TABLE_DATA: 'SAVE_PROJ_ACTIVITY_TABLE_DATA',
  SAVE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS: 'SAVE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS',
  SAVE_PROJ_ACTIVITY_TABLE_DATA_FAILURE: 'SAVE_PROJ_ACTIVITY_TABLE_DATA_FAILURE',
  DELETE_PROJ_ACTIVITY_TABLE_DATA: 'DELETE_PROJ_ACTIVITY_TABLE_DATA',
  DELETE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS: 'DELETE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS',
  DELETE_PROJ_ACTIVITY_TABLE_DATA_FAILURE: 'DELETE_PROJ_ACTIVITY_TABLE_DATA_FAILURE',
  CLEAR_PROJ_ACTIVITY_INFO_FORM: 'CLEAR_PROJ_ACTIVITY_INFO_FORM',
  GET_MAN_HOUR_BUDGET_LIST: 'GET_MAN_HOUR_BUDGET_LIST',
  GET_MAN_HOUR_BUDGET_LIST_SUCCESS: 'GET_MAN_HOUR_BUDGET_LIST_SUCCESS',
  GET_MAN_HOUR_BUDGET_LIST_FAILURE: 'GET_MAN_HOUR_BUDGET_LIST_FAILURE',
  GET_MAN_HOUR_BUDGET_TABLE: 'GET_MAN_HOUR_BUDGET_TABLE',
  GET_MAN_HOUR_BUDGET_TABLE_SUCCESS: 'GET_MAN_HOUR_BUDGET_TABLE_SUCCESS',
  GET_MAN_HOUR_BUDGET_TABLE_FAILURE: 'GET_MAN_HOUR_BUDGET_TABLE_FAILURE',
  SAVE_MAN_HOUR_BUDGET_LIST: ' SAVE_MAN_HOUR_BUDGET_LIST',
  COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM: 'COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM',
  COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_SUCCESS: 'COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_SUCCESS',
  COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_FAILURE: 'COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_FAILURE',
  COMPLETE_DEFINE_ACTIVITY: 'COMPLETE_DEFINE_ACTIVITY',
  COMPLETE_DEFINE_ACTIVITY_SUCCESS: 'COMPLETE_DEFINE_ACTIVITY_SUCCESS',
  COMPLETE_DEFINE_ACTIVITY_FAILURE: 'COMPLETE_DEFINE_ACTIVITY_FAILURE',
  PROJ_ACTIVITY_TABLE_DATA_STATUS: 'PROJ_ACTIVITY_TABLE_DATA_STATUS',
};

export const actions = {
  getProjActivityInfo: (data) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_FORM,
    data,
  }),
  getProjActivityInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getProjActivityInfoFormFailed: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_FORM_FAILURE,
    payload: content,
  }),
  clearProjActivityInfo: (data) => ({
    type: actionTypes.CLEAR_PROJ_ACTIVITY_INFO_FORM,
    data,
  }),
  saveProjActivityInfoForm: (data) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM,
    data,
  }),
  saveProjActivityInfoFormSucceeded: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM_SUCCESS,
    payload: content,
  }),
  saveProjActivityInfoFormFailed: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM_FAILURE,
    payload: content,
  }),
  getProjActivityByDiscipline: (data) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE,
    data,
  }),
  getProjActivityByDisciplineSucceeded: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS,
    payload: content,
  }),
  getProjActivityByDisciplineFailed: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE,
    payload: content,
  }),
  getModifiedProjActivityList: (data) => ({
    type: actionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST,
    data,
  }),
  getModifiedProjActivityListSucceeded: (content) => ({
    type: actionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS,
    payload: content,
  }),
  getModifiedProjActivityListFailed: (content) => ({
    type: actionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE,
    payload: content,
  }),
  saveModifiedProjActivityList: (data) => ({
    type: actionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST,
    data,
  }),
  saveModifiedProjActivityListSucceeded: (content) => ({
    type: actionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST_SUCCESS,
    payload: content,
  }),
  saveModifiedProjActivityListFailed: (content) => ({
    type: actionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST_FAILURE,
    payload: content,
  }),
  getProjActivityTableData: (data) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_TABLE_DATA,
    data,
  }),
  setProjActivityTableData: (data) => ({
    type: actionTypes.SET_PROJ_ACTIVITY_TABLE_DATA,
    data,
  }),
  getProjActivityTableDataSuccess: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_TABLE_DATA_SUCCESS,
    payload: content,
  }),
  getProjActivityTableDataFailure: (content) => ({
    type: actionTypes.GET_PROJ_ACTIVITY_TABLE_DATA_FAILURE,
    payload: content,
  }),
  saveProjActivityTableData: (data) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA,
    data,
  }),
  saveProjActivityTableDataSuccess: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS,
    payload: content,
  }),
  saveProjActivityTableDataFailure: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA_FAILURE,
    payload: content,
  }),
  saveProjActivityByDiscipline: (data, metaInfo, setShowModal) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE,
    data,
    metaInfo,
    setShowModal,
  }),
  saveProjActivityByDisciplineSucceeded: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_SUCCESS,
    payload: content,
  }),
  saveProjActivityByDisciplineFailed: (content) => ({
    type: actionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE_FAILURE,
    payload: content,
  }),
  getManHourBudgetList: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_LIST,
    payload: content,
  }),
  getManHourBudgetListSuccess: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_LIST_SUCCESS,
    payload: content,
  }),
  getManHourBudgetListFaliure: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_LIST_FAILURE,
    payload: content,
  }),
  getManHourBudgetTable: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_TABLE,
    payload: content,
  }),
  getManHourBudgetTableSuccess: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_TABLE_SUCCESS,
    payload: content,
  }),
  getManHourBudgetTableFaliure: (content) => ({
    type: actionTypes.GET_MAN_HOUR_BUDGET_TABLE_FAILURE,
    payload: content,
  }),
  completeManHourBudgetListItem: (content) => ({
    type: actionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM,
    payload: content,
  }),
  completeManHourBudgetListItemSuccess: (content) => ({
    type: actionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_SUCCESS,
    payload: content,
  }),
  completeManHourBudgetListItemFaliure: (content) => ({
    type: actionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM_FAILURE,
    payload: content,
  }),
  completeDefineActivity: (data) => ({
    type: actionTypes.COMPLETE_DEFINE_ACTIVITY,
    data,
  }),
  completeDefineActivitySuccess: (content) => ({
    type: actionTypes.COMPLETE_DEFINE_ACTIVITY_SUCCESS,
    payload: content,
  }),
  completeDefineActivityFaliure: (content) => ({
    type: actionTypes.COMPLETE_DEFINE_ACTIVITY_FAILURE,
    payload: content,
  }),
  deleteProjActivityTableData: (data) => ({
    type: actionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA,
    data,
  }),
  deleteProjActivityTableDataSuccess: (content) => ({
    type: actionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA_SUCCESS,
    payload: content,
  }),
  deleteProjActivityTableDataFaliure: (content) => ({
    type: actionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA_FAILURE,
    payload: content,
  }),
  projActivityTableDataStatus: (content) => ({
    type: actionTypes.PROJ_ACTIVITY_TABLE_DATA_STATUS,
    payload: content,
  }),
};
