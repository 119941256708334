export const actionTypes = {
  GET_PLANS: 'GET_PLANS',
  GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
  GET_PLANS_FAILURE: 'GET_PLANS_FAILURE',
  GET_PROJECT_PLAN_DATA: 'GET_PROJECT_PLAN_DATA',
  GET_PROJECT_PLAN_DATA_ALL: 'GET_PROJECT_PLAN_DATA_ALL',
  GET_FILTER_DATA: 'GET_FILTER_DATA',
  GET_FILTER_DATA_SUCCESS: 'GET_FILTER_DATA_SUCCESS',
  GET_FILTER_DATA_FAILURE: 'GET_FILTER_DATA_FAILURE',
  SAVE_DISCIPLINE_DATA: 'SAVE_DISCIPLINE_DATA',
  SAVE_DISCIPLINE_DATA_SUCCESS: 'SAVE_DISCIPLINE_DATA_SUCCESS',
  SAVE_DISCIPLINE_DATA_FAILURE: 'SAVE_DISCIPLINE_DATA_FAILURE',
  GET_PLAN_COMPLETE_REFERENCE: 'GET_PLAN_COMPLETE_REFERENCE',
  GET_PLAN_COMPLETE_REFERENCE_SUCCESS: 'GET_PLAN_COMPLETE_REFERENCE_SUCCESS',
};

export const actions = {
  getPlans: (data) => ({
    type: actionTypes.GET_PLANS,
    data,
  }),
  getViewPlanReference: (data) => ({
    type: actionTypes.GET_PLAN_COMPLETE_REFERENCE,
    data,
  }),
  getPlansSuccess: (content) => ({
    type: actionTypes.GET_PLANS_SUCCESS,
    payload: content,
  }),
  getPlansFaliure: (content) => ({
    type: actionTypes.GET_PLANS_FAILURE,
    payload: content,
  }),
  getProjectPlanData: (data) => ({
    type: actionTypes.GET_PROJECT_PLAN_DATA,
    payload: data,
  }),
  getProjectPlanDataAll: (data) => ({
    type: actionTypes.GET_PROJECT_PLAN_DATA_ALL,
    payload: data,
  }),
  getFilterData: (data) => ({
    type: actionTypes.GET_FILTER_DATA,
    data,
  }),
  getFilterDataSuccess: (content) => ({
    type: actionTypes.GET_FILTER_DATA_SUCCESS,
    payload: content,
  }),
  getFilterDataFailure: (content) => ({
    type: actionTypes.GET_FILTER_DATA_FAILURE,
    payload: content,
  }),
  saveDisciplineData: (data) => ({
    type: actionTypes.SAVE_DISCIPLINE_DATA,
    data,
  }),
  saveDisciplineDataSuccess: (content) => ({
    type: actionTypes.SAVE_DISCIPLINE_DATA_SUCCESS,
    payload: content,
  }),
  saveDisciplineDataFailure: (content) => ({
    type: actionTypes.SAVE_DISCIPLINE_DATA_FAILURE,
    payload: content,
  }),
  getViewPlanReferenceSuccess: (content) => ({
    type: actionTypes.GET_PLAN_COMPLETE_REFERENCE_SUCCESS,
    payload: content,
  }),
};
