import { get } from 'lodash';
import { touch } from 'redux-form';

export const projectActivitiesValidation = (values, props) => {
  const tableData = values.projectActivityTable;
  const fieldMap = get(props.projectActivityTableData, 'fields', []);

  const errors = {};
  if (tableData?.length) {
    const rowArrayErrors = [];

    const rowErrors = {};

    const firstRow = get(props.projectActivityTableData, 'firstRow[0]', []);

    Object.keys(firstRow).forEach(function (key, index) {
      if (key != 'dosActivity' && key != 'totalENGHours' && key != 'totalDSGNHours') {
        const totalHours = tableData.reduce(function (prev, current) {
          return prev + (isNaN(current[key]) ? 0 : Number(current[key]));
        }, 0);

        // all fields in the column set touch to trigger error
        if (totalHours != Number(firstRow[key])) {
          tableData.map((row, rowIndex) => {
            rowErrors[key] = ' ';
            rowArrayErrors[rowIndex] = rowErrors;

            if (row[key] != '') {
              props.dispatch(touch('ProjActivitiesTable', `projectActivityTable[${rowIndex}]${key}`));
            }
          });
        }
      }
    });

    tableData.map((row, rowIndex) => {
      const rowErrors = {};
      fieldMap.map((field) => {
        const { validation } = field;
        const currentFieldValue = row[field.key];
        if (
          currentFieldValue &&
          validation.maxLength !== undefined &&
          typeof currentFieldValue === 'string' &&
          currentFieldValue.length > validation.maxLength
        ) {
          rowErrors[field.key] = `Value length should be less than ${validation.maxLength}`;
          rowArrayErrors[rowIndex] = rowErrors;
        }

        if (currentFieldValue && field.type === 'number' && validation.maxDecimal !== undefined) {
          const decimalExp =
            validation.maxDecimal == 0
              ? 'd*$' // for non decimal
              : `(.[0-9]{1,${validation.maxDecimal}})?$`; // for decimal
          const decimalRegexp = new RegExp(`^[0-9]+${decimalExp}`);

          if (!decimalRegexp.test(currentFieldValue)) {
            rowErrors[field.key] =
              validation.maxDecimal == 0 ? 'No Decimal Values' : `Valid up to ${validation.maxDecimal} decimal points`;
            rowArrayErrors[rowIndex] = rowErrors;
          }
        }

        if (currentFieldValue) {
          if (parseFloat(currentFieldValue) < parseFloat(0)) {
            rowErrors[field.key] = 'Do not enter negative values';
            rowArrayErrors[rowIndex] = rowErrors;
          }
        }
      });
    });

    if (rowArrayErrors.length) {
      errors.projectActivityTable = rowArrayErrors;
    }
  }

  return errors;
};
