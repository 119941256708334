import React from 'react';
import ToogleButton from '../../common/toggleButton';
import { cloneDeep } from 'lodash';
import './styles.scss';

function WbsCriteriaRow(props) {
  const [tableData, setTableData] = React.useState([]);
  const [clonedTableData, setClonedTableData] = React.useState([]);

  React.useEffect(() => {
    if (props.dosActivityData) {
      const { freshData, activityData } = props.dosActivityData;

      activityData.length > 0 &&
        freshData &&
        activityData.map((activity) => {
          activity.primary = {
            value: activity.primaryWBSDOS,
            show: activity.primaryWBSDOS ? true : false,
            checked: activity.primaryWBSDOS ? true : false,
            disabled: false,
          };
          activity.secondary = {
            value: activity.secondaryWBSDOS,
            show: activity.secondaryWBSDOS ? true : false,
            checked: activity.secondaryWBSDOS ? true : false,
            disabled: false,
          };
          activity.terciary = {
            value: activity.terciaryWBSDOS,
            show: activity.terciaryWBSDOS ? true : false,
            checked: activity.terciaryWBSDOS ? true : false,
            disabled: false,
          };
          activity.isBudgetCompleted = false;
          activity.isActivityCompleted = false;
        });

      setTableData(activityData);
      setClonedTableData(cloneDeep(activityData));
    }
  }, [props.dosActivityData]);

  const refactorValue = (value) => value.split(' ')[0];

  const renderToggleButton = (refrenceKey, { value, checked, disabled }, additionalInfo, index) => (
    <ToogleButton
      id={refrenceKey + additionalInfo}
      label={value}
      checked={checked}
      handleValue={handleValue}
      reference={{ additionalInfo, index }}
      disabled={disabled}
    />
  );

  const handleValue = (reference, event) => {
    const { index, additionalInfo } = reference;
    const checked = event.target.checked;
    const newTableData = [...tableData];
    newTableData[index][additionalInfo].checked = checked;

    const secondary = newTableData[index]['secondary'];
    const terciary = newTableData[index]['terciary'];

    if (additionalInfo === 'primary' && secondary.show) {
      handleValue({ index, additionalInfo: 'secondary' }, { target: { checked } });
      secondary.disabled = !checked ? true : false;
      if (checked) newTableData[index]['secondary'] = clonedTableData[index]['secondary'];
    } else if (additionalInfo === 'secondary' && terciary.show) {
      handleValue({ index, additionalInfo: 'terciary' }, { target: { checked } });
      terciary.disabled = !checked ? true : false;
      if (checked) newTableData[index]['terciary'] = clonedTableData[index]['terciary'];
    }

    setTableData(newTableData);
  };

  return tableData.map((row, index) => (
    <tr key={index}>
      <td className="defineActivity-table-cell-main">{row.dosActivity}</td>
      <td className="defineActivity-table-cell-side">
        {row.primary.show && renderToggleButton(row.refrenceKey, row.primary, 'primary', index)}
      </td>
      <td className="defineActivity-table-cell-side">
        {row.secondary.show && renderToggleButton(row.refrenceKey, row.secondary, 'secondary', index)}
      </td>
      <td className="defineActivity-table-cell-side">
        {row.terciary.show && renderToggleButton(row.refrenceKey, row.terciary, 'terciary', index)}
      </td>
    </tr>
  ));
}

export default WbsCriteriaRow;
