import { put, takeEvery, call } from 'redux-saga/effects';
import get from 'lodash/get';
import AxiosInstance from '../../services/commons/Interceptor';
import servicelist from '../../services/serviceList';
import { assignActions, assignActionTypes, commonActions } from '../actions';

export function* getassignLeadsInformInfoFormSaga(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('ProjectForm'), {
      params: payload.data,
    });
    // const responseData = wbsInfoFormMock;
    yield put(
      assignActions.getassignLeadsInfoFormSucceeded({
        isSucceeded: true,
        addingLeadsData: responseData.data,
      })
    );
    // yield put(commonActions.showLoader(false));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // setting wbs data as null to remove previous success response if there's any
    yield put(
      assignActions.getassignLeadsInfoFormFailed({
        isSucceeded: false,
        addingLeadsData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchAssignLeadsInformInfoFormSaga() {
  yield takeEvery([assignActionTypes.GET_ASSIGN_LEADS_INFO_FORM], getassignLeadsInformInfoFormSaga);
}

export function* getassignLeadsFetchInitialData(payload) {
  // try catch method instead of if and else due to failing of saga from 500 internal error
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('leadList'), {
      params: payload.data,
    });
    yield put(assignActions.fetchInitialUsers(responseData.data));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    // cleanup data
    yield put(assignActions.fetchInitialUsers([]));
  }
  yield put(commonActions.showLoader(false));
}

export function* watchAssignLeadsfetchInitialSaga() {
  yield takeEvery([assignActionTypes.GET_ASSIGN_LEADS_INITIAL], getassignLeadsFetchInitialData);
}

export function* getassignedRolesFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('assignedRoles'), {
      params: {
        projectNumber: payload.data.projectNumber,
        userEmail: payload.data.userEmail,
        roleName: payload.data.roleName,
        isCustomRole: payload.data.isCustomRole,
      },
    });
    yield put(
      assignActions.getassignRolesSucceeded({
        isSucceeded: true,
        addingRolesData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      assignActions.getassignRolesFailed({
        isSucceeded: false,
        addingRolesData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchAddingRolesFromSaga() {
  yield takeEvery([assignActionTypes.GET_ASSIGN_ROLES], getassignedRolesFromSaga);
}

export function* saveassignedRolesFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.put, servicelist('saveassignedRoles'), payload.data);
    yield put(
      assignActions.saveassignRolesSuccess({
        isSucceeded: true,
        savedRolesData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      assignActions.saveassignRolesFailed({
        isSucceeded: false,
        savedRolesData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* watchSavingRolesFromSaga() {
  yield takeEvery([assignActionTypes.SAVE_ASSIGN_ROLES], saveassignedRolesFromSaga);
}

export function* getAddRolesMasterFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getAddRoleMaster'), {
      params: {
        menuId: '74e89f2e-c55f-4dce-8939-8f858661a139',
        submenuId: 'a8c17167-3feb-4518-b3d5-4b985e572fdc',
      },
    });
    yield put(
      assignActions.getAddRoleMasterSuccess({
        isSucceeded: true,
        addRoleDataMaster: responseData.data[0],
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      assignActions.getAddRoleMasterFailure({
        isSucceeded: false,
        addRoleDataMaster: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getAddRoleMaterStatus() {
  yield takeEvery([assignActionTypes.GET_ADD_ROLE_MASTER], getAddRolesMasterFromSaga);
}

export function* getAddRolesFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('getAddRole'), {
      params: {
        projectNumber: payload.payload.projectNumber,
        menuId: payload.payload.menuId,
        submenuId: payload.payload.submenuId,
      },
    });
    yield put(
      assignActions.getAddRoleSuccess({
        isSucceeded: true,
        addRoleData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      assignActions.getAddRoleFailure({
        isSucceeded: false,
        addRoleData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getAddRoleStatus() {
  yield takeEvery([assignActionTypes.GET_ADD_ROLE], getAddRolesFromSaga);
}

export function* saveRolesFromSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const payloadData = payload.payload.postData || {};
    const responseData = yield call(AxiosInstance.put, servicelist('saveRoleFields'), payloadData);

    yield put(
      assignActions.getassignLeadsInitialData({
        menuId: payloadData.menuId,
        submenuId: payloadData.submenuId,
        projectNumber: payloadData.projectNumber,
      })
    );

    yield put(
      commonActions.infoPopup({
        messageId: 'Role added',
        theme: 'alert-success',
      })
    );
  } catch (e) {
    yield put(
      assignActions.saveRoleFieldsFailure({
        isSucceeded: false,
        saveRoleData: null,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: e.response?.data?.msg || 'Request failed',
        theme: 'alert-danger',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveRoleDataStatus() {
  yield takeEvery([assignActionTypes.SAVE_ROLE_DATA], saveRolesFromSaga);
}
