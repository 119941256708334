import React from 'react';
import Select from 'react-select';
import { get, isArray } from 'lodash';

const BVAutoSelectField = (props) => {
  const {
    input,
    suggestions,
    disabled,
    placeholder,
    className,
    classNamePrefix,
    checked,
    labelKey,
    multipleValues,
    meta,
    onFieldChange,
    onFieldSelect,
    onOptionChange,
    errorText,
    selectedRoleItem,
    isSearchable,
    menuPlacement,
    isPortal,
    isClearable,
    customMultiSelectStyles,
    setValue,
    ...custom
  } = props;
  const mounted = React.useRef();

  const valueKey = props.valueKey ? props.valueKey : 'value';

  // hooks update and mount life cycle
  React.useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else if (!checked && input.onChange) {
      input.onChange('');
    }
  }, [checked]);

  const handleChange = (selectedOption) => {
    if (input.onChange) {
      if (multipleValues) {
        const listOfValues = [];
        selectedOption && selectedOption.map((obj) => listOfValues.push(obj[valueKey]));
        input.onChange(listOfValues);
      } else {
        input.onChange(selectedOption ? selectedOption[valueKey] : ''); // for clearing select selected Options is null
        if (props.onFieldChange) {
          onFieldChange(selectedOption[valueKey], selectedRoleItem);
        }
        if (onOptionChange) {
          onOptionChange(selectedOption);
        }
      }
    }
  };

  const handleInputValue = (input) => {
    if (multipleValues) {
      const tempValues = [];
      const tempInput = Array.isArray(input) ? input : typeof input === 'string' ? input.split() : [];

      tempInput &&
        (Array.isArray(tempInput) && tempInput).map(
          (val) =>
            suggestions &&
            Array.isArray(suggestions) &&
            suggestions.map((option) => {
              (option.value === val || option.key === val) && tempValues.push(option);
            })
        );
      return tempValues;
    }
    const formattedOptions = {};
    suggestions &&
      isArray(suggestions) &&
      suggestions.forEach((obj) => {
        formattedOptions[obj[valueKey]] = obj;
      });
    return formattedOptions[input];
  };

  const handleInputBlur = (input) => {
    if (input) {
      if (get(input, 'onBlur', false)) {
        return input.onBlur(input.value);
      }
      return input.value;
    }
    return '';
  };

  const isError = meta && meta.touched && meta.error ? meta.error : errorText || '';
  return (
    <>
      <Select
        // {...input}
        onChange={(event) => {
          input.onChange = event;
        }}
        isMulti={multipleValues}
        menuPlacement={menuPlacement || 'bottom'}
        menuPortalTarget={isPortal ? null : document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          ...customMultiSelectStyles,
        }}
        // handling for without reduxform BVAutoSelectField
        // value={get(input, 'value', false) ? handleInputValue(input.value) : ''}
        value={
          setValue && setValue.key && suggestions
            ? suggestions?.find((c) => c.key === setValue.key)
            : get(input, 'value', false)
            ? handleInputValue(input.value)
            : ''
        }
        onBlur={() => handleInputBlur(input)}
        onChange={handleChange}
        isClearable={isClearable}
        options={suggestions}
        isOptionDisabled={(suggestion) => get(suggestion, 'disabled', false) === true}
        getOptionLabel={(option) => (labelKey ? option[labelKey] : option.label || option.name)} // if label is not specified it will render options based on label key in options
        getOptionValue={(option) => (valueKey ? option[valueKey] : option.value)}
        isDisabled={disabled}
        placeholder={placeholder}
        className={className}
        classNamePrefix={classNamePrefix}
        isSearchable={isSearchable === undefined ? true : isSearchable}
        {...custom}
      />
      {isError && (
        <span className="errorHelperText">
          {isError} {/* For error text */}
        </span>
      )}
    </>
  );
};

BVAutoSelectField.defaultProps = {
  isPortal: false,
  customMultiSelectStyles: {},
};

export default BVAutoSelectField;
