import { useQuery } from 'react-query';
import servicelist from '../../../services/serviceList';
import AxiosInstance from '../../../services/commons/Interceptor';

async function fetchProjectInfo({ projectNumber }) {
  const apiResponse = await AxiosInstance.get(servicelist('getProjectInfo'), {
    params: {
      projectNumber,
    },
  });
  return apiResponse;
}

function useGetProjectInfo({ projectNumber }) {
  return useQuery(
    ['ProjectInfo', projectNumber],
    async () => {
      if (!projectNumber) return;
      const result = await fetchProjectInfo({
        projectNumber,
      });
      return result?.data;
    },
    {
      refetchInterval: 30 * 1000,
    }
  );
}
export default useGetProjectInfo;
