import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { authActions, commonActions } from '../../redux/actions';
import { msalInstance } from '../../constants/config/msalConfig';
import { LOGIN_TYPES } from '../../constants/common';
import './styles.scss';

function Logout(props) {
  const { isLoggedIn, loginType, logoutAuth, msalLogoutAuth } = props;

  const logout = async () => {
    if (props.formEditing || props.modalPopupIsEdited) {
      props.sendMessageDispatch({
        messageId: 'Save the form before signing out',
        theme: 'alert-danger',
      });
    } else {
      localStorage.clear();
      // Get account for AD Login
      const account = msalInstance.getAccount();
      // For now, we call the msalLogoutAuth for both SSO Login and AD Login
      await msalLogoutAuth();
      // If loginType is ADLogin and an accountName exists
      if (account) {
        await msalInstance.clearCache();
        await msalInstance.logout({
          account: msalInstance.getAccount(),
        });
      }
    }
    // Note: we will now be having only AD Login for UAT and SSO and AD Login for both DEV and SIT.
    // TODO: refactor and perform code cleanup Post MVP
    // Move LoginTypes to backend, so on page refresh the state is not lost
    // if (loginType === LOGIN_TYPES.AD_LOGIN) {
    //   const account = msalInstance.getAccount();
    //   await msalLogoutAuth();
    //   if (account) {
    //     await msalInstance.clearCache();
    //     await msalInstance.logout({
    //       account: msalInstance.getAccount(),
    //     });
    //   }
    // } else if (loginType === LOGIN_TYPES.SSO_LOGIN) {
    //   await logoutAuth();
    // }
  };

  return (
    <button
      type="button"
      className="user-sign-out"
      onClick={() => {
        logout();
      }}
    >
      Sign Out
    </button>
  );
}

Logout.propTypes = {};

const mapStateToProps = (state) => ({
  isLoggedIn: get(state, 'authReducer.isLoggedIn'),
  loginType: get(state, 'authReducer.loginType'),
  formEditing: get(state, 'commonData.formEditing', false),
  modalPopupIsEdited: get(state, 'commonData.modalPopup.isEdited', false),
});

const mapDispatchToProps = (dispatch) => ({
  logoutAuth: () => dispatch(authActions.logoutAuth()),
  msalLogoutAuth: () => dispatch(authActions.msalLogoutAuth()),
  sendMessageDispatch: (data) => dispatch(commonActions.infoPopup(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
