import React, { useState, useEffect, useRef } from 'react';
import { NavLink, withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isArray, trim } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';

import Slider from 'react-slick';
import { ICONS, TOP_MENU } from '../../constants/assets';
import { projectSetupActions, commonActions } from '../../redux/actions';
import GigActivityBreadCrumb from '../gigWorkflow/common/gigActivityBreadCrumb';

import BreadCrumb from '../breadCrumb';
import './styles.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProjectTopbar(props) {
  const [menuList, setMenuList] = useState([]);
  const { menuListData, projectDetails, projectNumber, projectName } = props;

  const [windowWidth, setWindowWidth] = useState(12);
  const menuRef = useRef(null);
  let baseProjectUrl = trim(window.location.pathname, '/').split('/')[0];
  baseProjectUrl = baseProjectUrl ? `${baseProjectUrl}/` : '/projectSetup/';

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setMenuList(get(menuListData, 'menuItems', []));
    queryParams.delete('tabs');
  }, [menuListData, projectDetails.selectedMenu]);

  useEffect(() => {
    setWindowWidth(window.innerWidth > 0 ? window.innerWidth : screen.width);
  }, []);

  const afterChange = (current) => {
    props.onClickMenu({ menuIndex: current });
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    arrows: true,
    autoPlay: false,
    infinite: false,
    variableWidth: true,
    afterChange: (current) => afterChange(current),
  };

  useEffect(() => {
    if (!get(projectDetails, 'selectedMenu', '')) {
      if (get(menuList, '[0].id', '')) {
        // selecting the frst menu id and assigning it in the store
        props.onClickMenu({ selectedMenu: get(menuList, '[0].id', '') });
        // routing the first selected Menu
        props.history.push(`/${baseProjectUrl + TOP_MENU[get(menuList, '[0].id')].url}/${projectNumber}`);
      }
    } else if (menuRef.current) {
      menuRef.current.slickGoTo(projectDetails.menuIndex);
    }
  }, [menuList, projectDetails.selectedMenu]);

  const renderMenuList = () =>
    isArray(menuList) &&
    menuList.map((data, index) => (
      <div className="project-step col" onClick={() => menuClick(data)} key={index}>
        {TOP_MENU[data.id] != undefined ? (
          <NavLink
            // Added to decide active menu (Bug: 71193)
            isActive={() => projectDetails.selectedMenu === data.id}
            to={
              props.formEditing || data.isActive === false || get(props.modalPopup, 'isEdited', false)
                ? '#'
                : `/${baseProjectUrl + TOP_MENU[data.id].url}/${projectNumber}`
            }
            activeClassName="active"
            className={`nav-menu-link ${data.isActive === false ? ' disable' : ''}`}
          >
            <div className="image-container">
              <span className="notificationContainer" id={`UncontrolledTooltip${index}`}>
                {TOP_MENU[data.id].url == props.notificationMenu && props.notificationCount > 0 ? (
                  <span className="notificationCount">{props.notificationCount}</span>
                ) : TOP_MENU[data.id].url == props.notificationMenuNonLaminar &&
                  props.notificationCountNonLaminar > 0 ? (
                  <span className="notificationCount">{props.notificationCountNonLaminar}</span>
                ) : (
                  ''
                )}
                <img src={getMenuIcon(data)} />
              </span>
            </div>
            {data.desc && (
              <UncontrolledTooltip placement="top" target={`UncontrolledTooltip${index}`} className="header-tooltip">
                {data.desc}
              </UncontrolledTooltip>
            )}
            <div
              className={`step-title ${
                get(data, 'menuName', '').length > 25
                  ? 'large-title'
                  : get(data, 'menuName', '').length > 17
                  ? 'custom-step-title'
                  : ''
              }`}
            >
              {data.menuName}
            </div>
          </NavLink>
        ) : (
          <NavLink
            // Added to decide active menu (Bug: 71193)
            isActive={() => projectDetails.selectedMenu === data.id}
            to="#"
            activeClassName="active"
            className="nav-menu-link"
          >
            <div
              className={`image-container ${data.isActive === false ? ' disable' : ''}`}
              id={`UncontrolledTooltip${index}`}
            >
              <img src={data.isComplete ? ICONS.COMPLETED : ICONS.DEFAULT} />
            </div>
            <UncontrolledTooltip placement="top" target={`UncontrolledTooltip${index}`}>
              {data.desc}
            </UncontrolledTooltip>
            <div className="step-title">{data.menuName}</div>
          </NavLink>
        )}
      </div>
    ));

  const menuClick = (data) => {
    if (props.formEditing) {
      // If the selected item is Master Data QA, then we display the former message, else the latter.
      const messageId =
        props.notificationMenu === 'masterDataQA'
          ? 'Apply to Master Database before leaving the page'
          : 'Save the form before leaving the page';
      const messageDispatchProperties = { messageId, theme: 'alert-danger' };
      props.sendMessageDispatch(messageDispatchProperties);
    } else if (get(props.modalPopup, 'isEdited', false)) {
      props.modalPopupDispatch({
        showModal: true,
        isEdited: true,
        menu: data,
      });
    } else if (data.isActive !== false) {
      props.onClickMenu({
        selectedMenu: data.id,
        submenuName: '',
      });
    }
  };

  const getMenuIcon = (data) => {
    if (data.isComplete) {
      return ICONS.COMPLETED;
    }
    if (location.pathname === `/${baseProjectUrl + TOP_MENU[data.id].url}/${projectNumber}`) {
      return TOP_MENU[data.id].iconActive;
    }
    if (data.isActive === false && TOP_MENU[data.id].iconMuted !== undefined) {
      return TOP_MENU[data.id].iconMuted;
    }
    return TOP_MENU[data.id].icon;
  };

  return (
    <div
      ref={props?.topBarRef ? props?.topBarRef : null}
      className={`project-header ${
        props?.currentPage === 'Engineering Execution' || projectDetails.stateKey === 'dataManager'
          ? 'engineering-execution'
          : 'project-setup'
      } ${projectDetails.stateKey === 'dataManager' ? 'data-manager' : ''}`}
    >
      <div className="project-topbar">
        <div className="row">
          <div className="left-section col-md-3">
            {projectDetails.stateKey === 'dataManager' ? (
              <div className="d-flex align-items-center" style={{ height: '100%' }}>
                <span className="project-topbar-title">Master Data Manager</span>
              </div>
            ) : (
              <div className="project-topbar-title">
                {props?.currentPage === 'Engineering Execution' ? 'Engineering Execution' : 'Project Set-Up'}
              </div>
            )}
            {projectDetails.stateKey !== 'dataManager' && (
              <>
                <div className="project-title">{projectDetails.projectName || projectName}</div>
                <div className="project-id">{projectDetails.projectNumber}</div>
              </>
            )}
          </div>
          <div className="right-section col-md-9">
            {windowWidth < 1490 && isArray(menuList) && menuList.length > 9 ? (
              <div className="slider-block">
                <Slider {...settings} ref={menuRef}>
                  {' '}
                  {renderMenuList()}
                </Slider>
              </div>
            ) : (
              <div className="row">{renderMenuList()}</div>
            )}
          </div>
        </div>
      </div>
      {typeof props.showBreadCrumb !== 'undefined' && props.showBreadCrumb === false ? null : (
        <BreadCrumb currentPage={props.currentPage} />
      )}
      {props.showGigActivityBreadCrumb && (
        <GigActivityBreadCrumb currentPage={props.currentStep} onNavigation={(page) => props.setCurrentPage(page)} />
      )}
    </div>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  getProjectMenu: (projectDetails) => dispatch(projectSetupActions.getProjectMenu(projectDetails)),
  onClickMenu: (data) => dispatch(projectSetupActions.projectDetails(data)),
  sendMessageDispatch: (data) => dispatch(commonActions.infoPopup(data)),
  modalPopupDispatch: (data) => dispatch(commonActions.modalPopup(data)),
});

export const mapStateToProps = (state) => ({
  menuListData: get(state, 'projectSetupReducer.menuList', {}),
  projectName: get(state, 'projectSetupReducer.menuList.projectName', ''),
  projectDetails: get(state, 'projectSetupReducer.projectDetails', {}),
  projectNumber: get(state, 'projectSetupReducer.projectDetails.projectNumber', ''),
  formEditing: state.commonData && state.commonData.formEditing,
  modalPopup: state.commonData && state.commonData.modalPopup,
  notificationCount: get(state, 'commonData.notificationCount.count'),
  notificationMenu: get(state, 'commonData.notificationCount.menu'),
  notificationCountNonLaminar: get(state, 'commonData.notificationCountNonLaminar.count'),
  notificationMenuNonLaminar: get(state, 'commonData.notificationCountNonLaminar.menu'),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectTopbar));
