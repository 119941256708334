export const actionTypes = {
    GET_ASSUMPTION_ARCHIVE_TABLE: 'GET_ASSUMPTION_ARCHIVE_TABLE',
    GET_ASSUMPTION_ARCHIVE_TABLE_SUCCESS: 'GET_ASSUMPTION_ARCHIVE_TABLE_SUCCESS',
    GET_ASSUMPTION_ARCHIVE_TABLE_FAILURE: 'GET_ASSUMPTION_ARCHIVE_TABLE_FAILURE',
  };
  // Actions pertaining to the Assumption Archive table in the I/O tab in Engineering Execution
  export const actions = {
    getAssumptionArchiveTable: (data) => ({
      type: actionTypes.GET_ASSUMPTION_ARCHIVE_TABLE,
      data,
    }),
    getAssumptionArchiveTableSucceeded: (content) => ({
      type: actionTypes.GET_ASSUMPTION_ARCHIVE_TABLE_SUCCESS,
      payload: content,
    }),
    getAssumptionArchiveTableFailed: (content) => ({
      type: actionTypes.GET_ASSUMPTION_ARCHIVE_TABLE_FAILURE,
      payload: content,
    }),
  };
  