import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Modal } from 'react-bootstrap';
import { ICONS } from '../../constants/assets';
import './styles.scss';
import IconGenerator from '../../constants/IconPack/iconGenerator';

export function BVModal(props) {
  const {
    showModal,
    title,
    toggleModal,
    modalContent,
    buttonData,
    items,
    isOutSideDismiss,
    closeButton,
    size,
    showFooter,
    scrollable,
    className,
    contentClassName,
    dialogClassName,
    modalTheme,
    onAfterCloseModal,
    backdropClassName,
    showIcon,
  } = { ...props };

  const handleCloseModal = () => {
    toggleModal();
  };

  return (
    <Modal
      show={showModal}
      // onHide={toggleModal}
      centered
      backdrop={isOutSideDismiss ? true : 'static'}
      size={size}
      scrollable={scrollable}
      contentClassName={contentClassName}
      className={className}
      dialogClassName={dialogClassName}
      backdropClassName={`${backdropClassName} ${modalTheme === 'plan-full-view' && 'no-backdrop'}`}
    >
      <div className="bvModalHeader">
        {modalTheme === 'plan-full-view' ? (
          <div className="custom-close-block" onClick={handleCloseModal}>
            {closeButton && (
              <>
                <img className="bvModalCloseButton" src={ICONS.LEFT_NAV_ICON_DARK} />
                <span>Back</span>
              </>
            )}
          </div>
        ) : (
          <>
            <span className="margin-right-10">
              {showIcon && <IconGenerator classes="icon-svg icon-theme icon-size-25" iconName="icon-no-data" />}
            </span>
            <span className="bvModalTitle">
              {title}
              {title === 'Attention!' ? <img className="attentionIcon" src={ICONS.TRANGLE} alt="attention" /> : ''}{' '}
            </span>

            {closeButton && <img className="bvModalCloseButton" onClick={toggleModal} src={ICONS.CLOSEX} alt="close" />}
          </>
        )}
      </div>
      <Modal.Body>{modalContent || props.children} {}</Modal.Body>
      { items && items.length ? (
          <Modal.Footer id='impactedDisciplines'>
            <div>
            <p>All disciplines outside of this specific Change Instance that are impacted by the attempted on-screen changes are listed below:</p>
            <></>
            <ul>{ items.map((item) => (<li>{item}</li>)) }</ul>  
            </div>
                     
          </Modal.Footer>
        ) : ''
      }
      {showFooter && (  
        <Modal.Footer>
          {buttonData && (
            // Designated container for the decline button functionality if needed
            <div className="tertiaryButtonContainer">
           {buttonData.map((data, index) =>
             data.isTertiaryButton && (
                <button
                  type="button"
                  onClick={data?.buttonClick}
                  key={index}
                  className={'modalTertiaryButton'}
                >
                  {data.buttonLabel}
                </button>
              )
            )}
            </div>
          )}        
          {buttonData && (
            <div className="bvModalButtonContainer">
              {buttonData.map((data, index) =>          
              // Don't want the decline button (tertiary button) being displayed here
                !data.isTertiaryButton && (
                data.disabled && data.disableMessage ? (
                  <Tooltip
                    position="top"
                    tooltipClassName="theme-blue-dark rework-tooltip"
                    parentTitle="true"
                    anchorElement="target"
                  >
                    <button
                      type="button"
                      title={data.disableMessage}
                      key={index}
                      className={
                        data.isPrimaryButton
                          ? `modalPrimaryButton btn btn-primary btn-primary-dark ${data.disabled ? 'disabled' : ''}`
                          : 'modalSecondaryButton'
                      }
                      disabled={!!data.isDisabled}
                    >
                      {data.buttonLabel}
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    type="button"
                    onClick={data?.buttonClick}
                    key={index}
                    className={
                      data.isPrimaryButton
                        ? `modalPrimaryButton btn btn-primary btn-primary-dark ${data.disabled ? 'disabled' : ''}`
                        : 'modalSecondaryButton'
                    }
                    disabled={!!data.isDisabled}
                  >
                    {data.buttonLabel}
                  </button>
                ))
              )}
            </div>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}
BVModal.propTypes = {
  showModal: PropTypes.bool,
  modalContent: PropTypes.any,
  isOutSideDismiss: PropTypes.bool,
  toggleModal: PropTypes.func,
  closeButton: PropTypes.bool,
  buttonData: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonClick: PropTypes.func,
      isPrimaryButton: PropTypes.bool,
      // For type checking on the fly
      isTertiaryButton: PropTypes.bool,
    })
  ),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.string,
};

BVModal.defaultProps = {
  showModal: true,
  closeButton: false,
  title: '',
  isOutSideDismiss: true,
  size: '',
  showFooter: true,
  scrollable: true,
  className: '',
  dialogClassName: '',
  contentClassName: '',
  modalTheme: '',
  backdropClassName: '',
};
