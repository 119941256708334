export const actionTypes = {
  GET_CREATE_PROJECT_FIELDS: 'GET_CREATE_PROJECT_FIELDS',
  GET_CREATE_PROJECT_FIELDS_SUCCESS: 'GET_CREATE_PROJECT_FIELDS_SUCCESS',
  GET_CREATE_PROJECT_FIELDS_FAILURE: 'GET_CREATE_PROJECT_FIELDS_FAILURE',
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',
  GET_PROJECT_MENU: 'GET_PROJECT_MENU',
  GET_PROJECT_MENU_SUCCESS: 'GET_PROJECT_MENU_SUCCESS',
  GET_PROJECT_MENU_FAILURE: 'GET_PROJECT_MENU_FAILURE',
  PROJECT_DETAILS: 'PROJECT_DETAILS',
  GET_PROJECT_SUBMENU: 'GET_PROJECT_SUBMENU',
  GET_PROJECT_SUBMENU_SUCCESS: 'GET_PROJECT_SUBMENU_SUCCESS',
  GET_PROJECT_SUBMENU_FAILURE: 'GET_PROJECT_SUBMENU_FAILURE',
  CLEAR_PROJECT_MENU: 'CLEAR_PROJECT_MENU',
  CLEAR_PROJECT_SUBMENU: 'CLEAR_PROJECT_SUBMENU',
  COMPLETE_SUBMENU: 'COMPLETE_SUBMENU',
  COMPLETE_PROJECT_FORM_MENU: 'COMPLETE_PROJECT_FORM_MENU',
  COMPLETE_SUBMENU_SUCCESS: 'COMPLETE_SUBMENU_SUCCESS',
  COMPLETE_SUBMENU_FAILURE: 'COMPLETE_SUBMENU_FAILURE',
  GET_PROJECT_INFO: 'GET_PROJECT_INFO',
  GET_PROJECT_INFO_SUCCESS: 'GET_PROJECT_INFO_SUCCESS',
  GET_PROJECT_INFO_FAILURE: 'GET_PROJECT_INFO_FAILURE',
};

export const actions = {
  getCreateProjectFields: (data) => ({
    type: actionTypes.GET_CREATE_PROJECT_FIELDS,
    data,
  }),
  getCreateProjectFieldsSucceded: (content) => ({
    type: actionTypes.GET_CREATE_PROJECT_FIELDS_SUCCESS,
    payload: content,
  }),
  getCreateProjectFieldsFailed: (content) => ({
    type: actionTypes.GET_CREATE_PROJECT_FIELDS_FAILURE,
    payload: content,
  }),
  createProject: (data) => ({
    type: actionTypes.CREATE_PROJECT,
    data,
  }),
  createProjectSucceded: (content) => ({
    type: actionTypes.CREATE_PROJECT_SUCCESS,
    payload: content,
  }),
  createProjectFailed: (content) => ({
    type: actionTypes.CREATE_PROJECT_FAILURE,
    payload: content,
  }),
  getProjectMenu: (data) => ({
    type: actionTypes.GET_PROJECT_MENU,
    data,
  }),
  getProjectMenuSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_MENU_SUCCESS,
    payload: content,
  }),
  getProjectMenuFailed: (content) => ({
    type: actionTypes.GET_PROJECT_MENU_FAILURE,
    payload: content,
  }),
  projectDetails: (data) => ({
    type: actionTypes.PROJECT_DETAILS,
    data,
  }),
  getProjectSubMenu: (submenuList) => ({
    type: actionTypes.GET_PROJECT_SUBMENU,
    submenuList,
  }),
  getProjectSubMenuSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_SUBMENU_SUCCESS,
    payload: content,
  }),
  getProjectSubMenuFailed: (content) => ({
    type: actionTypes.GET_PROJECT_SUBMENU_FAILURE,
    payload: content,
  }),
  completeSubMenu: (content) => ({
    type: actionTypes.COMPLETE_SUBMENU,
    payload: content,
  }),

  completeProjectFormMenu: (content) => ({
    type: actionTypes.COMPLETE_PROJECT_FORM_MENU,
    payload: content,
  }),

  completeSubMenuSucceeded: (content) => ({
    type: actionTypes.COMPLETE_SUBMENU_SUCCESS,
    payload: content,
  }),
  completeSubMenuFailed: (content) => ({
    type: actionTypes.COMPLETE_SUBMENU_FAILURE,
    payload: content,
  }),
  clearProjectMenu: () => ({
    type: actionTypes.CLEAR_PROJECT_MENU,
  }),
  clearProjectSubMenu: () => ({
    type: actionTypes.CLEAR_PROJECT_SUBMENU,
  }),
  getProjectInfo: (data) => ({
    type: actionTypes.GET_PROJECT_INFO,
    data,
  }),
  getProjectInfoSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_INFO_SUCCESS,
    payload: content,
  }),
  getProjectInfoFailed: (content) => ({
    type: actionTypes.GET_PROJECT_INFO_FAILURE,
    payload: content,
  }),
};
