import { projectGigsTableActionTypes } from '../actions';

const InitialState = {};

export const projectGigsTableReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projectGigsTableActionTypes.GET_PROJECT_GIGS_TABLE:
      return { ...state };
    case projectGigsTableActionTypes.GET_PROJECT_GIGS_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case projectGigsTableActionTypes.GET_PROJECT_GIGS_TABLE_FAILURE:
      return { ...state, ...action.payload };
    case projectGigsTableActionTypes.MASS_ASSIGN_GIGS:
      return { ...state };
    case projectGigsTableActionTypes.MASS_ASSIGN_GIGS_SUCCESS:
      return { ...state, ...action.payload };
    case projectGigsTableActionTypes.MASS_ASSIGN_GIGS_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
