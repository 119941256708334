import { p6ActionTypes, p6SyncActions, p6SyncActionTypes } from '../actions';

const InitialState = {};

export const p6SyncReducer = (state = InitialState, action) => {
  switch (action.type) {
    case p6SyncActionTypes.GET_P6_SYNC_FORM:
      return { ...state };
    case p6SyncActionTypes.GET_P6_SYNC_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.GET_P6_SYNC_FORM_FAILURE:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.EXPORT_P6_SYNC_TABLE:
      return { ...state };
    case p6SyncActionTypes.EXPORT_P6_SYNC_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.EXPORT_P6_SYNC_TABLE_FAILURE:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA:
        return { ...state };
    case p6SyncActionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA_SUCCESS:
        return { ...state, ...action.payload };
    case p6SyncActionTypes.GET_EXCEL_EXPORT_EVENT_TABLE_DATA_FAILURE:
        return { ...state, ...action.payload };
        case p6SyncActionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL:
          return { ...state };
      case p6SyncActionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL_SUCCESS:
          return { ...state, ...action.payload };
      case p6SyncActionTypes.EXPORT_ARCHIVE_TABLE_TO_EXCEL_FAILURE:
          return { ...state, ...action.payload };
    case p6SyncActionTypes.GET_P6_VERSIONS:
      return { ...state };
    case p6SyncActionTypes.GET_P6_VERSIONS_SUCCESS:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.GET_P6_VERSIONS_FAILURE:
      return { ...state, ...action.payload };

    case p6SyncActionTypes.ADD_P6_SYNC_ITERATION:
      return { ...state };
    case p6SyncActionTypes.ADD_P6_SYNC_ITERATION_SUCCESS:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.ADD_P6_SYNC_ITERATION_FAILURE:
      return { ...state, ...action.payload };

    case p6SyncActionTypes.PUSH_P6_TO_LAMINAR:
      return { ...state };
    case p6SyncActionTypes.PUSH_P6_TO_LAMINAR_SUCCESS:
      return { ...state, ...action.payload };
    case p6SyncActionTypes.PUSH_P6_TO_LAMINAR_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
