import { projectSetupActionTypes } from '../actions';

const InitialState = {
  projectDetails: {},
  submenuList: [],
};

export const projectSetupReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projectSetupActionTypes.GET_CREATE_PROJECT_FIELDS:
      return { ...state };
    case projectSetupActionTypes.GET_CREATE_PROJECT_FIELDS_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_CREATE_PROJECT_FIELDS_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.CREATE_PROJECT:
      return { ...state };
    case projectSetupActionTypes.CREATE_PROJECT_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.CREATE_PROJECT_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_PROJECT_MENU:
      return { ...state };
    case projectSetupActionTypes.GET_PROJECT_MENU_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_PROJECT_MENU_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: { ...state.projectDetails, ...action.data },
      };
    case projectSetupActionTypes.GET_PROJECT_SUBMENU:
      return { ...state };
    case projectSetupActionTypes.GET_PROJECT_SUBMENU_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_PROJECT_SUBMENU_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.COMPLETE_SUBMENU:
      return { ...state };
    case projectSetupActionTypes.COMPLETE_PROJECT_FORM_MENU:
      return { ...state };
    case projectSetupActionTypes.COMPLETE_SUBMENU_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.COMPLETE_SUBMENU_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_PROJECT_INFO_SUCCESS:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.GET_PROJECT_INFO_FAILURE:
      return { ...state, ...action.payload };
    case projectSetupActionTypes.CLEAR_PROJECT_MENU:
      return {
        ...state,
        submenuList: [],
        menuList: [],
        projectDetails: {
          ...state.projectDetails,
          submenuId: null,
          submenuName: null,
          submenuRights: null,
        },
        projectInfo: {},
      };
    case projectSetupActionTypes.CLEAR_PROJECT_SUBMENU:
      return {
        ...state,
        submenuList: [],
        projectDetails: {
          ...state.projectDetails,
          submenuId: null,
          submenuName: null,
          submenuRights: null,
        },
      };
    default:
      return state;
  }
};
