export const actionTypes = {
  GET_PROJECT_INFO_FORM: 'GET_PROJECT_INFO_FORM',
  GET_PROJECT_INFO_FORM_SUCCESS: 'GET_PROJECT_INFO_FORM_SUCCESS',
  GET_PROJECT_INFO_FORM_FAILURE: 'GET_PROJECT_INFO_FORM_FAILURE',
  ADD_FIELD: 'ADD_FIELD',
  NEW_FIELD: 'NEW_FIELD',
  TEMP_ADDITIONAL_FIELDS: 'TEMP_ADDITIONAL_FIELDS',
  GET_ADDITIONAL_FIELD_FORM: 'GET_ADDITIONAL_FIELD_FORM',
  GET_ADDITIONAL_FIELD_FORM_SUCCESS: 'GET_ADDITIONAL_FIELD_FORM_SUCCESS',
  GET_ADDITIONAL_FIELD_FORM_FAILURE: 'GET_ADDITIONAL_FIELD_FORM_FAILURE',
  SAVE_PROJECT_FORM: 'SAVE_PROJECT_FORM',
  SAVE_PROJECT_FORM_SUCCESS: 'SAVE_PROJECT_FORM_SUCCESS',
  SAVE_PROJECT_FORM_FAILURE: 'SAVE_PROJECT_FORM_FAILURE',
};

export const actions = {
  getProjectInfoForm: (data) => ({
    type: actionTypes.GET_PROJECT_INFO_FORM,
    data,
  }),
  getProjectInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_PROJECT_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getProjectInfoFormFailed: (content) => ({
    type: actionTypes.GET_PROJECT_INFO_FORM_FAILURE,
    payload: content,
  }),
  addField: (addField) => ({
    type: actionTypes.ADD_FIELD,
    addField,
  }),
  newField: (newField) => ({
    type: actionTypes.NEW_FIELD,
    newField,
  }),
  tempAdditionalFields: (tempAdditionalFields) => ({
    type: actionTypes.TEMP_ADDITIONAL_FIELDS,
    tempAdditionalFields,
  }),
  getAdditionalFieldForm: (data) => ({
    type: actionTypes.GET_ADDITIONAL_FIELD_FORM,
    data,
  }),
  getAdditionalFieldFormSucceeded: (content) => ({
    type: actionTypes.GET_ADDITIONAL_FIELD_FORM_SUCCESS,
    payload: content,
  }),
  getAdditionalFieldFormFailed: (content) => ({
    type: actionTypes.GET_ADDITIONAL_FIELD_FORM_FAILURE,
    payload: content,
  }),
  saveProjectForm: (data) => ({
    type: actionTypes.SAVE_PROJECT_FORM,
    data,
  }),
  saveProjectFormSucceeded: (content) => ({
    type: actionTypes.SAVE_PROJECT_FORM_SUCCESS,
    payload: content,
  }),
  saveProjectFormFailed: (content) => ({
    type: actionTypes.SAVE_PROJECT_FORM_FAILURE,
    payload: content,
  }),
};
