import { put, takeEvery, call } from 'redux-saga/effects';
import { isEmpty, get } from 'lodash';
import { laminarScopeActionTypes, laminarScopeActions, commonActions, enggExeProjectHomeActions } from '../actions';
import servicelist, { camundaService, getQualityWidgetForm, queueJobStatus } from '../../services/serviceList';
import AxiosInstance from '../../services/commons/Interceptor';
import { getGigProgress, actionDropdown } from '../../mocks/assignGig';
import {
  getProjectHomeOverviewSaga,
  getProjectHomeMyWorkItemsSaga,
  getProjectHomeMyGigsSaga,
} from './enggExeProjectHome';
import { getProjActivityAndFragStepsSaga } from './projActivityAndFragSteps';
import {
  bypassManagerRequestAssumption,
  saveLogTimeHistory
} from '../../services/serviceList';

export function* getLaminarScopeOverviewSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const bodyData = payload.data.refreshData ? payload.data.refreshData : payload.data;
    const response = yield call(AxiosInstance.post, servicelist('getLaminarScopeOverviewForm'), bodyData);
    try {
      const qualityResponse = yield call(AxiosInstance.get, getQualityWidgetForm(), bodyData);
      response.data.qualityCheckList = qualityResponse.data.data;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    yield put(
      laminarScopeActions.getLaminarScopeOverviewFormSucceeded({
        isSucceeded: true,
        laminarScopeOverviewFormData: response.data,
      })
    );

    if (payload.refreshActions) {
      const gcRole = get(response, 'data.action.gcEditable.role', '');
      const gdRole = get(response, 'data.action.gdEditable.role', '');
      let camundaRole = 'gc';
      if (!isEmpty(gcRole) && !isEmpty(gdRole)) {
        camundaRole = '';
      } else if (!isEmpty(gdRole)) {
        camundaRole = gdRole;
      } else if (!isEmpty(gcRole)) {
        camundaRole = gcRole;
      }
      const refreshActions = { ...payload.refreshActions, requesterRole: camundaRole };
      yield put(laminarScopeActions.getGigStatus(refreshActions));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.getLaminarScopeOverviewFormFailed({
        laminarScopeOverviewFormData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* getLaminarScopeOverviewStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_LAMINAR_SCOPE_OVERVIEW_FORM], getLaminarScopeOverviewSaga);
}

export function* requestAssumptionSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.get, servicelist('getRequestAssumptionModal'), { params: payload.data });
    yield put(
      laminarScopeActions.requestAssumptionSucceeded({
        isSucceeded: true,
        requestAssumptionModalData: response.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.requestAssumptionFailed({
        isSucceeded: false,
        requestAssumptionModalData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* requestAssumptionStatus() {
  yield takeEvery([laminarScopeActionTypes.REQUEST_ASSUMPTION], requestAssumptionSaga);
}

// When a Request Assumption is made, it comes to this saga with the payload
export function* saveRequestAssumptionSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // Calls only the post functionality for the getRequestAssumptionModal endpoint
    const response = yield call(AxiosInstance.post, servicelist('getRequestAssumptionModal'), payload.data.saveObj);
    yield put(
      laminarScopeActions.saveRequestAssumptionSucceeded({
        isSucceeded: true,
        // requestAssumptionModalData: response.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Request Notification is sent',
      })
    );
    yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.data.refreshData, payload.data.refreshActions));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveRequestAssumptionFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* saveRequestAssumptionStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_REQUEST_ASSUMPTION], saveRequestAssumptionSaga);
}

/**
 * When the user hits the "Request Assumption button", the redux action BYPASS_MANAGER_REQUEST_ASSUMPTION
 * is executed and hits this saga. The saga will call the Engineering Execution endpoint responsible for receiving
 * the payload and display a loader until this action is finished.
 * 
 * @param {any} payload - The collection of request assumption properties
 */
export function* bypassManagerRequestAssumptionSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, myTeamsAssumptionPage} = payload.data.saveObj;
    // There is no response to come back from this API call, it is all processed on the backend
    yield call(AxiosInstance.post, bypassManagerRequestAssumption(projectNumber), payload.data.saveObj);
    yield put(
      laminarScopeActions.bypassManagerRequestAssumptionSucceeded({
        isSucceeded: true,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Assumption saved and has successfully bypassed the Manager Verification process.',
      })
    );
    // We need to properly refresh each page as appropriate depending on the value of this property from the payload
    if(myTeamsAssumptionPage) {
      // This refreshes the dashboard on the my teams needed inputs page
      const { discipline } = payload.data.saveObj;
      yield put(enggExeProjectHomeActions.getProjectHomeOverviewData({projectNumber, discipline}));
      yield put(enggExeProjectHomeActions.getProjectHomeMyGigsData({projectNumber, discipline}));
      yield put(enggExeProjectHomeActions.getProjectHomeMyWorkItemsData({projectNumber, discipline}));
    } else {
    // Allow for the refresh of the gig dashboard when the user presses the button to request the assumption
      yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.data.refreshData, payload.data.refreshActions));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.bypassManagerRequestAssumptionFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* bypassManagerRequestAssumptionStatus() {
  yield takeEvery([laminarScopeActionTypes.BYPASS_MANAGER_REQUEST_ASSUMPTION], bypassManagerRequestAssumptionSaga);
}



export function* getMissingIOTableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.post, servicelist('findMissingIOModal'), payload.data);
    yield put(
      laminarScopeActions.getMissingIOTableSucceeded({
        isSucceeded: true,
        findMissingIOTableData: response.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.getMissingIOTableFailed({
        isSucceeded: false,
        findMissingIOTableData: null,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* getMissingIOTableStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_MISSING_IO_TABLE], getMissingIOTableSaga);
}

export function* updateMissingIOTableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.put, servicelist('findMissingIOModal'), payload.data);
    yield put(
      laminarScopeActions.updateMissingIOTableSucceeded({
        updateIOSucceeded: true,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409
            ? e?.response?.data?.message
            : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.updateMissingIOTableFailed({
        updateIOSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* updateMissingIOTableStatus() {
  yield takeEvery([laminarScopeActionTypes.UPDATE_MISSING_IO_TABLE], updateMissingIOTableSaga);
}

export function* saveGigInstructionSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('saveGigInstruction'), payload.payload);
    yield put(
      laminarScopeActions.saveGigInstructionSucceeded({
        isSucceeded: true,
        gigInstructionData: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Gig Instruction saved Successfully',
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveGigInstructionFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* saveGigInstructionStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_GIG_INSTRUCTION], saveGigInstructionSaga);
}

export function* saveOutputChecklistDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.post,
      servicelist('saveOutputChecklistData'),
      payload.payload.saveObj
    );
    yield put(
      laminarScopeActions.saveOutputChecklistDataSucceeded({
        isSucceeded: true,
        outputChecklistData: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Output Data Saved Successfully',
      })
    );
    if (!payload.payload.gigFragnetChanged) {
      yield put(
        laminarScopeActions.getLaminarScopeOverviewForm(payload.payload.refreshData, payload.payload.refreshActions)
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveOutputChecklistDataFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* saveOutputChecklistDataStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_OUTPUT_CHECKLIST_DATA], saveOutputChecklistDataSaga);
}

export function* saveLogTimeDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('saveLogTimeData'), payload.payload.saveObj);
    yield put(
      laminarScopeActions.saveLogTimeDataSucceeded({
        isSucceeded: true,
        logTimeData: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Log Time Saved Successfully',
      })
    );
    if (!payload.payload.gigFragnetChanged) {
      yield put(
        laminarScopeActions.getLaminarScopeOverviewForm(payload.payload.refreshData, payload.payload.refreshActions)
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveLogTimeDataFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
  yield put(
    laminarScopeActions.getLaminarScopeOverviewForm(payload.payload.refreshData, payload.payload.refreshActions)
  );
}

export function* saveLogTimeDataStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_LOG_TIME_DATA], saveLogTimeDataSaga);
}
/**
 * Calls the endpoint for the backend that communicates with the database to save and retrieve the log time history for edited gigs.
 * 
 * @param {Object} payload - This holds the data needed for the backend to update and add data to the database for the gig log edited time
 */
export function* saveGigLogTimeHistorySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, giginstanceId } = payload.payload.retrieveGigLogPayload || payload.payload.logTimeHistory || payload.payload;
    const data = payload.payload.retrieveGigLogPayload ? payload.payload.retrieveGigLogPayload : payload.payload.logTimeHistory ? payload.payload.logTimeHistory : payload.payload;
    
    const responseData = yield call(AxiosInstance.post, saveLogTimeHistory(projectNumber, giginstanceId), data);
    yield put(
      laminarScopeActions.saveGigLogTimeHistorySucceeded({
        isSucceeded: true,
        logTimeData: responseData?.data?.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveGigLogTimeHistoryFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveGigLogTimeHistoryStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_GIG_LOG_TIME_HISTORY], saveGigLogTimeHistorySaga);
}

// Laminar Scope Actions Section
export function* assignGigRequestSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('assignGigRequest'), payload.payload);
    yield put(
      laminarScopeActions.assignGigRequestSucceeded({
        isSucceeded: true,
        assignGigResponse: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: get(payload, 'payload.isUnAssign', false)
          ? 'Gig UnAssigned Successfully'
          : 'Gig Assigned Successfully',
      })
    );
    payload.payload.requesterRole = payload.payload.camundaRole;
    if (payload.workItem && payload.workItem.category !== '') {
      yield put(enggExeProjectHomeActions.getProjectHomeOverviewData(payload.workItem.payload));
      yield put(enggExeProjectHomeActions.getProjectHomeMyGigsData(payload.workItem.payload));
      yield put(enggExeProjectHomeActions.getProjectHomeMyWorkItemsData(payload.workItem.payload));
    } else if (!isEmpty(payload.refreshData)) {
      yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.refreshData, payload.payload));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.assignGigRequestFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* assignGigRequestStatus() {
  yield takeEvery([laminarScopeActionTypes.ASSIGN_GIG_REQUEST], assignGigRequestSaga);
}

export function* gigTransferRequestSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('gigTransferRequest'), payload.payload);
    yield put(
      laminarScopeActions.gigTransferRequestSucceeded({
        isSucceeded: true,
        gigTransferResponse: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Gig Transfer Requested',
      })
    );
    if (!isEmpty(payload.refreshData)) {
      yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.refreshData, payload.payload));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.gigTransferRequestFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* gigTransferRequestStatus() {
  yield takeEvery([laminarScopeActionTypes.GIGTRANSFER_REQUEST], gigTransferRequestSaga);
}

export function* gigTransferRequestCancellationSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('gigTransferRequestCancellation'), payload.payload);
    yield put(
      laminarScopeActions.gigTransferRequestCancellationSucceeded({
        isSucceeded: true,
        gigTransferCancellationResponse: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Transfer request cancelled',
      })
    );
    if (!isEmpty(payload.refreshData)) {
      yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.refreshData, payload.payload));
      if (payload.myProjectHome) {
        yield* getProjectHomeOverviewSaga({ payload: payload.myProjectHome });
        yield* getProjectHomeMyGigsSaga({ payload: payload.myProjectHome });
        yield* getProjectHomeMyWorkItemsSaga({ payload: payload.myProjectHome });
      }
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.gigTransferRequestCancellationFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* gigTransferRequestCancellationStatus() {
  yield takeEvery([laminarScopeActionTypes.GIGTRANSFER_REQUEST_CANCELLATION], gigTransferRequestCancellationSaga);
}

export function* updateGigStatusNodeSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('updateGigStatusNode'), payload.payload);
    const params = { ...get(payload, 'refreshFragSteps.params', {}), giginstanceId: payload.payload.gigInstanceId };
    yield call(getProjActivityAndFragStepsSaga, {
      payload: params,
      id: get(payload, 'refreshFragSteps.gigId', ''),
    });
  } catch (e) {
    return;
  }
  yield put(commonActions.showLoader(false));
}

export function* updateGigStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    yield call(AxiosInstance.get, queueJobStatus(payload.payload.projectNumber));
    const responseData = yield call(AxiosInstance.post, camundaService('updateGigStatus'), payload.payload);
    yield put(
      laminarScopeActions.updateGigStatusSucceeded({
        isSucceeded: true,
        updateGigStatus: responseData.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Gig status updated Successfully',
      })
    );
    yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.refreshData, payload.payload));
    yield call(updateGigStatusNodeSaga, payload);
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.updateGigStatusFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* updateGigStatusStatus() {
  yield takeEvery([laminarScopeActionTypes.UPDATE_GIG_STATUS], updateGigStatusSaga);
}

export function* getGigStatusSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = getGigProgress;
    // Remove activityName from the payload, due to CORS issue.
    if (payload.payload && payload.payload.activityName) {
      delete payload.payload.activityName;
    }
    const responseData = yield call(AxiosInstance.get, camundaService('getGigStatus'), {
      params: payload.payload,
    });
    // const responseData = yield call(AxiosInstance.post, camundaService('getGigStatus'), payload.payload);
    yield put(
      laminarScopeActions.getGigStatusSucceeded({
        isSucceeded: true,
        gigStatusData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.getGigStatusFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* getGigStatusStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_GIG_STATUS], getGigStatusSaga);
}

export function* getActionDropdownSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    let requestUrl = '';
    const mockResponse = actionDropdown;
    if (payload.payload.type == 'transfer') {
      requestUrl = servicelist('transferReasonDropdown');
    } else {
      requestUrl = servicelist('holdReasonDropdown');
    }

    const responseData = yield call(AxiosInstance.get, requestUrl);

    yield put(
      laminarScopeActions.getActionDropdownSucceeded({
        isSucceeded: true,
        actionDropdown: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.getActionDropdownFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* getActionDropdownStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_ACTION_DROPDOWN], getActionDropdownSaga);
}

export function* getUsersDropdownSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const mockResponse = actionDropdown;
    // const responseData = yield call(AxiosInstance.get, servicelist('getusersbygroup'), {
    //   params: payload.payload,
    // });
    const responseData = yield call(AxiosInstance.post, servicelist('getusersbygroup'), payload.payload);
    yield put(
      laminarScopeActions.getUsersDropdownSucceeded({
        isSucceeded: true,
        actionDropdown: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.getUsersDropdownFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}
export function* getUsersDropdownStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_USERS_DROPDOWN], getUsersDropdownSaga);
}

export function* getRequiredInputDropdownOptionsSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    // const responseData = yield call(AxiosInstance.get, servicelist('getRequiredInputDropdownOptions'), {
    //   params: payload.payload,
    // });
    const responseData = yield call(
      AxiosInstance.post,
      servicelist('getRequiredInputDropdownOptions'),
      payload.payload
    );

    yield put(
      laminarScopeActions.getRequiredInputDropdownOptionsSucceeded({
        isSucceeded: true,
        requestInputDropdownOptionsData: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    laminarScopeActions.getRequiredInputDropdownOptionsFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}
export function* getRequiredInputDropdownOptionsStatus() {
  yield takeEvery([laminarScopeActionTypes.GET_REQUIRED_INPUT_DROPDOWN_OPTIONS], getRequiredInputDropdownOptionsSaga);
}

export function* saveEditRequiredInputSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.post, servicelist('saveEditRequiredInput'), payload.payload.saveObj);
    yield put(
      laminarScopeActions.saveEditRequiredInputSucceeded({
        isSucceeded: true,
        saveData: response.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Your changes have been saved',
      })
    );
    yield put(
      laminarScopeActions.getLaminarScopeOverviewForm(payload.payload.refreshData, payload.payload.refreshActions)
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409 ? e?.response?.data?.msg : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveEditRequiredInputFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveEditRequiredInputStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_EDIT_REQUIRED_INPUT], saveEditRequiredInputSaga);
}

export function* saveSelectInputVersionSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = yield call(AxiosInstance.post, servicelist('replaceInputs'), payload.data.saveObj);
    yield put(
      laminarScopeActions.saveSelectInputVersionSucceeded({
        isSucceeded: true,
        saveData: response.data,
      })
    );
    yield put(
      commonActions.infoPopup({
        messageId: 'Your changes have been saved',
      })
    );
    yield put(laminarScopeActions.getLaminarScopeOverviewForm(payload.data.refreshData, payload.data.refreshActions));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId:
          e?.response?.status === 409
            ? e?.response?.data?.message
            : 'Something went wrong. A system error has occurred.',
        theme: e?.response?.status === 409 ? 'alert-warning' : 'alert-danger',
      })
    );
    yield put(
      laminarScopeActions.saveSelectInputVersionFailed({
        isSucceeded: false,
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveSelectInputVersionStatus() {
  yield takeEvery([laminarScopeActionTypes.SAVE_SELECT_INPUT_VERSION], saveSelectInputVersionSaga);
}
