import React, { useState, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { createNotificationMessage } from './createNotificationMessage';
import { systemNotificationsActions } from '../../redux/actions';
import { getNotificationStatusIcon } from './getNotificationStatusIcon';
import { ICONS } from '../../constants/assets';
import { DATE_FORMAT } from '../../constants/common';
import { getLocalDate } from '../../helpers/common';
import './styles.scss';
import InfinitScroll from 'react-infinite-scroll-component';

function NotificationsPopup(props) {
  const [notificationPopupContent, setNotificationPopupContent] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [noNotification, setNoNotification] = useState(true);

  /**
   * pagination
   * currentPage -> tells which page the user is in
   * limit -> defines total number of records to be fetched at a time
   */
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const dispatch = useDispatch();
  const format = DATE_FORMAT.DDMMYYYY_MERIDIAN_TIME_HYPHEN_WITH_SPACE;

  const { showNotification, systemNotificationsPopupData, notificationRef, setNoUnReadNotification } = props;

  useEffect(() => {
    if (!isEmpty(systemNotificationsPopupData.notifications)) {
      let unreadPopupData = [];
      unreadPopupData = systemNotificationsPopupData.notifications.filter((item) => item.isRead === false);

      setNotificationPopupContent([...notificationPopupContent, ...unreadPopupData]);
    }
    setNoNotification(!systemNotificationsPopupData.isNotifications);
  }, [systemNotificationsPopupData]);

  useEffect(() => {
    if (isEmpty(notificationPopupContent)) {
      setNoUnReadNotification(true);
    } else {
      setNoUnReadNotification(false);
    }
  }, [notificationPopupContent]);

  /**
   * on unmounting reset values to begin
   */
  useEffect(() => {
    return function cleanup() {
      dispatch(
        systemNotificationsActions.getSystemNotificationLandingPage({
          userId: get(props.user, 'id', ''),
          isRead: false,
          page: 1,
          limit: limit,
        })
      );
    };
  }, []);

  const handleMarkAsRead = (e, idx) => {
    const tempData = [...notificationPopupContent];
    tempData[idx].isRead = true;
    setActiveRow(idx);
    dispatch(
      systemNotificationsActions.saveSystemNotifications({
        notificationIds: [tempData[idx].id],
        type: 'Mark_Notification',
      })
    );
    // make save call for change
    setTimeout(() => {
      tempData.splice(idx, 1);
      setNotificationPopupContent(tempData);
      setActiveRow(null);
    }, 750);
  };

  const fetchMoreData = () => {
    /**
     * add loader so loading shows when data get fetched
     */

    setCurrentPage(currentPage + 1);
    dispatch(
      systemNotificationsActions.getSystemNotificationLandingPage({
        userId: get(props.user, 'id', ''),
        isRead: false,
        page: currentPage + 1,
        limit: limit,
      })
    );
  };

  const getLoader = () => {
    return (
      <div className="inline-loader loading">
        <div className="il-loader-wrapper">
          <span className="il-loader" />
        </div>
        <span className="il-loading-text">Loading</span>
      </div>
    );
  };

  return (
    <Container
      ref={notificationRef}
      className="dropdown-notification-menu"
      style={
        showNotification
          ? {
              display: 'block',
              width: '320px',
              height: '424px',
              paddingRight: '0px',
              paddingLeft: '0px',
              position: 'absolute',
              top: '56px',
              right: '69px',
            }
          : {}
      }
    >
      <div className="notificationHeader">Notifications</div>
      <div
        id="scrollableDiv"
        className={`notificationBody ${isEmpty(notificationPopupContent) ? 'notificationBodyText' : ''}`}
      >
        {isEmpty(notificationPopupContent) ? (
          'No Un-Read Notifications'
        ) : (
          <table className="table">
            <tbody className="notificationTableBody">
              {!isEmpty(notificationPopupContent) && (
                /**
                 * has more -> tell if there is more data to load
                 * loader -> shows loader if while data is being retrived
                 *
                 */
                <InfinitScroll
                  dataLength={notificationPopupContent?.length}
                  next={fetchMoreData}
                  hasMore={currentPage * limit < systemNotificationsPopupData.totalRecords - 1}
                  loader={getLoader()}
                  scrollableTarget="scrollableDiv"
                  style={{ overflow: 'hidden' }}
                >
                  {notificationPopupContent.map((item, index) => (
                    <tr key={index} className={`${activeRow === index ? 'notificationRow' : ''}`}>
                      <td>
                        <span className="notificationImgContainer">
                          {!item.isRead ? (
                            <img src={ICONS.ICON_RED_DOT} className="notificationUnReadDot" alt="" />
                          ) : (
                            ''
                          )}
                          <img src={getNotificationStatusIcon(item.nIcon).src} className="notificationImg" style={getNotificationStatusIcon(item.nIcon).style} alt="" />
                        </span>
                      </td>
                      <td style={{ padding: '8px 0px 8px 0px' }}>
                        <div dangerouslySetInnerHTML={{ __html: createNotificationMessage(item) }} />
                        <div className="dateAndTime">
                          <span>
                            {item.timeStamp ? getLocalDate(item.timeStamp, format).toUpperCase() : item.nDateTime}
                          </span>
                        </div>
                      </td>
                      <td style={{ padding: '8px 5px 8px ' }}>
                        <img
                          src={getNotificationStatusIcon().src}
                          onClick={(e) => handleMarkAsRead(e, index)}
                          className="notificationArrow"
                          alt=""
                        />
                      </td>
                    </tr>
                  ))}
                </InfinitScroll>
              )}
            </tbody>
          </table>
        )}
      </div>
      <div className="notificationViewAll">
        <a
        /* The project will no longer default to the first project in the props list but the current project the user comes from */
          href={`/notifications?project-number=${props.projectNumber}`}
          target="_blank"
          rel="noreferrer"
          className={`${noNotification ? 'disabledATag' : 'linkAnchor'}`}
        >
          View All
        </a>
      </div>
    </Container>
  );
}

export default NotificationsPopup;

NotificationsPopup.defaultProps = {
  showNotification: false,
  systemNotificationsPopupData: {},
  notificationRef: null,
  setNoUnReadNotification: true,
};
