import { workflowDataActionTypes } from '../actions';

const InitialState = {
  workflowData: {},
  saveWorkflow: {},
  completeWorkflow: {},
};

export const workflowDataReducer = (state = InitialState, action) => {
  switch (action.type) {
    case workflowDataActionTypes.GET_WORKFLOW_DATA_SUCCESS:
      return { ...state, workflowData: action.payload };
    case workflowDataActionTypes.GET_WORKFLOW_DATA_FAILURE:
      return { ...state, workflowData: action.payload };
    case workflowDataActionTypes.SAVE_WORKFLOW_DATA_SUCCESS:
      return { ...state, saveWorkflow: action.payload };
    case workflowDataActionTypes.SAVE_WORKFLOW_DATA_FAILURE:
      return { ...state, saveWorkflow: action.payload };
    case workflowDataActionTypes.COMPLETE_WORKFLOW_DETAILS_SUCCESS:
      return { ...state, completeWorkflow: action.payload };
    case workflowDataActionTypes.COMPLETE_WORKFLOW_DETAILS_FAILURE:
      return { ...state, completeWorkflow: action.payload };
    default:
      return state;
  }
};
