/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Select from 'react-select';
import { useFormContext } from 'react-hook-form';
import { ICONS } from '../../constants/assets';
import './dropdownWithCompletedInfo.css';
import { Tooltip } from '@progress/kendo-react-tooltip';

function DropdownWithCompletedInfo(props) {
  const {
    selectedOption,
    options,
    onCustomCodeToggled,
    useCustomActivityCode,
    useCustomActivityCodeDisabled,
    property,
    handleChange,
    count,
    total,
    completeActivity,
    editActivity,
    disableComplete,
    isbuttonVisible,
    handleOptionDisable,
    isChangeRequest,
    subDivClassName,
    keyProp,
    isTableEmpty,
    searchTerm,
    editStatus,
    isNotApplicableShown, // Project Setup Only
    isNotApplicableDisabled, // Project Setup Only
    handleNotApplicableChange, // Project Setup Only
  } = props;
  const register = useFormContext()?.register;
  const watch = useFormContext()?.watch;
  const isDirty = useFormContext()?.formState?.isDirty;
  const isNotApplicable = watch?.('isNotApplicable');
  const selectStyle = {
    control: (base) => ({
      ...base,
      border: '1px solid #ced4da',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #ced4da',
      },
    }),
  };
  const getOptionLabel = (option) => (
    <div className="selectOption">
      <span className={option.labelClassName}>{option.name}</span>
      {option[property] ? <img src={ICONS.CHECK_CIRCLE_GREEN} /> : ''}
    </div>
  );
  // for isNotApplicable button
  const editButtonEnabled = selectedOption && selectedOption[property];
  const notApplicableOnSave = !isDirty && isNotApplicable;
  return (
    <div className="activitySelectContainer">
      <div className={subDivClassName || ''}>
        <Select
          key={keyProp || 'dropdown-key'}
          className="activitySelect"
          classNamePrefix="activitySelect"
          placeholder="Select Option"
          value={selectedOption}
          options={options}
          getOptionValue={(option) => option.key}
          onChange={handleChange}
          styles={selectStyle}
          getOptionLabel={(option) => getOptionLabel(option)}
          isOptionDisabled={(option) => handleOptionDisable(option)}
        />
      </div>
      <p className="completedStatus dropdown-component-count">
        <span>{count}</span>/{total} Completed
      </p>
      {isbuttonVisible &&
        selectedOption &&
        (selectedOption[property] ? (
          <button
            name="editActivity"
            className="activityComplete activityEdit"
            onClick={() => editActivity(selectedOption)}
            disabled={editStatus}
          >
            <img src={ICONS.CHECK_CIRCLE_GREEN} />
            <span className="completeText">Edit</span>
          </button>
        ) : isTableEmpty && !notApplicableOnSave ? (
          <Tooltip
            position="top"
            parentTitle="true"
            tooltipClassName="theme-blue-dark define-tooltip mark-as-complete-width"
            anchorElement="target"
          >
            <button
              name="completeActivity"
              className="activityComplete dropdown-component-button disabled"
              onClick={(e) => {
                e.preventDefault();
                completeActivity(selectedOption);
              }}
              disabled={(disableComplete || isTableEmpty) && !notApplicableOnSave}
              title="At least 1 Activity must be listed in order to Complete"
            >
              <span className="circleGrey" />
              <span className="completeText">Mark as Completed</span>
            </button>
          </Tooltip>
        ) : searchTerm ? (
          <Tooltip
            position="top"
            parentTitle="true"
            tooltipClassName="theme-blue-dark define-tooltip mark-as-complete-width"
            anchorElement="target"
          >
            <button
              name="completeActivity"
              className="activityComplete dropdown-component-button"
              onClick={(e) => {
                e.preventDefault();
                completeActivity(selectedOption);
              }}
              disabled={searchTerm}
              title="Please clear the search bar before Marking as Complete"
            >
              <span className="circleGrey" />
              <span className="completeText">Mark as Completed</span>
            </button>
          </Tooltip>
        ) : (disableComplete || isTableEmpty) && !notApplicableOnSave ? (
          <button
            name="completeActivity"
            className="activityComplete dropdown-component-button"
            onClick={(e) => {
              e.preventDefault();
              completeActivity(selectedOption);
            }}
            disabled={(disableComplete || isTableEmpty) && !notApplicableOnSave}
          >
            <span className="circleGrey" />
            <span className="completeText">Mark as Completed</span>
          </button>
        ) : (
          <button
            name="completeActivity"
            className="activityComplete dropdown-component-button"
            onClick={() => completeActivity(selectedOption)}
          >
            <span className="circleBlue" />
            <span className="completeText">Mark as Completed</span>
          </button>
        ))}
      {keyProp === 'defineActivities' && (
        <div className="checkbox-wrapper-define">
          <Tooltip
            position="top"
            tooltipClassName="theme-blue-dark define-tooltip"
            parentTitle="true"
            anchorElement="target"
            filter={() => !isTableEmpty}
          >
            <label
              className="app-c-checkbox"
              title="Delete all Project Activities in the table below to activate this option."
            >
              <input
                className="defineact"
                disabled={!isTableEmpty || editButtonEnabled}
                type="checkbox"
                aria-checked="false"
                tabIndex="0"
                name="defineact"
                {...register('isNotApplicable')}
              />
              <div className="app-c-check-label">Not Applicable</div>
            </label>
          </Tooltip>
        </div>
      )}
      {/* A check box to be shown for Define activities only,
          This add up columns to the table to update the CWA code and activity codes.*/}
      {keyProp === 'defineActivities' && (
        <div className="checkbox-wrapper-define">
          <Tooltip
            position="top"
            tooltipClassName="theme-blue-dark define-tooltip"
            parentTitle="true"
            anchorElement="target"
            filter={() => !isTableEmpty}
          >
            <label className="app-c-checkbox" title={!isDirty?"To update the CWA and activity codes.":"Save the form before toggling the button."}>
              <input
                className="defineact"
                disabled={editButtonEnabled || useCustomActivityCodeDisabled||isDirty}
                type="checkbox"
                aria-checked="false"
                tabIndex="0"
                name="defineact"
                onClick={(e) => onCustomCodeToggled(e)}
                checked={useCustomActivityCode||useCustomActivityCodeDisabled}
              />
              <div className="app-c-check-label">Assign Custom ID Values</div>
            </label>
          </Tooltip>
        </div>
      )}
      {
        /* Project Setup Only. The Not Applicable Box above only works with react-hook-form */
        isNotApplicableShown && (
          <div className="checkbox-wrapper-define">
            <Tooltip
              position="top"
              tooltipClassName="theme-blue-dark define-tooltip"
              parentTitle="true"
              anchorElement="target"
              filter={() => !isTableEmpty}
            >
              <label
                className="app-c-checkbox"
                title="Delete all Project Activities in the table below to activate this option."
              >
                <input
                  disabled={isNotApplicableDisabled}
                  type="checkbox"
                  checked={selectedOption?.['isActivityNotApplicable'] ?? false}
                  onClick={() => handleNotApplicableChange(selectedOption)}
                />
                <div className="app-c-check-label">Not Applicable</div>
              </label>
            </Tooltip>
          </div>
        )
      }
      {/* </div> */}
    </div>
  );
}

DropdownWithCompletedInfo.defaultProps = {
  isbuttonVisible: true,
  editStatus: false,
  isNotApplicableShown: false,
  isNotApplicableDisabled: false,
};

export default DropdownWithCompletedInfo;
