import React from 'react';
import ToolTip from '../../components/common/toolTip';
import './styles.css';

export default function BVTextField(props) {
  const { type, label, input, defaultValue, placeholder, meta, errorText, isFieldSelect, ...custom } = {
    ...props,
  };
  const isError = meta && meta.error ? meta.error : errorText || ''; // to distinguish between redux field and normal input filed
  // if (defaultValue) {
  //   if (!meta.visited) input.value = defaultValue;
  // }
  // highlighiting number input field
  const handleClick = (e) => {
    if (type === 'number') {
      e.target.select();
    }
  };

  return (
    <div className="bv-text-field-container">
      <div className="bv-text-field-container">
        <ToolTip hover={props.hoverText}>
          <input
            onClick={handleClick}
            max="2050-01-01"
            type={type}
            min="0"
            placeholder={placeholder}
            {...input}
            {...custom}
            className={`form-control ${isError ? 'errorTextBox ' : ''}${custom.className ? custom.className : ''}`}
          />
        </ToolTip>
      </div>
      {isError && (
        <span className="errorHelperText">
          {isError} {/* For error text */}
        </span>
      )}
    </div>
  );
}
BVTextField.defaultProps = {
  type: 'text',
};
