import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import IconGenerator from '../../constants/IconPack/iconGenerator';

import './styles.scss';

function BreadCrumb(props) {
  const [subMenu, setSubMenu] = React.useState('');
  const submenuName = useSelector((state) => get(state, 'projectSetupReducer.projectDetails.submenuName', ''));
  const formEditing = useSelector((state) => get(state, 'commonData.formEditing', ''));

  React.useEffect(() => {
    setSubMenu(submenuName);
  }, [submenuName]);

  return (
    <nav className="project-breadcrumb" aria-label="Breadcrumb">
      {/* <button className="icon-btn icon-btn--primary edit-form  tab-label-action" aria-label="Back">
        <IconGenerator classes={'icon-svg icon-theme icon-size-16 icon-edit-toggle'} iconName={'icon-arrow-left'} />
        <div className="edit-form-title">Back</div>
      </button> */}
      {formEditing ? (
        <span>Home</span>
      ) : (
        <NavLink to="/landing" className="home">
          Home
        </NavLink>
      )}
      {props?.currentPage === 'Engineering Execution' ? null : <span> / Project Set-up </span>}
      <span aria-current="page"> / {props.currentPage} </span>
      {props.currentPage === 'Workflow Rules' ? <span /> : subMenu && <span> {` / ${subMenu}`} </span>}
    </nav>
  );
}

export default withRouter(BreadCrumb);
