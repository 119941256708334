export const actionTypes = {
  GET_DROPDOWN_DATA: 'GET_DROPDOWN_DATA',
  GET_DROPDOWN_DATA_SUCCESS: 'GET_DROPDOWN_DATA_SUCCESS',
  GET_DROPDOWN_DATA_FAILURE: 'GET_DROPDOWN_DATA_FAILURE',
  GET_SUB_ACTIVITY_LIST: 'GET_SUB_ACTIVITY_LIST',
  GET_SUB_ACTIVITY_LIST_SUCCESS: 'GET_SUB_ACTIVITY_LIST_SUCCESS',
  GET_SUB_ACTIVITY_LIST_FAILURE: 'GET_SUB_ACTIVITY_LIST_FAILURE',
  SET_CURRENT_ACTIVITY_DATA: 'SET_CURRENT_ACTIVITY_DATA',
  GET_CUSTOMIZE_FRAGNET_DATA: 'GET_CUSTOMIZE_FRAGNET_DATA',
  GET_CUSTOMIZE_FRAGNET_DATA_SUCCESS: 'GET_CUSTOMIZE_FRAGNET_DATA_SUCCESS',
  GET_CUSTOMIZE_FRAGNET_DATA_FAILURE: 'GET_CUSTOMIZE_FRAGNET_DATA_FAILURE',
  FETCH_FRAGNET_STEPS: 'FETCH_FRAGNET_STEPS',
  FETCH_FRAGNET_STEPS_SUCCESS: 'FETCH_FRAGNET_STEPS_SUCCESS',
  FETCH_FRAGNET_STEPS_FAILURE: 'FETCH_FRAGNET_STEPS_FAILURE',
  SAVE_CUSTOMIZE_FRAGNET_DATA: 'SAVE_CUSTOMIZE_FRAGNET_DATA',
  SAVE_CUSTOMIZE_FRAGNET_DATA_SUCCESS: 'SAVE_CUSTOMIZE_FRAGNET_DATA_SUCCESS',
  SAVE_CUSTOMIZE_FRAGNET_DATA_FAILURE: 'SAVE_CUSTOMIZE_FRAGNET_DATA_FAILURE',
  GET_ACTIVITY_INFO_FORM: 'GET_ACTIVITY_INFO_FORM',
  GET_ACTIVITY_INFO_FORM_SUCCESS: 'GET_ACTIVITY_INFO_FORM_SUCCESS',
  GET_ACTIVITY_INFO_FORM_FAILURE: 'GET_ACTIVITY_INFO_FORM_FAILURE',

  UPDATE_ACTIVITY_RLATED_DATA: 'UPDATE_ACTIVITY_RLATED_DATA',
  UPDATE_ACTIVITY_RLATED_DATA_SUCCESS: 'UPDATE_ACTIVITY_RLATED_DATA_SUCCESS',
  UPDATE_ACTIVITY_RLATED_DATA_FAILURE: 'UPDATE_ACTIVITY_RLATED_DATA_FAILURE',

  // Step 4 Customized Gignet
  GET_CUSTOMIZED_GIG_STEPS: 'GET_CUSTOMIZED_GIG_STEPS',
  GET_CUSTOMIZED_GIG_STEPS_SUCCESS: 'GET_CUSTOMIZED_GIG_STEPS_SUCCESS',
  GET_CUSTOMIZED_GIG_STEPS_FAILURE: 'GET_CUSTOMIZED_GIG_STEPS_FAILURE',
  GET_CUSTOMIZE_GIGNET_FORM: 'GET_CUSTOMIZE_GIGNET_FORM',
  GET_CUSTOMIZE_GIGNET_FORM_SUCCESS: 'GET_CUSTOMIZE_GIGNET_FORM_SUCCESS',
  GET_CUSTOMIZE_GIGNET_FORM_FAILURE: 'GET_CUSTOMIZE_GIGNET_FORM_FAILURE',
  SAVE_CUSTOMIZE_GIGNET_FORM: 'SAVE_CUSTOMIZE_GIGNET_FORM',
  SAVE_CUSTOMIZE_GIGNET_FORM_SUCCESS: 'SAVE_CUSTOMIZE_GIGNET_FORM_SUCCESS',
  SAVE_CUSTOMIZE_GIGNET_FORM_FAILURE: 'SAVE_CUSTOMIZE_GIGNET_FORM_FAILURE',

  // step 5 - Gig Information
  GET_FRAGNET_STEPS: 'GET_FRAGNET_STEPS',
  GET_FRAGNET_STEPS_SUCCESS: 'GET_FRAGNET_STEPS_SUCCESS',
  GET_FRAGNET_STEPS_FAILURE: 'GET_FRAGNET_STEPS_FAILURE',
  GET_GIGNET_STEP_INFO: 'GET_GIGNET_STEP_INFO',
  GET_GIGNET_STEP_INFO_SUCCESS: 'GET_GIGNET_STEP_INFO_SUCCESS',
  GET_GIGNET_STEP_INFO_FAILURE: 'GET_GIGNET_STEP_INFO_FAILURE',
  SAVE_GIGNET_STEP_INFO: 'SAVE_GIGNET_STEP_INFO',
  SAVE_GIGNET_STEP_INFO_SUCCESS: 'SAVE_GIGNET_STEP_INFO_SUCCESS',
  SAVE_GIGNET_STEP_INFO_FAILURE: 'SAVE_GIGNET_STEP_INFO_FAILURE',
  GET_GIG_INFO_FORM: 'GET_GIG_INFO_FORM',
  GET_GIG_INFO_FORM_SUCCESS: 'GET_GIG_INFO_FORM_SUCCESS',
  GET_GIG_INFO_FORM_FAILURE: 'GET_GIG_INFO_FORM_FAILURE',
  SAVE_GIG_INFO_FORM: 'SAVE_GIG_INFO_FORM',
  SAVE_GIG_INFO_FORM_SUCCESS: 'SAVE_GIG_INFO_FORM_SUCCESS',
  SAVE_GIG_INFO_FORM_FAILURE: 'SAVE_GIG_INFO_FORM_FAILURE',
  CLEAR_GIG_INFO_FORM: 'CLEAR_GIG_INFO_FORM',
  GET_FRAGNET_STEP_INFO: 'GET_FRAGNET_STEP_INFO',
  GET_FRAGNET_STEP_INFO_SUCCESS: 'GET_FRAGNET_STEP_INFO_SUCCESS',
  GET_FRAGNET_STEP_INFO_FAILURE: 'GET_FRAGNET_STEP_INFO_FAILURE',
  SAVE_FRAGNET_STEP_INFO: 'SAVE_FRAGNET_STEP_INFO',
  SAVE_FRAGNET_STEP_INFO_SUCCESS: 'SAVE_FRAGNET_STEP_INFO_SUCCESS',
  SAVE_FRAGNET_STEP_INFO_FAILURE: 'SAVE_FRAGNET_STEP_INFO_FAILURE',
  SAVE_ACTIVITY_INFO_FORM: 'SAVE_ACTIVITY_INFO_FORM',
  SAVE_ACTIVITY_INFO_FORM_SUCCESS: 'SAVE_ACTIVITY_INFO_FORM_SUCCESS',
  SAVE_ACTIVITY_INFO_FORM_FAILURE: 'SAVE_ACTIVITY_INFO_FORM_FAILURE',
  SAVE_LANDING_PAGE_DATA: 'SAVE_LANDING_PAGE_DATA',
  SAVE_LANDING_PAGE_DATA_SUCCESS: 'SAVE_LANDING_PAGE_DATA_SUCCESS',
  SAVE_LANDING_PAGE_DATA_FAILURE: 'SAVE_LANDING_PAGE_DATA_FAILURE',
  GET_LANDING_PAGE_DATA: 'GET_LANDING_PAGE_DATA',
  GET_LANDING_PAGE_DATA_SUCCESS: 'GET_LANDING_PAGE_DATA_SUCCESS',
  GET_LANDING_PAGE_DATA_FAILURE: 'GET_LANDING_PAGE_DATA_FAILURE',
  FINISH_WORKFLOW: 'FINISH_WORKFLOW',
  FINISH_WORKFLOW_SUCCESS: 'FINISH_WORKFLOW_SUCCESS',
  FINISH_WORKFLOW_FAILURE: 'FINISH_WORKFLOW_FAILURE',
  CLEAR_GIG_STEPS: 'CLEAR_GIG_STEPS',
  AUTO_COMPLETE_GIGS: 'AUTO_COMPLETE_GIGS',
  AUTO_COMPLETE_GIGS_SUCCESS: 'AUTO_COMPLETE_GIGS_SUCCESS',
  AUTO_COMPLETE_GIGS_FAILURE: 'AUTO_COMPLETE_GIGS_FAILURE',
  CLEAR_GIG_DROPDOWN_INFO: 'CLEAR_GIG_DROPDOWN_INFO',
  COMPLETE_GIG_WORKFLOWS: 'COMPLETE_GIG_WORKFLOWS',
  COMPLETE_GIG_WORKFLOWS_SUCCESS: 'COMPLETE_GIG_WORKFLOWS_SUCCESS',
  COMPLETE_GIG_WORKFLOWS_FAILURE: 'COMPLETE_GIG_WORKFLOWS_FAILURE',
  UPDATE_GIGINFO_MENU_STATUS: 'UPDATE_GIGINFO_MENU_STATUS',
  UPDATE_GIGINFO_MENU_STATUS_SUCCESS: 'UPDATE_GIGINFO_MENU_STATUS_SUCCESS',
  UPDATE_GIGINFO_MENU_STATUS_FAILURE: 'UPDATE_GIGINFO_MENU_STATUS_FAILURE',
};

export const actions = {
  getDropdownData: (data) => ({
    type: actionTypes.GET_DROPDOWN_DATA,
    data,
  }),
  getDropdownDataSucceeded: (content) => ({
    type: actionTypes.GET_DROPDOWN_DATA_SUCCESS,
    payload: content,
  }),
  getDropdownDataFailed: (content) => ({
    type: actionTypes.GET_DROPDOWN_DATA_FAILURE,
    payload: content,
  }),
  getSubActivityList: (data) => ({
    type: actionTypes.GET_SUB_ACTIVITY_LIST,
    data,
  }),
  getSubActivityListSucceeded: (content) => ({
    type: actionTypes.GET_SUB_ACTIVITY_LIST_SUCCESS,
    payload: content,
  }),
  getSubActivityListFailed: (content) => ({
    type: actionTypes.GET_SUB_ACTIVITY_LIST_FAILURE,
    payload: content,
  }),
  setCurrentActivityData: (data) => ({
    type: actionTypes.SET_CURRENT_ACTIVITY_DATA,
    data,
  }),
  getCustomizeFragnetData: (data) => ({
    type: actionTypes.GET_CUSTOMIZE_FRAGNET_DATA,
    data,
  }),
  getCustomizeFragnetDataSucceeded: (content) => ({
    type: actionTypes.GET_CUSTOMIZE_FRAGNET_DATA_SUCCESS,
    payload: content,
  }),
  getCustomizeFragnetDataFailed: (content) => ({
    type: actionTypes.GET_CUSTOMIZE_FRAGNET_DATA_FAILURE,
    payload: content,
  }),
  fetchFragnetSteps: (data) => ({
    type: actionTypes.FETCH_FRAGNET_STEPS,
    data,
  }),
  fetchFragnetStepsSucceeded: (content) => ({
    type: actionTypes.FETCH_FRAGNET_STEPS_SUCCESS,
    payload: content,
  }),
  fetchFragnetStepsFailed: (content) => ({
    type: actionTypes.FETCH_FRAGNET_STEPS_FAILURE,
    payload: content,
  }),
  saveCustomizeFragnetData: (data) => ({
    type: actionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA,
    data,
  }),
  saveCustomizeFragnetDataSucceeded: (content) => ({
    type: actionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA_SUCCESS,
    payload: content,
  }),
  saveCustomizeFragnetDataFailed: (content) => ({
    type: actionTypes.SAVE_CUSTOMIZE_FRAGNET_DATA_FAILURE,
    payload: content,
  }),
  getActivityInfoForm: (data) => ({
    type: actionTypes.GET_ACTIVITY_INFO_FORM,
    data,
  }),
  getActivityInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_ACTIVITY_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getActivityInfoFormFailed: (content) => ({
    type: actionTypes.GET_ACTIVITY_INFO_FORM_FAILURE,
    payload: content,
  }),

  // step 4
  getCustomizedGigSteps: (data) => ({
    type: actionTypes.GET_CUSTOMIZED_GIG_STEPS,
    data,
  }),
  getCustomizedGigStepsSucceeded: (content) => ({
    type: actionTypes.GET_CUSTOMIZED_GIG_STEPS_SUCCESS,
    payload: content,
  }),
  getCustomizedGigStepsFailed: (content) => ({
    type: actionTypes.GET_CUSTOMIZED_GIG_STEPS_FAILURE,
    payload: content,
  }),
  getCustomizeGignetForm: (data) => ({
    type: actionTypes.GET_CUSTOMIZE_GIGNET_FORM,
    data,
  }),
  getCustomizeGignetFormSucceeded: (content) => ({
    type: actionTypes.GET_CUSTOMIZE_GIGNET_FORM_SUCCESS,
    payload: content,
  }),
  getCustomizeGignetFormFailed: (content) => ({
    type: actionTypes.GET_CUSTOMIZE_GIGNET_FORM_FAILURE,
    payload: content,
  }),
  saveCustomizeGignetForm: (data, status) => ({
    type: actionTypes.SAVE_CUSTOMIZE_GIGNET_FORM,
    data,
    status,
  }),
  saveCustomizeGignetFormSucceeded: (content) => ({
    type: actionTypes.SAVE_CUSTOMIZE_GIGNET_FORM_SUCCESS,
    payload: content,
  }),
  saveCustomizeGignetFormFailed: (content) => ({
    type: actionTypes.SAVE_CUSTOMIZE_GIGNET_FORM_FAILURE,
    payload: content,
  }),

  // step 5
  updateGigInfoMenuStatus: (data) => ({
    type: actionTypes.UPDATE_GIGINFO_MENU_STATUS,
    data,
  }),
  updateGigInfoMenuStatusSucceeded: (content) => ({
    type: actionTypes.UPDATE_GIGINFO_MENU_STATUS_SUCCESS,
    payload: content,
  }),
  updateGigInfoMenuStatusFailed: (content) => ({
    type: actionTypes.UPDATE_GIGINFO_MENU_STATUS_FAILURE,
    payload: content,
  }),
  getFragnetSteps: (data) => ({
    type: actionTypes.GET_FRAGNET_STEPS,
    data,
  }),
  getFragnetStepsSucceeded: (content) => ({
    type: actionTypes.GET_FRAGNET_STEPS_SUCCESS,
    payload: content,
  }),
  getFragnetStepsFailed: (content) => ({
    type: actionTypes.GET_FRAGNET_STEPS_FAILURE,
    payload: content,
  }),

  getGignetStepInfo: (data) => ({
    type: actionTypes.GET_GIGNET_STEP_INFO,
    data,
  }),

  getGignetStepInfoSucceeded: (content) => ({
    type: actionTypes.GET_GIGNET_STEP_INFO_SUCCESS,
    payload: content,
  }),
  getGignetStepInfoFailure: (content) => ({
    type: actionTypes.GET_GIGNET_STEP_INFO_FAILURE,
    payload: content,
  }),

  saveGignetStepInfo: (data, refreshMenu) => ({
    type: actionTypes.SAVE_GIGNET_STEP_INFO,
    data,
    refreshMenu,
  }),

  saveGignetStepInfoSucceeded: (content) => ({
    type: actionTypes.SAVE_GIGNET_STEP_INFO_SUCCESS,
    payload: content,
  }),
  saveGignetStepInfoFailure: (content) => ({
    type: actionTypes.SAVE_GIGNET_STEP_INFO_FAILURE,
    payload: content,
  }),

  getGigInfoForm: (data) => ({
    type: actionTypes.GET_GIG_INFO_FORM,
    data,
  }),
  getGigInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_GIG_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getGigInfoFormFailed: (content) => ({
    type: actionTypes.GET_GIG_INFO_FORM_FAILURE,
    payload: content,
  }),
  saveGigInfoForm: (data) => ({
    type: actionTypes.SAVE_GIG_INFO_FORM,
    data,
  }),
  saveGigInfoFormSucceeded: (content) => ({
    type: actionTypes.SAVE_GIG_INFO_FORM_SUCCESS,
    payload: content,
  }),
  saveGigInfoFormFailed: (content) => ({
    type: actionTypes.SAVE_GIG_INFO_FORM_FAILURE,
    payload: content,
  }),
  clearGigInfoForm: () => ({
    type: actionTypes.CLEAR_GIG_INFO_FORM,
  }),
  getFragnetStepInfo: (data) => ({
    type: actionTypes.GET_FRAGNET_STEP_INFO,
    data,
  }),
  getFragnetStepInfoSuccess: (content) => ({
    type: actionTypes.GET_FRAGNET_STEP_INFO_SUCCESS,
    payload: content,
  }),
  getFragnetStepInfoFailure: (content) => ({
    type: actionTypes.GET_FRAGNET_STEP_INFO_FAILURE,
    payload: content,
  }),
  saveFragnetStepInfo: (data) => ({
    type: actionTypes.SAVE_FRAGNET_STEP_INFO,
    data,
  }),
  saveFragnetStepInfoSuccess: (content) => ({
    type: actionTypes.SAVE_FRAGNET_STEP_INFO_SUCCESS,
    payload: content,
  }),
  saveFragnetStepInfoFailure: (content) => ({
    type: actionTypes.SAVE_FRAGNET_STEP_INFO_FAILURE,
    payload: content,
  }),
  saveActivityInfoForm: (data) => ({
    type: actionTypes.SAVE_ACTIVITY_INFO_FORM,
    data,
  }),
  saveActivityInfoFormSuccess: (content) => ({
    type: actionTypes.SAVE_ACTIVITY_INFO_FORM_SUCCESS,
    payload: content,
  }),
  saveActivityInfoFormFailure: (content) => ({
    type: actionTypes.SAVE_ACTIVITY_INFO_FORM_FAILURE,
    payload: content,
  }),
  saveLandingPageData: (data) => ({
    type: actionTypes.SAVE_LANDING_PAGE_DATA,
    data,
  }),
  saveLandingPageDataSuccess: (content) => ({
    type: actionTypes.SAVE_LANDING_PAGE_DATA_SUCCESS,
    payload: content,
  }),
  saveLandingPageDataFailure: (content) => ({
    type: actionTypes.SAVE_LANDING_PAGE_DATA_FAILURE,
    payload: content,
  }),
  getLandingPageData: (data) => ({
    type: actionTypes.GET_LANDING_PAGE_DATA,
    data,
  }),
  getLandingPageDataSuccess: (content) => ({
    type: actionTypes.GET_LANDING_PAGE_DATA_SUCCESS,
    payload: content,
  }),
  getLandingPageDataFailure: (content) => ({
    type: actionTypes.GET_LANDING_PAGE_DATA_FAILURE,
    payload: content,
  }),
  finishWorkflow: (data) => ({
    type: actionTypes.FINISH_WORKFLOW,
    data,
  }),
  finishWorkflowSucceeded: (content) => ({
    type: actionTypes.FINISH_WORKFLOW_SUCCESS,
    payload: content,
  }),
  finishWorkflowFailed: (content) => ({
    type: actionTypes.FINISH_WORKFLOW_FAILURE,
    payload: content,
  }),
  clearGigSteps: () => ({
    type: actionTypes.CLEAR_GIG_STEPS,
  }),
  updateActivityRelatedData: (data) => ({
    type: actionTypes.UPDATE_ACTIVITY_RLATED_DATA,
    data,
  }),
  updateActivityRelatedDataSucceeded: (content) => ({
    type: actionTypes.UPDATE_ACTIVITY_RLATED_DATA_SUCCESS,
    content,
  }),
  updateActivityRelatedDataFailed: (content) => ({
    type: actionTypes.UPDATE_ACTIVITY_RLATED_DATA_FAILURE,
    content,
  }),
  autoCompleteGigs: (data) => ({
    type: actionTypes.AUTO_COMPLETE_GIGS,
    data,
  }),
  autoCompleteGigsSucceeded: (content) => ({
    type: actionTypes.AUTO_COMPLETE_GIGS_SUCCESS,
    payload: content,
  }),
  autoCompleteGigsFailure: (content) => ({
    type: actionTypes.AUTO_COMPLETE_GIGS_FAILURE,
    payload: content,
  }),
  clearGigDropDown: () => ({
    type: actionTypes.CLEAR_GIG_DROPDOWN_INFO,
  }),
  completeGigWorkFlows: (data) => ({
    type: actionTypes.COMPLETE_GIG_WORKFLOWS,
    data,
  }),
  completeGigWorkFlowsSuccess: (content) => ({
    type: actionTypes.COMPLETE_GIG_WORKFLOWS_SUCCESS,
    payload: content,
  }),
  completeGigWorkFlowsFaliure: (content) => ({
    type: actionTypes.COMPLETE_GIG_WORKFLOWS_FAILURE,
    payload: content,
  }),
};
