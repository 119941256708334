import { p6ActionTypes } from '../actions';

const InitialState = {};

export const p6Reducer = (state = InitialState, action) => {
  switch (action.type) {
    case p6ActionTypes.GET_ITERATION_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case p6ActionTypes.GET_ITERATION_LIST_FAILURE:
      return { ...state, ...action.payload };
    case p6ActionTypes.ADD_P6_ITERATION_SUCCESS:
      return { ...state, ...action.payload };
    case p6ActionTypes.ADD_P6_ITERATION_FAILURE:
      return { ...state, ...action.payload };
    case p6ActionTypes.RELEASE_P6_ITERATION_SUCCESS:
      return { ...state, ...action.payload };
    case p6ActionTypes.RELEASE_P6_ITERATION_FAILURE:
      return { ...state, ...action.payload };
    case p6ActionTypes.GET_P6_FORM:
      return { ...state };
    case p6ActionTypes.GET_P6_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case p6ActionTypes.GET_P6_FORM_FAILURE:
      return { ...state, ...action.payload };
    case p6ActionTypes.SEND_P6_NOTIFICATION:
      return { ...state, ...action.payload };

    case p6ActionTypes.SEND_P6_NOTIFICATION_SUCCESS:
      return { ...state, ...action.payload };

    case p6ActionTypes.SEND_P6_NOTIFICATION_FAILURE:
      return { ...state, ...action.payload };

    case p6ActionTypes.EXPORT_P6_TABLE:
      return { ...state, ...action.payload };

    case p6ActionTypes.EXPORT_P6_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case p6ActionTypes.EXPORT_P6_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case p6ActionTypes.GET_P6_PROJECT_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case p6ActionTypes.GET_P6_PROJECT_LIST_FAILURE:
      return { ...state, ...action.payload };
    case p6ActionTypes.CLEAR_P6_DATA:
      return { ...action.payload };
    default:
      return state;
  }
};
