import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ICONS } from '../../../constants/assets';
import './gigActivityBreadCrumb.scss';

function GigActivityBreadCrumb(props) {
  const { onNavigation, currentPage, currentActivity } = props;
  const [steps, setSteps] = React.useState();

  React.useEffect(() => {
    currentActivity && setSteps(currentActivity.steps);
  }, [currentActivity]);

  const renderSteps = (step, index) => {
    return (
      <li key={index} onClick={() => (step.inProgress || step.isCompleted) && onNavigation(step.submenuName)}>
        <div className="activityPage">
          {step.isCompleted ? (
            <img src={ICONS.CHECK_CIRCLE_GREEN} />
          ) : (
            <div className={step.inProgress || step.isCompleted ? 'numCircle' : 'numCircleGray'}>{index + 1}</div>
          )}
          <div className={step.inProgress || step.isCompleted ? 'fragmentStepsDetails' : 'fragmentStepsDetailsGray'}>
            {step.submenuName}
          </div>
        </div>
        {currentPage === step.submenuName && <div className="underLine" />}
      </li>
    );
  };

  return (
    <div className="activityContainerBreadCrumb">
      <div className="activityContentBreadCrumb">
        <ul className="activityBreadCrumb">{steps && steps.map((step, index) => renderSteps(step, index))}</ul>
      </div>
      <div className="Line" />
    </div>
  );
}

export const mapStateToProps = (state) => ({
  currentActivity: get(state, 'gigWorkflowInformation.currentActivity'),
});

export default connect(mapStateToProps)(GigActivityBreadCrumb);
