export const actionTypes = {
  GET_MASTER_DATA_TABLE_STATUS: 'GET_MASTER_DATA_TABLE_STATUS',
  GET_MASTER_DATA_TABLE_STATUS_SUCCESS: 'GET_MASTER_DATA_TABLE_STATUS_SUCCESS',
  GET_MASTER_DATA_TABLE_STATUS_FAILURE: 'GET_MASTER_DATA_TABLE_STATUS_FAILURE',
  CHANGE_MASTER_DATA_TABLE_STATUS: 'CHANGE_MASTER_DATA_TABLE_STATUS',
  CHANGE_MASTER_DATA_TABLE_STATUS_SUCCESS: 'CHANGE_MASTER_DATA_TABLE_STATUS_SUCCESS',
  CHANGE_MASTER_DATA_TABLE_STATUS_FAILURE: 'CHANGE_MASTER_DATA_TABLE_STATUS_FAILURE',
  GET_MASTER_DATA_TABLE: 'GET_MASTER_DATA_TABLE',
  GET_MASTER_DATA_TABLE_SUCCESS: 'GET_MASTER_DATA_TABLE_SUCCESS',
  GET_MASTER_DATA_TABLE_FAILURE: 'GET_MASTER_DATA_TABLE_FAILURE',
  GET_MASTER_DATA_QA_TABLE: 'GET_MASTER_DATA_QA_TABLE',
  GET_MASTER_DATA_QA_TABLE_SUCCESS: 'GET_MASTER_DATA_QA_TABLE_SUCCESS',
  GET_MASTER_DATA_QA_TABLE_FAILURE: 'GET_MASTER_DATA_QA_TABLE_FAILURE',
  SAVE_MASTER_DATA_TABLE: 'SAVE_MASTER_DATA_TABLE',
  SAVE_MASTER_DATA_TABLE_SUCCESS: 'SAVE_MASTER_DATA_TABLE_SUCCESS',
  SAVE_MASTER_DATA_TABLE_FAILURE: 'SAVE_MASTER_DATA_TABLE_FAILURE',
  SEND_MASTER_TO_QA: 'SEND_MASTER_TO_QA',
  SEND_MASTER_TO_QA_SUCCESS: 'SEND_MASTER_TO_QA_SUCCESS',
  SEND_MASTER_TO_QA_FAILURE: 'SEND_MASTER_TO_QA_FAILURE',
  CLEAR_MASTER_DATA_TABLE: 'CLEAR_MASTER_DATA_TABLE',
  GET_MASTER_PREVIEW_DATA: 'GET_MASTER_PREVIEW_DATA',
  GET_MASTER_PREVIEW_DATA_SUCCESS: 'GET_MASTER_PREVIEW_DATA_SUCCESS',
  GET_MASTER_PREVIEW_DATA_FAILURE: 'GET_MASTER_PREVIEW_DATA_FAILURE',
  PULL_RELATED_GIGS_FAILURE: ' PULL_RELATED_GIGS_FAILURE',
  PULL_RELATED_GIGS_SUCCESS: ' PULL_RELATED_GIGS_SUCCESS',
  PULL_RELATED_GIGS: 'PULL_RELATED_GIGS',

  GET_CHARGECODE_BY_FRAGNET_ID: 'GET_CHARGECODE_BY_FRAGNET_ID',
  GET_CHARGECODE_BY_FRAGNET_ID_SUCCESS: 'GET_CHARGECODE_BY_FRAGNET_ID_SUCCESS',
  GET_CHARGECODE_BY_FRAGNET_ID_FAILURE: 'GET_CHARGECODE_BY_FRAGNET_ID_FAILURE',

  // Master Data QA
  GET_MASTER_QA_COUNT: 'GET_MASTER_QA_COUNT',
  GET_MASTER_QA_COUNT_SUCCESS: 'GET_MASTER_QA_COUNT_SUCCESS',
  GET_MASTER_QA_COUNT_FAILURE: 'GET_MASTER_QA_COUNT_FAILURE',

  GET_MASTER_QA_EDIT_MODE: 'GET_MASTER_QA_EDIT_MODE',
  GET_MASTER_QA_EDIT_MODE_SUCCESS: 'GET_MASTER_QA_EDIT_MODE_SUCCESS',
  GET_MASTER_QA_EDIT_MODE_FAILURE: 'GET_MASTER_QA_EDIT_MODE_FAILURE',

  SAVE_MASTER_QA_EDIT_MODE: 'SAVE_MASTER_QA_EDIT_MODE',
  SAVE_MASTER_QA_EDIT_MODE_SUCCESS: 'SAVE_MASTER_QA_EDIT_MODE_SUCCESS',
  SAVE_MASTER_QA_EDIT_MODE_FAILURE: 'SAVE_MASTER_QA_EDIT_MODE_FAILURE',

  RESET_MASTER_QA_CHANGES: 'RESET_MASTER_QA_CHANGES',
  RESET_MASTER_QA_CHANGES_SUCCESS: 'RESET_MASTER_QA_CHANGES_SUCCESS',
  RESET_MASTER_QA_CHANGES_FAILURE: 'RESET_MASTER_QA_CHANGES_FAILURE',

  SAVE_MASTER_QA_CHANGES: 'SAVE_MASTER_QA_CHANGES',
  SAVE_MASTER_QA_CHANGES_SUCCESS: 'SAVE_MASTER_QA_CHANGES_SUCCESS',
  SAVE_MASTER_QA_CHANGES_FAILURE: 'SAVE_MASTER_QA_CHANGES_FAILURE',
};

export const actions = {
  getMasterDataTableStatus: (data) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE_STATUS,
    data,
  }),
  getMasterDataTableStatusSuccess: (content) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE_STATUS_SUCCESS,
    payload: content,
  }),

  getMasterDataTableStatusFailure: (content) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE_STATUS_FAILURE,
    payload: content,
  }),
  changeMasterDataTableStatus: (data) => ({
    type: actionTypes.CHANGE_MASTER_DATA_TABLE_STATUS,
    data,
  }),
  changeMasterDataTableStatusSuccess: (content) => ({
    type: actionTypes.CHANGE_MASTER_DATA_TABLE_STATUS_SUCCESS,
    payload: content,
  }),

  changeMasterDataTableStatusFailure: (content) => ({
    type: actionTypes.CHANGE_MASTER_DATA_TABLE_STATUS_FAILURE,
    payload: content,
  }),
  getMasterDataTable: (data) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE,
    data,
  }),
  getMasterDataQATable: (data) => ({
    type: actionTypes.GET_MASTER_DATA_QA_TABLE,
    data,
  }),
  getMasterDataTableSuccess: (content) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE_SUCCESS,
    payload: content,
  }),

  getMasterDataTableFailure: (content) => ({
    type: actionTypes.GET_MASTER_DATA_TABLE_FAILURE,
    payload: content,
  }),

  getMasterDataQATableSuccess: (content) => ({
    type: actionTypes.GET_MASTER_DATA_QA_TABLE_SUCCESS,
    payload: content,
  }),

  getMasterDataQATableFailure: (content) => ({
    type: actionTypes.GET_MASTER_DATA_QA_TABLE_FAILURE,
    payload: content,
  }),
  saveMasterDataTable: (data) => ({
    type: actionTypes.SAVE_MASTER_DATA_TABLE,
    data,
  }),
  saveMasterDataTableSuccess: (content) => ({
    type: actionTypes.SAVE_MASTER_DATA_TABLE_SUCCESS,
    payload: content,
  }),

  saveMasterDataTableFailure: (content) => ({
    type: actionTypes.SAVE_MASTER_DATA_TABLE_FAILURE,
    payload: content,
  }),

  sendMasterToQA: (data) => ({
    type: actionTypes.SEND_MASTER_TO_QA,
    data,
  }),
  sendMasterToQASuccess: (content) => ({
    type: actionTypes.SEND_MASTER_TO_QA_SUCCESS,
    payload: content,
  }),

  sendMasterToQAFailure: (content) => ({
    type: actionTypes.SEND_MASTER_TO_QA_FAILURE,
    payload: content,
  }),

  clearMasterDataTable: () => ({
    type: actionTypes.CLEAR_MASTER_DATA_TABLE,
  }),

  getMasterPreviewData: (data) => ({
    type: actionTypes.GET_MASTER_PREVIEW_DATA,
    data,
  }),
  getMasterPreviewDataSuccess: (content) => ({
    type: actionTypes.GET_MASTER_PREVIEW_DATA_SUCCESS,
    payload: content,
  }),
  getMasterPreviewDataFailure: (content) => ({
    type: actionTypes.GET_MASTER_PREVIEW_DATA_FAILURE,
    payload: content,
  }),

  // getChargeCodeByFragnetId: (data) => ({
  //   type: actionTypes.GET_CHARGECODE_BY_FRAGNET_ID,
  //   data,
  // }),
  // getChargeCodeByFragnetIdSuccess: (content) => ({
  //   type: actionTypes.GET_CHARGECODE_BY_FRAGNET_ID_SUCCESS,
  //   payload: content,
  // }),
  // getChargeCodeByFragnetIdFailure: (content) => ({
  //   type: actionTypes.GET_CHARGECODE_BY_FRAGNET_ID_FAILURE,
  //   payload: content,
  // }),

  // Master Data QA
  getMasterQACount: (data) => ({
    type: actionTypes.GET_MASTER_QA_COUNT,
    data,
  }),
  getMasterQACountSuccess: (content) => ({
    type: actionTypes.GET_MASTER_QA_COUNT_SUCCESS,
    payload: content,
  }),
  getMasterQACountFailure: (content) => ({
    type: actionTypes.GET_MASTER_QA_COUNT_FAILURE,
    payload: content,
  }),
  getMasterQAEditMode: (data) => ({
    type: actionTypes.GET_MASTER_QA_EDIT_MODE,
    data,
  }),
  getMasterQAEditModeSuccess: (content) => ({
    type: actionTypes.GET_MASTER_QA_EDIT_MODE_SUCCESS,
    payload: content,
  }),
  getMasterQAEditModeFailure: (content) => ({
    type: actionTypes.GET_MASTER_QA_EDIT_MODE_FAILURE,
    payload: content,
  }),
  saveMasterQAEditMode: (data) => ({
    type: actionTypes.SAVE_MASTER_QA_EDIT_MODE,
    data,
  }),
  saveMasterQAEditModeSuccess: (content) => ({
    type: actionTypes.SAVE_MASTER_QA_EDIT_MODE_SUCCESS,
    payload: content,
  }),
  saveMasterQAEditModeFailure: (content) => ({
    type: actionTypes.SAVE_MASTER_QA_EDIT_MODE_FAILURE,
    payload: content,
  }),
  resetMasterQAChanges: (data) => ({
    type: actionTypes.RESET_MASTER_QA_CHANGES,
    data,
  }),
  resetMasterQAChangesSuccess: (content) => ({
    type: actionTypes.RESET_MASTER_QA_CHANGES_SUCCESS,
    payload: content,
  }),
  resetMasterQAChangesFailure: (content) => ({
    type: actionTypes.RESET_MASTER_QA_CHANGES_FAILURE,
    payload: content,
  }),
  saveMasterQAChanges: (data) => ({
    type: actionTypes.SAVE_MASTER_QA_CHANGES,
    data,
  }),
  saveMasterQAChangesSuccess: (content) => ({
    type: actionTypes.SAVE_MASTER_QA_CHANGES_SUCCESS,
    payload: content,
  }),
  saveMasterQAChangesFailure: (content) => ({
    type: actionTypes.SAVE_MASTER_QA_CHANGES_FAILURE,
    payload: content,
  }),

  pullRelatedGigsSuccess: (content) => ({
    type: actionTypes.PULL_RELATED_GIGS_SUCCESS,
    payload: content,
  }),
  pullRelatedGigsFailure: (content) => ({
    type: actionTypes.PULL_RELATED_GIGS_FAILURE,
    payload: content,
  }),

  pullRelatedGigs: (content) => ({
    type: actionTypes.PULL_RELATED_GIGS,
  }),
};
