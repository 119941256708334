import { get } from 'lodash';
import { projActivityAndFragStepsActionTypes, laminarScopeActionTypes } from '../actions';

const InitialState = {};

export const projActivityAndFragStepsReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projActivityAndFragStepsActionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS:
      return { ...state };
    case projActivityAndFragStepsActionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS_SUCCESS:
      return { ...state, ...action.payload };
    case projActivityAndFragStepsActionTypes.GET_PROJ_ACTIVITY_AND_FRAG_STEPS_FAILURE:
      return { ...state, ...action.payload };
    case laminarScopeActionTypes.GET_GIG_STATUS_SUCCESS:
      return {
        ...state,
        projActivityData: {
          ...state.projActivityData,
          fragnetSteps: get(state.projActivityData, 'fragnetSteps', []).map((steps) => ({
            ...steps,
            gignets: steps.gignets.map((gignet) => {
              const item = { ...gignet };
              if (item.giginstanceId === action.payload.gigStatusData.gigInstanceId) {
                item.gigStatus = action.payload.gigStatusData.progressStatus.currentStatus;
              }
              return item;
            }),
          })),
        },
      };
    case projActivityAndFragStepsActionTypes.FRAG_STEP_ACTIVE:
      return {
        ...state,
        projActivityData: {
          ...state.projActivityData,
          fragnetSteps: get(state.projActivityData, 'fragnetSteps', []).map((step, key) => ({
            ...step,
            isActive: key === action.payload,
            gignets: step.gignets.length
              ? [...step.gignets].map((gig, ind) => ({
                  ...gig,
                  isGigActive: ind === 0,
                }))
              : [],
          })),
        },
      };
    case projActivityAndFragStepsActionTypes.FRAG_GIG_ACTIVE:
      return {
        ...state,
        projActivityData: {
          ...state.projActivityData,
          fragnetSteps: get(state.projActivityData, 'fragnetSteps', []).map((step, key) => ({
            ...step,
            gignets: [...step.gignets].map((gig, ind) => ({
              ...gig,
              isGigActive: ind === action.payload,
            })),
          })),
        },
      };

    case projActivityAndFragStepsActionTypes.CLEAR_FRAGNETS:
      return { ...state, projActivityData: {} };

    default:
      return state;
  }
};
