const ASYNC_MASTER_FIELDS = [
  'masterActivitiesFragnets[].fragnetId',
  // 'masterDataGig[].durationRule',
  // 'masterDataGig[].earningRule',
  // 'masterDataGig[].sortOrder',
  'masterGigType[].gigtypeId',
  'masterFragnetSteps[].sortOrder',
  'masterFragnetSteps[].fragnetstepEarningRuleEngr',
  'masterFragnetSteps[].fragnetstepEarningRuleTech',
  'masterOutput[].ioPurposeStep',
];

const TABLE_MASTER_TYPES = {
  // Heirarchy
  heirarchyDisciplines: 'disciplineListMaster',
  heirarchySubjects: 'subjectDOSMasterType',
  hierarchyGigSubjects: 'gigSubjectMaster',
  heirarchySubtype: 'subtypeMaster',
  // Activities
  masterActivitiesFragnets: 'fragnetMaster',
  masterActivitiesDOS: 'backendMaster',
  // Fragnet Steps
  masterFragnetSteps: 'FragnetStepMaster',
  masterFragnetStepType: 'FragnetStepTypeMaster',
  masterWfVersions: 'workflowVersion',
  masterFragnetLogicTies: 'fragnetLogicTiesMaster',
  // Gigs
  masterDataGig: 'GignetMaster',
  masterGigType: 'GigTypeMaster',
  // Input Output
  masterOutput: 'outputBackendMaster',
  masterInput: 'inputBackendMaster',
  // References
  masterReferences: 'refLibBackendMaster',
  masterGigReferences: 'gigReferencesBackendMaster',
};

const ASSESSMENT_RESULT_TABLE_FIELD_KEY = {
  fields: [
    {
      key: 'overall',
      label: 'OVERALL',
      sortOrder: 1,
      fields: [
        {
          key: 'manHours',
          label: 'Manhours',
          sortOrder: 1,
        },
        {
          key: 'daysDelay',
          label: 'Days Delay',
          sortOrder: 2,
        },
        {
          key: 'daysChangeInFloat',
          label: 'Days Change in Float',
          sortOrder: 3,
          type: 'number',
        },
      ],
    },
    {
      key: 'activities',
      label: 'ACTIVITIES',
      sortOrder: 2,
      fields: [
        {
          key: 'manHours',
          label: 'Manhours',
          sortOrder: 1,
        },
        {
          key: 'daysDelay',
          label: 'Days Delay',
          sortOrder: 2,
        },
        {
          key: 'daysChangeInFloat',
          label: 'Days Change in Float',
          sortOrder: 3,
          type: 'number',
        },
      ],
    },
  ],
};

export { ASYNC_MASTER_FIELDS, TABLE_MASTER_TYPES, ASSESSMENT_RESULT_TABLE_FIELD_KEY };
