export const actionTypes = {
  GET_LAMINAR_SCOPE_GIG_INFO: 'GET_LAMINAR_SCOPE_GIG_INFO',
  GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS: 'GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS',
  GET_LAMINAR_SCOPE_GIG_INFO_FAILURE: 'GET_LAMINAR_SCOPE_GIG_INFO_FAILURE',
  EDIT_GET_LAMINAR_SCOPE_GIG_INFO: 'EDIT_GET_LAMINAR_SCOPE_GIG_INFO',

  SAVE_GET_LAMINAR_SCOPE_GIG_INFO: 'SAVE_GET_LAMINAR_SCOPE_GIG_INFO',
  SAVE_GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS: 'SAVE_GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS',
  SAVE_GET_LAMINAR_SCOPE_GIG_INFO_FAILURE: 'SAVE_GET_LAMINAR_SCOPE_GIG_INFO_FAILURE',

  GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN: 'GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN',
  GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_SUCCESS: 'GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_SUCCESS',
  GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_FAILURE: 'GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_FAILURE',
  MOVE_WITHOUT_FULL_PAGE: 'MOVE_WITHOUT_FULL_PAGE',
};

export const actions = {
  moveWithoutFullPage: (data) => ({
    type: actionTypes.MOVE_WITHOUT_FULL_PAGE,
    payload: data,
  }),
  getLaminarScopeGigInfo: (data) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO,
    payload: data,
  }),

  getLaminarScopeGigInfoSucceeded: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS,
    payload: content,
  }),

  getLaminarScopeGigInfoFailed: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO_FAILURE,
    payload: content,
  }),

  saveGetLaminarScopeGigInfo: (content) => ({
    type: actionTypes.SAVE_GET_LAMINAR_SCOPE_GIG_INFO,
    payload: content,
  }),
  saveGetLaminarScopeGigInfoSuccess: (content) => ({
    type: actionTypes.SAVE_GET_LAMINAR_SCOPE_GIG_INFO_SUCCESS,
    payload: content,
  }),
  saveGetLaminarScopeGigInfoFailure: (content) => ({
    type: actionTypes.SAVE_GET_LAMINAR_SCOPE_GIG_INFO_FAILURE,
    payload: content,
  }),

  getLaminarScopeGigInfoDropdown: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN,
    payload: content,
  }),
  getLaminarScopeGigInfoDropDownSucceeded: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_SUCCESS,
    payload: content,
  }),
  getLaminarScopeGigInfoDropDownFailed: (content) => ({
    type: actionTypes.GET_LAMINAR_SCOPE_GIG_INFO_DROP_DOWN_FAILURE,
    payload: content,
  }),

  editGigInfo: (content) => ({
    type: actionTypes.EDIT_GET_LAMINAR_SCOPE_GIG_INFO,
    payload: content,
  }),
};
