import React from 'react';
import { featureFlag } from '../helpers/common';

const useFeatureFlags = (enabledFeatureFlagsData) => {
  React.useEffect(() => {
    featureFlag(enabledFeatureFlagsData);
  }, []);
};

export default useFeatureFlags;
