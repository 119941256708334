import { put, takeEvery, call, select } from 'redux-saga/effects';
import { get, cloneDeep } from 'lodash';
import { manhrsbudgetlistmock } from '../../mocks/manHrsBudgetListMock';
import { projActivityMock } from '../../mocks/projectActivityMock';
import servicelist, {
  projectActivityServiceList,
  saveProjectActivity,
  getActivityModifiedList,
  addDefineActivities,
  getDefineActivites,
  editDefineActivities,
  deleteActivity,
  saveActivityModifiedList,
  completeProjectForms,
  addAndGetManHourBudget,
  editManHourBudget,
} from '../../services/serviceList';

import { projActivitiesActions, projActivitiesActionTypes, commonActions, projectSetupActions } from '../actions';
import AxiosInstance from '../../services/commons/Interceptor';

export function* getProjActivityInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const discipline = yield getSelectedDiscipline();
    let responseData;
    responseData = yield call(AxiosInstance.get, addAndGetManHourBudget('projectSetup', payload.data.projectNumber), {
      params: {
        // dosActivity: payload.data.dosActivity,
        submenuId: payload.data.submenuId,
        refrenceKey: payload.data.refrenceKey,
        discipline,
        menuId: payload.data.menuId,
        activitySubMenuId: payload.data.activitySubMenuId,
      },
    });

    yield put(
      projActivitiesActions.getProjActivityInfoFormSucceeded({
        isSucceeded: true,
        tableData: responseData.data.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getProjActivityInfoFormFailed({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjActivitiesInfoStatus() {
  yield takeEvery(projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_FORM, getProjActivityInfoSaga);
}

export function* saveProjActivityInfoSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const discipline = yield getSelectedDiscipline();
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', false);
    const stateKey = yield select(getStateKey);
    let responseData;
    /**
     * Edit data if its aldready created
     */
    if (payload.data.id) {
      responseData = yield call(
        AxiosInstance.put,
        editManHourBudget('projectSetup', payload.data.projectNumber, payload.data.id),
        {
          dosActivity: payload.data.dosActivity,
          projectNumber: payload.data.projectNumber,
          menuId: payload.data.menuId,
          submenuId: payload.data.submenuId,
          refrenceKey: payload.data.refrenceKey,
          discipline,
          id: payload.data.id,
          body: payload.data.body,
        }
      );
    } else {
      responseData = yield call(
        AxiosInstance.post,
        addAndGetManHourBudget('projectSetup', payload.data.projectNumber),
        {
          dosActivity: payload.data.dosActivity,
          projectNumber: payload.data.projectNumber,
          menuId: payload.data.menuId,
          submenuId: payload.data.submenuId,
          refrenceKey: payload.data.refrenceKey,
          discipline,
          body: payload.data.body,
        }
      );
    }
    if (responseData) {
      yield put(
        projActivitiesActions.saveProjActivityInfoFormSucceeded({
          isSucceeded: true,
          savedForm: responseData.data,
        })
      );
      yield put(commonActions.infoPopup({ messageId: 'Your form has been saved' }));
      yield put(
        projActivitiesActions.getProjActivityInfo({
          // dosActivity: payload.data.dosActivity,
          projectNumber: payload.data.projectNumber,
          submenuId: payload.data.submenuId,
          refrenceKey: payload.data.refrenceKey,
          menuId: payload.data.menuId,
          activitySubMenuId: '3bfdb421-64a3-4800-b476-7949ab9f58e3',
        })
      );
      yield put(
        projectSetupActions.getProjectMenu({
          projectNumber: payload.data.projectNumber,
          stateKey,
        })
      );
    } else {
      yield put(
        projActivitiesActions.saveProjActivityInfoFormFailed({
          isSucceeded: false,
        })
      );
    }
  } catch (e) {
    let errorMessage = '';
    if (e?.response?.data?.message) {
      errorMessage =
        e.response.data.errors.length > 0
          ? `${e.response.data.errors.join('\n')}`
          : 'Something went wrong. A system error has occurred';
    } else {
    }
    const infoPopupMessage = errorMessage;

    yield put(
      commonActions.infoPopup({
        messageId: infoPopupMessage,
        theme: 'alert-danger',
      })
    );

    yield put(
      projActivitiesActions.getProjActivityInfo({
        // dosActivity: payload.data.dosActivity,
        projectNumber: payload.data.projectNumber,
        submenuId: payload.data.submenuId,
        refrenceKey: payload.data.refrenceKey,
        menuId: payload.data.menuId,
        activitySubMenuId: '3bfdb421-64a3-4800-b476-7949ab9f58e3',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* saveProjActivitiesInfoStatus() {
  yield takeEvery([projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_FORM], saveProjActivityInfoSaga);
}

const getForm = (state) => state.form;
const getDisciplines = (state) => state.projectSetupReducer?.menuList?.disciplines;

function* getSelectedDiscipline() {
  try {
    // yield put(commonActions.showLoader(true));
    const form = yield select(getForm);
    const disciplines = yield select(getDisciplines);
    return form?.DefineActivities?.values
      ? form?.DefineActivities?.values?.Discipline
      : disciplines?.[0] === 'Management'
      ? disciplines?.[1]
      : disciplines?.[0];
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
  }
  // yield put(commonActions.showLoader(false));
}

export function* getProjActivityByDisciplineSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { menuId, submenuId, projectNumber } = payload.data;
    const discipline = yield getSelectedDiscipline();
    const responseData = yield call(AxiosInstance.get, projectActivityServiceList('projectSetup', projectNumber), {
      params: {
        menuId,
        submenuId,
        discipline,
      },
    });

    yield put(
      projActivitiesActions.getProjActivityByDisciplineSucceeded({
        isSucceeded: true,
        dosActivityData: responseData.data.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getProjActivityByDisciplineFailed({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjActivityByDisciplineStatus() {
  yield takeEvery([projActivitiesActionTypes.GET_PROJ_ACTIVITY_INFO_BY_DISCIPLINE], getProjActivityByDisciplineSaga);
}

export function* getModifiedProjActivityListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { menuId, submenuId, projectNumber, discipline: dis } = payload.data;
    const discipline = dis || (yield getSelectedDiscipline());
    const responseData = yield call(AxiosInstance.get, getActivityModifiedList('projectSetup', projectNumber), {
      params: {
        menuId,
        submenuId,
        discipline,
      },
    });

    yield put(
      projActivitiesActions.getModifiedProjActivityListSucceeded({
        isSucceeded: true,
        modifiedProjectActivityList: cloneDeep(responseData.data.data),
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getModifiedProjActivityListFailed({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* getModifiedProjActivityListStatus() {
  yield takeEvery([projActivitiesActionTypes.GET_MODIFIED_PROJ_ACTIVITY_LIST], getModifiedProjActivityListSaga);
}

export function* saveModifiedProjActivityListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { menuId, submenuId, projectNumber, infoData, discipline: dis, notSetResponseData } = payload.data;
    const discipline = dis || (yield getSelectedDiscipline());
    const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', false);
    const stateKey = yield select(getStateKey);
    const responseData = yield call(
      AxiosInstance.put,
      saveActivityModifiedList('projectSetup', projectNumber),
      infoData,
      {
        params: {
          menuId,
          submenuId,
          discipline,
        },
      }
    );

    const responseObj = {
      isSucceeded: true,
      modifiedProjectActivityList: responseData.data.data,
    };

    yield put(projActivitiesActions.saveModifiedProjActivityListSucceeded(responseObj));
    yield put(
      projectSetupActions.getProjectMenu({
        projectNumber: responseData.data.data.projectNumber,
        stateKey,
      })
    );

    yield put(projectSetupActions.getProjectSubMenu(payload.data));
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.saveModifiedProjActivityListFailed({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveModifiedProjActivityListStatus() {
  yield takeEvery([projActivitiesActionTypes.SAVE_MODIFIED_PROJ_ACTIVITY_LIST], saveModifiedProjActivityListSaga);
}

export function* getProjActivityTableDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const { projectNumber, discipline, submenuId, refrenceKey, page, searchKey } = payload.data;

    yield put(
      projActivitiesActions.projActivityTableDataStatus({
        projActivityTableDataStatus: null,
      })
    );

    const responseData = yield call(AxiosInstance.get, getDefineActivites('projectSetup', projectNumber), {
      params: {
        // dosActivity,
        discipline,
        submenuId,
        refrenceKey,
        page,
        searchKey,
      },
    });

    if (responseData) {
      yield put(
        projActivitiesActions.getProjActivityTableDataSuccess({
          isSucceeded: true,
          projectActivityTableData: responseData.data.data,
        })
      );

      yield put(
        projActivitiesActions.projActivityTableDataStatus({
          projActivityTableDataStatus: {
            isSucceeded: true,
            status: responseData.status,
          },
        })
      );
    } else {
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getProjActivityTableDataFailure({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* getProjActivityTableDataStatus() {
  yield takeEvery([projActivitiesActionTypes.GET_PROJ_ACTIVITY_TABLE_DATA], getProjActivityTableDataSaga);
}

export function* saveProjActivityTableDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const requestData = cloneDeep(payload.data);
    let responseData;
    delete requestData.headers;
    delete requestData.fields;
    delete requestData.message;
    delete requestData.error;
    delete requestData.totalPages;
    delete requestData.matchCount;
    delete requestData.isCompleteDisable;
    delete requestData.isGlobalEditEnable;

    if (payload.data.id) {
      responseData = yield call(
        AxiosInstance.put,
        editDefineActivities('projectSetup', requestData.projectNumber, requestData.id),
        requestData
      );
    } else {
      delete requestData.id;
      responseData = yield call(
        AxiosInstance.post,
        addDefineActivities('projectSetup', requestData.projectNumber),
        requestData
      );
    }
    const projectActivitiesTableData = {
      ...payload.data,
      id: responseData.data.data.id,
      totalPages: responseData.data.data.totalPages,
      pageNumber: responseData.data.data.pageNumber,
      isCompleteDisable: responseData.data.data.isCompleteDisable,
    };
    projectActivitiesTableData.body = responseData.data.data.body;
    if (projectActivitiesTableData.matchCount)
      projectActivitiesTableData.matchCount = responseData.data.data.matchCount;
    yield put(
      projActivitiesActions.saveProjActivityTableDataSuccess({
        isSucceeded: true,
        projectActivityTableData: projectActivitiesTableData,
      })
    );

    yield put(
      commonActions.infoPopup({
        messageId: 'Your Define Activities has been Saved',
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.saveProjActivityTableDataFailure({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveProjActivityTableDataStatus() {
  yield takeEvery([projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_TABLE_DATA], saveProjActivityTableDataSaga);
}

export function* saveProjActivityByDisciplineSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const discipline = yield getSelectedDiscipline();
    const data = {
      menuId: payload.metaInfo.menuId,
      submenuId: payload.metaInfo.submenuId,
      activityData: payload.data.activityData,
      disciplineDOS: discipline,
      projectNumber: payload.metaInfo.projectNumber,
    };

    const responseData = yield call(
      AxiosInstance.post,
      saveProjectActivity('projectSetup', payload.metaInfo.projectNumber),
      data
    );

    responseData && payload.setShowModal(false);

    yield put(
      projActivitiesActions.saveProjActivityByDisciplineSucceeded({
        modalActivityDataSaved: true,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.saveProjActivityByDisciplineFailed({
      modalActivityDataSaved: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* saveProjActivityByDisciplineStatus() {
  yield takeEvery([projActivitiesActionTypes.SAVE_PROJ_ACTIVITY_INFO_BY_DISCIPLINE], saveProjActivityByDisciplineSaga);
}

export function* getManHourBudgetListSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.get, servicelist('manHrsBudgetList'), {
      params: payload.payload,
    });

    yield put(
      projActivitiesActions.getManHourBudgetListSuccess({
        isSucceeded: true,
        manHourListItems: responseData.data,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getManHourBudgetListFaliure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getManHourBudgetStatus() {
  yield takeEvery([projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_LIST], getManHourBudgetListSaga);
}

export function* getManHourBudgetTableSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const response = projActivityMock;
    yield put(
      projActivitiesActions.getManHourBudgetTableSuccess({
        isSucceeded: true,
        manHourTableData: response,
      })
    );
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.getManHourBudgetTableFaliure({ isSucceeded: false });
  }
  yield put(commonActions.showLoader(false));
}

export function* getManHourBudgetTableStatus() {
  yield takeEvery([projActivitiesActionTypes.GET_MAN_HOUR_BUDGET_TABLE], getManHourBudgetTableSaga);
}

export function* completeManHourBudgetListItemSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(AxiosInstance.post, servicelist('manHrsBudgetList'), payload.payload);
    if (responseData) {
      yield put(
        projActivitiesActions.completeManHourBudgetListItemSuccess({
          isSucceeded: true,
          completeItem: responseData,
        })
      );

      yield put(
        projActivitiesActions.getManHourBudgetList({
          projectNumber: payload.payload.projectNumber,
          submenuId: payload.payload.submenuId,
        })
      );
    } else {
      yield put(
        projActivitiesActions.completeManHourBudgetListItemFaliure({
          isSucceeded: false,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
  }
  yield put(commonActions.showLoader(false));
}

export function* completeManHourBudgetListItemStatus() {
  yield takeEvery([projActivitiesActionTypes.COMPLETE_MAN_HOUR_BUDGET_LIST_ITEM], completeManHourBudgetListItemSaga);
}

export function* completeDefineActivitySaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const responseData = yield call(
      AxiosInstance.put,
      completeProjectForms('projectSetup', payload.data.projectNumber),
      payload.data
    );
    const defineActivity = '3bfdb421-64a3-4800-b476-7949ab9f58e3';
    const manhourBudget = 'fe0cb894-9ba3-4ad1-ac07-f4f7b2810d4b';
    if (!payload.data.isComplete && payload.data.submenuId === defineActivity) {
      payload.data.submenuId = manhourBudget;
      yield put(projActivitiesActions.completeDefineActivity(payload.data));
    } else {
      const getStateKey = (state) => get(state, 'projectSetupReducer.projectDetails.stateKey', false);
      const stateKey = yield select(getStateKey);
      yield put(
        projActivitiesActions.completeDefineActivitySuccess({
          isSucceeded: true,
          completeResponse: responseData.data,
        })
      );

      if (payload.data.isComplete) {
        yield put(
          commonActions.infoPopup({
            messageId: 'Your Define Activities has been Completed',
          })
        );
      } else if (payload.data.showMessage) {
        yield put(commonActions.infoPopup({ messageId: 'You can edit your Activities' }));
      }

      yield put(
        projectSetupActions.getProjectMenu({
          projectNumber: responseData.data.data.projectNumber,
          stateKey,
        })
      );

      yield put(projectSetupActions.getProjectSubMenu(payload.data));
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.completeDefineActivityFaliure({
      isSucceeded: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* completeDefineActivityStatus() {
  yield takeEvery([projActivitiesActionTypes.COMPLETE_DEFINE_ACTIVITY], completeDefineActivitySaga);
}

export function* deleteProjActivityTableDataSaga(payload) {
  try {
    yield put(commonActions.showLoader(true));
    const discipline = yield getSelectedDiscipline();
    payload.data.discipline = discipline;
    yield put(
      projActivitiesActions.deleteProjActivityTableDataFaliure({
        isSuccessfullyDeleted: false,
      })
    );
    const responseData = yield call(AxiosInstance.delete, deleteActivity('projectSetup', payload.data.projectNumber), {
      params: payload.data,
    });

    if (responseData && responseData.status === 200) {
      yield put(
        projActivitiesActions.deleteProjActivityTableDataSuccess({
          isSuccessfullyDeleted: true,
        })
      );
    }
  } catch (e) {
    yield put(
      commonActions.infoPopup({
        messageId: 'Something went wrong. A system error has occurred.',
        theme: 'alert-danger',
      })
    );
    projActivitiesActions.deleteProjActivityTableDataFaliure({
      isSuccessfullyDeleted: false,
    });
  }
  yield put(commonActions.showLoader(false));
}

export function* deleteProjActivityTableDataStatus() {
  yield takeEvery([projActivitiesActionTypes.DELETE_PROJ_ACTIVITY_TABLE_DATA], deleteProjActivityTableDataSaga);
}
