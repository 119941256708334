export const actionTypes = {
  GET_WBS_INFO_FORM: 'GET_WBS_INFO_FORM',
  GET_WBS_INFO_FORM_SUCCESS: 'GET_WBS_INFO_FORM_SUCCESS',
  GET_WBS_INFO_FORM_FAILURE: 'GET_WBS_INFO_FORM_FAILURE',
  SAVE_WBS_INFO_FORM: 'SAVE_WBS_INFO_FORM',
  SAVE_WBS_INFO_FORM_SUCCESS: 'SAVE_WBS_INFO_FORM_SUCCESS',
  SAVE_WBS_INFO_FORM_FAILURE: 'SAVE_WBS_INFO_FORM_FAILURE',
};

export const actions = {
  getWbsInfoForm: (data) => ({
    type: actionTypes.GET_WBS_INFO_FORM,
    data,
  }),
  getWbsInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_WBS_INFO_FORM_SUCCESS,
    payload: content,
  }),
  getWbsInfoFormFailed: (content) => ({
    type: actionTypes.GET_WBS_INFO_FORM_FAILURE,
    payload: content,
  }),
  saveWbsInfoForm: (data) => ({
    type: actionTypes.SAVE_WBS_INFO_FORM,
    data,
  }),
  saveWbsInfoFormSucceeded: (content) => ({
    type: actionTypes.SAVE_WBS_INFO_FORM_SUCCESS,
    payload: content,
  }),
  saveWbsInfoFormFailed: (content) => ({
    type: actionTypes.SAVE_WBS_INFO_FORM_FAILURE,
    payload: content,
  }),
};
