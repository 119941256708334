import { gigReferenceActionType } from '../actions';

const InitialState = {
  gigReferenceData: [],
  editGigReferenceFlag: false,
  gigReferenceDropdownData: [],
  savedReferenceData: [],
  deleteReferenceData: [],
};

export const gigReferenceReducer = (state = InitialState, action) => {
  switch (action.type) {
    case gigReferenceActionType.GET_REFERENCE_DATA_SUCCESS:
      return { ...state, gigReferenceData: action.payload };
    case gigReferenceActionType.GET_REFERENCE_DATA_FAILURE:
      return { ...state, gigReferenceData: action.payload };

    case gigReferenceActionType.GET_REFERENCE_DROPDOWN_SUCCESS:
      return { ...state, gigReferenceDropdownData: action.payload };
    case gigReferenceActionType.GET_REFERENCE_DROPDOWN_FAILURE:
      return { ...state, gigReferenceDropdownData: action.payload };

    case gigReferenceActionType.SAVE_REFERENCE_DATA_SUCCESS:
      return { ...state, savedReferenceData: action.payload };
    case gigReferenceActionType.SAVE_REFERENCE_DATA_FAILURE:
      return { ...state, savedReferenceData: action.payload };

    case gigReferenceActionType.DELETE_REFERENCE_DATA_SUCCESS:
      return { ...state, deleteReferenceData: action.payload };
    case gigReferenceActionType.DELETE_REFERENCE_DATA_FAILURE:
      return { ...state, deleteReferenceData: action.payload };

    case gigReferenceActionType.SET_EDIT_REFERENCE_FLAG:
      return { ...state, editGigReferenceFlag: action.payload };
    default:
      return state;
  }
};
