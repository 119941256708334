export const actionTypes = {
  GET_DISCUSSION_DATA: 'GET_DISCUSSION_DATA',
  GET_DISCUSSION_DATA_SUCCESS: 'GET_DISCUSSION_DATA_SUCCESS',
  GET_DISCUSSION_DATA_FAILURE: 'GET_DISCUSSION_DATA_FAILURE',
  SAVE_DISCUSSION_DATA: 'SAVE_DISCUSSION_DATA',
  SAVE_DISCUSSION_DATA_SUCCESS: 'SAVE_DISCUSSION_DATA_SUCCESS',
  SAVE_DISCUSSION_DATA_FAILURE: 'SAVE_DISCUSSION_DATA_FAILURE',
  GET_USER_DATA: 'GET_USER_DATA',
  GET_USER_DATA_SUCCESS: 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAILURE: 'GET_USER_DATA_FAILURE',

  GET_LAMINAR_DISCUSSION: 'GET_LAMINAR_DISCUSSION',
  GET_LAMINAR_DISCUSSION_SUCCESS: 'GET_LAMINAR_DISCUSSION_SUCCESS',
  GET_LAMINAR_DISCUSSION_FAILURE: 'GET_LAMINAR_DISCUSSION_FAILURE',
};

export const actions = {
  getDiscussionData: (content) => ({
    type: actionTypes.GET_DISCUSSION_DATA,
    payload: content,
  }),
  getDiscussionDataSucceeded: (content) => ({
    type: actionTypes.GET_DISCUSSION_DATA_SUCCESS,
    payload: content,
  }),
  getDiscussionDataFailed: (content) => ({
    type: actionTypes.GET_DISCUSSION_DATA_FAILURE,
    payload: content,
  }),

  getLaminarDiscussion: (content) => ({
    type: actionTypes.GET_LAMINAR_DISCUSSION,
    payload: content,
  }),

  getLaminarDiscussionSucceeded: (content) => ({
    type: actionTypes.GET_LAMINAR_DISCUSSION_SUCCESS,
    payload: content,
  }),
  getLaminarDiscussionFailed: (content) => ({
    type: actionTypes.GET_LAMINAR_DISCUSSION_FAILURE,
    payload: content,
  }),

  saveDiscussionData: (content) => ({
    type: actionTypes.SAVE_DISCUSSION_DATA,
    payload: content,
  }),
  saveDiscussionDataSucceeded: (content) => ({
    type: actionTypes.SAVE_DISCUSSION_DATA_SUCCESS,
    payload: content,
  }),
  saveDiscussionDataFailed: (content) => ({
    type: actionTypes.SAVE_DISCUSSION_DATA_FAILURE,
    payload: content,
  }),
  getUserData: (content) => ({
    type: actionTypes.GET_USER_DATA,
    payload: content,
  }),
  getUserDataSucceeded: (content) => ({
    type: actionTypes.GET_USER_DATA_SUCCESS,
    payload: content,
  }),
  getUserDataFailed: (content) => ({
    type: actionTypes.GET_USER_DATA_FAILURE,
    payload: content,
  }),
};
