import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { ICONS } from '../../../constants/assets/index';
import GenerateActivityTableRow from './generateActivityTableRow';
import { projActivitiesActions, commonActions } from '../../../redux/actions';
import './styles.scss';

function GenerateActivityTable(props) {
  const dispatch = useDispatch();
  const [tableData, setTableData] = React.useState();
  const { property, selectedOption, setIsSaved, setIsResetEnable, resetTableData, isSaved, isTableDisabled } = props;

  React.useEffect(() => {
    if (props.tableData) {
      const { fields, body, id } = props.tableData;

      fields[1].isDisabled = !selectedOption.primary.show;
      fields[2].isDisabled = !selectedOption.secondary.show;
      fields[3].isDisabled = !selectedOption.terciary.show;

      if (!id) {
        if (!selectedOption.primary.checked) setValueForToggleOff(body, fields[1]);
        if (!selectedOption.secondary.checked) setValueForToggleOff(body, fields[2]);
        if (!selectedOption.terciary.checked) setValueForToggleOff(body, fields[3]);
      }

      setTableData(props.tableData);
    }
  }, [props.tableData, selectedOption]);
  React.useEffect(() => {
    if (tableData != undefined && props.isAddRow) {
      handleAddRow();
    }
  }, [tableData]);
  const setValueForToggleOff = (body, field) => {
    body[0] &&
      body.forEach((data) => {
        data[field.key] = [];
        field.titleMap[0] &&
          field.titleMap.forEach((t) => {
            if (t.value !== 'none' && t.value !== 'COMMON') {
              data[field.key].push(t.value);
            }
          });
      });
  };

  const updateTableData = (rowData, index) => {
    const data = cloneDeep(tableData);
    data.body[index] = rowData;
    props.tableData.body[index] = rowData;
    setIsSaved(false);
    setIsResetEnable(true);
  };

  const handleAddRow = () => {
    if (props.tableData.totalPages == 0 || props.currentPage + 1 == props.tableData.totalPages) {
      const data = cloneDeep(tableData);
      const obj = {
        activity: '',
        fullActivityName: '',
        refrenceKey: selectedOption.key,
      };
      data.body.push(obj);
      props.tableData.body = data.body;
      setTableData(data);
      props.setIsAddRow(false);
    } else if (!isSaved) {
      dispatch(
        commonActions.infoPopup({ messageId: 'Please save your edits before proceeding.', theme: 'alert-danger' })
      );
    } else {
      props.setIsAddRow(true);
    }
  };

  const handleDeleteRow = (index) => {
    const data = cloneDeep(tableData);
    data.body[index].isDeleted = true;
    props.tableData.body = data.body;
    setTableData(data);
    setIsResetEnable(true);
    setIsSaved(false);
  };

  const renderColumnName = (header, index) => {
    const a = header.key.includes('primary') && selectedOption.primary.show;
    if (
      header.key === 'activity' ||
      (header.key.includes('primary') && selectedOption.primary.show) ||
      (header.key.includes('secondary') && selectedOption.secondary.show) ||
      (header.key.includes('terciary') && selectedOption.terciary.show) || 
      (header.key == 'customCWACode' && selectedOption?.isCustomActivityCode) ||
      (header.key == 'customActivityCode' && selectedOption?.isCustomActivityCode)
    ) {
      return (
        <>
          <th key={`genAct-table-header-column-${index}`} className={`${(selectedOption?.isCustomActivityCode && (header.key == 'customCWACode'|| header.key == 'customActivityCode')) && 'genAct-table-custom-code-width'}`}>
            <div className="genAct-table-header-container"><div className="genAct-table-header-label">{header.label}</div></div>
          </th>
        </>
      );
    }
  };

  return (
    <>
      {tableData && (
        <>
          <div className="genAct-table-container">
            <div className="genAct-table-frame">
              <Table borderless>
                <thead className="genAct-table-header">
                  <tr className="genAct-table-header-row">
                  {tableData.headers &&
                      tableData.headers.map((header, index) => renderColumnName(header, index))}
                    <th />
                  </tr>
                </thead>
                <tbody className="genAct-table-body">
                  <tr className="genAct-table-body-firstRow">
                    <td
                      colSpan="7"
                      className={`${
                        isTableDisabled
                          ? 'genAct-table-body-firstRow-completed'
                          : 'genAct-table-body-firstRow-notCompleted'
                      }`}
                    >
                      {selectedOption.name}
                    </td>
                  </tr>
                  {tableData.body &&
                    tableData.body.map(
                      (rowData, index) =>
                        rowData &&
                        !rowData.isDeleted && (
                          <GenerateActivityTableRow
                            rowData={rowData}
                            resetTableData={resetTableData}
                            fields={tableData.fields}
                            handleDeleteRow={handleDeleteRow}
                            index={index}
                            isCompleted={isTableDisabled}
                            updateTableData={updateTableData}
                            setIsSaved={setIsSaved}
                            setIsResetEnable={setIsResetEnable}
                            selectedOption={selectedOption}
                          />
                        )
                    )}
                </tbody>
              </Table>
            </div>
            <div className="genAct-table-footer">
              {!isTableDisabled && (
                <>
                  <div className="genetateActivityTableRow-addRowButton">
                    <div style={{ cursor: 'pointer' }} onClick={handleAddRow}>
                      <img src={ICONS.ADD_FIELD} />
                      <span className="genetateActivityTableRow-addRowButton-label">Add Row</span>
                    </div>
                  </div>
                  <hr style={{ margin: '0 30px' }} />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateActivityTable);
