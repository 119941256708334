import React from 'react';

const IconGenerator = (props) => (
  <svg
    className={props.classes}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {props.iconTitle ? <title>{props.iconTitle} </title> : <title>{null} </title>}
    <use xlinkHref={`#${props.iconName}`} />
  </svg>
);
export default IconGenerator;
