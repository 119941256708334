import React from 'react';
import { connect, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { ICONS } from '../../../constants/assets/index';
import WbsCriteriaRow from './wbsCriteriaRow';
import { projActivitiesActions } from '../../../redux/actions';
import useFeatureFlags from '../../../customHooks/usefeatureFlags';
import './styles.scss';

function WbsCriteriaTable(props) {
  const headers = [{ value: 'Activity' }, { value: 'Primary' }, { value: 'Secondary' }, { value: 'Tertiary' }];

  // feature toggling
  useFeatureFlags(['F-BRNDCHNG-24082021']);
  const [enabledFeatureFlags, setEnabledFeatureFlags] = React.useState([]);
  const globalEnabledFeatureFlagsData = useSelector((state) => get(state, 'commonData.enabledFlags', []));

  React.useEffect(() => {
    if (!isEqual(globalEnabledFeatureFlagsData, enabledFeatureFlags)) {
      setEnabledFeatureFlags(globalEnabledFeatureFlagsData);
    }
  }, [globalEnabledFeatureFlagsData]);

  React.useEffect(() => {
    const { getProjActivityByDiscipline, projectNumber, menuId, submenuId } = props;
    getProjActivityByDiscipline({
      projectNumber,
      menuId,
      submenuId,
    });
  }, []);

  return (
    <>
      <section>
        <div>
          You must first select/de-select the WBS criteria you would like{' '}
          {enabledFeatureFlags.includes('F-BRNDCHNG-24082021') ? 'EnGig' : 'Laminar'} to use to generate Project
          Activities:
        </div>
      </section>
      <section className="defineActivity-modal-table-container">
        <table className="table defineActivity-table-striped">
          <thead className="defineActivity-thead">
            <tr className="defineActivity-table-row">
              {headers.map((headCell, index) => (
                <th key={index} className="defineActivity-modal-table-header">
                  {headCell.value}
                  {/* <img alt="sortArrows" className="defineActivity-modal-header-image" src={ICONS.SORT_ARROWS} /> */}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="defineActivity-tbody">
            <WbsCriteriaRow dosActivityData={props.dosActivityData} />
          </tbody>
        </table>
      </section>
    </>
  );
}

export const mapStateToProps = (state) => ({
  dosActivityData: get(state, 'projActivitiesInformation.dosActivityData'),
});

export const mapDispatchToProps = (dispatch) => ({
  getProjActivityByDiscipline: (data) => dispatch(projActivitiesActions.getProjActivityByDiscipline(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WbsCriteriaTable);
