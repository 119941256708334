import React from 'react';
import Select from 'react-select';

const PageNumbers = (props) => {
  const { pageCount, onPageChange, currentPage, pageSelectLabel = 'Page:' } = props;
  const pages = [];
  for (let i = 0; i < pageCount; i++) {
    pages.push({ value: i, label: i + 1 });
  }

  return (
    <>
      <span className="page-number-label">{pageSelectLabel}</span>
      <Select
        menuPlacement="top"
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 3, marginTop: 0 }),
        }}
        className="page-number-dropdown"
        options={pages}
        value={pages.filter((option) => option.value === currentPage)}
        onChange={(selected) => {
          onPageChange({ selected: selected.value });
        }}
      />
    </>
  );
};

export default PageNumbers;
