import { masterDataManagerActionTypes } from '../actions';

const InitialState = {};

export const masterDataManagerReducer = (state = InitialState, action) => {
  switch (action.type) {
    case masterDataManagerActionTypes.GET_MASTER_DATA_TABLE_STATUS_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_DATA_TABLE_STATUS_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.CHANGE_MASTER_DATA_TABLE_STATUS_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.CHANGE_MASTER_DATA_TABLE_STATUS_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_DATA_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_DATA_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_DATA_QA_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_DATA_QA_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.SAVE_MASTER_DATA_TABLE_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.SEND_MASTER_TO_QA_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.SEND_MASTER_TO_QA_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.CLEAR_MASTER_DATA_TABLE:
      return { ...state, masterTableData: {} };

    case masterDataManagerActionTypes.GET_MASTER_PREVIEW_DATA_SUCCESS:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_PREVIEW_DATA_FAILURE:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.PULL_RELATED_GIGS_SUCCESS: {
      return { ...state, relatedGigs: { ...action.payload.relatedGigs } };
    }

    case masterDataManagerActionTypes.PULL_RELATED_GIGS_FAILURE:
      return { ...state, relatedGigs: {} };
    // case masterDataManagerActionTypes.GET_CHARGECODE_BY_FRAGNET_ID_SUCCESS:
    //   return { ...state, gigsChargeCodeData: action.payload };
    // case masterDataManagerActionTypes.GET_CHARGECODE_BY_FRAGNET_ID_FAILURE:
    //   return { ...state, gigsChargeCodeData: action.payload };

    // Master Data QA
    case masterDataManagerActionTypes.GET_MASTER_QA_COUNT_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.GET_MASTER_QA_COUNT_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_MASTER_QA_EDIT_MODE:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.GET_MASTER_QA_EDIT_MODE_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.GET_MASTER_QA_EDIT_MODE_FAILURE:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_QA_EDIT_MODE:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_QA_EDIT_MODE_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_QA_EDIT_MODE_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.RESET_MASTER_QA_CHANGES:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.RESET_MASTER_QA_CHANGES_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.RESET_MASTER_QA_CHANGES_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.SAVE_MASTER_QA_CHANGES:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_QA_CHANGES_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_QA_CHANGES_FAILURE:
      return { ...state, ...action.payload };

    case masterDataManagerActionTypes.GET_CHARGECODE_BY_FRAGNET_ID_SUCCESS:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.GET_CHARGECODE_BY_FRAGNET_ID_FAILURE:
      return { ...state, ...action.payload };
    case masterDataManagerActionTypes.SAVE_MASTER_DATA_TABLE_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
