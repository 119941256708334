import { projectDashboardActionTypes } from '../actions';

const InitialState = {};

export const projectDashboardReducer = (state = InitialState, action) => {
  switch (action.type) {
    case projectDashboardActionTypes.GET_ITERATIONS:
      return { ...state };
    case projectDashboardActionTypes.GET_ITERATIONS_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_ITERATIONS_FAILURE:
      return { ...state, ...action.payload };
    // Quick Links widget
    case projectDashboardActionTypes.GET_QUICKLINKS:
      return { ...state };
    case projectDashboardActionTypes.GET_QUICKLINKS_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_QUICKLINKS_FAILURE:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.SAVE_QUICKLINKS:
      return { ...state };
    case projectDashboardActionTypes.SAVE_QUICKLINKS_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.SAVE_QUICKLINKS_FAILURE:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_DROPDOWN_QUICKLINKS:
      return { ...state };
    case projectDashboardActionTypes.GET_DROPDOWN_QUICKLINKS_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_DROPDOWN_QUICKLINKS_FAILURE:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_PROJECT_TEAM_USERS_FAILURE:
      return { ...state, projectTeamUsers: action.payload };
    case projectDashboardActionTypes.GET_PROJECT_TEAM_USERS_SUCCESS:
      return { ...state, projectTeamUsers: action.payload };
    case projectDashboardActionTypes.GET_PROJECT_TEAM_MENUITEMS:
      return { ...state, projectTeamMenuItems: { ...action.data } };

    // project image upload widget
    case projectDashboardActionTypes.GET_PROJECT_DASHBOARD_WIDGETS_DATA_SUCCESS:
      return { ...state, projectDashboardWidgetsData: { ...action.payload } };

    // Project Info

    case projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_DROPDOWN_FAILURE:
      return { ...state, ...action.payload };

    case projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_ENGEXEC_PROJECT_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };

    case projectDashboardActionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.SAVE_ENGEXEC_PROJECT_INFO_FORM_FAILURE:
      return { ...state, ...action.payload };

    // WBS Info

    case projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_DROPDOWN_FAILURE:
      return { ...state, ...action.payload };

    case projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.GET_ENGEXEC_WBS_INFO_TABLE_FAILURE:
      return { ...state, ...action.payload };

    case projectDashboardActionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE_SUCCESS:
      return { ...state, ...action.payload };
    case projectDashboardActionTypes.SAVE_ENGEXEC_WBS_INFO_TABLE_FAILURE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
