export const actionTypes = {
  GET_ASSIGN_LEADS_INFO_FORM: 'GET_ASSIGN_LEADS_INFO_FORM',
  GET_ASSIGN_LEADS_INFO_SUCCESS: 'GET_ASSIGN_LEADS_INFO_SUCCESS',
  GET_ASSIGN_LEADS_INFO_FAILURE: 'GET_ASSIGN_LEADS_INFO_FAILURE',
  GET_ASSIGN_LEADS_USERS: 'GET_ASSIGN_LEADS_USERS',
  FETCH_INITIAL_USERS: 'FETCH_INITIAL_USERS',
  GET_ASSIGN_ROLES: 'GET_ASSIGN_ROLES',
  GET_ASSIGN_ROLES_SUCCESS: 'GET_ASSIGN_ROLES-SUCCESS',
  GET_ASSIGN_ROLES_FAILURE: 'GET_ASSIGN_ROLES_FAILURE',
  SAVE_ASSIGN_ROLES: 'SAVE_ASSIGN_ROLES',
  SAVE_ASSIGN_ROLES_SUCCESS: 'SAVE_ASSIGN_ROLES_SUCCESS',
  SAVE_ASSIGN_ROLES_FAILED: 'SAVE_ASSIGN_ROLES_FAILED',
  GET_ADD_ROLE: 'GET_ADD_ROLE',
  GET_ADD_ROLES_SUCCESS: 'GET_ADD_ROLES_SUCCESS',
  GET_ADD_ROLES_FAILURE: 'GET_ADD_ROLES_FAILURE',
  GET_ADD_ROLE_MASTER: 'GET_ADD_ROLE_MASTER',
  GET_ADD_ROLES_MASTER_SUCCESS: 'GET_ADD_ROLES_MASTER_SUCCESS',
  GET_ADD_ROLES_MASTER_FAILURE: 'GET_ADD_ROLES_MASTER_FAILURE',
  SAVE_ROLE_DATA: 'SAVE_ROLE_DATA',
  SAVE_ROLE_DATA_SUCCESS: 'SAVE_ROLE_DATA_SUCCESS',
  SAVE_ROLE_DATA_FAILURE: 'SAVE_ROLE_DATA_FAILURE',
  GET_ASSIGN_LEADS_INITIAL: 'GET_ASSIGN_LEADS_INITIAL',
};

export const actions = {
  getassignLeadsInfoForm: (data) => ({
    type: actionTypes.GET_ASSIGN_LEADS_INFO_FORM,
    data,
  }),

  getassignLeadsInitialData: (data) => ({
    type: actionTypes.GET_ASSIGN_LEADS_INITIAL,
    data,
  }),
  getassignLeadsInfoFormSucceeded: (content) => ({
    type: actionTypes.GET_ASSIGN_LEADS_INFO_SUCCESS,
    payload: content,
  }),
  getassignLeadsInfoFormFailed: (content) => ({
    type: actionTypes.GET_ASSIGN_LEADS_INFO_FAILURE,
    payload: content,
  }),
  getassignLeadsUsersSuccess: (content) => ({
    type: actionTypes.GET_ASSIGN_LEADS_USERS,
    payload: content,
  }),
  getassignLeadsUsersFailed: (content) => ({
    type: actionTypes.GET_ASSIGN_LEADS_USERS,
    payload: content,
  }),
  getassignRoles: (data) => ({
    type: actionTypes.GET_ASSIGN_ROLES,
    data,
  }),
  getassignRolesSucceeded: (content) => ({
    type: actionTypes.GET_ASSIGN_ROLES_SUCCESS,
    payload: content,
  }),
  getassignRolesFailed: (content) => ({
    type: actionTypes.GET_ASSIGN_ROLES_FAILURE,
    payload: content,
  }),
  saveassignRoles: (data) => ({
    type: actionTypes.SAVE_ASSIGN_ROLES,
    data,
  }),
  saveassignRolesSuccess: (content) => ({
    type: actionTypes.SAVE_ASSIGN_ROLES_SUCCESS,
    payload: content,
  }),
  saveassignRolesFailed: (content) => ({
    type: actionTypes.SAVE_ASSIGN_ROLES_FAILED,
    payload: content,
  }),
  fetchInitialUsers: (content) => ({
    type: actionTypes.FETCH_INITIAL_USERS,
    payload: content,
  }),
  getAddRole: (content) => ({
    type: actionTypes.GET_ADD_ROLE,
    payload: content,
  }),
  getAddRoleSuccess: (content) => ({
    type: actionTypes.GET_ADD_ROLES_SUCCESS,
    payload: content,
  }),
  getAddRoleFailure: (content) => ({
    type: actionTypes.GET_ADD_ROLES_FAILURE,
    payload: content,
  }),

  getAddRoleMaster: (content) => ({
    type: actionTypes.GET_ADD_ROLE_MASTER,
    payload: content,
  }),
  getAddRoleMasterSuccess: (content) => ({
    type: actionTypes.GET_ADD_ROLES_MASTER_SUCCESS,
    payload: content,
  }),
  getAddRoleMasterFailure: (content) => ({
    type: actionTypes.GET_ADD_ROLES_MASTER_FAILURE,
    payload: content,
  }),

  saveRoleFields: (content) => ({
    type: actionTypes.SAVE_ROLE_DATA,
    payload: content,
  }),
  saveRoleFieldsSuccess: (content) => ({
    type: actionTypes.SAVE_ROLE_DATA_SUCCESS,
    payload: content,
  }),
  saveRoleFieldsFailure: (content) => ({
    type: actionTypes.SAVE_ROLE_DATA_FAILURE,
    payload: content,
  }),
};
